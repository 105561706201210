import {
  CrewTeamType,
  ScheduleType,
  EmployeeReportType,
} from "../payrollLiveTypes";
import { JobsiteType } from "src/components/SidebarPages/FleetMaintenanceView/types";
import { withinRadius } from "../../Payroll/Tabs/Activity/components/payrollActivityModalData";
import findPolygonRange from "../../Payroll/Tabs/DEG/components/modalComponents/utils/findPolygonRange";

type RangeType = {
  inRange: boolean;
  distanceInFeet: number;
};

type CoordinatesMatchProps = {
  crewTeams: Array<CrewTeamType>;
  employees: Array<EmployeeReportType>;
  jobs: Array<JobsiteType>;
  schedules: Array<ScheduleType>;
};

type CoordinatesMatchResponse = {
  matchedEmployees: Array<EmployeeReportType>;
  unmatchedReports: Array<EmployeeReportType>;
  jobsitesMatch: Array<JobsiteType | ScheduleType>;
};

export function coordinatesMatch({
  jobs,
  crewTeams,
  employees,
  schedules,
}: CoordinatesMatchProps): CoordinatesMatchResponse {
  let jobsitesMatch = [];
  let unmatchedReports = [];
  let matchedEmployees = [];

  for (let i = 0; i < employees.length; i++) {
    const emp = employees[i];
    let jobMatch: JobsiteType | ScheduleType | undefined;

    for (let j = 0; j < schedules.length; j++) {
      const schedule = schedules[j];
      const geoInfo = schedule?.geoFenceInfo;
      const empAssigned =
        schedule?.employeesAssigned?.length &&
        schedule?.employeesAssigned.findIndex(
          (el) => Number(el?.["ID Number"]) === Number(emp?.employeeNumber)
        );

      // #region employee assigned to this schedule by excel sheet
      if (empAssigned > -1) {
        let range: RangeType;
        jobMatch = schedule;

        if (!!geoInfo?.length) {
          range = findPolygonRange({
            points: geoInfo[0]?.geoFenceInfo,
            position: emp?.punchCoordinates,
            radius: +schedule?.radius || 300,
          });
        }

        if (!range?.inRange) {
          const distanceFromJob = withinRadius(
            schedule?.addressPosition,
            emp?.punchCoordinates,
            +schedule.radius || 300
          );
          range = {
            inRange: distanceFromJob?.withinRange,
            distanceInFeet: distanceFromJob.distanceInFeet,
          };
        }

        let foreman = false;
        let teamIndex = crewTeams.findIndex((el) => {
          const employeeNumber = (el?.crewForeman?.employeeId || "").split(
            "-"
          )?.[1];

          const isForeman =
            Number(employeeNumber) === Number(emp?.employeeNumber);

          const isMember =
            el.crewMembers.findIndex(
              (mem) =>
                Number((mem?.employeeId || "").split("-")?.[1]) ===
                Number(emp?.employeeNumber)
            ) > -1;
          if (isForeman) {
            foreman = isForeman;
          }
          return isForeman || isMember;
        });
        const matchEmpData = {
          ...emp,
          isForeman: foreman,
          color: !range?.inRange && emp?.liveStatus === "In" ? "#e9c466" : null,
          distance: range?.distanceInFeet,
          projectId: jobMatch?.projectId,
          crewTeamId: crewTeams?.[teamIndex]?.crewTeamId,
          crewTeamName: crewTeams?.[teamIndex]?.crewTeamName || "No Team",
        };

        const isMultipleMatch = matchedEmployees.findIndex(
          (mch) => mch?.employeeNumber === emp?.employeeNumber
        );
        if (isMultipleMatch > -1) {
          const empMatched = matchedEmployees[isMultipleMatch];

          if (empMatched?.distance > range?.distanceInFeet) {
            matchedEmployees[isMultipleMatch] = matchEmpData;
          }
        } else {
          matchedEmployees.push(matchEmpData);
        }
        range = undefined;
      } else if (!schedule?.employeesAssigned?.length) {
        // #region schedules outside of excel sheet
        let range: RangeType;
        if (!!geoInfo?.length) {
          range = findPolygonRange({
            points: geoInfo[0]?.geoFenceInfo,
            position: emp?.punchCoordinates,
            radius: +schedule?.radius || 300,
          });
        }

        if (!range?.inRange) {
          const distanceFromJob = withinRadius(
            schedule?.addressPosition,
            emp?.punchCoordinates,
            +schedule?.radius || 300
          );
          range = {
            inRange: distanceFromJob?.withinRange,
            distanceInFeet: distanceFromJob.distanceInFeet,
          };
        }
        if (range.inRange) {
          let foreman = false;
          let teamIndex = crewTeams.findIndex((el) => {
            const employeeNumber = (el?.crewForeman?.employeeId || "").split(
              "-"
            )?.[1];

            const isForeman =
              Number(employeeNumber) === Number(emp?.employeeNumber);

            const isMember =
              el.crewMembers.findIndex(
                (mem) =>
                  Number((mem?.employeeId || "").split("-")?.[1]) ===
                  Number(emp?.employeeNumber)
              ) > -1;
            if (isForeman) {
              foreman = isForeman;
            }
            return isForeman || isMember;
          });

          matchedEmployees.push({
            ...emp,
            isForeman: foreman,
            color:
              !range?.inRange && emp.liveStatus === "In" ? "#e9c466" : null,
            distance: range?.distanceInFeet,
            projectId: schedule?.projectId,
            crewTeamId: crewTeams?.[teamIndex]?.crewTeamId,
            crewTeamName: crewTeams?.[teamIndex]?.crewTeamName || "No Team",
          });
          jobMatch = schedule;
        }
      }
    }

    for (let i = 0; i < jobs.length; i++) {
      const jobsite = jobs[i];
      const geoInfo = jobsite?.geoFenceInfo;
      const empAssigned =
        jobsite?.employeesAssigned?.length &&
        jobsite?.employeesAssigned.findIndex(
          (el) => Number(el?.["ID Number"]) === Number(emp?.employeeNumber)
        );

      if (empAssigned > -1) {
        let range: RangeType;
        jobMatch = jobsite;

        if (!!geoInfo?.length) {
          range = findPolygonRange({
            points: geoInfo[0]?.geoFenceInfo,
            position: emp?.punchCoordinates,
            radius: +jobsite?.locationRadius || 300,
          });
        }

        if (!range?.inRange) {
          const distanceFromJob = withinRadius(
            jobsite?.addressPosition,
            emp?.punchCoordinates,
            +jobsite?.locationRadius || 300
          );
          range = {
            inRange: distanceFromJob?.withinRange,
            distanceInFeet: distanceFromJob.distanceInFeet,
          };
        }
        let foreman = false;
        let teamIndex = crewTeams.findIndex((el) => {
          const employeeNumber = (el?.crewForeman?.employeeId || "").split(
            "-"
          )?.[1];

          const isForeman =
            Number(employeeNumber) === Number(emp?.employeeNumber);

          const isMember =
            el.crewMembers.findIndex(
              (mem) =>
                Number((mem?.employeeId || "").split("-")?.[1]) ===
                Number(emp?.employeeNumber)
            ) > -1;
          if (isForeman) {
            foreman = isForeman;
          }
          return isForeman || isMember;
        });

        const matchEmpData = {
          ...emp,
          isForeman: foreman,
          color: !range?.inRange && emp.liveStatus === "In" ? "#e9c466" : null,
          distance: range?.distanceInFeet,
          projectId: jobsite?.projectId,
          crewTeamId: crewTeams?.[teamIndex]?.crewTeamId,
          crewTeamName: crewTeams?.[teamIndex]?.crewTeamName || "No Team",
        };

        const isMultipleMatch = matchedEmployees.findIndex(
          (mch) => mch?.employeeNumber === emp?.employeeNumber
        );
        if (isMultipleMatch > -1) {
          const empMatched = matchedEmployees[isMultipleMatch];

          if (empMatched?.distance > range?.distanceInFeet) {
            matchedEmployees[isMultipleMatch] = matchEmpData;
          }
        } else {
          matchedEmployees.push(matchEmpData);
        }
        jobsitesMatch.push(jobMatch);
        // range = undefined;
      } else if (!jobsite?.employeesAssigned?.length) {
        // #region jobsites outside of excel sheet
        let range: RangeType;
        if (!!geoInfo?.length) {
          range = findPolygonRange({
            points: geoInfo[0]?.geoFenceInfo,
            position: emp?.punchCoordinates,
            radius: +jobsite?.locationRadius || 300,
          });
        }

        if (!range?.inRange) {
          const distanceFromJob = withinRadius(
            jobsite?.addressPosition,
            emp?.punchCoordinates,
            +jobsite?.locationRadius || 300
          );
          range = {
            inRange: distanceFromJob?.withinRange,
            distanceInFeet: distanceFromJob.distanceInFeet,
          };
        }

        if (range.inRange) {
          let foreman = false;
          let teamIndex = crewTeams.findIndex((el) => {
            const employeeNumber = (el?.crewForeman?.employeeId || "").split(
              "-"
            )?.[1];

            const isForeman =
              Number(employeeNumber) === Number(emp?.employeeNumber);

            const isMember =
              el.crewMembers.findIndex(
                (mem) =>
                  Number((mem?.employeeId || "").split("-")?.[1]) ===
                  Number(emp?.employeeNumber)
              ) > -1;
            if (isForeman) {
              foreman = isForeman;
            }
            return isForeman || isMember;
          });

          matchedEmployees.push({
            ...emp,
            isForeman: foreman,
            color:
              !range?.inRange && emp?.liveStatus === "In" ? "#e9c466" : null,
            distance: range?.distanceInFeet,
            projectId: jobsite?.projectId,
            crewTeamId: crewTeams?.[teamIndex]?.crewTeamId,
            crewTeamName: crewTeams?.[teamIndex]?.crewTeamName || "No Team",
          });
        }
      }
    }
  }

  return { jobsitesMatch, matchedEmployees, unmatchedReports };
}
