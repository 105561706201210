import { SearchOutlined } from "@ant-design/icons";
import { InputComponent } from "../../../../../../../SidebarPages/Fleet/components";
import { InfoText } from "../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { CardTemplate } from "../../../../Management/EditUser/components";
import NotificationPermissions from "./NotificationPermissions";
import RoleHeader from "./RoleHeader";
import RolePermissions from "./RolePermissions";
import { useMemo, useState } from "react";
import { selectedTabInfoText } from "../../../../Management/EditUser/components/UserAccessWrites/utils";
import { GoogleDrivePermissions } from "../../../../Management/EditUser/components/UserAccessWrites/components";

function RolesAccessWrites({
  roleData,

  accessConfiguration,
  setAccessConfiguration,
  notificationSettings,
  isDarkMode,
  currentRole,

  form,
  gDriveCredentials,
  accessToken,
  currentUser,
  disabled = false,
}) {
  const [selectedTab, setSelectedTab] = useState("Sidebar");

  const [searchRouteConfigs, setSearchRouteConfigs] = useState("");

  const infoText = useMemo(() => {
    return selectedTabInfoText({
      selectedTab,
      nameOfUser: currentRole,
    });
  }, [selectedTab]);

  return (
    <div className="rightContainer">
      <CardTemplate
        {...{
          title: `Access Management / ${currentRole} Role`,
          isDarkMode,
          className: "collapsedContainer",
          // className: "roles-access-writes-container",
        }}
      >
        <RoleHeader
          {...{ roleData, selectedTab, setSelectedTab, setSearchRouteConfigs }}
        />
        <div
          className={`info-and-search ${
            selectedTab === "Notification Preferences" && "only-info"
          }`}
        >
          <InfoText
            {...{
              isDarkMode,
              text: infoText,
              style: { maxWidth: "unset" },
              className: "user-access-info-text",
            }}
          />
          {selectedTab !== "Notification Preferences" && (
            <div className="config-search">
              <InputComponent
                placeholder="Search Configuration..."
                value={searchRouteConfigs}
                onChange={(e) => setSearchRouteConfigs(e.target.value)}
                prefix={<SearchOutlined />}
              />
            </div>
          )}
        </div>
        {selectedTab !== "Notification Preferences" &&
        selectedTab !== "Drive Access Rights" ? (
          <>
            <div className="user-access-list-header">
              <div>Route Title</div>
              <div>Access Type</div>
            </div>

            <RolePermissions
              {...{
                selectedTab,
                accessConfiguration,
                setAccessConfiguration,
                searchRouteConfigs,
                disabled,
              }}
            />
          </>
        ) : selectedTab === "Drive Access Rights" ? (
          <GoogleDrivePermissions
            {...{
              form,
              gDriveCredentials,
              accessToken,
              currentUser: null,
              searchRouteConfigs,
              isDarkMode,
            }}
          />
        ) : (
          <NotificationPermissions
            {...{
              accessConfiguration,
              notificationSettings,
              setAccessConfiguration,
            }}
          />
        )}
      </CardTemplate>
    </div>
  );
}

export default RolesAccessWrites;
