import { batchUpdateItems } from "../../../../../utils";
import { apiRoutes } from "../../../../SidebarPages/Fleet/utils";

export const checkIsValidIp = (ip) => {
  // Regex pattern for IPv4
  let ipv4Pattern =
    /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$/;

  // Regex pattern for IPv6
  let ipv6Pattern = /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/;

  return ipv4Pattern.test(ip) || ipv6Pattern.test(ip);
};

export function updateIpAddressForAllUsers(userConfiguration, isEnabled) {
  const updateData = userConfiguration.allUsers.Items.reduce(
    (acc, user) => ({
      ...acc,
      [user.identityId]: user.ipAddressWhitelist?.list.reduce(
        (acc, item, index) => ({
          ...acc,
          [`ipAddressWhitelist.list[${index}].isEnabled`]: isEnabled,
        }),
        {}
      ),
    }),
    {}
  );

  console.log({ updateData });

  batchUpdateItems({
    tableName: apiRoutes.userConfiguration,
    conditionKey: "identityId",
    updateData,
  });
}

const notificationActions = {
  create: "created a new",
  delete: "deleted a",
  edit: "edited a",
  toggle: "toggle",
};

export const buildedNotificationBody = (data) => {
  const proceedAction = notificationActions[data.actionType];
  const proceedRecordName = data?.recordName || "";

  const toReturn = `has ${
    proceedAction === "toggle"
      ? `${Object.values(data.currentData)[0] ? "enabled" : "disabled"} all`
      : proceedAction
  } Ipv4 ${proceedRecordName}`;

  return toReturn;
};

const sampleObjectOfIpAddress = {
  ipv4: "string",
  devices: [
    {
      deviceName: "string",
      deviceId: "string",
      deviceLabel: "string",
    },
  ],
  description: "string",
  addedAt: "number",
  addedBy: {
    name: "string",
    uid: "string",
  },
  isEnabled: "boolean",
  id: "string",
};
