import React, { useEffect, useState } from "react";
import { Badge, Modal, Popover, message } from "antd";
import { InputComponent } from "../../../../../../SidebarPages/Fleet/components/index";
import "./FloorsModal.scss";
import { MondayButton } from "../../../../../../commonComponents/index";
import { colors, repeatelem } from "../../../SchedulingFirstPage/helperData";
import { PercentageIcon } from "../../../../../../SidebarPages/BasePage/src";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import { CloseModal } from "../../../../../../SidebarPages/Scheduling/GanttChart/assets/OtherIcons";
import { TickIcon } from "../../../../../../pages/Settings/settingsComponents/Roles/src";
import { updateDocumentTitle } from "../../../../../../../utils";
import { useSelector } from "react-redux";
import { driveApi } from "../../../../../../../integrations/DriveRequest";
import { fetchProgressChangesForemenFiles } from "../../../../../../pages/ScheduleProgress/Components/ForemanProject/ProgressChanges/fetchProgressChangesForemenFiles";
import { DocUploader } from "../../../../../../SidebarPages/Documentation/View/components/DocumentationModal/components";
const FloorsModal = ({
  isModalVisible,
  setIsModalVisible,
  jump_state,
  scheduleDays,
  onChangeProgressFloor,
  onMatchForemanProgress,
  hoistDriveIds,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { list } = driveApi({ accessToken });
  const [allForemenFiles, setAllForemenFiles] = useState({});
  const [open, setOpen] = useState(false);

  const handleCloseModal = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setIsModalVisible(false);
  };

  const daysToFloors = jump_state?.progressByDay?.filter((el) =>
    scheduleDays?.some((a) => a?.id === el?.day)
  );

  const sortedData = daysToFloors
    ?.map?.((day) => ({
      ...day,
      date: scheduleDays?.find((d) => d?.id === day?.day)?.startDate,
      dayNr: scheduleDays?.find((d) => d?.id === day?.day)?.day,
    }))
    ?.sort((a, b) => dayjsNY(a?.date).diff(dayjsNY(b?.date)));

  console.log("sortedData", { sortedData });

  async function getForemenFiles() {
    let obj = {};
    sortedData?.map((dayId, index) => {
      obj[dayId?.day] = {};
      const folderName = dayjsNY(dayId?.date).format("MM/DD/YYYY");
      const allJumps = sortedData?.[index]?.progress || [];
      const folderId = hoistDriveIds?.[folderName];
      allJumps?.map(async (jump, i) => {
        const floor = jump?.floor;
        const foremanFiles = jump?.files || {};
        let uploadedFiles = [];
        if (folderId && Object.keys(foremanFiles).length) {
          uploadedFiles = await fetchProgressChangesForemenFiles({
            list,
            all: true,
            folderId,
            foremanFiles,
            userConfiguration,
          });
        }
        obj[dayId?.day][floor] = uploadedFiles;
      });
    });
    setAllForemenFiles(obj);
  }

  useEffect(() => {
    getForemenFiles();
  }, []);

  return (
    <div>
      <Modal
        className={`floors-modal ${isDarkMode && "floors-modal-dark"}`}
        title="Progress for each floor"
        afterOpenChange={(event) => {
          event && updateDocumentTitle({ newTitle: "Progress for each floor" });
        }}
        open={isModalVisible}
        centered
        onCancel={handleCloseModal}
        footer={
          <>
            <MondayButton
              onClick={handleCloseModal}
              {...{
                className: "mondayButtonRed",
                Icon: <CloseModal />,
              }}
            >
              Close
            </MondayButton>
            <MondayButton
              onClick={() => {
                handleCloseModal();
                message.success("Progress saved successfully");
              }}
              {...{
                Icon: <TickIcon />,
              }}
            >
              Save
            </MondayButton>
          </>
        }
        closeIcon={<XIcon />}
        width="fit-content"
        height="fit-content"
      >
        <div
          className="mainContainerFloorsModal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {sortedData?.map?.((dayId, index) => {
            const allJumps = sortedData?.[index]?.progress || [];
            console.log("allJumps", { allJumps });

            return (
              <div className="floors-modal-container-data">
                <div className="header-container-floors-modal">
                  <b>
                    <Badge
                      text={"DAY " + dayId?.dayNr}
                      color={
                        repeatelem(colors, daysToFloors?.length - 1)?.[index]
                      }
                    />
                  </b>
                  <span>{dayjsNY(dayId?.date).format("MM/DD/YYYY")}</span>
                </div>
                <div className="allJumpsContainer">
                  {allJumps?.map((s, i) => {
                    const filesLength =
                      Object.values(s?.files || {})?.flat()?.length || 0;
                    const hasForemanProgress = !!s?.foreman || !!filesLength;
                    return (
                      <div className="inputFloors">
                        <div
                          style={{
                            display: "flex",
                            gap: 10,
                            alignItems: "baseline",
                          }}
                        >
                          <span>{"Floor" + " " + s?.floor}</span>
                          {hasForemanProgress && (
                            <span
                              style={{
                                display: "flex",
                                gap: 10,
                                alignItems: "center",
                              }}
                            >
                              <Popover
                                trigger={"click"}
                                content={
                                  <div>
                                    Progress by {s?.foreman?.nameOfForeman}:{" "}
                                    <b
                                      onClick={() =>
                                        onMatchForemanProgress(
                                          dayId,
                                          s?.floor,
                                          s?.foreman?.progress
                                        )
                                      }
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      {s?.foreman?.["progress"]}
                                    </b>
                                  </div>
                                }
                              >
                                <Badge
                                  color="rgb(122, 193, 77)"
                                  text="Foreman"
                                  style={{ marginLeft: 10, cursor: "pointer" }}
                                />
                              </Popover>
                              {!!filesLength && (
                                <span
                                  style={{
                                    color: "#1264a3",
                                    cursor: "pointer",
                                    fontWeight: 600,
                                  }}
                                  onClick={() =>
                                    setOpen({
                                      day: dayId?.day,
                                      floor: s?.floor,
                                    })
                                  }
                                >
                                  {filesLength} File{filesLength > 1 ? "s" : ""}
                                </span>
                              )}
                            </span>
                          )}
                        </div>
                        <InputComponent
                          className="input-floors-modal"
                          type="input"
                          value={s?.progress}
                          onChange={(e) =>
                            onChangeProgressFloor(e, index, s?.floor)
                          }
                          suffixIcon={<PercentageIcon />}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        {!!open && (
          <Modal
            {...{
              open: !!open,
              onCancel: () => setOpen(false),
              width: 500,
              centered: true,
              footer: null,
              closeIcon: <XIcon />,
              title: "Foreman Files",
              className: "progress-changes-modal service-file-modal",
            }}
          >
            <DocUploader
              {...{
                uploadedFiles:
                  allForemenFiles?.[open?.day]?.[open?.floor] || [],
                onlyUploadedFiles: true,
                responsiveWidth: 10,
                disabled: true,
              }}
            />
          </Modal>
        )}
      </Modal>
    </div>
  );
};

export default FloorsModal;
