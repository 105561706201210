import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { Modal, Form, message, Tour } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";

import {
  footerButtons,
  crewTeamModalFields,
  formatMembersOptions,
} from "./crewTeamModalData";
import {
  lazyFetch,
  fetchAllData,
  updateDocumentTitle,
} from "../../../../../../utils";
import {
  MondayButton,
  WarningModal,
  MultiLevelTreeLogs,
} from "../../../../../commonComponents";
import { TickIcon } from "../../Roles/src";
import { useEditLogs } from "../../../../../../hooks";
import { identicalCrewMembers } from "../utils/identicalCrewMembers";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { useMultipleRefs } from "../../../../../../hooks/useMultipleRefs";
import { WarningTriangle } from "../../../../../SidebarPages/DynamicView/src";
import { RenderDynamicComponents } from "../../../../../Header/forms/Components";
import { broadcastArrayToString } from "../../../../../../utils/broadcastArrayToString";
import CustomTransfer from "../../../../../commonComponents/CustomTransfer/CustomTransfer";
import broadcastNotification from "../../../../../../helpers/controllers/broadcastNotification";
import CustomModalHeader from "../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { CrewsHeader } from "../../../../Settings/settingsComponents/Crews/Components/CrewsHeader/CrewsHeader";

import "./CrewTeamModal.scss";

const CrewTeamModal = ({
  open,
  rowData,
  onCancel,
  rowToEdit,
  setRowData,
  newTeamModal,
}) => {
  const [{ isDarkMode }, { userConfiguration }] = useSelector((state) => [
    state.darkMode,
    state.userConfig,
  ]);

  const [members, setMembers] = useState([]);
  const [editLogs, setEditLogs] = useState([]);
  const [changes, setChanges] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [tourOpen, setTourOpen] = useState(false);
  const [addRef, cancelRef, saveRef] = useMultipleRefs(3);
  const [foremanMembers, setForemanMembers] = useState([]);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [newMemberModal, setNewMemberModal] = useState(false);
  const [selectedForeman, setSelectedForeman] = useState(false);
  const [logsModalVisible, setLogsModalVisible] = useState(false);
  const [closeModalWarning, setCloseModalWarning] = useState(false);

  const [form] = Form.useForm();
  const { saveAddedLogs } = useEditLogs();

  const steps = [
    {
      title: "Add Member",
      description: "Here you can create a new member",
      target: () => addRef.current,
    },
    {
      title: "Cancel",
      description: "Cancel actions to create a new crew team",
      target: () => cancelRef.current,
    },
    {
      title: "Save",
      description: "Create a new crew team",
      target: () => saveRef.current,
    },
  ];

  const fieldsJSON = useMemo(() => {
    return crewTeamModalFields({
      foremanMembers,
      members,
      companies,
      selectedForeman,
      setSelectedForeman,
    });
  }, [foremanMembers, members, companies, selectedForeman]);

  const broadcastHelperFunction = ({
    actionType,
    recordId,
    recordName,
    common2,
  }) => {
    const actions = {
      ["Edit"]: "onEditCrewTeam",
      ["Create"]: "onCreateCrewTeam",
      ["Delete"]: "onDeleteCrewTeam",
    };
    if (!actions?.[actionType]) {
      console.error(
        `${actionType} does not exist as broadcast action for Crew Teams!`
      );
      return;
    }

    broadcastNotification(
      "106",
      actions[actionType],
      [
        {
          common: userConfiguration.nameOfUser,
          ...(actionType === "Edit" && { common2 }),
          commonNext: recordName,
        },
        {
          userName: userConfiguration.nameOfUser,
          currentUser: userConfiguration.cognitoUserId,
          recordId,
        },
      ],
      "/"
    );
  };

  function generateNewLog(actionType, currentData, previousData, updatedKeys) {
    const broadCastChanges = [];

    const newLog = {
      recordId: "",
      recordName: "",
      topic: "Crew Teams",
      actionType,
      category: "Crew Teams",
      currentData,
      label: "",
      previousData,
      updatedKeys,
    };

    if (rowToEdit?.crewTeamId) {
      Object.assign(newLog, { recordId: rowToEdit?.crewTeamId });
    }

    saveAddedLogs(newLog);

    previousData.company !== currentData.company &&
      broadCastChanges.push("Company");
    previousData.crewTeamName !== currentData.crewTeamName &&
      broadCastChanges.push("Team Name");
    previousData.crewForeman !== currentData.crewForeman &&
      broadCastChanges.push("Foreman");
    !identicalCrewMembers(previousData.crewMembers, currentData.crewMembers) &&
      broadCastChanges.push("Members");

    broadcastHelperFunction({
      actionType,
      recordId: currentData?.crewTeamId,
      recordName:
        actionType === "Create"
          ? currentData?.crewTeamName
          : previousData?.crewTeamName,
      common2: broadcastArrayToString(broadCastChanges),
    });
  }

  function resetFieldsOnCancel() {
    form.resetFields();
    onCancel();
    updateDocumentTitle(); // reset document title to "Lead Manager";
  }

  function bodyObj() {
    const formData = form.getFieldsValue();
    return {
      crewTeamId: rowToEdit ? rowToEdit.crewTeamId : uuidv4(),
      ...formData,
      crewForeman: selectedForeman,
      crewMembers: members.flatMap((member) => {
        if (selectedMembers.includes(member?.crewId)) {
          return {
            crewName: member.crewName,
            crewId: member.crewId,
            employeeId: member.employeeId,
          };
        } else {
          return [];
        }
      }),
      createdAt: Date.now(),
      createdBy: {
        nameOfUser: userConfiguration.nameOfUser,
        identityId: userConfiguration.identityId,
      },
    };
  }

  function onSave() {
    form.validateFields().then(() => {
      if (!!rowToEdit) {
        const exists = rowData
          .filter((row) => row.crewTeamId !== rowToEdit.crewTeamId)
          .find(
            ({ crewTeamName }) =>
              crewTeamName.toLowerCase() ===
              form.getFieldValue("crewTeamName").toLowerCase()
          );
        if (!!exists) {
          return message.error(`${exists.crewTeamName} already exists!`);
        }

        const { crewTeamId, ...editObject } = bodyObj();

        API.put("crewTeams", `/crewTeams/${rowToEdit?.crewTeamId}`, {
          body: editObject,
        })
          .then(() => {
            API.put("crews", `/crews/${editObject?.crewForeman?.crewId}`, {
              body: {
                members: editObject?.crewMembers,
              },
            });

            const oldObj = {
              company: rowToEdit.company,
              crewMembers: rowToEdit.crewMembers,
              crewForeman: rowToEdit.crewForeman.crewName,
              crewTeamName: rowToEdit.crewTeamName,
            };

            const updatedObj = {
              company: editObject.company,
              crewMembers: editObject.crewMembers,
              crewForeman: editObject?.crewForeman.crewName,
              crewTeamName: editObject?.crewTeamName,
            };

            generateNewLog("Edit", updatedObj, oldObj, []);
            setRowData((prev) =>
              prev.map((el) => {
                return el?.crewTeamId === rowToEdit?.crewTeamId
                  ? bodyObj()
                  : el;
              })
            );

            message.success("Team saved successfully!");
            resetFieldsOnCancel();
          })
          .catch((err) => {
            message.error("There was a problem saving this team!");
            console.log("Error: ", err);
          });
      } else {
        const exists = rowData.find(
          ({ crewTeamName }) =>
            crewTeamName.toLowerCase() ===
            form.getFieldValue("crewTeamName").toLowerCase()
        );
        if (!!exists) {
          return message.error(`${exists.crewTeamName} already exists!`);
        }

        API.post("crewTeams", "/crewTeams", {
          body: bodyObj(),
        })
          .then((res) => {
            API.put("crews", `/crews/${res?.crewForeman?.crewId}`, {
              body: {
                members: res?.crewMembers,
              },
            });
            message.success("Team saved successfully!");
            generateNewLog(
              "Create",
              res,
              {
                crewName: "Does not exists",
                company: "Does not exists",
                createdAt: "Does not exists",
                crewTeamName: "Does not exists",
                crewMembers: "Does not exists",
                crewForeman: "Does not exists",
              },
              [{ collapsable: true }]
            );
            resetFieldsOnCancel();
            setRowData((prev) => [res, ...prev]);
          })
          .catch((err) => {
            message.error("There was a problem saving this team!");
            console.log("Error: ", err);
          });
      }
    });
  }

  // function onShowLogs() {
  //   setLogsModalVisible(true);
  // }

  useEffect(() => {
    lazyFetch({
      tableName: "accounts",
      listOfKeys: ["accountId", "accountName", "accountRecordType"],
      filterKey: "accountRecordType",
      filterValue: "Subcontractors",
    })
      .then(setCompanies)
      .catch((error) => console.log("Error getting accounts: ", error));
    lazyFetch({
      tableName: "crews",
      listOfKeys: [
        "crewId",
        "crewName",
        "employeeId",
        "crewStatus",
        "foreman",
        "crewPosition",
        "members",
      ],
      filterKey: "crewStatus",
      filterValue: "Active",
    })
      .then((res) => {
        const onlyForeman = res.filter(
          ({ foreman, members }) => foreman === true
          // && members?.length === 0
        );
        const busyMembers = onlyForeman.reduce((acc, { members }) => {
          return [...acc, ...members];
        }, []);

        const onlyMembers = formatMembersOptions(res, busyMembers, rowToEdit);
        setForemanMembers(onlyForeman);
        setMembers(onlyMembers);
        1;
      })
      .catch((error) => console.log("Error getting Employees: ", error));

    fetchAllData({
      endpoint: "editLogs",
      resultPosition: "editLogs",
      resultId: "logId",
      otherStringParams: {
        getMaxLimit: "true",
        filters: JSON.stringify([
          {
            conditions: [
              {
                column: "recordId",
                value: rowToEdit?.crewTeamId,
                formula: "is",
              },
            ],
          },
        ]),
      },
    })
      .then((r) => {
        setEditLogs(r);
      })
      .catch((error) => console.log("Error getting logs: ", error));
  }, []);

  useEffect(() => {
    if (rowToEdit && !!foremanMembers?.length && !!members?.length) {
      const { crewTeamName, crewForeman, crewMembers, company } = rowToEdit;

      form.setFieldValue("company", company);
      form.setFieldValue("crewTeamName", crewTeamName);
      setSelectedMembers((crewMembers || [])?.map?.((el) => el?.crewId) || []);
      // form.setFieldValue(
      //   "crewMembers",
      //   (crewMembers || []).map((member) => JSON.stringify(member))
      // );
      setSelectedForeman({
        crewId: crewForeman.crewId,
        crewName: crewForeman.crewName,
      });
      form.setFieldValue("crewForeman", crewForeman.crewName);
    }
  }, [rowToEdit, foremanMembers, members]);

  return (
    <Modal
      {...{
        open,
        newTeamModal,
        onCancel: changes
          ? () => setCloseModalWarning(true)
          : resetFieldsOnCancel,
        closeIcon: <XIcon />,
        title: (
          <CustomModalHeader
            title={`${
              !!rowToEdit?.crewTeamName
                ? "Edit Crew - " + rowToEdit?.crewTeamName
                : "Create a New Crew"
            }  `}
            onClick={() => {
              setTourOpen(true);
            }}
          />
        ),
        afterOpenChange: (event) => {
          event && updateDocumentTitle({ newTitle: "Crew Team Modal" });
        },
        className: `crewTeamModal ${isDarkMode && "crewTeamModalDark"}`,
        closable: true,
        centered: true,
        destroyOnClose: true,
        ["data-testid"]: "crews-team-modal",
        footer: footerButtons({
          onCancel: changes
            ? () => setCloseModalWarning(true)
            : resetFieldsOnCancel,
          onSave,
          onDelete: () => setDeleteWarning(true),
          editMode: !!rowToEdit,
          cancelRef,
          saveRef,
          onShowLogs: () => setLogsModalVisible(true),
        }),
      }}
    >
      <div className="createNewCrewInfo">
        <InfoCircleFilled style={{ color: "#0F5C97", fontSize: 20 }} />
        {!!rowToEdit?.crewTeamId ? (
          <p style={{ margin: 0 }}>
            Edit the details of the selected crew, use the available lists to
            manage and assign crew members efficiently.
          </p>
        ) : (
          <p className="createNewCrewText">
            Complete the form to create a new crew by entering the details,
            assigning a department,role and setting up login credentials.
            <br />
            If you can't find a member and would like to add them to this crew,
            please{" "}
            <span
              ref={addRef}
              onClick={() => {
                setNewMemberModal(true);
              }}
              className="createNewCrwBtn"
            >
              create a new member
            </span>{" "}
            and it will be added automatically.
          </p>
        )}
      </div>
      <Form onFieldsChange={() => setChanges(true)} form={form}>
        <section
          // className={`fields-section ${mobile && "fields-section-mobile"}`}
          className={"fields-section"}
        >
          <section className="team-fields">
            {RenderDynamicComponents(fieldsJSON.slice(0, 3), { form })}
          </section>
          <section className="members-container">
            {/* {RenderDynamicComponents([fieldsJSON[3]], { form })} */}
            <CustomTransfer
              {...{
                redSectionTitle: "Available Crew Members",
                greenSectionTitle: "Selected Crew Members",
                dataSource: members?.flatMap(
                  ({ crewId, crewName, disabled = false }) =>
                    disabled
                      ? []
                      : {
                          key: crewId,
                          title: crewName,
                        }
                ),
                onChange: (member) => {
                  setSelectedMembers(member);
                },
                value: selectedMembers,
              }}
            />
          </section>
        </section>
      </Form>

      <CrewsHeader
        {...{
          visible: newMemberModal,
          setVisible: setNewMemberModal,
          title: "Member",
          listOfMembers: members,
          defaultEmployeeType: "crews",
          setListOfMembers: setMembers,
          foremanMembers,
          setForemanMembers,
        }}
      />
      {deleteWarning && (
        <WarningModal
          visible={deleteWarning}
          setVisible={setDeleteWarning}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p>Are you sure you want to delete {rowToEdit?.crewTeamName}?</p>
            <div className="buttons">
              <MondayButton
                onClick={() => setDeleteWarning(false)}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={onDelete}
                Icon={<TickIcon width={17} height={17} />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {closeModalWarning && (
        <WarningModal
          visible={closeModalWarning}
          setVisible={setCloseModalWarning}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p>Are you sure you want to cancel?</p>
            <div className="buttons">
              <MondayButton
                onClick={() => setCloseModalWarning(false)}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={resetFieldsOnCancel}
                Icon={<TickIcon width={17} height={17} />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}

      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={steps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
      {logsModalVisible && (
        <MultiLevelTreeLogs
          {...{
            visible: logsModalVisible,
            setVisible: setLogsModalVisible,
            logsData: editLogs || [],
            title: `${rowToEdit?.crewTeamName} Logs`,
          }}
        />
      )}
    </Modal>
  );
};

export default CrewTeamModal;
