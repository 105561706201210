import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form } from "antd";
import CustomModalHeader from "../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { XIcon } from "../../../../../Communication/assets";
import { RenderDynamicComponents } from "../../../../../../Header/forms/Components";
import FooterSection from "./FooterSection";
import {
  engineerFields,
  architectFields,
  driverFields,
} from "../../utils/fieldsData";
import { fleetFields as updateFleetFields } from "../../../../../../../actions";
import { getTeams } from "../../services/teamService";

import { API } from "aws-amplify";
import { getCardAccess } from "../../../../utils/getAccessRight";

import "./UpdateEmployeeModal.scss";

const UpdateEmployeeModal = ({
  recordData,
  updateEmployeeModalVisible = false,
  handleUpdateModalVisible,
  refreshTable,
}) => {
  const employeeData = recordData?.data;

  const id =
    employeeData?.engineerId ||
    employeeData?.architectId ||
    employeeData?.driverId;

  const employeeType = employeeData?.engineerId
    ? "engineers"
    : employeeData?.architectId
    ? "architects"
    : employeeData?.driverId
    ? "drivers"
    : null;

  // Breaking Full Name to First and Last name to support the modal
  var nameParts = employeeData.nameOfUser.split(" ");
  employeeData.firstName = nameParts[0];
  employeeData.lastName = nameParts[nameParts.length - 1];

  const dispatch = useDispatch();

  const { programFields } = useSelector((state) => state?.programFields);
  const { userConfiguration } = useSelector((state) => state?.userConfig);
  const { fleetFields } = useSelector((state) => state?.fleetFields);
  const { isDarkMode } = useSelector((state) => state?.darkMode);

  const [form] = Form.useForm();

  const [teams, setTeams] = useState([]);
  const [driverRating, setDriverRating] = useState([]);
  const [driverStatus, setDriverStatus] = useState([]);

  const salaryAccess = getCardAccess(
    "Employee View",
    "Overview",
    "Salary Information"
  );

  const engineerFieldsJSON = useMemo(() => {
    return engineerFields(isDarkMode, salaryAccess);
  }, []);

  const architectFieldsJSON = useMemo(() => {
    return architectFields(isDarkMode, salaryAccess);
  }, []);

  const driverFieldsJSON = useMemo(() => {
    return driverFields({ driverRating, driverStatus, teams });
  }, [driverRating, driverStatus, teams]);

  useEffect(() => {
    if (programFields?.length) {
      const fieldsObj = programFields.reduce(
        (acc, { fieldName, fieldOptions }) => ({
          ...acc,
          [fieldName]: fieldOptions,
        })
      );
      setDriverRating(
        programFields
          ?.find((item) => item.fieldName === "Statuses Of Categories")
          .fieldOptions["Drivers"].filter(({ status }) => status)
      );
    }

    if (fleetFields?.length) {
      const fieldOptionsObj = fleetFields.reduce(
        (acc, cur) => ({ ...acc, [cur.fieldName]: cur.fieldOptions }),
        {}
      );
      setDriverStatus(fieldOptionsObj["Driver Status"]);
    }
  }, [userConfiguration, programFields, fleetFields]);

  useEffect(() => {
    getTeams(setTeams);

    if (!fleetFields?.length > 0) {
      API.get("fleetFields", `/fleetFields`)
        .then((res) => dispatch(updateFleetFields(res)))
        .catch((err) => console.log("Error fetching Fleet Fields: ", err));
    }
  }, []);

  return (
    <Modal
      title={<CustomModalHeader title={`Edit ${employeeData?.nameOfUser}`} />}
      open={updateEmployeeModalVisible}
      closable={true}
      closeIcon={<XIcon />}
      onCancel={handleUpdateModalVisible}
      centered={true}
      destroyOnClose={true}
      className={isDarkMode && "updateEmployeesModalDark"}
      footer={
        <FooterSection
          {...{
            id,
            employeeType,
            form,
            refreshTable,
            onCancel: () => handleUpdateModalVisible(),
          }}
        />
      }
    >
      <Form form={form} initialValues={employeeData}>
        <section className="formSection">
          {employeeType === "engineers" &&
            RenderDynamicComponents(engineerFieldsJSON, form)}
          {employeeType === "architects" &&
            RenderDynamicComponents(architectFieldsJSON, form)}
          {employeeType === "drivers" &&
            RenderDynamicComponents(driverFieldsJSON, form)}
        </section>
      </Form>
    </Modal>
  );
};

export default UpdateEmployeeModal;
