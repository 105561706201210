import { Checkbox, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { getContent } from "../../utils";
import NotificationItemActions from "./notification-item-actions/NotificationItemActions";
import { PushpinFilled, PushpinOutlined } from "@ant-design/icons";
import apiService from "../../utils/notifications-api";
import { toggleNotificationPinnedStatus } from "../../../../../../actions/notificationsActions";
import { useDispatch } from "react-redux";
import { showErrorMsg } from "../../../../../../utils";

function NotificationItem({
  item,
  icon,
  onItemClick,
  setAnimatedClassName,
  apiName,
  path,
  animatedClassName,
  moreActionsContent,
  selectedItems,
  setSelectedItems,
  type,
  activeTab,
}) {
  // Hook to dispatch actions to the Redux store
  const dispatch = useDispatch();

  // Destructure properties from the notification item
  const {
    id,
    seen,
    status,
    url,
    isPinned,
    notificationBody: { createdAt },
  } = item;

  // Get current tab and filter criteria from Redux state
  const { tab, filterBy } = useSelector((state) => state.notifications);

  // Get the text content of the notification, default to "N/A" if unavailable
  const text = getContent(item) || "N/A";

  // Function to toggle the pinned status of the notification
  const handleTogglePinnedStatus = async (notificationId, tab, filterBy) => {
    try {
      // Prepare payload to update pinned status
      const payload = { notificationId, isPinned: !isPinned };
      await apiService.updateNotificationPinnedStatus(payload); // Call API to update pinned status
    } catch (error) {
      // Show error message if the API call fails
      showErrorMsg({
        content:
          "Error while toggling notification pinned status, please try again later!",
      });
      // Dispatch an action to update the pinned status in Redux store
      dispatch(toggleNotificationPinnedStatus(notificationId, tab, filterBy));
    }
  };

  // Get dark mode state from Redux
  const { isDarkMode } = useSelector((state) => state.darkMode);

  // Determine background color based on notification status, read state, and dark mode
  const backgroundColor = selectedItems?.includes(item?.id)
    ? seen
      ? isDarkMode
        ? "#1f2436"
        : "#D5DDF4"
      : status === "new"
      ? isDarkMode
        ? "#ffbfa1e3"
        : "#FACBC2"
      : isDarkMode
      ? "#1f2436"
      : "#D5DDF4"
    : seen
    ? isDarkMode
      ? "#1f212d"
      : "#fff"
    : status === "new"
    ? isDarkMode
      ? "#ffbfa1e3"
      : "#FFE4CE"
    : isDarkMode
    ? "#1f2436"
    : "#F4F7FF";

  // Determine dynamic styles for the notification checkbox based on its state
  const checkBoxDynamicStyles = selectedItems?.includes(id)
    ? `notification-checkbox-active-box`
    : seen
    ? "notification-checkbox-inactive-read"
    : status === "new"
    ? "notification-checkbox-inactive-new"
    : "notification-checkbox-inactive-not-read";

  // Set cursor styles based on the presence of a valid URL
  const cursorStyles =
    !!url && url !== "/null" && url !== "null" ? "pointer" : "default";

  // Set icon styles based on notification status
  const iconStyles = status === "new" ? "new" : "";

  return (
    <div
      className={`notification-item ${isPinned ? "pinned-item" : ""} ${
        animatedClassName[1] === id ? animatedClassName[0] : ""
      }`}
      style={{ backgroundColor }} // Apply dynamic background color
    >
      {activeTab !== "communication" && ( // Render checkbox and pin icon if not in communication tab
        <div className={`notification-checkbox ${checkBoxDynamicStyles}`}>
          <Checkbox
            checked={selectedItems?.includes(id)} // Set checkbox checked state
            onChange={(event) => {
              // Update selected items based on checkbox state
              setSelectedItems((prev) =>
                event.target.checked
                  ? [...prev, id]
                  : prev?.filter((el) => el !== id)
              );
            }}
          />

          {isPinned ? (
            // Render filled pushpin icon if pinned
            <PushpinFilled
              style={{ fontSize: 16 }}
              onClick={() => {
                dispatch(toggleNotificationPinnedStatus(id, tab, filterBy)); // Dispatch action to toggle pinned status
                handleTogglePinnedStatus(id, tab, filterBy); // Call function to toggle pinned status
              }}
            />
          ) : (
            // Render outlined pushpin icon if not pinned
            <PushpinOutlined
              style={{ fontSize: 16 }}
              onClick={() => {
                dispatch(toggleNotificationPinnedStatus(id, tab, filterBy)); // Dispatch action to toggle pinned status
                handleTogglePinnedStatus(id, tab, filterBy); // Call function to toggle pinned status
              }}
            />
          )}
        </div>
      )}

      <div style={{ marginTop: 5, display: "flex" }}>
        {isPinned && !selectedItems.includes(id) && (
          // Render pinned icon if the notification is pinned and not selected
          <PushpinFilled className="pinned-icon" />
        )}

        <span className={`notification-icon notification-${iconStyles}`}>
          {icon} {/* Render notification icon */}
        </span>
      </div>
      <div
        className="notification-text"
        style={{
          cursor: cursorStyles, // Apply cursor style
        }}
        onClick={onItemClick} // Handle click event to execute provided function
      >
        <Tooltip title={text?.replace(/<[^>]+>/g, "") || "N/A"}>
          <div
            dangerouslySetInnerHTML={{
              __html: text, // Render notification text as HTML
            }}
            className="notification-innerHtml"
          />
        </Tooltip>
      </div>

      <span className="notification-date">
        {moment(createdAt)?.format("MM/DD/YYYY")}
        {/* Format and display the creation date */}
      </span>

      {activeTab !== "communication" && (
        // Render action buttons if not in communication tab
        <div className="notification-actions">
          <NotificationItemActions
            item={item} // Pass item prop to actions component
            setAnimatedClassName={setAnimatedClassName}
            apiName={apiName}
            path={path}
            text={text}
            animatedClassName={animatedClassName}
            content={moreActionsContent}
            type={type}
          />
        </div>
      )}
    </div>
  );
}

export default NotificationItem;
