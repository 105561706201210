import React, { useEffect, useState } from "react";
import { DatePicker, Input, Radio, Form, Avatar } from "antd";
import moment from "moment";
import { GPicker } from "../../../../../integrations/GPicker";
import { LabeledInput } from "../../../XComponents";
import { compareIncluding } from "../../../utils";
import { notCollapsed, attachIcon } from "../../assets";
import { calendarIcon, collapsed as collapsedIcon } from "../../../assets";
import { DatepickerIcon } from "../../../Scheduling/ScheduleChangesModal/assets";

const QuestionCategoryCard = ({
  form,
  onPickerSuccess,
  accessToken,
  categoryId,
  categoryName,
  questions,
  images,
  setShownImages,
  setVisible,
  isDraft,
  parentId,
  style,
  inspectionTypeProp,
  isEditable = true,
  type,
  inspectionApplicantLicense,
  setChanges,
  isDarkMode,
  setNewImagesCount,
  setIsAnswerChanged,
  onSaveLogs,
}) => {
  const [collapsed, setCollapsed] = useState(true);

  const collapsedIconSource = collapsed ? collapsedIcon : notCollapsed;

  const inputQuestions = questions?.filter(
    ({ questionType, inspectionType }) => {
      return (
        compareIncluding(questionType, "input") &&
        inspectionType?.some(
          (el) => el === inspectionTypeProp || el === "Default"
        )
      );
    }
  );

  const permitNumberFields = inputQuestions.filter(
    (question) => (question.fieldName = "Permit #")
  );
  useEffect(() => {
    permitNumberFields.forEach((permit) =>
      form.setFieldValue(
        permit.questionId,
        inspectionApplicantLicense?.find(
          (application) => application.work_type === type
        )?.work_permit || form.getFieldValue(permit.questionId)
      )
    );
  }, [inputQuestions, inspectionApplicantLicense]);

  const selectionQuestions = questions?.filter(
    ({ questionType, inspectionType }) =>
      !compareIncluding(questionType, "input") &&
      inspectionType?.some(
        (el) => el === inspectionTypeProp || el === "Default"
      )
  );

  const filteredImages = (questionId) =>
    images?.filter(({ id }) => compareIncluding(questionId, id))[0]?.images;

  return (
    <div key={categoryId} className="questionaryCardContent">
      <div
        className="questionaryCardHeader"
        onClick={() => setCollapsed(!collapsed)}
        {...{ style }}
      >
        {categoryName}
        {/* <img src={collapsedIconSource} alt={collapsedIconSource} /> */}
      </div>
      <div
        className="questionaryCardBody"
        style={{
          height: collapsed ? "fit-content" : 0,
          padding: collapsed ? "0 30px" : "0 30px",
        }}
      >
        <div className="inputQuestionsSection">
          {inputQuestions?.map(
            ({ questionId, question, answerType, required, answer }, i) => (
              <div key={questionId + i} className="questionContainer">
                {!compareIncluding(answerType, "date") ? (
                  <LabeledInput
                    label={question}
                    formItemName={questionId}
                    initialValue={answer}
                    required={!!required}
                  >
                    <Input
                      disabled={!isEditable || !isDraft}
                      type={
                        compareIncluding(answerType, "int") ? "number" : "text"
                      }
                    />
                  </LabeledInput>
                ) : (
                  <LabeledInput
                    required
                    label={question}
                    formItemName={questionId}
                    initialValue={!!answer ? moment(new Date(answer)) : answer}
                  >
                    <DatePicker
                      format="MM/DD/YYYY"
                      allowClear={false}
                      popupClassName={isDarkMode && "darkDateDropDown"}
                      disabled={!isEditable || !isDraft}
                      suffixIcon={<DatepickerIcon />}
                      inputReadOnly
                    />
                  </LabeledInput>
                )}
              </div>
            )
          )}
        </div>
        <div className="selectionQuestionsSection">
          {selectionQuestions?.map(
            ({
              questionId,
              question,
              questionType,
              questionOptions = ["Yes", "No"],
              required,
              answer,
            }) => (
              <div key={questionId} className="questionContainer">
                {compareIncluding(questionType, "boolean") ? (
                  <>
                    <div className="questionText">
                      {question}
                      {isDraft && (
                        <GPicker
                          token={accessToken}
                          onAuthenticate={(t) => {}}
                          createPicker={(google, oauthToken) => {
                            const uploadView =
                              new google.picker.DocsUploadView()
                                .setIncludeFolders(true)
                                .setParent(parentId);
                            const picker =
                              new window.google.picker.PickerBuilder()
                                .enableFeature(
                                  google.picker.Feature.SIMPLE_UPLOAD_ENABLED
                                )
                                .enableFeature(
                                  google.picker.Feature.MULTISELECT_ENABLED
                                )
                                .enableFeature(google.picker.Feature.MINE_ONLY)
                                .enableFeature(google.picker.Feature.NAV_HIDDEN)
                                .addView(uploadView)
                                .setOAuthToken(oauthToken)
                                .setDeveloperKey(
                                  "AIzaSyBPFYynCEmAoBWPFm9G1X_ldzbFfI3azIo"
                                )
                                .setCallback(async (data, event) => {
                                  if (
                                    data.action ===
                                      google.picker.Action.PICKED &&
                                    !!data.docs.length
                                  ) {
                                    setChanges(true);
                                    onPickerSuccess({
                                      questionId,
                                      uploadedFiles: data.docs,
                                    }).then(() => {
                                      setNewImagesCount(
                                        (prevState) => prevState + 1
                                      );
                                    });

                                    onSaveLogs({
                                      ...data,
                                      actionType: "Upload",
                                    });
                                  }
                                });
                            picker.build().setVisible(true);
                          }}
                        >
                          <img src={attachIcon} alt={attachIcon} />
                        </GPicker>
                      )}
                    </div>
                    <div className="booleanButtonsContainer">
                      <Form.Item
                        name={questionId || ""}
                        initialValue={answer || ""}
                        required={!!required}
                      >
                        <Radio.Group
                          buttonStyle="solid"
                          disabled={!isDraft}
                          onChange={() =>
                            setIsAnswerChanged((prevState) => !prevState)
                          }
                        >
                          {questionOptions.map((label, i) => (
                            <Radio.Button
                              key={i}
                              value={label.toUpperCase() || ""}
                              disabled={!isEditable}
                            >
                              {label}
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    {!!filteredImages(questionId)?.length && (
                      <div className="thumbnailsContainer">
                        {filteredImages(questionId)
                          ?.slice(0, 2)
                          ?.map(({ fileId, src }, i) => (
                            <>
                              <img
                                alt="..."
                                key={`${fileId}_${i}`}
                                className="questionThumbnail"
                                src={src}
                                onClick={() => {
                                  setShownImages(filteredImages(questionId));
                                  setVisible(true);
                                }}
                              />
                              {i === 1 &&
                                filteredImages(questionId)?.length > 2 && (
                                  <div
                                    onClick={() => {
                                      setShownImages(
                                        filteredImages(questionId)
                                      );
                                      setVisible(true);
                                    }}
                                    style={{
                                      marginLeft: "5px",
                                    }}
                                  >
                                    <Avatar
                                      shape="square"
                                      size={65}
                                      style={{
                                        backgroundColor: "#87d068",
                                        fontSize: "25px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      +{filteredImages(questionId)?.length - 2}
                                    </Avatar>
                                  </div>
                                )}
                            </>
                          ))}
                      </div>
                    )}
                  </>
                ) : (
                  <LabeledInput
                    label={question}
                    formItemName={questionId || ""}
                    initialValue={answer || ""}
                    required={!!required}
                  >
                    <Radio.Group disabled={!isDraft}>
                      {questionOptions.map((item, i) => (
                        <Radio
                          value={item || ""}
                          key={i}
                          disabled={!isEditable}
                        >
                          {item}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </LabeledInput>
                )}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionCategoryCard;
