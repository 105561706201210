import { useEffect, useMemo, useState } from "react";
import { Form } from "antd";

import { GreenCheckMarkIcon } from "../icons";
import CustomOtpInput from "./CustomOtpInput";
import { AlertIcon } from "../../../assets";
import { validateFields } from "../utils";
import { Auth } from "aws-amplify";
import { showErrorMsg, showSuccessMsg } from "../../../utils";

const OneTimePasswordConfirmation = ({
  setValidateStepMethod,
  stepsFormDisabled,
  setStepsFormDisabled,
  form,
  index,
  onCancel,
}) => {
  const [otpValue, setOtpValue] = useState("");

  const userConfig = (onSuccess) => {
    Auth.confirmSignUp(
      form.getFieldValue("username"),
      form.getFieldValue("verifyCode")
    )
      .then(async (res) => {
        if (res === "SUCCESS") {
          showSuccessMsg({ content: "New account is confirmed" });
          setStepsFormDisabled((prev) => ({
            ...prev,
            otp: "success",
            steps: { ...prev?.steps, [index]: true },
          }));
          onSuccess();
          onCancel();
        }
      })
      .catch((error) => {
        if (error.code === "CodeMismatchException") {
          showErrorMsg({
            content: "Invalid verification code provided, please try again.",
          });
          setStepsFormDisabled((prev) => ({ ...prev, otp: "fail" }));
          return;
        }
        showErrorMsg({
          content:
            "There was a problem confirming you account, please try again.",
        });
        console.log({ error });
      });
  };

  function validateOtp(onSuccess) {
    validateFields(form, () => userConfig(onSuccess), ["verifyCode"]);
  }

  useEffect(() => {
    if (stepsFormDisabled.steps?.[index]) {
      setValidateStepMethod("");
    } else {
      setValidateStepMethod(validateOtp);
    }
  }, []);

  const otpChange = (otp) => setOtpValue(otp);

  const otpValidateText = useMemo(() => {
    const isValid = stepsFormDisabled?.otp;
    if (isValid === "success") {
      return (
        <>
          <GreenCheckMarkIcon fill="#00A464" style={{ width: 24 }} />
          <h2>Code Confirmed Successfully</h2>
        </>
      );
    } else if (isValid === "fail") {
      return (
        <>
          <AlertIcon fill="#EB4345" />

          <h2>Code Confirmation Not Successful</h2>
        </>
      );
    } else {
      return (
        <>
          <GreenCheckMarkIcon fill="#ACACAC" style={{ width: 24 }} />
          <h2>Confirmation Code</h2>
        </>
      );
    }
  }, [stepsFormDisabled?.otp]);

  return (
    <div className="create-user-details flip-in-hor-bottom">
      <div className="create-user-form-header otp">{otpValidateText}</div>
      {!stepsFormDisabled?.otp && (
        <div>Please insert the pass code that was sent to your email.</div>
      )}
      <Form.Item className="pascodeInputs" name="verifyCode">
        <CustomOtpInput
          value={otpValue}
          className={"otpInput"}
          onChange={otpChange}
          length={6}
          inputStyle={"inputStyle"}
          focusStyle={"focusStyle"}
          disabled={!!stepsFormDisabled?.steps?.[index]}
          type={stepsFormDisabled?.otp}
        />
      </Form.Item>
    </div>
  );
};

export default OneTimePasswordConfirmation;
