import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Button, Form, Input, Modal, Tooltip } from "antd";
import { AgGridReact } from "ag-grid-react";
import { useDispatch, useSelector } from "react-redux";

import {
  apiPut,
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../../../../../../utils";
import columnDefs from "./columnDefs";
import { Close, OpenInNewTabIcon, TickIcon } from "../../../../../../Roles/src";
import { updateOtherUser } from "../../../../../../../../../../actions";
import {
  ExportOrEmailDropdown,
  MondayButton,
  MondaySwitch,
} from "../../../../../../../../../commonComponents";
import { apiRoutes } from "../../../../../../../../../SidebarPages/Fleet/utils";
import { XIcon } from "../../../../../../../../../SidebarPages/Communication/assets";
import "./AllowedIpAddresses.scss";
import { InfoText } from "../../../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { devices } from "../../../../../../DeviceManagement/utils";
import {
  antdInputStyle,
  infoIconStyle,
} from "../../../../../../Configurations/WidgetConfigKeys/styleHelpers/styles";
import { v4 as uuid } from "uuid";
import { defaultColDef } from "../../../../../../GeofenceConfigurations/AllProgramGeofences/PortalGeofences";
import {
  CloseOutlined,
  ExpandAltOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import { checkIsValidIp } from "../../../../../../AllowedIpAddresses/utils";
import { useFieldOptionFinder } from "../../../../../../../../../../hooks";

const AllowedIpAddresses = ({
  identityId,
  ipAddressWhitelist,
  allowedIpAddressVisible,
  setAllowedIpAddressVisible,
}) => {
  const [toEdit, setToEdit] = useState(false);
  const initialValuesUsedForEdit = toEdit ? { ...toEdit } : {};
  const defaultAllowedIpAddresses = useFieldOptionFinder({
    fieldName: "Allowed IP Addresses",
  });

  const dispatch = useDispatch();
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState();
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();

  const ipAddressInput = Form.useWatch("ipv4", form);
  const descriptionInput = Form.useWatch("description", form);

  const defaultEnabled = ipAddressWhitelist?.list?.every(
    ({ isEnabled }) => isEnabled === true
  );

  const [rowData, setRowData] = useState(ipAddressWhitelist?.list || []);

  const [isEnabled, setIsEnabled] = useState(defaultEnabled || false);

  const onGridReady = useCallback((param) => {
    setGridApi(param.api);
    setColumnApi(param.columnApi);
  });

  const isIpExistInList = () =>
    toEdit
      ? isEqual(ipAddressInput, initialValuesUsedForEdit.ipv4)
        ? false
        : rowData.some((item) => item.ipv4 === ipAddressInput)
      : rowData.some((item) => item.ipv4 === ipAddressInput);

  const handleDeviceClick = (selected, newData) => {
    const updated = selected
      ? selectedDevices.filter((device) => device.deviceId !== newData.deviceId)
      : [...selectedDevices, newData];
    setSelectedDevices(updated);
    form.setFieldsValue({ devices: updated });
  };

  const addNewIp = (values) => {
    const updatedRowData = toEdit
      ? rowData.map((data) =>
          data.id === toEdit.id
            ? {
                ...data,
                description: values.description,
                ipv4: values.ipv4,
                devices: values.devices,
              }
            : data
        )
      : [...rowData, values];
    setErrorMessage();
    form.resetFields();
    setSelectedDevices([]);
    setRowData(updatedRowData);
    setToEdit(false);
  };

  const saveIpHandler = async () => {
    showLoadingMsg();

    const newIpAddressWhitelist = {
      list: rowData.map((field) => ({
        ...field,
        isEnabled: isEnabled,
      })),
    };

    await apiPut(apiRoutes.userConfiguration, identityId, {
      ipAddressWhitelist: newIpAddressWhitelist,
    })
      .then(() => {
        dispatch(
          updateOtherUser({
            identityId,
            ipAddressWhitelist: newIpAddressWhitelist,
          })
        );
        showSuccessMsg();
      })
      .catch(() => showErrorMsg());
  };

  const addNewIpHandler = (values) => {
    const bodyToSave = {
      ...values,
      devices: selectedDevices,
      id: uuid(),
      addedBy: {
        name: userConfiguration.nameOfUser,
        uid: userConfiguration.userId,
      },
      addedAt: Date.now(),
    };

    if (!checkIsValidIp(bodyToSave.ipv4)) {
      return setErrorMessage(
        "IP Address in not in correct format (xxx.xxx.xxx.xxx)"
      );
    }

    if (isIpExistInList()) {
      return setErrorMessage("IP Address already exists in whitelist");
    }

    addNewIp(bodyToSave);
  };

  const deleteIpAddressHandler = (ipAddress) => {
    setRowData((prev) => prev.filter((ip) => ip.id !== ipAddress));
  };

  const onCancel = () => {
    setAllowedIpAddressVisible(false);
    setErrorMessage();
    form.resetFields();
    setSelectedDevices([]);
  };

  const isSaveDisabled =
    isEqual(rowData, ipAddressWhitelist?.list) &&
    isEqual(isEnabled, defaultEnabled);

  const onToggleChange = () => setIsEnabled((prev) => !prev);

  const buttonStatusInEdit = isEqual(
    {
      ipv4: initialValuesUsedForEdit.ipv4,
      description: initialValuesUsedForEdit.description,
      devices: initialValuesUsedForEdit.devices,
    },
    {
      ipv4: ipAddressInput,
      description: descriptionInput,
      devices: selectedDevices,
    }
  );

  useEffect(() => {
    if (toEdit) {
      form.setFieldsValue({
        ipv4: toEdit.ipv4,
        description: toEdit.description,
        devices: toEdit.devices,
      });
      setSelectedDevices(toEdit.devices || []);
    } else {
      form.resetFields();
      setSelectedDevices([]);
      setErrorMessage();
    }
  }, [toEdit]);

  const exportGridToExcel = (tableColumns) => {
    return gridApi.exportDataAsExcel({
      columnKeys: getExcelColumnKeys(gridApi, tableColumns),
      processCellCallback(params) {
        const value = params?.value;
        const headerName = params?.column?.userProvidedColDef?.headerName;

        if (headerName == "Added At") {
          return dayjs(value)?.format("MM/DD/YYYY - hh:mm a") || "";
        } else if (headerName === "Devices") {
          return value.map((item) => item.deviceName).join(", ");
        } else if (headerName === "Added By") {
          return value?.name || "";
        }

        return value === undefined ? "" : `${value}`;
      },
    });
  };

  const areIpAddressesEnabled = defaultAllowedIpAddresses.every(
    (option) => option?.isEnabled === true
  );

  return (
    <Modal
      centered
      className={`ip-address-whitelist-modal-user-session ${
        isDarkMode && "ip-address-whitelist-modal-user-session-dark"
      }`}
      open={allowedIpAddressVisible}
      closeIcon={<XIcon />}
      onCancel={onCancel}
      title={<span>Whitelist IP Addresses</span>}
      footer={
        <div className="modal-footer-ip-address-whitelist">
          <MondayButton
            Icon={<Close />}
            className="mondayButtonRed"
            onClick={onCancel}
            htmlType="reset"
          >
            Cancel
          </MondayButton>
          <MondayButton
            Icon={<TickIcon />}
            className={
              isSaveDisabled ? "mondayButtonGray" : "mondayButtonGreen"
            }
            onClick={saveIpHandler}
            disabled={isSaveDisabled}
          >
            Save
          </MondayButton>
        </div>
      }
    >
      <div className="ip-address-whitelist-modal-body">
        <InfoText
          {...{
            text: "Adding new IP addresses will allow access from those specific locations, while removing IPs can restrict access accordingly. Please ensure that the IP addresses are correctly entered to maintain the user’s security and accessibility.",
            isDarkMode,
          }}
        />
        <div className="modal-input-container">
          <Form
            // initialValues={initialValuesUsedForEdit}
            form={form}
            onFinish={addNewIpHandler}
            layout="vertical"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Form.Item
                label="New IP Address"
                name="ipv4"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder="Enter IP (format: 192.168.0.1)..."
                  style={{ ...antdInputStyle, width: "300px" }}
                />
              </Form.Item>
              <Form.Item
                label="Devices"
                name="devices"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <div className="formOfIpCreationDevices">
                  {devices.map(({ deviceName, deviceId, deviceLabel }) => {
                    const selected = selectedDevices.some((el) =>
                      el.deviceId.includes(deviceId)
                    );
                    return (
                      <div
                        className={`formOfIpCreationDevice ${
                          selected && "active"
                        }`}
                        key={deviceId}
                        onClick={() =>
                          handleDeviceClick(selected, {
                            deviceName,
                            deviceId,
                            deviceLabel,
                          })
                        }
                      >
                        {deviceName}
                      </div>
                    );
                  })}
                </div>
              </Form.Item>
            </div>
            <Form.Item label="Description" name="description">
              <Input.TextArea
                placeholder="type here..."
                style={{ ...antdInputStyle, height: "52px" }}
              />
            </Form.Item>
            <div className="submissionOfIpAddressFromAllUsers">
              <MondayButton
                htmlType="submit"
                Icon={<TickIcon />}
                className={`${
                  !ipAddressInput ? "mondayButtonGray" : "mondayButtonBlue"
                } addIpButton`}
                data-testid="add-ip-button"
                disabled={
                  !ipAddressInput ||
                  !selectedDevices.length ||
                  (toEdit && buttonStatusInEdit)
                }
              >
                Pre submit in table
              </MondayButton>
              {toEdit && (
                <Button
                  danger
                  onClick={() => setToEdit(false)}
                  icon={<CloseOutlined />}
                >
                  Cancel Editing
                </Button>
              )}
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                {!areIpAddressesEnabled ? (
                  <Tooltip
                    title={`All activities in table won't be executed cause ip addresses are disabled in settings`}
                    trigger="hover"
                    destroyTooltipOnHide
                  >
                    <InfoCircleFilled style={infoIconStyle} />
                  </Tooltip>
                ) : (
                  <></>
                )}
                <span style={{ fontSize: "14px", fontWeight: 600 }}>
                  Check IP:{" "}
                </span>
                <MondaySwitch
                  {...{
                    checked: isEnabled,
                    onToggleSwitch: onToggleChange,
                    onText: "Enabled",
                    offText: "Disabled",
                    removeIcon: false,
                  }}
                />
                <ExportOrEmailDropdown
                  {...{
                    rowData,
                    title: "Whitelist IP Addresses",
                    gridApi,
                    gridColumnApi: columnApi,
                    exportToExcel: (tableColumns) =>
                      exportGridToExcel(tableColumns),
                  }}
                />
              </div>
            </div>
          </Form>
        </div>
        {errorMessage && <div style={{ color: "red " }}>{errorMessage}</div>}
        <div className={`agGridContainer`}>
          <div
            className={`documentationsTable ${
              isDarkMode
                ? "dark-ag-theme ag-theme-alpine-dark"
                : "light-ag-theme ag-theme-alpine"
            }`}
            data-testid="ip-address-grid"
          >
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs({
                deleteIpAddressHandler,
                setToEdit,
              })}
              domLayout="autoHeight"
              suppressDragLeaveHidesColumns={true}
              defaultColDef={{
                ...defaultColDef,
                sortable: false,
              }}
              onGridReady={onGridReady}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AllowedIpAddresses;
