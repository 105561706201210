// Used to extract the word after 'Scope:' (pattern needed for schedule)
export const extractWordAfterScope = (htmlString) => {
  let text = htmlString.replace(/<\/?[^>]+(>|$)/g, (match) => {
    // Convert <br> tags to newlines
    if (match === "<br>") return "\n";
    return "";
  });

  // Regex for "Updated Scope:"
  let updatedScopeRegex = /^Updated\s*Scope\s*:\s*[^\n]*/gim;
  // Regex for "Scope:" (without "Updated")
  let scopeRegex = /^Scope\s*:\s*[^\n]*/gim;

  let updatedMatches = [];
  let regularMatches = [];
  let match;

  // First, find "Updated Scope:"
  while ((match = updatedScopeRegex.exec(text)) !== null) {
    let sentence = match[0].trim();
    updatedMatches.push(sentence);
  }

  // If "Updated Scope:" matches are found, return them
  if (updatedMatches.length > 0) {
    return updatedMatches.join("\r\n");
  }

  // Otherwise, find "Scope:"
  while ((match = scopeRegex.exec(text)) !== null) {
    let sentence = match[0].trim();
    regularMatches.push(sentence);
  }

  // Return all "Scope:" matches if "Updated Scope:" wasn't found
  return regularMatches.join("\r\n");
};
// Used to extract the number before 'crew' (pattern needed for schedule) or 'crews', current actually has a higher priority
export const extractNumberBeforeCrew = (htmlString) => {
  let text = htmlString;

  let crewRegex = /(\d+)\s+(crew|crews|current crew| current crews|)/gi; // current crew| current crews| is for higher priority
  let crewMatches = [];
  let match;
  let priority = null;

  while ((match = crewRegex.exec(text)) !== null) {
    console.log("match22", match[0]);
    if (match[0].includes("current") && !priority) {
      priority = match[1];
      console.log("priority", priority);
    }
    if (match[0].includes("crew")) {
      console.log("crewMatches", { crewMatches, match1: match[1] });
      crewMatches.push(match[1]);
    }
    // Extract the number (first capture group)
  }
  console.log("crewMatches", { priority });
  return !!priority ? priority : crewMatches.length > 0 ? crewMatches[0] : "";
};

export const extractWordAfterDash = (input) => {
  if (input.includes(" - ")) {
    let parts = input.split(" - ");
    return parts[parts.length - 1].trim();
  }
  return "";
};
export const removeDashAndWordAfter = (input) => {
  if (input.includes(" - ")) {
    let parts = input.split(" - ");
    return parts[0].trim();
  }
  return input.trim();
};

export const htmlToPlainText = (htmlString) => {
  if (htmlString === null || htmlString === undefined || !!!htmlString)
    return "";
  let text = htmlString.replace(/<br\s*\/?>/gi, "\n");
  text = text.replace(/<\/?[^>]+(>|$)/g, "");
  text = text.trim();

  return text;
};

export const predictScheduleType = (description, otherDesc) => {
  if (!description) return "N/A";
  if (otherDesc.toLowerCase().includes("new installation"))
    return "Installation";
  const desc = description.toLowerCase();
  const installationKeywords = [
    "instal",
    "setup",
    "deploy",
    "add",
    "configure",
    "mount",
    "inst",
    "instal",
    "instalation",
    "modif",
    "fix",
    "repair",
    "adjust",
    "modify",
    "relocat",
    "raise",
    "push",
  ];
  const removalKeywords = [
    "remove",
    "uninstal",
    "delete",
    "demount",
    "dismantle",
    "take down",
    "uninst",
    "uninstal",
    "removal",
    "remov",
    "unload",
    // "relocate",
    "pick up",
    "pick-up",
    "pickup",
    "picking",
    "load",
    "load up",
    "remov",
    "clear out",
    "clear",
  ];

  // let installationCount = 0;
  // let removalCount = 0;

  for (let word of installationKeywords) {
    if (desc?.toLowerCase().includes(word)) {
      // installationCount++;
      return "Installation";
    }
  }

  for (let word of removalKeywords) {
    if (desc?.toLowerCase()?.includes(word)) {
      // removalCount++;
      return "Removal";
    }
  }
  return "N/A";
  // if (installationCount > 0 && removalCount > 0) {
  //   if (installationCount > removalCount) {
  //     return "Installation";
  //   } else if (removalCount === installationCount) {
  //     return "Installation and Removal";
  //   } else {
  //     return "Removal";
  //   }
  // } else if (installationCount > 0) {
  //   return "Installation";
  // } else if (removalCount > 0) {
  //   return "Removal";
  // } else {
  //   return "N/A";
  // }
};

export const getColorFromIdEvents = (id) => {
  const colorMap = {
    1: "7986CB",
    2: "33B679",
    3: "8E24AA",
    4: "E67C73",
    5: "F6BF26",
    6: "F4511E",
    7: "039BE5",
    8: "616161",
    9: "3F51B5",
    10: "0B8043",
    11: "D50000",
  };

  return colorMap[id] || "039BE5";
};
