import { useState, useEffect, useMemo } from "react";
import { Breadcrumb, Tooltip, message } from "antd";
import "./Tabs.scss";
import { Page } from "../../Pages";
import { CopyIcon, UpIcon } from "../../src";
import { NavLink, useLocation } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "../../../../../hooks";
import { booleanIfContactAction } from "../../../../../actions/booleanIfContactAction";
import { RefreshIcon } from "../../../Communication/assets/index";
import { useMediaQuery } from "react-responsive";
import DynamicTab from "../../../../commonComponents/DynamicTab/DynamicTab";

// const { TabPane } = Tabs;
const TabsComp = ({
  viewTitle,
  customTooltipTitle,
  tabList = [],
  notesProps,
  rowData,
  todosProps,
  defaultOpen,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  let query = useQuery();

  const queryTab = query.get("tab");

  const [upVisible, setUpVisible] = useState(false);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { booleanIfContact } = useSelector((state) => state.ifContact);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [refreshSeed, setRefreshSeed] = useState(1);
  const dispatch = useDispatch();
  let tabRef = document.getElementsByClassName("tabBody");
  const path = location.pathname.split("/")[1];
  const linkTo = userConfiguration.routeConfig.find(({ path }) =>
    path.includes(location.pathname.split("/")[1])
  )?.endpoint;

  const tabPosition =
    queryTab || location.state?.tabPosition || location?.state?.data?.tab || "";

  /**
   * holds the number of tab clicks for a page
   * when we delete a record, the page used to go to the last opened tab of the same page,
   * not the original caller
   */
  let numberOfTabsClicked =
    location?.state?.tabsClicked || location?.state?.data?.tabsClicked || -1;

  const activeKey = tabList
    .map(({ tab = "" }) => tab.toLowerCase())
    .indexOf(tabPosition.toLowerCase());

  function getDefaultOpenTab() {
    let defaultIndex = tabList?.findIndex(
      ({ tab = "" }) => tab === defaultOpen
    );
    if (defaultIndex === -1) {
      return 0;
    }
    return defaultIndex;
  }

  const [selectedTab, setSelectedTab] = useState(
    activeKey === -1 ? getDefaultOpenTab() : activeKey
  );

  const specialRoutes = {
    "/fleets": "/fleets/overview",
    "/fleet/drivers": "/fleets/overview",
    "/settings/engineersAndArchitects/engineers":
      "/settings/engineersAndArchitects",
    "/settings/engineersAndArchitects/architects":
      "/settings/engineersAndArchitects",
    "/fleetViolations": "/fleets/overview",
    "/hrManagement/crews": "/hrManagement",
    "/hrManagement/engineers": "/hrManagement",
    "/hrManagement/architects": "/hrManagement",
    "/hrManagement/drivers": "/hrManagement",
    "/vendors": "/vendors",
  };

  const specialTitles = {
    "/fleets": "Fleets",
    "/fleet/drivers": "Drivers",
    "/settings/engineersAndArchitects/engineers": "Engineers And Architects",
    "/settings/engineersAndArchitects/architects": "Engineers And Architects",
    "/fleetViolations": "Violations",
  };
  const breadcrumbsExcludes = [
    "/fleets/overview",
    "/tasksManager",
    "/incidents",
    "/safety",
    "/safetyInspections",
    "/inspections",
    "/notes",
    "/todos",
    "/accounting",
  ];

  useMemo(() => {
    setSelectedTab(activeKey === -1 ? getDefaultOpenTab() : activeKey);
  }, [activeKey]);

  useEffect(() => {
    let ifBreadcrumbIsContact =
      tabList[parseInt(selectedTab)].tab === "Contacts" ? true : false;
    dispatch(booleanIfContactAction(ifBreadcrumbIsContact));
  }, [booleanIfContact, tabList[parseInt(selectedTab)].tab]);

  const createURLSearchParams = (tabName) => {
    const params = new URLSearchParams({
      tab: tabName,
    });
    return params?.toString();
  };

  useEffect(() => {
    if (rowData) {
      if (!queryTab || queryTab === "Overview") {
        setRefreshSeed(Math.random() * 10);
      }
    }
  }, [rowData, queryTab]);

  const checkPathForClipboard = () => {
    if (
      path === "estimations" ||
      path === "projects" ||
      path === "scheduling"
    ) {
      return true;
    }

    return false;
  };

  return (
    <div
      className={`tabContainer ${isDarkMode && "tabContainerDark"}`}
      key={refreshSeed}
    >
      <div
        className="titleComp"
        style={{
          position: isMobile ? "" : "absolute",
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          alignItems: "center",
        }}
      >
        {!!id && path !== "opportunities" && checkPathForClipboard() ? (
          <>
            <Tooltip
              title={!!customTooltipTitle ? customTooltipTitle : viewTitle}
            >
              <span className="title">{viewTitle}</span>
            </Tooltip>
            <Tooltip title="Copy to Clipboard!">
              <CopyIcon
                onClick={() => {
                  const title = viewTitle.split(" - ");
                  message.success("Copied to Clipboard");
                  navigator.clipboard.writeText(title[0]);
                }}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </>
        ) : (
          <Tooltip
            title={!!customTooltipTitle ? customTooltipTitle : viewTitle}
          >
            <span className="title">{viewTitle}</span>
          </Tooltip>
        )}
      </div>
      <div className="breadcrumpContainer">
        {!breadcrumbsExcludes.includes(window.location.pathname) && (
          <Breadcrumb
            itemRender={(route) => route.breadcrumbName}
            items={
              // Added the check for the inspections so the breadCrumb will not show in the inspections basePage

              [
                {
                  key: "1",
                  breadcrumbName: (
                    <NavLink
                      to={{
                        pathname:
                          specialRoutes[
                            window.location.pathname
                              .split("/")
                              .slice(0, -1)
                              .join("/")
                          ] || `/${linkTo}`,
                        state: {
                          tabPosition: specialRoutes[
                            window.location.pathname
                              .split("/")
                              .slice(0, -1)
                              .join("/")
                          ]
                            ? window.location.pathname
                                .split("/")
                                .slice(0, -1)
                                .join("/") === "/fleet/drivers"
                              ? "Drivers"
                              : "Fleet"
                            : "",
                        },
                      }}
                    >
                      {specialTitles[
                        window.location.pathname
                          .split("/")
                          .slice(0, -1)
                          .join("/")
                      ] ||
                        (userConfiguration.routeConfig.find(
                          ({ path }) =>
                            path ===
                            window.location.pathname
                              .split("/")
                              .slice(0, -1)
                              .join("/")
                        )?.title === "Accounts"
                          ? "Clients"
                          : userConfiguration.routeConfig.find(({ path }) =>
                              path.includes(location.pathname.split("/")[1])
                            )?.title)}
                    </NavLink>
                  ),
                },
                {
                  key: "2",
                  breadcrumbName: (
                    <a
                      onClick={() => {
                        setSelectedTab(0);
                      }}
                    >
                      View
                    </a>
                  ),
                },
                selectedTab !== 0 && {
                  key: "3",
                  breadcrumbName: <a>{tabList[parseInt(selectedTab)].tab}</a>,
                },
              ].filter(Boolean)
            }
          />
        )}
      </div>
      <DynamicTab
        onChange={(tab) => {
          setSelectedTab(tab);
          let ActualTab = tabList[parseInt(tab)].tab;
          if (window.location.pathname !== "/inspectionsView/:id") {
            navigate(
              `${window.location.pathname}?${createURLSearchParams(ActualTab)}`,
              {
                state: {
                  ...location.state,
                  tabPosition: ActualTab || tabList[parseInt(tab) || 0],
                  tabsClicked: --numberOfTabsClicked,
                },
              }
            );
          } else {
            // if (ActualTab === "Scheduling Overview") {
            // navigate(
            //   `${window.location.pathname}?${createURLSearchParams(ActualTab)}`,
            //   {
            //     state: {
            //       tabPosition: ActualTab || tabList[parseInt(tab) || 0],
            //       tabsClicked: --numberOfTabsClicked,
            //     },
            //   }
            // );
          }
        }}
        className={`tabComponent tabComponent${tabList.length}`}
        activeKey={selectedTab.toString()}
        defaultActiveKey={
          activeKey === -1 ? `${getDefaultOpenTab()}` : `${activeKey}`
        }
        // tabBarExtraContent={slot}
        items={tabList
          .filter(Boolean)
          .map(
            (
              {
                tab,
                props,
                getEditedValue,
                component: Component,
                stepperParams,
                sideButtons,
                rowData = {},
              },
              key
            ) => {
              return {
                key: key.toString(),
                label: tab,
                children: (
                  <div
                    id="tabBody"
                    className="tabBody"
                    style={tab === "Map" ? { padding: 0, paddingRight: 0 } : {}}
                    onScroll={({ target: { scrollTop } }) => {
                      if (scrollTop >= 200) {
                        if (upVisible === false) {
                          setUpVisible(true);
                        }
                      } else {
                        if (upVisible === true) {
                          setUpVisible(false);
                        }
                      }
                    }}
                  >
                    {Component ? (
                      <div style={{ height: "100%", width: "100%" }}>
                        <Component
                          {...props}
                          {...{
                            props,
                            viewTitle,
                            selectedId:
                              query.get("selectedId") || // this param is sended by backend to users email, when user click that record it's configured to send this queryParam
                              location?.state?.data?.selectedId,
                            rowSelected: location?.state?.data?.rowSelected,
                            sideButtons,
                            stepperParams,
                            getEditedValue,
                            currentStep:
                              stepperParams && stepperParams?.currentStep,
                          }}
                        />
                      </div>
                    ) : (
                      Page[tab]({
                        viewTitle,
                        stepperParams,
                        notesProps,
                        todosProps,
                        sideButtons: Array.isArray(sideButtons)
                          ? [
                              ...sideButtons,
                              {
                                Icon: RefreshIcon,
                                label: "Refresh",
                                onClick: () => setRefreshSeed(Math.random()),
                                tooltipKey: "refreshPage",
                              },
                            ]
                          : [],
                        props,
                        getEditedValue,
                        currentStep:
                          stepperParams && stepperParams?.currentStep,
                        rowData,
                      })
                    )}
                    {upVisible && (
                      <div
                        className="backTop"
                        onClick={(e) => {
                          for (var i = 0; i < tabRef.length; i++) {
                            tabRef[i].scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }
                        }}
                      >
                        <span className="backTopIcon">
                          <UpIcon />
                        </span>
                      </div>
                    )}
                  </div>
                ),
              };
            }
          )}
      />
    </div>
  );
};

export default TabsComp;
