import SelectRole from "./components/SelectRole";

/**
 *
 * @param {Array} departments list of departments for select component
 * @param {form} form  form component
 * @returns
 */
export const SignupFormData = (
  departments,
  roles,
  setRoleOptions,
  form,
  isDarkMode,
  allUsers,
  setAccessModalVisible,
  departmentFields,
  disabled
) => [
  {
    label: "First Name",
    required: true,
    formItemName: "firstName",
    placeholder: "Type here...",
    className: "formElement",
    rules: [
      {
        pattern: new RegExp("^[A-Z][a-z]{1,19}$"),
        message: "Please enter a valid name",
      },
    ],
    disabled,
  },
  {
    label: "Last Name",
    required: true,
    formItemName: "lastName",
    placeholder: "Type here...",
    className: "formElement",
    rules: [
      {
        pattern: new RegExp("^[A-Z][a-z]{1,19}$"),
        message: "Please enter a valid surname",
      },
    ],
    disabled,
  },
  {
    label: "Department",
    required: true,
    formItemName: "department",
    placeholder: "Select",
    className: "formElement",
    dropdownClassName: isDarkMode ? "darkDropDown" : "",
    type: "select",
    onSelect: (e) => {
      setRoleOptions(e);
      form.setFieldsValue({ role: "" });
    },
    options: departments,
    onClear: () => {
      setRoleOptions("");
      form.setFieldsValue({ role: "" });
    },
    disabled,
  },
  {
    customComponent: SelectRole,
    componentProps: {
      roles,
      form,
      setAccessModalVisible,
      isDarkMode,
      departmentFields,
      disabled,
    },
  },
  // {
  //   label: "Role",
  //   required: true,
  //   formItemName: "role",
  //   placeholder: "Select",
  //   className: "formElement",
  //   type: "select",
  //   dropdownClassName: isDarkMode ? "darkDropDown" : "",
  //   options: roles || [],
  // },
  {
    required: true,
    label: "Phone Nr.",
    formItemName: "phone_number",
    placeholder: "Type here...",
    disableCountryCode: false,
    type: "Phone",
    onlyUsPrefix: false,
    // mask: "(111) 111-1111",
    className: "formElement",
    rules: [
      {
        require: true,
        message: "Please complete the mobile number",
      },
    ],
    disabled,
  },
  {
    label: "Email",
    required: true,
    formItemName: "username",
    placeholder: "emailhere@email.com",
    className: "formElement",
    rules: [
      {
        type: "email",
        message: "Please enter a valid email",
      },
      {
        validator: (_, value) =>
          !!value
            ? allUsers.find((user) => user.userName === value)
              ? Promise.reject(new Error("User with this email already exists"))
              : Promise.resolve()
            : Promise.resolve(),
      },
    ],
    disabled,
  },

  {
    label: "Password",
    required: true,
    formItemName: "password",
    placeholder: "*********",
    className: "formElement",
    autoComplete: "new-password",
    typePassword: true,
    rules: [
      {
        pattern: new RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%^&\\*])(?=.{8,})"
        ),
        message: "*Password is not valid.",
      },
    ],
    disabled,
  },
  {
    label: "Confirm Password",
    required: true,
    formItemName: "confirmPassword",
    placeholder: "*********",
    className: "formElement",
    typePassword: true,
    rules: [
      {
        validator: (_, value) =>
          !!value
            ? form.getFieldValue("password") === value
              ? Promise.resolve()
              : Promise.reject(new Error("Passwords are not the same"))
            : Promise.resolve(),
      },
    ],
    disabled,
  },
];
/**
 * Json array for confirm form
 */
export const ConfirmData = [
  {
    label: "Verification Code",
    required: true,
    formItemName: "verifyCode",
    placeholder: "Verification Code",
    className: "formElement",
  },
];
