import { uniqBy } from "lodash";
import { API } from "aws-amplify";
import { v4 as uuid } from "uuid";
import { Form, message } from "antd";
import { AxiosResponse } from "axios";
import { useSelector } from "react-redux";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";

import {
  EntryType,
  ShiftEntry,
  FilterTypes,
  ScheduleType,
  CrewTeamType,
  GroupedEntry,
  AnalyticsType,
  EmployeeReportType,
  ControlPanelRefType,
} from "./payrollLiveTypes";
import LiveDegPayroll from "./Pages/LiveDegPayroll";
import PayrollLiveMap from "./Pages/PayrollLiveMap";
import PayrollLiveContext from "./PayrollLiveContext";
import EmployeeReports from "./Pages/EmployeeReports";
import {
  getShiftOrder,
  coordinatesMatch,
  matchSchedulesWithExcelData,
} from "./utils";
import {
  getEmployeeAnalytics,
  groupEntriesInShifts,
} from "../Payroll/Tabs/DEG/components/modalComponents/utils";
import {
  StoreType,
  JobsiteType,
  EmployeeType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import PayrollLiveAnalytics from "./Pages/PayrollLiveAnalytics";
import {
  getPayrollLiveReport,
  getEmployeesLiveEntries,
} from "../Payroll/Tabs/DEG/FingerCheckConfig/fingercheckFunctions";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { PayrollLiveControlPanel, PayrollLiveSidebar } from "./components";
import { ANALYTICS_REDUCER } from "src/components/pages/Payroll/Tabs/DEG/components/modalComponents/utils";
import { GOOGLE_API_KEY } from "src/components/SidebarPages/Scheduling/Tabs/SchedulingMap/schedulingMapData";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { REDUCER_INIT } from "src/components/pages/Payroll/Tabs/DEG/components/modalComponents/utils/reducerInitialStates";

import "./PayrollLive.scss";

const FILTER_INITIAL_VALUE = {
  schedules: [],
  crewSearch: "",
  liveStatus: [],
  employeeId: [],
  employeeName: [],
  scheduleMatch: [],
  selectedData: null,
  punchTimeEnd: null,
  employeeSearch: "",
  employeeNumber: [],
  scheduleAddress: "",
  punchTimeStart: null,
};

const EMPLOYEE_FILTERS = [
  "liveStatus",
  "employeeId",
  "employeeName",
  "punchTimeEnd",
  "scheduleMatch",
  "employeeNumber",
  "employeeSearch",
  "punchTimeStart",
];

const SCHEDULE_FILTERS = ["schedules", "scheduleAddress"];

const minute = 60000; // milliseconds in 1 minute

function PayrollLive() {
  const { isDarkMode } = useSelector((store: StoreType) => store.darkMode);
  const programFields = useSelector(
    (store: StoreType) => store.programFields.programFields
  );

  const [selectedPage, setSelectedPage] = useState<string>(
    sessionStorage.getItem("selectedPage") || "map"
  );
  const [jobsites, setJobsites] = useState<Array<JobsiteType>>([]);
  const [degEntries, setDegEntries] = useState<Array<EntryType>>([]);
  const [schedules, setSchedules] = useState<Array<ScheduleType>>([]);
  const [crewTeams, setCrewTeams] = useState<Array<CrewTeamType>>([]);
  const [shiftEntries, setShiftEntries] = useState<Array<ShiftEntry>>([]);
  const [employeesInfo, setEmployeesInfo] = useState<string | undefined>();
  const [schedulesInfo, setSchedulesInfo] = useState<string | undefined>();
  const [filters, setFilters] = useState<FilterTypes>(FILTER_INITIAL_VALUE);
  const [todaySchedules, setTodaySchedules] = useState<Array<ScheduleType>>([]);
  const [groupedEntries, setGroupedEntries] = useState<Array<GroupedEntry>>([]);
  const [programEmployees, setProgramEmployees] = useState<Array<EmployeeType>>(
    []
  );
  const [employeesReport, setEmployeesReport] = useState<
    Array<EmployeeReportType>
  >([]);

  const [analytics, analyticsUpdate] = useReducer(
    ANALYTICS_REDUCER,
    REDUCER_INIT
  );

  const [controlPanelForm] = Form.useForm();
  const clientCompany = Form.useWatch("clientCompany", controlPanelForm);

  const mapRef = useRef(null);
  const sidebarRef = useRef(null);
  const controlPanelRef = useRef<ControlPanelRefType>(null);

  // #region clearFilters
  function clearFilters() {
    setFilters({
      schedules: [],
      crewSearch: "",
      liveStatus: [],
      employeeId: [],
      employeeName: [],
      scheduleMatch: [],
      punchTimeEnd: null,
      employeeSearch: "",
      employeeNumber: [],
      scheduleAddress: "",
      punchTimeStart: null,
    });
    if (mapRef?.current) {
      mapRef.current.fitBoundsToMarkers([]);
    }
  }

  async function getLatestEntries(companyName: string, date?: string) {
    message.loading({
      duration: 0,
      key: "liveEntries",
      content: "Getting latest entries...",
    });
    getEmployeesLiveEntries({ companyName, date })
      .then((res) => {
        const nowTime = Date.now();
        localStorage.setItem("lastReportFetch", JSON.stringify(nowTime));
        controlPanelRef?.current?.setLastFetch?.(nowTime);
        setDegEntries((prev) => {
          const entries = res
            .map((el: EntryType) => el.entryId)
            .map((el) => ({ ...el, jobAddress: el?.jobsiteMatch?.jobAddress }));
          return res.concat(
            prev.filter((el) => !entries.includes(el?.entryId))
          );
        });

        message.success({
          key: "liveEntries",
          duration: 1.8,
          content: "Entries updated",
        });
      })
      .catch((error) => console.log("error: ", error));
  }

  // #region getEmployeeReports
  function getEmployeeReports(getLive?: boolean) {
    const selectedClient = clientConfigs.find(
      (el) => el.configId === clientCompany
    );
    if (!getLive) {
      message.loading({
        duration: 0,
        key: "getEmployeeReports",
        content: "Getting employees report....",
      });
    }
    getPayrollLiveReport({
      excludedEmployees: [],
      clientKey: selectedClient.clientKey,
      selectedDate: dayjsNY().startOf("d").format("YYYY-MM-DD"),
    })
      .then((res: AxiosResponse<Array<EmployeeReportType>>) => {
        const tmpEmployeesReport = res.data.map((el) => ({
          ...el,
          company: selectedClient?.clientName,
          employeeId: `${selectedClient?.clientName}-${Number(
            el.employeeNumber
          )}`,
        }));

        setEmployeesReport((prev) => {
          if (
            !prev.length ||
            prev?.[0]?.company !== selectedClient?.clientName
          ) {
            return tmpEmployeesReport;
          }

          let modifiedResponse = [];
          for (let i = 0; i < prev.length; i++) {
            const empReport = prev[i];
            let empToPush = empReport;
            for (let j = 0; j < tmpEmployeesReport.length; j++) {
              const resEmp = tmpEmployeesReport[j];
              if (
                empReport?.fingerCheckId === resEmp?.fingerCheckId &&
                empReport.punchTime !== resEmp.punchTime &&
                !!resEmp?.punchTime
              ) {
                empToPush = resEmp;
              }
            }
            modifiedResponse.push(empToPush);
          }
          return modifiedResponse;
        });
        message.destroy();
      })
      .catch((err) => {
        console.log("Get payroll live report error: ", err);
        setEmployeesReport([]);
        message.destroy();
      });

    if (getLive) {
      getLatestEntries(selectedClient?.clientName);
    } else {
      const appliedFilter = [
        {
          conditions: [
            {
              id: uuid(),
              formula: "is",
              operator: "AND",
              column: "company",
              dataType: "string",
              columnType: "string",
              value: selectedClient?.clientName,
            },
          ],
          id: uuid(),
          operator: "AND",
        },
      ];
      API.get("employeeReports", "/employeeReports", {
        queryStringParameters: {
          getMaxLimit: "true",
          withPagination: "true",
          ExclusiveStartKey: undefined,
          filters: JSON.stringify(appliedFilter),
        },
      })
        .then((res) => {
          getEmployeeAnalytics({
            degGridApi: {},
            analyticsUpdate,
            degRows: res.employeeReports,
          });

          setDegEntries(
            res.employeeReports.map((el) => ({
              ...el,
              jobAddress: el?.jobsiteMatch?.jobAddress,
            }))
          );
          message.destroy();
        })
        .catch((err) => {
          console.log("error: ", err);
          message.destroy();
        });
    }
  }

  function goFullScreen() {
    let elem = document.documentElement;
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    } else {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      }
    }
  }

  function onPageSelect(page: string) {
    setSelectedPage(page);
    sessionStorage.setItem("selectedPage", page);
  }

  const getLatLngFromAddress = async (address: string) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${GOOGLE_API_KEY}`
      );
      const data = await response.json();

      if (data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        return { lat, lng };
      } else {
        throw new Error("No results found");
      }
    } catch (error) {
      console.error("Error:", error.message, address);
    }
  };

  // #region filteredCrews
  const filteredCrews = useMemo(() => {
    let filteredData = [];
    for (let i = 0; i < crewTeams.length; i++) {
      const team = crewTeams[i];
      let pass = true;
      if (
        filters?.crewSearch?.length &&
        !team?.crewTeamName
          ?.toLowerCase()
          .includes(filters?.crewSearch.toLowerCase())
      ) {
        pass = false;
      }

      if (pass) {
        filteredData.push(team);
      }
    }
    return filteredData;
  }, [crewTeams, filters?.crewSearch]);

  // #region filteredSchedules
  const filteredSchedules = useMemo(() => {
    let filterPassSchedules = [];
    if (!schedules?.length) {
      return [];
    }

    for (const el of schedules as Array<
      ScheduleType & { jobAddress?: string }
    >) {
      let filterPass = true;
      const filterKeys = Object.keys(filters);
      if (filterKeys.length) {
        for (let k = 0; k < filterKeys.length; k++) {
          const key = filterKeys[k];
          if (SCHEDULE_FILTERS.includes(key)) {
            const filterValue = filters[key];
            if (filterValue?.length && !filterValue.includes(el?.projectId)) {
              filterPass = false;
            }
            if (key === "scheduleAddress" && !!filterValue) {
              filterPass = (el?.[key] || el?.jobAddress)
                .toLowerCase()
                .includes(filterValue.toLowerCase());
            }
          }
        }
        if (filterPass) {
          filterPassSchedules.push(el);
        }
      } else {
        filterPassSchedules = schedules;
      }
    }

    return filterPassSchedules;
  }, [schedules, JSON.stringify(filters)]);

  // #region filteredEmployees
  const filteredEmployees = useMemo(() => {
    let filterPassEmployees = [];
    if (!employeesReport?.length) {
      return [];
    }

    const filterKeys = Object.keys(filters);
    for (const emp of employeesReport) {
      let filterPass = true;

      if (filterKeys?.length) {
        for (let k = 0; k < filterKeys.length; k++) {
          const key = filterKeys[k];
          if (EMPLOYEE_FILTERS.includes(key)) {
            const filterValue = filters[key];
            if (
              filterValue?.length &&
              !filterValue.includes(
                emp?.[key] || emp?.projectId || emp?.color
              ) &&
              key !== "employeeSearch"
            ) {
              filterPass = false;
            }

            if (
              key === "liveStatus" &&
              filterValue.length === 1 &&
              filterValue.includes("no-match")
            ) {
              filterPass = emp.color === "#e9c466";
            }

            if (
              key === "liveStatus" &&
              filterValue.length === 1 &&
              filterValue.includes("In")
            ) {
              filterPass = emp.liveStatus === "In" && emp.color !== "#e9c466";
            }

            if (key === "punchTimeStart" && !!filterValue && !!emp?.punchTime) {
              filterPass =
                filterPass &&
                parseInTz(emp?.punchTime).valueOf() >= filterValue;
            }
            if (key === "punchTimeEnd" && !!filterValue && !!emp?.punchTime) {
              filterPass =
                filterPass &&
                parseInTz(emp?.punchTime).valueOf() <= filterValue;
            }
            if (filterPass && key === "employeeSearch" && !!filterValue) {
              filterPass =
                filterPass &&
                emp.employeeName
                  .toLowerCase()
                  .includes(filterValue.toLowerCase());
              if (
                "foreman".includes(filterValue.toLowerCase()) &&
                emp?.isForeman
              ) {
                filterPass = true;
              }
            }
          }
        }
        if (filterPass) {
          filterPassEmployees.push(emp);
        }
      } else {
        filterPassEmployees = employeesReport;
      }
    }

    return filterPassEmployees;
  }, [employeesReport, JSON.stringify(filters)]);

  const clientConfigs = useMemo(() => {
    if (programFields?.length) {
      let index = programFields.findIndex(
        (field) => field.fieldName === "Payroll Configuration"
      );

      return programFields[index].fieldOptions;
    }
    return [];
  }, [programFields]);

  const todayScheduleField = useMemo(() => {
    if (!programFields?.length) {
      return;
    }
    let index = programFields.findIndex(
      (el) => el.fieldName === "Today's Scheduling"
    );
    const selectedField = programFields?.[index];
    return selectedField;
  }, [programFields]);

  // #region useEffects
  // Get initial data and employee reports interval
  useEffect(() => {
    async function getJobs() {
      let allJobs = [];
      await API.get("jobsites", "/jobsites", {})
        .then((res) => {
          setJobsites(res);
          allJobs = res;
        })
        .catch((error) => console.log("Error getting Jobsites: ", error));

      const today = parseInTz().startOf("d").toISOString();
      const yesterday = parseInTz().subtract(1, "d").startOf("d").toISOString();

      API.get("fetchSchedulesByDay", `/fetchSchedulesByDay`, {
        queryStringParameters: {
          searchDates: JSON.stringify([today, yesterday]),
        },
      })
        .then(async (res) => {
          let modifiedRes = [];
          for (let i = 0; i < res?.items?.length; i++) {
            const schedule = res?.items?.[i];
            const jIndex = allJobs.findIndex(
              (job) => job.projectId === schedule.projectId
            );

            const scheduleObject = {
              ...schedule,
              addressPosition: allJobs?.[jIndex]?.addressPosition,
              geoFenceInfo: allJobs?.[jIndex]?.geoFenceInfo,
              radius: allJobs?.[jIndex]?.locationRadius || 300,
            };

            if (!allJobs?.[jIndex]?.addressPosition?.lat) {
              const addressCoordinates = await getLatLngFromAddress(
                schedule?.scheduleAddress
              );
              Object.assign(scheduleObject, {
                addressPosition: addressCoordinates,
              });
            }
            if (
              modifiedRes.findIndex(
                (el) => el?.projectId === scheduleObject?.projectId
              ) === -1
            ) {
              modifiedRes.push(scheduleObject);
            }
          }
          setTodaySchedules(modifiedRes);
        })
        .catch((err) => console.log("err schedule days: ", err));
    }
    getJobs();
    API.get("crews", "/crews", {})
      .then((res) => {
        setProgramEmployees(res);
      })
      .catch((error) =>
        console.log("Error getting program employees: ", error)
      );
    API.get("crewTeams", "/crewTeams", {})
      .then((res) => {
        setCrewTeams(res);
      })
      .catch((error) => console.log("Error getting crew teams: ", error));
  }, [todayScheduleField]);

  useEffect(() => {
    if (!!clientCompany) {
      getEmployeeReports(false);
      const fetchReportInterval = setInterval(() => {
        const now = new Date();

        const minute = now.getMinutes();
        if ([0, 15, 30, 45].includes(minute)) {
          const nowTime = Date.now();
          localStorage.setItem("lastReportFetch", JSON.stringify(nowTime));
          controlPanelRef?.current?.setLastFetch?.(nowTime);
          getEmployeeReports(false);
        }
      }, minute);

      return () => {
        clearInterval(fetchReportInterval);
      };
    }
  }, [clientCompany]);

  // get schedule matches with employee punchCoordinates
  useEffect(() => {
    if (
      clientCompany &&
      jobsites?.length &&
      crewTeams?.length &&
      clientConfigs?.length &&
      todaySchedules?.length &&
      employeesReport?.length
    ) {
      const selectedClient = clientConfigs.find(
        (el) => el.configId === clientCompany
      );
      const clientIndex = todayScheduleField.fieldOptions.findIndex(
        (el) => el?.clientName === selectedClient?.clientName
      );

      const defaultExcelData =
        clientIndex > -1
          ? todayScheduleField.fieldOptions?.[clientIndex]?.excelData
          : [];
      matchSchedulesWithExcelData(
        jobsites,
        todaySchedules,
        defaultExcelData
      ).then(({ jobsitesIncluded, schedulesIncluded }) => {
        const { jobsitesMatch, matchedEmployees } = coordinatesMatch({
          crewTeams,
          jobs: jobsitesIncluded,
          employees: employeesReport,
          schedules: schedulesIncluded,
        });

        const projectsIncluded = uniqBy(
          jobsitesMatch,
          (job: JobsiteType) => job?.projectId
        );

        setSchedules(() => {
          const schedulesIncludedd = todaySchedules.map((sch) => sch.projectId);
          return todaySchedules.concat(
            projectsIncluded.filter(
              (jb: ScheduleType) => !schedulesIncludedd.includes(jb.projectId)
            ) as Array<ScheduleType>
          );
        });

        setEmployeesReport((prev) =>
          prev.map((emp) => {
            const empIndex = matchedEmployees.findIndex(
              (el) => el.employeeNumber === emp.employeeNumber
            );
            const scheduleMatch = schedulesIncluded.findIndex(
              (el) => el.projectId === matchedEmployees?.[empIndex]?.projectId
            );
            if (empIndex > -1 && scheduleMatch > -1) {
              return matchedEmployees[empIndex];
            } else if (empIndex > -1 && scheduleMatch === -1) {
              const jobIndex = jobsitesIncluded.findIndex(
                (job) =>
                  job.projectId === matchedEmployees?.[empIndex].projectId
              );
              const jobMatch = jobsitesIncluded?.[jobIndex];
              // if (
              //   matchedEmployees?.[empIndex].employeeName.includes(
              //     "Emmanuel Delgadillo"
              //   )
              // ) {
              //   console.log("matchedEmployees?.[empIndex]: ", {
              //     emp: matchedEmployees?.[empIndex],
              //     jobMatch,
              //   });
              // }
              return {
                ...matchedEmployees?.[empIndex],
                jobsiteId: jobMatch?.jobsiteId,
              };
            } else {
              let foreman = false;
              let teamIndex = crewTeams.findIndex((el) => {
                const isForeman =
                  Number(
                    (el?.crewForeman?.employeeId || "").split("-")?.[1]
                  ) === Number(emp.employeeNumber);

                const isMember =
                  el.crewMembers.findIndex(
                    (mem) =>
                      Number((mem?.employeeId || "").split("-")?.[1]) ===
                      Number(emp.employeeNumber)
                  ) > -1;

                if (isForeman) {
                  foreman = isForeman;
                }
                return isForeman || isMember;
              });
              let jobIncluded = emp?.projectId
                ? schedulesIncluded.findIndex(
                    (el) => el?.projectId == emp?.projectId
                  ) > -1 ||
                  jobsitesIncluded.findIndex(
                    (el) => el?.projectId === emp?.projectId
                  ) > -1
                : false;

              return {
                ...emp,
                isForeman: foreman,
                color:
                  emp.liveStatus === "In" && !jobIncluded ? "#e9c466" : null,
                projectId: jobIncluded ? emp.projectId : undefined,
                employeeId: `${emp?.company}-${Number(emp?.employeeNumber)}`,
                crewTeamId: crewTeams?.[teamIndex]?.crewTeamId,
                crewTeamName: crewTeams?.[teamIndex]?.crewTeamName || "No Team",
              };
            }
          })
        );
      });
    }
  }, [
    jobsites,
    crewTeams,
    clientCompany,
    clientConfigs,
    todaySchedules,
    todayScheduleField,
    employeesReport?.length,
  ]);

  useEffect(() => {
    const shifts = groupEntriesInShifts({
      jobsites,
      analytics,
      rowData: degEntries,
      crews: programEmployees,
    });
    const groupedReports = getShiftOrder({ entries: degEntries });
    setShiftEntries(shifts);
    setGroupedEntries(groupedReports);
  }, [JSON.stringify(analytics), degEntries]);

  return (
    <PayrollLiveContext.Provider
      value={{
        mapRef,
        filters,
        jobsites,
        crewTeams,
        schedules,
        setFilters,
        sidebarRef,
        degEntries,
        goFullScreen,
        selectedPage,
        shiftEntries,
        setSchedules,
        onPageSelect,
        clearFilters,
        clientCompany,
        filteredCrews,
        clientConfigs,
        employeesInfo,
        setDegEntries,
        schedulesInfo,
        groupedEntries,
        setShiftEntries,
        employeesReport,
        controlPanelRef,
        setSelectedPage,
        setEmployeesInfo,
        programEmployees,
        controlPanelForm,
        setSchedulesInfo,
        getLatestEntries,
        setGroupedEntries,
        filteredEmployees,
        filteredSchedules,
        setEmployeesReport,
        getEmployeeReports,
        setProgramEmployees,
        analytics: analytics as AnalyticsType,
      }}
    >
      <section
        className={`payroll-live ${isDarkMode ? "payroll-live-dark" : ""}`}
      >
        <PayrollLiveControlPanel ref={controlPanelRef} />
        <div className="payroll-live-body">
          <PayrollLiveSidebar ref={sidebarRef} />
          {selectedPage === "map" ? <PayrollLiveMap ref={mapRef} /> : null}
          {selectedPage === "deg" ? <LiveDegPayroll /> : null}
          {selectedPage === "employeeReports" ? <EmployeeReports /> : null}
          {selectedPage === "analytics" ? <PayrollLiveAnalytics /> : null}
        </div>
      </section>
    </PayrollLiveContext.Provider>
  );
}

export default PayrollLive;
