import { Checkbox, Collapse, Modal, Tooltip } from "antd";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { useMemo, useState } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import "./RecordsToUpdate.scss";
import {
  getFieldsToUpdate,
  onUpdateTeamHandler,
} from "../helpers/helperFunctions";
import { useSelector } from "react-redux";
import {
  CollapseExtra,
  RecordsToUpdateFooter,
  TopicTitle,
} from "./CollapseExtra";
import { driveApi } from "../../../../../../../integrations/DriveRequest";
import {
  MondaySwitch,
  RedWarningModal,
} from "../../../../../../commonComponents";
import { InputComponent } from "../../../../../../SidebarPages/Fleet/components";
import { SmallLoadableComp } from "../../../../../../Sidebars/components";

function RecordsToUpdate({
  visible,
  setVisible,
  recordsToUpdate = [],
  callBack,
}) {
  const { accessToken } = useSelector((state) => state?.accessToken);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [rowData, setRowData] = useState(recordsToUpdate || []);
  const [loading, setLoading] = useState(false);
  const [disableInput, setDisableInput] = useState(false);
  const [giveDriveAccess, setGiveDriveAccess] = useState(true);

  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selected, setSelected] = useState({});

  const [searchInput, setSearchInput] = useState(
    rowData?.reduce((acc, { topic }) => {
      acc[topic] = "";
      return acc;
    }, {})
  );

  const driveRequest = driveApi({ accessToken });

  const allUsers = useMemo(
    () => userConfiguration?.allUsers?.Items || [],
    [userConfiguration]
  );
  const usersObj = useMemo(() => {
    return allUsers.reduce((acc, user) => {
      acc[user.cognitoUserId] = {
        nameOfUser: user.nameOfUser,
        cognitoUserId: user.cognitoUserId,
        identityId: user.identityId,
        googleDriveFileId: user.googleDriveFileId,
        userName: user.userName,
        groupName: user.groupName,
      };
      return acc;
    }, {});
  }, [allUsers]);

  const panelStyle = {
    marginBottom: 24,
    background: "#fafafa",
    borderRadius: "10px",
    border: "none",
  };

  async function fetchFieldsToUpdate() {
    setLoading(true);
    await getFieldsToUpdate(
      selectedUsers.map((user) => usersObj[user]),
      null,
      true
    ).then((res) => {
      const filter = res.filter((el) => el?.fields?.length > 0);
      setLoading(false);
      setDisableInput(true);
      setSearchInput(
        filter.reduce((acc, { topic }) => {
          acc[topic] = "";
          return acc;
        }, {})
      );
      setRowData(filter);
    });
  }

  return (
    <Modal
      {...{
        centered: true,
        open: visible,
        onCancel: () => setVisible(false),
        title: `Select Records to Update`,
        className: "recordsToUpdateModal",
        maskClosable: false,
        closeIcon: <XIcon />,
        footer: (
          <RecordsToUpdateFooter
            {...{
              checkRecords: {
                visible: !rowData?.length,
                onClick: fetchFieldsToUpdate,
                loading,
                checkDisabled: selectedUsers.length === 0,
              },
              disabled:
                Object.keys(selected).length === 0 ||
                Object.values(selected).every((item) => item.length === 0),
              onClose: () => setVisible(false),
              onSave: () => {
                setWarningModalVisible(true);
              },
            }}
          />
        ),
      }}
    >
      <div className="RTUInputs">
        {recordsToUpdate?.length === 0 && (
          <InputComponent
            {...{
              label: "Users",
              placeholder: "Select Users",
              customOptions: allUsers.map((user) => ({
                label: user.nameOfUser,
                value: user.cognitoUserId,
              })),
              initialValue: selectedUsers || [],
              disabled: disableInput,
              onChange: (e) => {
                setSelectedUsers(e);
              },
              mode: "multiple",
              maxTagCount: 3,
              maxTagPlaceholder: (e) => (
                <Tooltip title={e.map(({ label }) => label).join(", ")}>
                  <span>{`+ ${e.length} more`}</span>
                </Tooltip>
              ),
              type: "select",
              style: { maxWidth: "480px", minWidth: "300px" },
            }}
          />
        )}
        {rowData?.length > 0 && (
          <div className="switch-group">
            <label className="switch-group-label">Give Drive Access:</label>
            <MondaySwitch
              {...{
                checked: giveDriveAccess,
                onToggleSwitch: (e) => {
                  setGiveDriveAccess(e);
                },
                onText: "Enabled",
                offText: "Disabled",
              }}
            />
          </div>
        )}
      </div>
      <SmallLoadableComp loading={loading} />
      {rowData?.length > 0 && (
        <Collapse
          {...{
            className: "RTUCollapse",
            ghost: true,
            defaultActiveKey: rowData.map(({ topic }) => topic),
            expandIcon: ({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            ),
            bordered: false,
            expandIconPosition: "end",
            collapsible: "icon",
            items: rowData?.map(({ topic, fields }) => {
              const checkAll = selected[topic]?.length === fields.length;
              const onCheckAllChange = (e) => {
                e.stopPropagation();
                setSelected((prev) => {
                  let obj = {};
                  e.target.checked
                    ? (obj = {
                        ...prev,
                        [topic]: fields,
                      })
                    : (obj = { ...prev, [topic]: [] });
                  return obj;
                });
              };
              console.log({ fields, topic });
              return {
                key: topic,
                label: (
                  <TopicTitle
                    {...{
                      topic,
                      onCheckAllChange,
                      checkAll,
                      indeterminate:
                        selected?.[topic]?.length > 0 &&
                        selected?.[topic]?.length < fields?.length,
                    }}
                  />
                ),
                // camelCaseToNormalText(topic),
                children: (
                  <div key={topic}>
                    <Checkbox.Group
                      value={selected[topic]}
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setSelected((prev) => {
                          return { ...prev, [topic]: e };
                        });
                      }}
                    >
                      {fields
                        ?.filter((el) =>
                          el?.label
                            ?.toLowerCase()
                            ?.includes(searchInput?.[topic]?.toLowerCase())
                        )
                        ?.map((item, i) => {
                          return (
                            <Checkbox key={item.label + i} value={item}>
                              <Tooltip
                                title={
                                  item.newUsers?.length > 0
                                    ? () => (
                                        <div>
                                          {item.newUsers.map((user, i) => (
                                            <div key={i}>{user.nameOfUser}</div>
                                          ))}
                                        </div>
                                      )
                                    : null
                                }
                              >
                                {item?.label || "Record Name Missing"}
                              </Tooltip>
                            </Checkbox>
                          );
                        })}
                    </Checkbox.Group>
                  </div>
                ),
                style: panelStyle,
                extra: (
                  <CollapseExtra
                    {...{
                      topic,
                      searchInput,
                      setSearchInput,
                      onCheckAllChange,
                      checkAll,
                      indeterminate:
                        selected?.[topic]?.length > 0 &&
                        selected?.[topic]?.length < fields?.length,
                    }}
                  />
                ),
              };
            }),
          }}
        />
      )}
      {warningModalVisible && (
        <RedWarningModal
          {...{
            visible: warningModalVisible,
            onCancel: () => setWarningModalVisible(false),
            footerProps: {
              onConfirm: async () => {
                if (callBack) {
                  callBack(selected);
                  setVisible(false);
                } else {
                  await onUpdateTeamHandler(
                    selected,
                    setVisible,
                    driveRequest,
                    setWarningModalVisible,
                    (recordsToUpdate || [])?.length === 0,
                    giveDriveAccess
                  );
                }
              },
            },
            titleText: `Confirm Warning`,
          }}
        >
          <p>
            This action might take a while to complete. Please be patient until
            the modal closes.
          </p>
        </RedWarningModal>
      )}
    </Modal>
  );
}

export default RecordsToUpdate;
