import { Dayjs } from "dayjs";
import { uniqBy } from "lodash";
import { Form, FormInstance } from "antd";
import { Dispatch, SetStateAction, useContext, useMemo } from "react";

import { EntryType } from "../../payrollLiveTypes";
import PayrollLiveContext from "../../PayrollLiveContext";
import { HoverButton } from "src/components/commonComponents";
import CardComponent from "src/components/CardComponent/CardComponent";

import "./UnregisteredEmployees.scss";

type NewEmpModalType = {
  employeeId?: string;
  accountName?: string;
  employeeNumber?: string;
  employeeFullName?: string;
};

interface Props {
  setNewEmpModal: Dispatch<SetStateAction<NewEmpModalType>>;
  form: FormInstance<{ searchField: string; reportDate?: Array<Dayjs> }>;
}

function UnregisteredEmployees(props: Props) {
  const { form, setNewEmpModal } = props;
  const { degEntries, employeesReport, programEmployees } =
    useContext(PayrollLiveContext);

  const searchField = Form.useWatch("searchField", form);

  function onAddEmployee(data: EntryType) {
    let index = employeesReport.findIndex(
      (el) => el?.employeeId === data.employeeId
    );
    if (index > -1) {
      const empReport = employeesReport[index];
      setNewEmpModal({
        employeeId: empReport?.employeeId,
        accountName: empReport?.company,
        employeeFullName: empReport?.employeeName,
        employeeNumber: empReport?.employeeNumber,
      });
    } else {
      setNewEmpModal({
        employeeId: data?.employeeId,
        accountName: data?.company,
        employeeFullName: data?.employeeFullName,
      });
    }
  }

  const unregisteredEmployees = useMemo(() => {
    let filteredEmployees = [];

    for (let i = 0; i < degEntries.length; i++) {
      const emp = degEntries[i];
      let pass = false;
      const programIndex = programEmployees.findIndex(
        (el) => el.employeeId === emp.employeeId
      );
      if (!emp?.crewId || programIndex === -1) {
        pass = true;
      }
      if (
        searchField?.length &&
        !(emp?.employeeFullName || "")
          ?.toLowerCase()
          .includes(searchField.toLowerCase())
      ) {
        pass = false;
      }
      if (pass) {
        filteredEmployees.push(emp);
      }
    }

    return uniqBy(filteredEmployees, (el) => el?.employeeFullName).map((el) => (
      <div className="emp-card">
        <label>{el.employeeFullName}</label>
        <HoverButton
          type="new"
          text="Add Employee"
          onClick={() => onAddEmployee(el)}
        />
      </div>
    ));
  }, [degEntries, searchField, programEmployees]);

  return (
    <CardComponent
      title="Unregistered Employees"
      className="unregistered-emp-card"
    >
      <>
        <div className="employees-container">
          {unregisteredEmployees?.length ? (
            unregisteredEmployees
          ) : (
            <div className="empty-unregistered-employees">
              There are no unregistered Employees
            </div>
          )}
        </div>
        <div className="unregistered-count">
          {unregisteredEmployees?.length || 0} Total Unregistered Employees
        </div>
      </>
    </CardComponent>
  );
}

export default UnregisteredEmployees;
