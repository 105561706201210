import { groupBy } from "lodash";

import { getBulkCoordinates } from "src/utils";
import { ScheduleType } from "../payrollLiveTypes";
import {
  JobsiteType,
  CoordinateType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import { withinRadius } from "../../Payroll/Tabs/Activity/components/payrollActivityModalData";

type ExcelDataType = {
  Name?: string;
  Role?: string;
  Crew?: string;
  Foreman?: string;
  Location?: string;
  Department?: string;
  "ID Number"?: number;
};

/**
 * Function that matches locations scheduled for employees in excel with schedules in program
 *
 * @param jobsites Jobsites registered in the program
 * @param schedules Today schedules from the program
 * @param excelData Data exported from excel sheet
 * @returns {Promise<{jobsitesIncluded: Array<JobsiteType>; schedulesIncluded: Array<ScheduleType>;}>}
 */
export async function matchSchedulesWithExcelData(
  jobsites: Array<JobsiteType>,
  schedules: Array<ScheduleType>,
  excelData: Array<ExcelDataType>
): Promise<{
  jobsitesIncluded: Array<JobsiteType>;
  schedulesIncluded: Array<ScheduleType>;
  updatedExcelData: Array<ExcelDataType & { Position?: CoordinateType }>;
}> {
  let schedulesIncluded: Array<ScheduleType> = [];
  const jobsitesIncluded: Array<JobsiteType> = [];

  let tmpExcelData = [];
  for (let i = 0; i < excelData.length; i++) {
    const data = excelData[i];
    if ((data?.Location || "")?.includes("++")) {
      const locationsInData = data.Location.split("++");
      for (let j = 0; j < locationsInData.length; j++) {
        const location = locationsInData[j];
        tmpExcelData.push({ ...data, Location: location });
      }
    } else {
      tmpExcelData.push(data);
    }
  }

  const allLocations = groupBy(
    tmpExcelData.flatMap((el) =>
      !!el?.Location && el?.Location !== "undefined"
        ? {
            ...el,
            Location: el?.Location,
          }
        : []
    ),
    "Location"
  );
  const listOfLocations = Object.keys(allLocations);
  const totalCoordinates = {};
  for (let i = 0; i < listOfLocations.length; i += 10) {
    const locations = listOfLocations.slice(i, i + 10);

    const coordinates = await getBulkCoordinates({
      addresses: locations,
    });

    for (const address in coordinates) {
      const addressName = Object.keys(coordinates?.[address] || {})?.[0];
      const position = coordinates[address][addressName];
      Object.assign(totalCoordinates, { [addressName]: position });

      let possibleMatch = { index: -1, distance: 9999 };
      for (let i = 0; i < schedules.length; i++) {
        const sch = schedules[i];
        const match = withinRadius(position, sch?.addressPosition, 600);
        if (match?.withinRange) {
          if (possibleMatch?.distance > match?.distanceInFeet) {
            possibleMatch = { index: i, distance: match?.distanceInFeet };
          }
        }
      }

      if (
        typeof possibleMatch?.index === "number" &&
        possibleMatch?.index > -1
      ) {
        schedulesIncluded.push({
          ...schedules[possibleMatch?.index],
          employeesAssigned: allLocations[addressName],
        });
      }

      if (!possibleMatch?.index || possibleMatch?.index !== 0) {
        let possibleJobMatch = { index: -1, distance: 9999 };
        for (let i = 0; i < jobsites.length; i++) {
          const job = jobsites[i];
          const match = withinRadius(position, job?.addressPosition, 600);
          if (match?.withinRange) {
            if (possibleJobMatch?.distance > match?.distanceInFeet) {
              possibleJobMatch = { index: i, distance: match?.distanceInFeet };
            }
          }
        }

        if (
          typeof possibleJobMatch?.index === "number" &&
          possibleJobMatch?.index > -1
        ) {
          jobsitesIncluded.push({
            ...jobsites[possibleJobMatch?.index],
            employeesAssigned: allLocations[addressName],
          });
        }
      }
    }
  }
  if (schedules?.length > schedulesIncluded?.length) {
    schedulesIncluded = schedulesIncluded.concat(
      schedules.filter(
        (el) =>
          schedulesIncluded.findIndex(
            (sch) => sch.scheduleId === el.scheduleId
          ) === -1
      )
    );
  }

  return {
    schedulesIncluded,
    jobsitesIncluded,
    updatedExcelData: excelData.map((el) => ({
      ...el,
      Position: totalCoordinates?.[el?.Location] || null,
    })),
  };
}
