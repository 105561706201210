import { InfoCircleOutlined } from "@ant-design/icons";
import { getChangedData } from "../../../../SidebarPages/Accounting/components/utilities";
import { Tooltip } from "antd";
import { uniqueStructure } from "../../../../SidebarPages/Inspections/page/DailyInspection/utils";

export const svgTickGreenIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.63966 11.0518L1.72166 7.58382C1.62863 7.48949 1.51778 7.41458 1.39555 7.36346C1.27332 7.31233 1.14215 7.286 1.00966 7.286C0.877168 7.286 0.745998 7.31233 0.62377 7.36346C0.501541 7.41458 0.390691 7.48949 0.297659 7.58382C0.203291 7.67817 0.128433 7.79018 0.0773599 7.91346C0.0262871 8.03674 0 8.16888 0 8.30232C0 8.43576 0.0262871 8.5679 0.0773599 8.69118C0.128433 8.81446 0.203291 8.92647 0.297659 9.02082L3.91766 13.1968C4.01134 13.2918 4.12297 13.3673 4.24606 13.4187C4.36914 13.4702 4.50124 13.4967 4.63466 13.4967C4.76808 13.4967 4.90017 13.4702 5.02326 13.4187C5.14635 13.3673 5.25798 13.2918 5.35166 13.1968L13.7017 2.23482C13.796 2.14047 13.8709 2.02846 13.922 1.90518C13.973 1.7819 13.9993 1.64976 13.9993 1.51632C13.9993 1.38288 13.973 1.25074 13.922 1.12746C13.8709 1.00418 13.796 0.892168 13.7017 0.797821C13.6086 0.703487 13.4978 0.628582 13.3755 0.577455C13.2533 0.526328 13.1221 0.5 12.9897 0.5C12.8572 0.5 12.726 0.526328 12.6038 0.577455C12.4815 0.628582 12.3707 0.703487 12.2777 0.797821L4.63966 11.0518Z"
      fill="#71CF48"
    />
  </svg>
);

const desktopSvg = (
  color = "#323338",
  dimensions = { width: "54", height: "44" }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={dimensions.width}
    height={dimensions.height}
    viewBox="0 0 54 44"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.521591 43.4806C0.869318 43.8269 1.30909 44 1.84091 44H52.1591C52.6909 44 53.1307 43.8269 53.4784 43.4806C53.8261 43.1343 54 42.6963 54 42.1667C54 41.637 53.8261 41.1991 53.4784 40.8528C53.1307 40.5065 52.6909 40.3333 52.1591 40.3333H1.84091C1.30909 40.3333 0.869318 40.5065 0.521591 40.8528C0.173864 41.1991 0 41.637 0 42.1667C0 42.6963 0.173864 43.1343 0.521591 43.4806ZM3.55909 35.5667C4.29545 36.3 5.15455 36.6667 6.13636 36.6667H47.8636C48.8455 36.6667 49.7045 36.3 50.4409 35.5667C51.1773 34.8333 51.5455 33.9778 51.5455 33V3.66667C51.5455 2.68889 51.1773 1.83333 50.4409 1.1C49.7045 0.366667 48.8455 0 47.8636 0H6.13636C5.15455 0 4.29545 0.366667 3.55909 1.1C2.82273 1.83333 2.45455 2.68889 2.45455 3.66667V33C2.45455 33.9778 2.82273 34.8333 3.55909 35.5667ZM48 4H6V32H48V4Z"
      fill={color}
    />
  </svg>
);

const iPadSvg = (
  color = "#323338",
  dimensions = { width: "36", height: "44" }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={dimensions.width}
    height={dimensions.height}
    viewBox="0 0 36 44"
    fill="none"
  >
    <path
      d="M18.5087 40.25C18.9362 40.25 19.2917 40.1054 19.575 39.8162C19.8583 39.5271 20 39.1687 20 38.7413C20 38.3138 19.8554 37.9583 19.5663 37.675C19.2771 37.3917 18.9187 37.25 18.4913 37.25C18.0638 37.25 17.7083 37.3946 17.425 37.6838C17.1417 37.9729 17 38.3313 17 38.7588C17 39.1862 17.1446 39.5417 17.4338 39.825C17.7229 40.1083 18.0813 40.25 18.5087 40.25ZM3 44C2.175 44 1.46875 43.7062 0.88125 43.1188C0.29375 42.5312 0 41.825 0 41V3C0 2.175 0.29375 1.46875 0.88125 0.88125C1.46875 0.29375 2.175 0 3 0H33C33.825 0 34.5312 0.29375 35.1188 0.88125C35.7062 1.46875 36 2.175 36 3V41C36 41.825 35.7062 42.5312 35.1188 43.1188C34.5312 43.7062 33.825 44 33 44H3ZM3 33.5H33V7.5H3V33.5Z"
      fill={color}
    />
  </svg>
);

const mobileSvg = (
  color = "#323338",
  dimensions = { width: "28", height: "44" }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={dimensions.width}
    height={dimensions.height}
    viewBox="0 0 28 44"
    fill="none"
  >
    <path
      d="M3 44C2.2 44 1.5 43.7 0.9 43.1C0.3 42.5 0 41.8 0 41V3C0 2.2 0.3 1.5 0.9 0.9C1.5 0.3 2.2 0 3 0H25C25.8 0 26.5 0.3 27.1 0.9C27.7 1.5 28 2.2 28 3V41C28 41.8 27.7 42.5 27.1 43.1C26.5 43.7 25.8 44 25 44H3ZM14 40.25C14.4333 40.25 14.7917 40.1083 15.075 39.825C15.3583 39.5417 15.5 39.1833 15.5 38.75C15.5 38.3167 15.3583 37.9583 15.075 37.675C14.7917 37.3917 14.4333 37.25 14 37.25C13.5667 37.25 13.2083 37.3917 12.925 37.675C12.6417 37.9583 12.5 38.3167 12.5 38.75C12.5 39.1833 12.6417 39.5417 12.925 39.825C13.2083 40.1083 13.5667 40.25 14 40.25ZM3 33.5H25V7.5H3V33.5Z"
      fill={color}
    />
  </svg>
);

export const deviceManagementTooltipJsx = (
  <Tooltip
    destroyTooltipOnHide={true}
    overlayInnerStyle={{ color: "#323338" }}
    color="#d5f1c9"
    title="The autocomplete suggestion provides real-time search suggestions as users type in an input field. If the displayed suggestions do not meet your needs or preferences, you have the flexibility to enter your own custom input directly into the input field."
  >
    <InfoCircleOutlined style={{ color: "lightgray" }} />
  </Tooltip>
);

export const devices = [
  {
    deviceName: "Website",
    deviceId: "0",
    deviceIcon: desktopSvg,
    deviceLabel: "webManagement",
  },
  {
    deviceName: "IPad",
    deviceId: "1",
    deviceIcon: iPadSvg,
    deviceLabel: "iPadManagement",
  },
  {
    deviceName: "Mobile",
    deviceId: "2",
    deviceIcon: mobileSvg,
    deviceLabel: "mobileManagement",
  },
];

export function combineDepartmentsAndDevices(departments, devices) {
  let combinedObjects = [];

  departments.forEach((department) => {
    devices.forEach((device) => {
      combinedObjects.push({
        departmentName: department.departmentName,
        departmentColor: department.color,
        device: device.deviceName,
        deviceId: device.deviceId,
        deviceLabel: device.deviceLabel,
        roles: department.groupNames,
      });
    });
  });

  return combinedObjects;
}

export function prepareLogsToSave({
  recordId,
  recordName,
  actionType,
  curr,
  prev,
}) {
  const logToSave = {
    recordId,
    recordName,
    actionType,
    currentData: getChangedData(curr, prev),
    previousData: getChangedData(curr, prev),
    updatedKeys: [""],
  };

  return logToSave;
}

export function validation(allData = [], newData = "") {
  const lowerCaseNewData = newData ? String(newData).toLowerCase().trim() : "";
  if (lowerCaseNewData !== "" && allData.length !== 0) {
    return allData.some((el) => el.toLowerCase().trim() === lowerCaseNewData);
  }

  return false;
}

export function addDepartment(original) {
  const modified = {};

  const departments = [
    "Sales",
    "Project Management",
    "Engineering",
    "Executive",
    "Fleet",
    "Finance",
    "Field",
    "Safety",
    "Developers",
    "Payroll",
    "Departamenti i Lyfave",
  ];

  for (const deviceKey in original) {
    if (Object.hasOwnProperty.call(original, deviceKey)) {
      modified[deviceKey] = {};

      departments.forEach((department) => {
        modified[deviceKey][department] = original[deviceKey];
      });
    }
  }

  return modified;
}

const transformationRules = {
  "Fleets Inventory": {
    value: "Fleets Inventory",
    enhanced: "Fleet Inventory",
  },
  Fleet: { value: "Fleet", enhanced: "Fleets" },
  TasksManager: { value: "TasksManager", enhanced: "Tasks" },
  "Fleet Dispatching": { value: "Fleet Dispatching", enhanced: "Dispatches" },
  "Fleet Activity": { value: "Fleet Activity", enhanced: "Activity" },
  "Fleets Incidents": {
    value: "Fleets Incidents",
    enhanced: "Fleet Incident",
  },
  "Fleet Maintenance": {
    value: "Fleet Maintenance",
    enhanced: "Fleets Maintenances",
  },
  "Fleet Defects": {
    value: "Fleet Defects",
    enhanced: "Defects",
  },
  Charges: {
    value: "Charges",
    enhanced: "Charge",
  },
  Payments: {
    value: "Payments",
    enhanced: "Receive Payments",
  },
  "Incidents Property Damage": {
    value: "Incidents Property Damage",
    enhanced: "Property Damage Incidents",
  },
  "Incidents Personal Injury": {
    value: "Incidents Personal Injury",
    enhanced: "Personal Injury Incident",
  },
  "Other Trade Incident": {
    value: "Other Trade Incident",
    enhanced: "Other Incident",
  },
  "Claims General": {
    value: "Claims General",
    enhanced: "Claims",
  },
};

const stringsToDontShow = [
  "PermitDrawings",
  "Billed Services",
  "Task Manager",
  "Fleets Dispatching",
  "Fleets Drivers",
  "Invoices",
  "view",
  "/",
  "-",
  "chart",
  "password",
  "settings",
  "log",
  "pdf",
].map((str) => str.toLowerCase());

export const enhanceTitlesForDeviceManagement = (titles) => {
  const toReturn = titles
    .filter(
      (item) =>
        !stringsToDontShow.some((str) => item.toLowerCase().includes(str))
    )
    .map((item) => {
      const transformation = transformationRules[item];
      const enhancedValueAndLabel = transformation
        ? transformation.value === item
          ? transformation.enhanced
          : item
        : item;

      return enhancedValueAndLabel;
    });
  return uniqueStructure(toReturn.map((value) => value)).map((el, idx) => ({
    value: el,
    label: el,
    key: idx,
  }));
};

const transformationRulesForColumns = {
  Subcontractors: {
    value: "Subcontractors",
    enhanced: "Accounts",
  },
};

export const enhanceColumnsForDeviceManagement = (title) => {
  const needsToBeEnhanced = transformationRulesForColumns?.[title]?.value
    ? transformationRulesForColumns[title].enhanced
    : title;

  return needsToBeEnhanced;
};
