import { message } from "antd";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";

import {
  getShiftAnalytics,
  groupEntriesInShifts,
  gridCustomOverlayLoading,
} from "../utils";
import {
  WarningModal,
  MondayButton,
} from "../../../../../../../commonComponents";
import DegHeader from "../DegHeader/DegHeader";
import {
  formatNumber,
  formatCurrency,
  compareIncluding,
} from "../../../../../../../SidebarPages/utils";
import DegModalContext from "../DegModalContext";
import FooterTotals from "../FooterTotals/FooterTotals";
import { OVERHEAD_SHIFT_TYPES } from "../../degModalData";
import ShiftEditModal from "../ShiftEditModal/ShiftEditModal";
import SplitShiftModal from "../SplitShiftModal/SplitShiftModal";
import TeamShiftsModal from "../TeamShiftsModal/TeamShiftsModal";
import OverviewFilterModal from "../OverviewFilter/OverviewFilterModal";
import ShiftsToSplitModal from "../ShiftsToSplitModal/ShiftsToSplitModal";
import { columnDefs, excelShiftColumnsConfig } from "./degShiftsStepData";
import { DEG_DATE_FORMAT, DEG_TIME_FORMAT } from "../utils/cellFunctions";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import createPDF from "../../../../../../../../integrations/AgGridToPdf/index";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import CashEntryShiftModal from "../../CashEntryShiftModal/CashEntryShiftModal";
import { TickIcon } from "../../../../../../Settings/settingsComponents/Roles/src";
import MultipleHrShiftsModal from "../../AddMultipleHrShiftsModal/MultipleHrShiftsModal";
import { ReactComponent as WarningIcon } from "../../../../../../../../icons/warningTriangle.svg";

import "./DegShiftsStep.scss";

let filter = {
  payrollType: [],
  shiftStatus: [],
  employeeRole: [],
  timeFrame: undefined,
  selectedJobsites: [],
  selectedEmployees: [],
};

function DegShiftStep() {
  const {
    crews,
    degName,
    jobsites,
    foremans,
    notFound,
    analytics,
    rowToEdit,
    crewTeams,
    isDarkMode,
    entriesActions,
    addEntryAction,
    rowData: degRows,
    setShiftsGridApi,
    addActionToRegister,
    shiftExternalFilter,
    filtersFromSelection,
    setRowData: setDegRows,
    shiftsGridApi: gridApi,
    updateStatisticsHandler,
    updateShiftExternalFilter,
  } = useContext(DegModalContext);

  const { base64 } = useSelector((state) => state.base64);

  const [rowData, setRowData] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [splitModal, setSplitModal] = useState(false);
  const [searchInput, setSearchInput] = useState(null);
  const [shiftColumnApi, setShiftColumnApi] = useState();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [fieldSelected, setFieldSelected] = useState(false);
  const [teamShiftModal, setTeamShiftModal] = useState(false);
  const [shiftEditVisible, setShiftEditVisible] = useState(false);
  const [massEntryConflict, setMassEntryConflict] = useState(false);
  const [shiftDetailsVisible, setShiftDetailsVisible] = useState(false);
  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const [recommendedSplitModal, setRecommendedSplitModal] = useState(false);
  const [cashEntryModalVisible, setCashEntryModalVisible] = useState(false);
  const [multipleHrShiftVisible, setMultipleHrShiftVisible] = useState(false);
  const [analyticsState, setAnalyticsState] = useState(
    // eslint-disable-next-line no-undef
    structuredClone(analytics)
  );

  const location = useLocation();
  const { employeeWeekTotals, generalOverheadTotal } = analyticsState;

  const paginationPageSize = Math.floor((window.innerHeight - 340) / 42);
  useEffect(() => {
    if (gridApi) {
      const shiftsData = groupEntriesInShifts({
        analytics,
        jobsites,
        crews,
        rowData: degRows,
      });

      if (shiftsData?.length) {
        setRowData(shiftsData);
      }
    }
  }, [gridApi]);

  useEffect(() => {
    if (gridApi) {
      filter = { ...shiftExternalFilter };
      gridApi.onFilterChanged();
    }
  }, [shiftExternalFilter, gridApi]);

  const onGridReady = useCallback((param) => {
    setShiftsGridApi(param.api);
    setShiftColumnApi(param.columnApi);
    param.api.showLoadingOverlay();
    // if (!!degRows?.length && rowToEdit?.degId) {
    // } else {
    //   param.api.hideOverlay();
    // }
  }, []);

  const onFirstDataRendered = useCallback(({ columnApi, api }) => {
    const degColumnsState = JSON.parse(
      sessionStorage.getItem("degShiftColumns")
    );
    if (!degColumnsState?.length) {
      columnApi.autoSizeAllColumns(false);
      sessionStorage.setItem(
        "degShiftColumns",
        JSON.stringify(columnApi.getColumnState())
      );
    } else {
      columnApi.applyColumnState({
        state: degColumnsState,
        applyOrder: true,
      });
    }
    if (rowToEdit?.degId && degRows?.length) {
      api.hideOverlay();
    }
  }, []);

  const isExternalFilterPresent = useCallback(() => {
    return (
      !!filter.selectedEmployees?.length ||
      !!filter.selectedJobsites?.length ||
      !!filter.payrollType?.length ||
      !!filter.employeeRole?.length ||
      !!filter.shiftStatus?.length ||
      !!filter.timeFrame
    );
  }, [JSON.stringify(filter)]);

  const doesExternalFilterPass = useCallback(
    ({ data }) => {
      let employeeCondition = true;
      let jobsiteCondition = true;
      let payrollCondition = true;
      let roleCondition = true;
      let statusCondition = true;
      let timeCondition = true;

      if (shiftExternalFilter.selectedEmployees?.length) {
        employeeCondition = shiftExternalFilter.selectedEmployees?.includes(
          data?.employeeId
        );
      }

      if (shiftExternalFilter.selectedJobsites?.length) {
        jobsiteCondition = shiftExternalFilter.selectedJobsites.includes(
          data?.jobsiteMatch?.jobsiteId
        );
      }

      if (shiftExternalFilter.payrollType?.length) {
        payrollCondition = shiftExternalFilter.payrollType.includes(
          data?.payrollType
        );
      }

      if (shiftExternalFilter.employeeRole?.length) {
        roleCondition = shiftExternalFilter.employeeRole.includes(
          data?.employeeRole
        );
      }

      if (shiftExternalFilter.shiftStatus?.length) {
        roleCondition = shiftExternalFilter.shiftStatus.includes(
          data?.shiftStatus
        );
      }

      if (shiftExternalFilter.timeFrame) {
        let start = dayjsNY(shiftExternalFilter.timeFrame[0])
          .startOf("D")
          .valueOf();
        let end = dayjsNY(shiftExternalFilter.timeFrame[1])
          .startOf("D")
          .valueOf();
        timeCondition = data?.punchDate
          ? dayjsNY(data?.punchDate).startOf("D").valueOf() >= start &&
            dayjsNY(data?.punchDate).startOf("D").valueOf() <= end
          : data?.firstClockIn >= start && data?.firstClockIn <= end;
      }

      return (
        employeeCondition &&
        jobsiteCondition &&
        timeCondition &&
        payrollCondition &&
        statusCondition &&
        roleCondition
      );
    },
    [shiftExternalFilter]
  );

  const onFilterChanged = useCallback(() => {
    let tmpRows = [];

    if (gridApi) {
      gridApi.forEachNode(({ data }) => {
        if (doesExternalFilterPass({ data })) {
          tmpRows.push(data);
        }
      });
    }
    let selectedEmployeesSet = new Set(
      tmpRows?.map(({ employeeId }) => employeeId)
    );
    let selectedEmployees = [];

    selectedEmployeesSet.forEach((e) => {
      selectedEmployees.push(e);
    });

    getShiftAnalytics({
      employeeList: selectedEmployees?.length
        ? selectedEmployees
        : crews.map((emp) => emp?.employeeId).filter(Boolean),
      gridApi,
      degRows: rowData,
      analyticsUpdate: setAnalyticsState,
      externalFiltersPass: doesExternalFilterPass,
    });
  }, [gridApi, shiftExternalFilter]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: FooterTotals,
          key: "footerTotals",
          align: "left",
        },
      ],
    };
  }, []);

  const memoColumnDefs = useMemo(() => {
    const degColumnsState = JSON.parse(
      sessionStorage.getItem("degShiftColumns")
    );

    if (fieldSelected) {
      const columns = columnDefs().map((column) =>
        column.field === fieldSelected
          ? column
          : { ...column, getQuickFilterText: () => "" }
      );

      let modifiedColumns = [];
      if (degColumnsState?.length) {
        for (let i = 0; i < degColumnsState?.length; i++) {
          const el = degColumnsState[i];
          const elIndex = columns.findIndex(({ field }) => field === el?.colId);
          if (columns?.[elIndex]) {
            modifiedColumns.push(columns[elIndex]);
          }
        }
      }

      return degColumnsState?.length ? modifiedColumns : columns;
    } else {
      const columns = columnDefs();
      let modifiedColumns = [];
      if (degColumnsState?.length) {
        for (let i = 0; i < degColumnsState?.length; i++) {
          const el = degColumnsState[i];
          const elIndex = columns.findIndex(({ field }) => field === el?.colId);
          if (columns?.[elIndex]) {
            modifiedColumns.push(columns[elIndex]);
          }
        }
      }
      return degColumnsState?.length ? modifiedColumns : columns;
    }
  }, [fieldSelected, rowData]);

  function exportShiftToExcel() {
    gridApi.exportDataAsExcel({
      processCellCallback(params) {
        const value = params?.value;
        const headerName = params?.column?.userProvidedColDef?.headerName;
        if (headerName.includes("Hours")) {
          return formatNumber(value, { unit: "hour" });
        } else if (headerName === "Google Sheet Link") {
          return value?.googleSheetLink;
        } else if (headerName === "Clock In" || headerName === "Clock Out") {
          return dayjsNY(value).format(
            `${DEG_DATE_FORMAT} - ${DEG_TIME_FORMAT}`
          );
        } else if (headerName === "Punch Time") {
          return dayjsNY(value).format(DEG_TIME_FORMAT);
        } else if (headerName.includes("Total")) {
          return formatCurrency(value || 0);
        } else {
          return value === undefined ? "" : `${value}`;
        }
      },
    });
  }

  function exportShiftToPdf() {
    let rows = [];
    gridApi.forEachNode(({ data }) => {
      rows.push(data);
    });

    return createPDF({
      action: "download",
      gridApi: gridApi,
      rowData: rows,
      history: location?.pathname?.split("/")?.[1],
      historyTab: location?.pathname,
      gridColumnApi: shiftColumnApi,
      base64,
      masterDetail: true,
      params: {
        PDF_SELECTED_ROWS_ONLY: false,
        PDF_DOCUMENT_TITLE: degName || "New DEG",
        PDF_LOGO: base64?.find(({ fileName }) =>
          compareIncluding(fileName, "Core Logo Black")
        )?.base64,
      },
    });
  }

  function toggleEditModal(param) {
    setEditModalOpen(param);
  }

  function onFilterTextChange(e) {
    if (!gridApi) {
      gridApi?.forEachDetailGridInfo?.((params) => {
        params.api.setQuickFilter(e);
      });
    } else {
      gridApi.setQuickFilter(e);
    }
    setSearchInput(e);
  }

  function onSearch(e) {
    if (gridApi) {
      gridApi.setFilterModel({
        uploadName: {
          filterType: "text",
          type: "contains",
          filter: e,
        },
      });
    }
  }

  function openTeamsShiftsModal() {
    setTeamShiftModal(true);
  }

  function openSplitShiftModal() {
    gridApi?.getSelectedNodes()?.length
      ? setSplitModal(true)
      : message.error({
          content: "Please select a Shift to split first!",
          key: "warningKey",
        });
  }

  function onShiftRemove(fromWarning = false) {
    if (!gridApi) {
      return;
    }

    let selectedEntries = (gridApi?.getSelectedNodes() || [])?.flatMap(
      ({ data }) => {
        return data?.entries || [];
      }
    );
    if (!selectedEntries.length) {
      message.warning({
        content: "Please select at least one shift to remove",
        key: "noSelectionWarning",
      });
      return;
    }

    if (!fromWarning) {
      setWarningModalVisible("DELETE");
    } else {
      const rowsToRemove = degRows.filter(({ entryId }) =>
        selectedEntries?.includes(entryId)
      );

      const removeActions = {
        editedEntries: [],
        newEntries: [],
        removedEntries: [],
      };

      rowsToRemove.forEach((row) => {
        let editIncluded = entriesActions?.editedEntries.findIndex(
          (el) => el?.entryId === row?.entryId
        );
        let newIncluded = entriesActions?.newEntries.findIndex(
          (el) => el?.entryId === row?.entryId
        );

        if (editIncluded > -1) {
          Object.assign(removeActions, {
            ["editedEntries"]: removeActions.editedEntries.concat(row),
          });
        } else if (newIncluded > -1) {
          Object.assign(removeActions, {
            ["newEntries"]: removeActions.newEntries.concat(row),
          });
        } else {
          Object.assign(removeActions, {
            ["removedEntries"]: removeActions.removedEntries.concat(row),
          });
        }
      });

      setDegRows((prev) => {
        return prev?.filter(
          ({ entryId }) => !selectedEntries?.includes(entryId)
        );
      });

      addActionToRegister({ type: "remove", removeActions });
      addEntryAction({
        type: "remove",
        entry: rowsToRemove,
      });
    }
  }

  function openRecommendedModal() {
    setRecommendedSplitModal(true);
  }

  function getColumnState(event) {
    if (
      event?.finished &&
      (event.source === "uiColumnMoved" || event.source === "uiColumnResized")
    ) {
      const columnOrder = event.columnApi.getColumnState();
      sessionStorage.setItem("degShiftColumns", JSON.stringify(columnOrder));
    }
  }

  function onMassEntry() {
    const allSelectedNodes = gridApi.getSelectedNodes();
    const hasOverheadEntries = allSelectedNodes.some(({ data }) =>
      OVERHEAD_SHIFT_TYPES.includes(data?.shiftType)
    );
    const hasNormalEntries = allSelectedNodes.some(({ data }) =>
      ["Regular Shift", "HR Shift"].includes(data?.shiftType)
    );

    if (hasOverheadEntries && hasNormalEntries) {
      setMassEntryConflict(true);
    } else if (hasOverheadEntries && !hasNormalEntries) {
      setShiftDetailsVisible("overhead");
    } else if (hasNormalEntries && !hasOverheadEntries) {
      setShiftDetailsVisible("normal");
    }
  }

  useEffect(() => {
    updateStatisticsHandler();
  }, [degRows]);

  useEffect(() => {
    const newShifts = groupEntriesInShifts({
      analytics,
      jobsites,
      crews,
      rowData: degRows,
    });
    if (newShifts?.length) {
      setRowData(newShifts);
      // eslint-disable-next-line no-undef
      setAnalyticsState(structuredClone(analytics));
    }
  }, [analytics, gridApi]);

  useEffect(() => {
    if (gridApi) {
      const filterObject = { ...shiftExternalFilter };

      if (Object.keys(filtersFromSelection)?.length) {
        Object.assign(filterObject, filtersFromSelection);
      }

      if (filtersFromSelection?.selectedEmployees?.length) {
        Object.assign(filterObject, {
          timeFrame: filtersFromSelection?.timeFrame?.map((time) =>
            dayjsNY(time)
          ),
          selectedEmployees: crews.flatMap(({ employeeId, crewId }) =>
            filtersFromSelection?.selectedEmployees?.includes(crewId)
              ? employeeId
              : []
          ),
        });
      } else {
        Object.assign(filterObject, {
          timeFrame: shiftExternalFilter?.timeFrame,
          selectedEmployees: shiftExternalFilter?.selectedEmployees,
        });
      }

      updateShiftExternalFilter(filterObject);
      gridApi.onFilterChanged();
    }
  }, [JSON.stringify(filtersFromSelection), gridApi]);

  return (
    <div className="deg-step" data-testid="deg-shift-step">
      <DegHeader
        {...{
          setFilterOpen,
          exportShiftToExcel,
          exportShiftToPdf,
          onFilterTextChange,
          onSearch,
          searchInput,
          setSearchInput,
          setFieldSelected,
          shiftsColumnDefs: columnDefs(),
          openSplitShiftModal,
          setShiftVisibleFromShift: onMassEntry,
          setShiftEditVisible,
          setTeamShiftModal,
          openTeamsShiftsModal,
          onShiftRemove,
          openRecommendedModal,
          setMultipleHrShiftVisible,
          setCashEntryModalVisible,
        }}
      />
      <div
        className={`deg-grid-container ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          {...{
            rowData,
            columnDefs: memoColumnDefs,
            onGridReady,
            isExternalFilterPresent,
            doesExternalFilterPass,
            onFilterChanged,
            onFirstDataRendered,
            statusBar,
            animateRows: true,
            pagination: true,
            paginationPageSize,
            rowGroupPanelShow: "always",
            defaultExcelExportParams: {
              columnKeys: excelShiftColumnsConfig,
            },
            context: {
              employeeWeekTotals,
              toggleEditModal,
              notFound,
              foremans,
              generalOverheadTotal,
              crewTeams,
            },
            suppressRowClickSelection: true,
            onColumnMoved: getColumnState,
            onColumnResized: debounce(getColumnState, 300),
            // onComponentStateChanged,
            overlayLoadingTemplate: gridCustomOverlayLoading(),
            // onComponentStateChanged: ({ columnApi }) => {
            //   clearTimeout(timeoutRef.current);

            //   timeoutRef.current = setTimeout(() => {
            //     const degColumnsState = JSON.parse(
            //       sessionStorage.getItem("degShiftColumns")
            //     );

            //     columnApi.applyColumnState({
            //       state: degColumnsState,
            //       applyOrder: true,
            //     });

            //     columnApi.autoSizeAllColumns();
            //   }, 600);
            // },
            enableCellChangeFlash: true,
            rowSelection: "multiple",
            getRowId: "",
            sideBar: {
              toolPanels: [
                {
                  id: "columns",
                  labelDefault: "Columns",
                  labelKey: "columns",
                  iconKey: "columns",
                  toolPanel: "agColumnsToolPanel",
                },
                {
                  id: "filters",
                  labelDefault: "Filters",
                  labelKey: "filters",
                  iconKey: "filter",
                  toolPanel: "agFiltersToolPanel",
                },
              ],
            },
            defaultColDef: {
              resizable: true,
              enableColResize: true,
              enableRowGroup: false,
              sortable: true,
              filter: true,
              flex: 1,
              suppressSizeToFit: true,
              minWidth: 130,
              suppressDragLeaveHidesColumns: true,
            },
          }}
        />
      </div>
      {filterOpen && (
        <OverviewFilterModal
          {...{
            open: filterOpen,
            onCancel() {
              setFilterOpen(false);
            },
            rowData,
            onFilter(param) {
              updateShiftExternalFilter({ ...param });
              gridApi.onFilterChanged();
            },
            filter: shiftExternalFilter,
            isDarkMode,
          }}
        />
      )}
      {splitModal && (
        <SplitShiftModal
          open={splitModal}
          onCancel={() => setSplitModal(false)}
          gridApi={gridApi}
          setRowData={setRowData}
        />
      )}
      {shiftEditVisible === true && (
        <ShiftEditModal
          open={shiftEditVisible}
          onCancel={() => setShiftEditVisible(false)}
          mode={"NEW"}
        />
      )}
      {teamShiftModal && (
        <TeamShiftsModal
          open={teamShiftModal}
          onCancel={() => setTeamShiftModal(false)}
        />
      )}
      {editModalOpen &&
        !["CASH Shift", "1099 Shift"].includes(editModalOpen?.shiftType) && (
          <ShiftEditModal
            {...{
              open: !!editModalOpen,
              onCancel() {
                setEditModalOpen(false);
              },
              shift: editModalOpen,
            }}
          />
        )}
      {["CASH Shift", "1099 Shift"].includes(editModalOpen?.shiftType) && (
        <CashEntryShiftModal
          {...{
            open: !!editModalOpen,
            onCancel() {
              setEditModalOpen(false);
            },
            selectedRow: editModalOpen,
            shiftView: true,
          }}
        />
      )}
      {shiftDetailsVisible === "normal" && (
        <ShiftEditModal
          open={shiftDetailsVisible === "normal"}
          onCancel={() => setShiftDetailsVisible(false)}
          mode={"MASS_ENTRY"}
        />
      )}
      {shiftDetailsVisible === "overhead" && (
        <CashEntryShiftModal
          open={shiftDetailsVisible === "overhead"}
          onCancel={() => setShiftDetailsVisible(false)}
          selectedNodes={gridApi.getSelectedNodes().map(({ data }) => data)}
          shiftView={true}
        />
      )}
      {recommendedSplitModal && (
        <ShiftsToSplitModal
          {...{
            open: recommendedSplitModal,
            onCancel() {
              setRecommendedSplitModal(false);
            },
          }}
        />
      )}
      {multipleHrShiftVisible && (
        <MultipleHrShiftsModal
          open={multipleHrShiftVisible}
          onCancel={() => setMultipleHrShiftVisible(false)}
        />
      )}
      {cashEntryModalVisible && (
        <CashEntryShiftModal
          open={cashEntryModalVisible}
          onCancel={() => setCashEntryModalVisible(false)}
        />
      )}
      {warningModalVisible && (
        <WarningModal
          visible={warningModalVisible}
          setVisible={setWarningModalVisible}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningIcon />
            </span>
            <p>
              {warningModalVisible === "DELETE"
                ? "Are you sure you want to remove the selected shifts?"
                : ""}
            </p>
            <div className="buttons">
              <MondayButton
                onClick={() => setWarningModalVisible(false)}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  if (warningModalVisible === "DELETE") {
                    onShiftRemove(true);
                  }
                  setWarningModalVisible(false);
                }}
                Icon={<TickIcon width={17} height={17} />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {!!massEntryConflict && (
        <WarningModal
          visible={massEntryConflict}
          setVisible={setMassEntryConflict}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal shiftsBreakWarning"
        >
          <div className="logout-modal-body">
            <span>
              <WarningIcon />
            </span>
            <p style={{ textAlign: "center" }}>
              There is a mixed selection. If you want to mass update normal
              shifts please chose only shift with type <b>Regular Shift</b> or{" "}
              <b>HR Shift</b>. If you want to mass update overhead shifts please
              chose shifts with type <b>CASH Shift</b> or <b>1099 Shift</b>.
            </p>
            <div className="buttons">
              <MondayButton
                onClick={() => {
                  const allSelectedNodes = gridApi.getSelectedNodes();
                  for (const node of allSelectedNodes) {
                    if (OVERHEAD_SHIFT_TYPES.includes(node?.data?.shiftType)) {
                      node.setSelected(false);
                    }
                  }
                  setMassEntryConflict(false);
                  setShiftDetailsVisible("normal");
                }}
                Icon={<TickIcon width={17} height={17} />}
                className="mondayButtonBlue"
              >
                Mass update Normal entries
              </MondayButton>
              <MondayButton
                className="mondayButtonBlue"
                onClick={() => {
                  const allSelectedNodes = gridApi.getSelectedNodes();
                  for (const node of allSelectedNodes) {
                    if (!OVERHEAD_SHIFT_TYPES.includes(node?.data?.shiftType)) {
                      node.setSelected(false);
                    }
                  }
                  setMassEntryConflict(false);
                  setShiftDetailsVisible("overhead");
                }}
                Icon={<TickIcon width={17} height={17} />}
              >
                Mass update Overhead entries
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
    </div>
  );
}

export default DegShiftStep;
