import axios from "axios";
import { message } from "antd";
import {
  FleetType,
  DriverType,
  DefectType,
  InventoryType,
  WorkOrderType,
  VehicleReport,
  VehicleReportError,
  FleetInspectionType,
  FleetMaintenanceType,
  ServiceProgramType,
} from "../types";

import { lazyFetch } from "src/utils";
import { fetchData } from "../../Fleet/utils";

export const reportEndpoints = {
  locale: "http://localhost:8080/scheduleMaintenance/serviceDailyReports",
  online:
    process.env.NODE_ENV === "production"
      ? "https://leadmanager-sockets.ue.r.appspot.com/scheduleMaintenance/serviceDailyReports/prod"
      : "https://leadmanager-sockets.ue.r.appspot.com/scheduleMaintenance/serviceDailyReports",
};

async function getMaintenanceBaseData() {
  message.loading({
    content: "Loading maintenance data...",
    key: "maintenanceLoading",
    duration: 0,
  });

  return await Promise.allSettled([
    lazyFetch({
      tableName: "fleet",
      filterKey: undefined,
      filterValue: undefined,
      listOfKeys: [
        "fleetName",
        "fleetId",
        "vinNumber",
        "assignedDriver",
        "deviceSerialNumber",
        "fleetStatus",
      ],
    }),
    lazyFetch({
      tableName: "drivers",
      filterKey: undefined,
      filterValue: undefined,
      listOfKeys: ["driverId", "driverName", "driverStatus", "employeeId"],
    }),
    lazyFetch({
      tableName: "fleetDefects",
      filterKey: undefined,
      filterValue: undefined,
    }),
    fetchData("fleetInventory"),
    fetchData("workOrders"),
    fetchData("fleetInspections"),
    fetchData("fleetMaintenance"),
    axios.get<{
      vehicleReports: VehicleReport[];
      reportErrors: VehicleReportError[];
    }>(reportEndpoints.online),
    fetchData("servicePrograms"),
    fetchData("locations"),
  ]).then((value) => {
    message.destroy("maintenanceLoading");

    const fleetRes = value[0] as PromiseFulfilledResult<FleetType[]>;
    const driverRes = value[1] as PromiseFulfilledResult<DriverType[]>;
    const defectRes = value[2] as PromiseFulfilledResult<DefectType[]>;
    const inventoryRes = value[3] as PromiseFulfilledResult<InventoryType[]>;
    const workOrders = value[4] as PromiseFulfilledResult<WorkOrderType[]>;
    const fleetInspections = value[5] as PromiseFulfilledResult<
      FleetInspectionType[]
    >;
    const maintenances = value[6] as PromiseFulfilledResult<
      FleetMaintenanceType[]
    >;

    const failed = [];
    let errMessage = "";

    if (value.some((e) => e.status === "rejected")) {
      errMessage = "Something went wrong while getting ";

      for (let i = 0; i < value.length; i++) {
        if (value[i]["status"] === "rejected") {
          if (!i) {
            failed.push("fleets");
          } else if (i === 1) {
            failed.push("drivers");
          } else if (i === 2) {
            failed.push("defects");
          } else if (i === 3) {
            failed.push("inventory items");
          } else if (i === 4) {
            failed.push("work orders");
          } else if (i === 5) {
            failed.push("inspections");
          } else {
            failed.push("maintenances");
          }
        }
      }

      if (failed.length === value.length) {
        throw new Error("All promises failed");
      }

      errMessage = errMessage + failed.join(", ");
    }

    if (errMessage) {
      void message.error({
        content: errMessage,
        key: "initialFetchError",
        duration: 1.5 + 0.5 * failed.length,
      });
    }

    const reports =
      value[7].status === "fulfilled"
        ? value[7].value.data
        : {
            vehicleReports: [],
            reportErrors: [],
          };

    const servicePrograms: ServiceProgramType[] =
      value[8].status === "fulfilled" ? value[8].value : [];

    const locations =
      value[9].status === "fulfilled"
        ? value[9]["value"]["data"]["locations"]
        : [];

    return {
      reports,
      locations,
      servicePrograms,
      fleet: fleetRes.value || [],
      drivers: driverRes.value || [],
      defects: defectRes.value || [],
      inventoryItems: inventoryRes.value || [],
      workOrders: workOrders.value || [],
      inspections: fleetInspections.value || [],
      maintenances: maintenances.value || [],
    };
  });
}

export default getMaintenanceBaseData;
