import { useContext } from "react";
import { OverlayViewF } from "@react-google-maps/api";

import { EmployeeInfo } from "../";
import { PersonLocationIcon } from "src/assets/icons";
import { Marker } from "src/components/commonComponents";
import PayrollLiveContext from "../../PayrollLiveContext";
import { EmployeeReportType } from "../../payrollLiveTypes";

interface Props {
  data: EmployeeReportType & { isForeman?: boolean };
  color?: string;
  key: string | number;
  onClick?: () => void;
}

function EmployeeMarker(props: Props) {
  const { key, data, color, onClick } = props;
  const { employeesInfo, setEmployeesInfo, mapRef } =
    useContext(PayrollLiveContext);

  function toggleInfo() {
    mapRef.current.setDirections(undefined);
    setEmployeesInfo((prev) => {
      if (prev === data.employeeNumber) {
        return undefined;
      } else {
        return data.employeeNumber;
      }
    });
  }

  return (
    <OverlayViewF
      key={key}
      zIndex={2}
      position={data.punchCoordinates}
      mapPaneName={"overlayMouseTarget"}
    >
      <Marker
        onClick={() => {}}
        address={data.punchLocation}
        coordinates={data.punchCoordinates}
      >
        <PersonLocationIcon
          width={40}
          height={38}
          onClick={() => {
            toggleInfo();
            if (!!onClick) {
              onClick();
            }
          }}
          fill={data?.isForeman ? "#1264a3" : color || "#e9c466"}
        />
      </Marker>
      {employeesInfo === data.employeeNumber ? (
        <EmployeeInfo
          data={data}
          onClick={toggleInfo}
          color={data?.isForeman ? "#1264a3" : color || "#e9c466"}
        />
      ) : null}
    </OverlayViewF>
  );
}

export default EmployeeMarker;
