import { Dayjs } from "dayjs";
import { groupBy } from "lodash";
import { API } from "aws-amplify";
import { AxiosResponse } from "axios";
import { read, utils, write } from "xlsx";
import { useSelector } from "react-redux";
import { Form, Modal, Upload, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { UploadRequestOption } from "rc-upload/lib/interface";

import { ExcelIcon } from "src/assets";
import {
  HoverButton,
  MondayButton,
  WarningModal,
} from "../../../../../../../commonComponents";
import {
  StoreType,
  JobsiteType,
  EmployeeType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import {
  PayrollRes,
  ReportResultType,
  getScheduledData,
  getReportWarnings,
  ReportWarningsType,
  updateReportResults,
  payrollReportColumns,
} from "../../../../../../PayrollLive/utils/getPayrollReportUtils";
import { DownloadIcon } from "src/components/SidebarPages/BasePage/src";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { WarningTriangle } from "src/components/SidebarPages/DynamicView/src";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { callGmailAPI } from "src/components/SidebarPages/Communication/functions";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import { getPayrollOnDateRange } from "../../../FingerCheckConfig/fingercheckFunctions";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

import "./GetPayrollReportModal.scss";

type Props = {
  open: boolean;
  onCancel: () => void;
};

type FileType = { name: string; type: string; blob: string };

const defaultColDef = {
  flex: 1,
  filter: true,
  sortable: true,
  editable: false,
  resizable: true,
  enablePivot: true,
  enableRowGroup: true,
};

const warningTitle = {
  noJobsiteMatch: "No Jobsite Match",
  longLunchTimes: "Long Lunch Times",
  missingPunchActions: "Missing Punch Actions",
};

const header = [
  // Don't change the indexes of this array
  "Name",
  "Department",
  "Role",
  "Crew",
  "Foreman",
  "Location",
];

const GetPayrollReportModal = (props: Props) => {
  const { open, onCancel } = props;
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);
  const programFields = useSelector(
    (store: StoreType) => store.programFields.programFields
  );

  const [getDisabled, setGetDisabled] = useState<boolean>(true);
  const [excelFiles, setExcelFiles] = useState<Array<FileType>>([]);
  const [cancelWarning, setCancelWarning] = useState<boolean>(false);
  const [employees, setEmployees] = useState<Array<EmployeeType>>([]);
  const [uploadFileList, setUploadFileList] = useState<Array<any>>([]);
  const [uploadedFileData, setUploadedFileData] = useState<Array<any>>();
  const [warningsData, setWarningsData] = useState<ReportWarningsType>({
    longLunchTimes: [],
    noJobsiteMatch: [],
    missingPunchActions: [],
  });
  const [sendEmailWarning, setSendEmailWarning] = useState<boolean>(false);
  const [generatedFiles, setGeneratedFiles] = useState<Array<FileType>>([]);
  const [scheduledJobsites, setScheduledJobsites] = useState<{
    [empName: string]: JobsiteType;
  }>({});
  const [selectedWarningsData, setSelectedWarningsData] = useState<
    string | undefined
  >();

  const [form] = Form.useForm();

  function disabledDates(current: Dayjs) {
    return (
      current &&
      parseInTz(current).startOf("d").valueOf() >
        dayjsNY().startOf("d").valueOf()
    );
  }

  function getPayrollData() {
    form.validateFields().then((formData) => {
      message.loading({
        key: "getData",
        content: "Loading payroll data...",
        duration: 8,
      });
      setGetDisabled(true);
      const selectedClient = clientConfigs.find(
        (el) => el.configId === formData.clientCompany
      );

      getPayrollOnDateRange({
        clientKey: selectedClient.clientKey,
        dateRange: formData.dateRange.map((date) => date.format("YYYY-MM-DD")),
      })
        .then((res: AxiosResponse<Array<PayrollRes>>) => {
          message.success({
            key: "getData",
            content: "Data loaded successfully",
            duration: 1.8,
          });
          if (!res?.data || res?.data?.length == 0) {
            message.warning({
              key: "getData",
              content: "There are no records in this date range.",
              duration: 1.8,
            });
          }
          console.log("res: ", res);
          const updatedResult = updateReportResults({
            results: res?.data,
            scheduledJobsites,
            selectedClient,
            // uploadedFileData,
          });

          getWarnings(updatedResult);
        })
        .catch((err) => {
          message.error({
            key: "getData",
            content: "There was a problem loading payroll data",
            duration: 1.8,
          });
          console.log("Error: ", err);
        });
    });
  }

  function getWarnings(reportData: Array<ReportResultType>) {
    const warnings = getReportWarnings(reportData);

    setWarningsData(warnings);

    let tmpExcelList = [];

    for (const warning in warnings) {
      const warningData = warnings?.[warning] || [];
      if (!warningData?.length) {
        continue;
      }
      const groupByDate = groupBy(warningData, (el) => el.Date);
      const workbook = utils.book_new();
      for (const date in groupByDate) {
        const dateWarnings = groupByDate[date];
        const workSheet = utils.json_to_sheet(dateWarnings);
        utils.book_append_sheet(workbook, workSheet, date);
      }
      const excelBufferNoJob = write(workbook, {
        bookType: "xls",
        type: "base64",
      });

      tmpExcelList.push({
        name: warningTitle[warning] + ".xls",
        blob: `base64,${excelBufferNoJob}`,
        type: "application/vnd.ms-excel",
      });
    }
    setGeneratedFiles(tmpExcelList);
  }

  function onFileClick(file) {
    setExcelFiles((prev) => {
      const exists = prev.findIndex((el) => el.name === file.name) > -1;
      if (exists) {
        return prev.filter((el) => el.name !== file.name);
      } else {
        return prev.concat(file);
      }
    });
  }

  function sendEmail() {
    const templateMessages = {
      "No Jobsite Match":
        "The following employees do not match any of the jobsite locations",
      "Long Lunch Times": "Employees who's lunch time exceeds 60 minutes.",
      "Missing Punch Actions": "Employees that have missing punch actions",
    };
    excelFiles.forEach((file) => {
      callGmailAPI("sendEmail", {
        to: [
          "eduard@corescaffold.com",
          "payroll@corescaffold.com",
          "eni@corescaffold.com",
        ],
        subject: templateMessages[file.name],
        body: `</br>
        <p>Please see attached report</p>`,
        attachments: [file],
        authData: false,
      })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }

  function onDownloadFile(file: FileType) {
    const url = `data:${file.type};${file.blob}`;
    const a = document.createElement("a");
    a.href = url;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  function resetHandle() {
    form.resetFields();
    setExcelFiles([]);
    setGetDisabled(true);
    setGeneratedFiles([]);
    setUploadFileList([]);
    setUploadedFileData([]);
    setSelectedWarningsData(undefined);
  }

  function formatFileTitle(title: string) {
    if (!title) {
      return "";
    }
    let str =
      title?.[0].toLowerCase() +
      title.slice(1, title.length - 4).replaceAll(" ", "");
    return str;
  }

  function selectFileData(file) {
    const warningsKey = formatFileTitle(file.name);
    setSelectedWarningsData(warningsKey);
  }

  function onFileUpload(event: UploadRequestOption<any> & { file: any }) {
    let fileReader = new FileReader();
    message.loading({
      key: "uplMsg",
      content: "Uploading file...",
      duration: 0,
    });
    fileReader.addEventListener("load", async () => {
      let { Sheets } = read(fileReader.result, {
        sheets: "Crew composition",
      });

      if (Sheets?.["Crew composition"]) {
        const excelData = utils.sheet_to_json(Sheets["Crew composition"], {
          range: 2,
          header,
        });
        console.log("excelData: ", excelData);
        setUploadedFileData(excelData);
        setUploadFileList([event?.file]);

        const employeesScheduled = await getScheduledData({
          excelData,
          employees,
        });
        //// get scheduled data from excel
        message.success({
          key: "uplMsg",
          content: "File Uploaded",
          duration: 1.8,
        });
        setGetDisabled(false);
        setScheduledJobsites(employeesScheduled);
        return event.onSuccess(Sheets);
      } else {
        message.error({
          key: "uplMsg",
          content: "Sheet Crew composition was not found",
          duration: 2.2,
        });
        return event.onError({
          message: "Sheet Crew composition was not found",
          name: "Upload Error",
        });
      }
    });
    fileReader.readAsArrayBuffer(event?.file);
  }

  function onFileRemove() {
    setGetDisabled(true);
    setUploadFileList([]);
    setUploadedFileData([]);
  }

  const clientConfigs = useMemo(() => {
    if (programFields?.length) {
      let index = programFields.findIndex(
        (field) => field.fieldName === "Payroll Configuration"
      );

      return programFields[index].fieldOptions;
    }
    return [];
  }, [programFields]);

  const customOptions = useMemo(() => {
    return clientConfigs.map((el, key) => ({
      key,
      value: el.configId,
      label: el.clientName,
    }));
  }, [clientConfigs]);

  const columnDefs = useMemo(() => {
    return payrollReportColumns?.[selectedWarningsData];
  }, [selectedWarningsData]);

  useEffect(() => {
    API.get("crews", "/crews", {})
      .then((result) => {
        setEmployees(result);
      })
      .catch((err) => {
        console.log("Error getting employees: ", err);
        message.error("Error getting Employees data");
      });
  }, []);

  return (
    <Modal
      title={`Get Payroll Report`}
      open={open}
      onCancel={() =>
        form.isFieldsTouched() ? setCancelWarning(true) : onCancel()
      }
      closeIcon={<XIcon />}
      closable={true}
      centered={true}
      className={`get-payroll-report-modal ${
        darkMode ? "get-payroll-report-modal-dark" : ""
      }`}
      footer={[
        <MondayButton
          className="mondayButtonRed"
          Icon={<XIcon />}
          onClick={() =>
            form.isFieldsTouched() ? setCancelWarning(true) : onCancel()
          }
        >
          Cancel
        </MondayButton>,
        <MondayButton
          className="mondayButtonBlue"
          Icon={<TickIcon />}
          disabled={!excelFiles?.length}
          onClick={() => setSendEmailWarning(true)}
        >
          Send Email
        </MondayButton>,
      ]}
    >
      <Form form={form} className="report-control-panel">
        <InputComponent
          type="select"
          allowClear={false}
          label={"Company: "}
          required
          formItemName="clientCompany"
          customOptions={customOptions}
          placeholder="Chose Subcontractor..."
        />
        <InputComponent
          type="rangepicker"
          label={"Date Range"}
          required
          dataTestid={"upload-date-range"}
          disabledDates={disabledDates}
          onChange={(e) =>
            form.setFieldValue(
              "testDateRange",
              e.map((i) => parseInTz(i))
            )
          }
          formItemName={"dateRange"}
        />
        <Upload
          multiple={false}
          accept="xlsx, xls"
          onRemove={onFileRemove}
          fileList={uploadFileList}
          className="upload-button"
          customRequest={onFileUpload}
        >
          <MondayButton
            Icon={<DownloadIcon />}
            className={darkMode ? "mondayButtonBlue" : "mondayButtonGrey"}
            disabled={!!uploadedFileData?.length}
          >
            Upload
          </MondayButton>
        </Upload>
        <HoverButton
          type="action"
          text="Get Data"
          disabled={getDisabled}
          icon={<DownloadIcon />}
          onClick={getPayrollData}
          iconSize={{ height: 13, width: 13 }}
        />
        <HoverButton
          type="decline"
          text="Reset"
          icon={<XIcon />}
          onClick={resetHandle}
        />
      </Form>
      <section className="generated-files-container">
        {generatedFiles?.length ? (
          generatedFiles.map((file) => {
            return (
              <div
                className={`file-container ${
                  excelFiles.findIndex((el) => el.name === file.name) > -1
                    ? "active"
                    : ""
                }`}
              >
                <ExcelIcon
                  onClick={() => formatFileTitle(file.name)}
                  height={17}
                  width={17}
                />
                <label onClick={() => onFileClick(file)}>{file?.name}</label>
                <div className="file-actions">
                  <label
                    className={`${
                      selectedWarningsData === formatFileTitle(file.name)
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => selectFileData(file)}
                  >
                    Show Report
                  </label>
                  <DownloadIcon
                    onClick={() => onDownloadFile(file)}
                    className="download-file"
                  />
                </div>
              </div>
            );
          })
        ) : (
          <div className="empty-files">No Files Generated</div>
        )}
      </section>
      {selectedWarningsData ? (
        <div
          className={`warnings-table ${
            darkMode
              ? "dark-ag-theme ag-theme-alpine-dark"
              : "light-ag-theme ag-theme-alpine"
          }`}
        >
          <label>{warningTitle?.[selectedWarningsData]}</label>
          <AgGridReact
            pagination={true}
            animateRows={true}
            columnDefs={columnDefs}
            paginationPageSize={10}
            defaultColDef={defaultColDef}
            rowData={warningsData?.[selectedWarningsData] || []}
          />
        </div>
      ) : null}
      {sendEmailWarning ? (
        <WarningModal
          visible={sendEmailWarning}
          setVisible={setSendEmailWarning}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p style={{ fontWeight: 600 }}>
              You are about to send an official email. Please make sure the
              content and the files attached are accurate and ready to be sent.
            </p>
            <div className="buttons">
              <MondayButton
                onClick={() => setSendEmailWarning(false)}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton onClick={sendEmail} Icon={<TickIcon />}>
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      ) : null}
      {cancelWarning ? (
        <WarningModal
          visible={cancelWarning}
          setVisible={setCancelWarning}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p>Do you wish to close this report?</p>
            <div className="buttons">
              <MondayButton
                onClick={() => setCancelWarning(false)}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton onClick={onCancel} Icon={<TickIcon />}>
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      ) : null}
    </Modal>
  );
};

export default GetPayrollReportModal;
