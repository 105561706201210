import React from "react";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Form, message, Modal, Select, Tour } from "antd";
import { useEffect, useState, useMemo, useRef } from "react";
import { InfoCircleFilled, PlusOutlined } from "@ant-design/icons";

import {
  footerButtons,
  employeeFields,
  selectEmployeeTypeField,
} from "./crewModalData";
import {
  updateTeamsConfiguration,
  getCognitosForNotification,
} from "../../../../../../../utils";
import { TickIcon } from "../../../Roles/src";
import { VectorIcon } from "../../../../../../../assets";
import { useEditLogs } from "../../../../../../../hooks";
import { ClientModal } from "../../../../../../Header/forms";
import CreateTeamModal from "../CreateTeamModal/CreateTeamModal";
import { NextIcon } from "../../../../../../SidebarPages/BasePage/src";
import { compareIncluding } from "../../../../../../SidebarPages/utils";
import { MondayButton } from "../../../../../../commonComponents/index";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { useModalOnceObject } from "../../../../../../../hooks/useModalOnce";
import { useMultipleRefs } from "../../../../../../../hooks/useMultipleRefs";
import { hasValuesChanged } from "../../../../../../../utils/hasValuesChanged";
import CustomModalHeader, {
  findTutorialSteps,
} from "../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { fleetFields as updateFleetFields } from "../../../../../../../actions";
import { WarningTriangle } from "../../../../../../SidebarPages/DynamicView/src";
import { RenderDynamicComponents } from "../../../../../../Header/forms/Components";
import {
  driverFields,
  engineerFields,
  architectFields,
} from "../../../../../../SidebarPages/HrManagement/Pages/Employee/utils/fieldsData";
import { InputComponent } from "../../../../../../SidebarPages/Fleet/components/index";
import WarningModal from "../../../../../../commonComponents/WarningModal/WarningModal";
import DynamicTeamModal from "../../../../../../Header/forms/DynamicTeamModal/DynamicTeamModal";
import { getCardAccess } from "../../../../../../SidebarPages/HrManagement/utils/getAccessRight";
import broadcastNotification from "../../../../../../../helpers/controllers/broadcastNotification";
import { AnimatedCheckBox } from "../../../../../../SidebarPages/Fleet/components/InputComponent/components";
import usePostEventsAutomation from "../../../PostEventsAutomationsConfig/functions/usePostEventsAutomation";
import { getActiveEmployeesData } from "../../../../../Payroll/Tabs/DEG/FingerCheckConfig/fingercheckFunctions";
import { forceToNumber } from "../../../../../../SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

import "./CrewsModal.scss";

export const CrewsHeader = ({
  visible,
  setVisible,
  isNextStep,
  defaultData,
  refreshTable,
  title = false,
  defaultEmployeeType = null,
}) => {
  /**@type {{isDarkMode: boolean}} */
  const { isDarkMode } = useSelector((state) => state.darkMode);
  /**@type {{programFields: Array}} */

  const dispatch = useDispatch();

  const { postEventsAutomationHandler } = usePostEventsAutomation();
  const { fleetFields } = useSelector((state) => state.fleetFields);
  const { programFields } = useSelector((state) => state.programFields);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [role, setRole] = useState();
  const [teams, setTeams] = useState([]);
  const [open, setOpen] = useState(false);
  const [members, setMembers] = useState([]);
  const [createTeam, setCreateTeam] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [driverStatus, setDriverStatus] = useState([]);
  const [subcontractor, setSubcontractor] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [fetchedEmployee, setFetchedEmployee] = useState(false);
  const [createTeamModal, setCreateTeamModal] = useState(false);
  const [newSubcontractor, setNewSubcontractor] = useState(false);
  const [driverRatingOptions, setDriverRatingOptions] = useState([]);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [selectedEmployeeType, setSelectedEmployeeType] =
    useState(defaultEmployeeType);

  const { saveAddedLogs } = useEditLogs();

  const selectRef = useRef();

  const [form] = Form.useForm();
  const employeeType = Form.useWatch("foreman", form);
  const accountName = Form.useWatch("accountName", form);
  const employeeNumber = Form.useWatch("employeeNumber", form);

  const [cancelRef, saveRef] = useMultipleRefs(2);
  const [tourOpen, setTourOpen] = useModalOnceObject("Tour");

  function stepsMapHelper(title = "Crew Modal") {
    if (title?.includes("Cancel")) {
      return cancelRef.current;
    } else if (title?.includes("Save")) {
      return saveRef.current;
    }
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        ...step,
        target: () => stepsMapHelper(step?.title),
        className: isDarkMode ? `custom-tour-dark` : `custom-tour-light`,
      };
    });
    return newSteps;
  }

  const dbSteps = mapRefs(findTutorialSteps("Crew Modal", programFields));

  const user = userConfiguration?.nameOfUser;

  const resetForm = () => {
    form.resetFields();
    setCancelModalVisible(false);
  };

  const onCancel = () => {
    setCancelModalVisible(false);
    resetForm();
  };

  const onCancelModal = () => {
    setCancelModalVisible(false);
    setVisible(false);
  };

  const onEnterPress = (event) => {
    if (event.charCode === 13) onCancel();
  };

  const clientsConfig = useMemo(() => {
    if (programFields?.length) {
      let index = programFields.findIndex(
        (field) => field.fieldName === "Payroll Configuration"
      );
      return programFields[index].fieldOptions;
    }
    return [];
  }, [programFields]);

  const selectEmployeeFieldJSON = useMemo(() => {
    return selectEmployeeTypeField({ isDarkMode });
  }, [isDarkMode]);

  const salaryAccess = getCardAccess(
    "Employee View",
    "Overview",
    "Salary Information"
  );

  const engineerFieldsJSON = useMemo(() => {
    return engineerFields(isDarkMode, salaryAccess);
  }, [isDarkMode]);

  const architectFieldsJSON = useMemo(() => {
    return architectFields(isDarkMode, salaryAccess);
  }, [isDarkMode]);

  const driverFieldsJSON = useMemo(() => {
    return driverFields({
      driverRatingOptions,
      driverStatus,
      teams,
      onTeamSelect,
    });
  }, [isDarkMode, driverRatingOptions, driverStatus, teams]);

  const formFieldsJSON = useMemo(() => {
    return employeeFields({ isDarkMode, role, accountName });
  }, [isDarkMode, role, accountName]);

  const createCrew = async (crew) => {
    const teamsConfiguration = updateTeamsConfiguration(
      userConfiguration,
      selectedTeam
    );

    return API.post("crews", "/crews", {
      body: crew,
      teamsConfiguration,
    })
      .then((el) => {
        if (typeof visible === "object") {
          refreshTable(el);
        }
        return el;
      })
      .catch((err) => console.log("Error posting Employee", err));
  };

  const getMembersId = (selectedMembers) => {
    let membersId = [];
    selectedMembers?.flatMap((member) =>
      members.some((m) => m?.crewId === member)
        ? {
            crewId: member?.crewId,
            crewName: member?.crewName,
            employeeId: member?.employeeId,
          }
        : []
    );
    return membersId;
  };

  function onTeamSelect(_, data) {
    setSelectedTeam((prev) => [
      ...prev,
      {
        value: data?.value,
        members: data?.members?.map((e) => e),
      },
    ]);
  }

  function onFetchEmployee() {
    message.loading({
      key: "fingerCheck-employee-fetch",
      content: "Getting Employee Data...",
      duration: 0,
    });
    const clientCompany = form.getFieldValue("accountName");
    const clientKey = clientsConfig.find(
      (el) => el.clientName === clientCompany
    )?.clientKey;
    getActiveEmployeesData(clientKey, employeeNumber)
      .then((res) => {
        const employeeData = res?.data?.[0];

        form.setFieldsValue({
          crewName: employeeData?.crewName,
          crewPosition: employeeData.crewPosition,
          crewStatus: employeeData.crewStatus,
          employeeId: `${accountName}-${employeeData?.employeeId}`,
          employeeRate: employeeData?.employeeRate,
          fingerCheckId: employeeData?.fingerCheckId,
          foreman: employeeData?.foreman,
          salaryType: employeeData?.salaryType,
        });
        setFetchedEmployee(employeeData);
        message.success({
          key: "fingerCheck-employee-fetch",
          content: "Employee Data fetched successfully!",
          duration: 1.5,
        });
      })
      .catch((err) => {
        console.log("Error getting employee data: ", err);
        message.error({
          key: "fingerCheck-employee-fetch",
          content: "There was a problem getting this employee",
          duration: 1.5,
        });
      });
  }

  // saves crew in DB
  const onCreateMember = () => {
    form
      .validateFields()
      .then((value) => {
        const teamsConfiguration = updateTeamsConfiguration(
          userConfiguration,
          selectedTeam
        );

        const duplicateEmployeeId = members?.find(
          (crewEl) =>
            (crewEl?.employeeId || "")?.trim() === value?.employeeId?.trim()
        );

        if (["engineers", "architects"].includes(selectedEmployeeType)) {
          const newEmployee = {
            nameOfUser:
              form.getFieldValue("firstName") +
              " " +
              form.getFieldValue("lastName"),
            userName: form.getFieldValue("userName"),
            employeeRate: form.getFieldValue("employeeRate"),
            salaryType: form.getFieldValue("salaryType"),
            employeeStatus: form.getFieldValue("employeeStatus"),
            createdBy: {
              nameOfUser: userConfiguration?.nameOfUser,
              cognitoId: userConfiguration?.cognitoUserId,
            },
          };

          let path = selectedEmployeeType;

          API.post(path, `/${path}`, {
            body: {
              ...newEmployee,
            },
          })
            .then(async (res) => {
              message.success("Employee added successfully");
              form.resetFields();
              setVisible(false);

              // Adding temp data to display the new Employee on the Table
              // ToDo: Remove once the data are unified
              res.crewName = res.nameOfUser;
              res.role =
                selectedEmployeeType === "engineers" ? "Engineer" : "Architect";
              refreshTable(res);
            })
            .catch((error) => {
              console.log("Error adding engineer: ", error);
            });

          return;
        }

        if (["drivers"].includes(selectedEmployeeType)) {
          const {
            driverName,
            driverRating,
            driverStatus,
            employeeId,
            driverType,
          } = form.getFieldsValue();

          const newEmployeeData = {
            driverName,
            employeeId,
            driverType,
            driverRating,
            driverStatus,
            teamsConfiguration: updateTeamsConfiguration(
              userConfiguration,
              selectedTeam
            ),
          };

          let path = selectedEmployeeType;

          API.post(path, `/${path}`, {
            body: {
              ...newEmployeeData,
            },
          })
            .then(async (res) => {
              message.success("Employee added successfully");
              refreshTable(res);
              form.resetFields();
              setVisible(false);
            })
            .catch((error) => {
              console.log("Error adding engineer: ", error);
            });

          return;
        }

        const body = {
          crewName: value.crewName,
          employeeId: form.getFieldValue("employeeId"),
          employeeRate: forceToNumber(form.getFieldValue("employeeRate")),
          salaryType: value?.salaryType || form.getFieldValue("salaryType"),
          employeeNumber: value?.employeeNumber,
          members: getMembersId(value.members) || [],
          foreman: !!value?.foreman,
          crewPosition: value?.crewPosition || "",
          accountName: value?.accountName || "",
          teamsConfiguration,
          createdAt: Date.now(),
          createdBy: userConfiguration?.nameOfUser,
          dynamicMemberSelction: form.getFieldValue("dynamicSelection"),
        };
        if (fetchedEmployee) {
          Object.assign(body, {
            fingerCheckId: fetchedEmployee?.fingerCheckId,
            crewStatus: fetchedEmployee?.crewStatus,
          });
        }
        if (duplicateEmployeeId) {
          message.error("Crew with this Employee Id already exists!");
        } else {
          createCrew(body)
            .then(async (res) => {
              if (!!refreshTable) {
                refreshTable(res);
              }

              const teamsConfiguration = updateTeamsConfiguration(
                userConfiguration,
                selectedTeam
              );

              broadcastNotification(
                "20",
                "onCrewCreation",
                [
                  {
                    common: user,
                    commonNext: res?.crewName,
                  },
                  {
                    userName: user,
                    currentUser: userConfiguration?.cognitoUserId,
                    cognitos: getCognitosForNotification(
                      userConfiguration,
                      teamsConfiguration
                    ),
                    recordId: res?.crewId,
                  },
                ],
                ""
              );

              const newEditLogs = {
                recordId: res?.crewId,
                recordName: res?.crewName,
                topic: "Crew Logs",
                actionType: "Create",
                category: "Crew Logs",
                currentData: {
                  [res?.crewName]: { creation: res?.crewName },
                },
                label: "",
                previousData: {
                  [res?.crewName]: { creation: "Doesn't exists" },
                },
                updatedKeys: [],
              };

              saveAddedLogs(newEditLogs);

              postEventsAutomationHandler({
                automationName: "post employee actions",
                category: "Employees",
                dataToDisplayInAutomation: body,
                action: "Create",
              });

              message.success("Employee added successfully");
              form.resetFields();
              setVisible(false);
              const tempMember = members?.find(
                (el) => el?.foreman === true && el?.crewId === value?.team
              );
              tempMember?.members?.push({
                crewId: res?.crewId,
                crewName: res?.crewName,
              });
              if (!!tempMember) {
                await API.put("crews", `/crews/${tempMember?.crewId}`, {
                  body: {
                    members: tempMember?.members || [],
                  },
                }).catch((err) =>
                  console.log("error updating Employee: ", err)
                );
              }
            })
            .catch((e) => console.log(e));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  console.log("form: ", form.getFieldsValue());
  const ClearOptions = () => {
    setOpen(false);
  };

  useEffect(() => {
    setInitialValues(form.getFieldsValue());

    API.get("crews", "/crews")
      .then((res) => {
        let members = res?.filter((crew) => crew.foreman !== "");
        setMembers(members);
      })
      .catch((error) => console.log("Error getting crews: ", error));

    API.get("teams", "/teams")
      .then((res) => {
        setTeams(res);
      })
      .catch((err) => {
        console.log("Error fetching teams: ", err);
      });

    API.get("accounts", "/accounts")
      .then((r) => {
        setSubcontractor(() =>
          r?.filter((item) =>
            compareIncluding(item?.accountRecordType, "subcontractors")
          )
        );
        if (defaultData) {
          let nameComps = defaultData?.employeeFullName;
          form.setFieldValue("crewName", nameComps);
          form.setFieldValue("dynamicSelection", true);
          form.setFieldValue("employeeId", defaultData?.employeeId);
          form.setFieldValue("employeeNumber", defaultData?.employeeNumber);
          if (!!defaultData?.accountName) {
            if (
              r.find(
                ({ accountName }) => accountName === defaultData?.accountName
              )
            ) {
              form.setFieldValue("accountName", defaultData?.accountName);
            } else {
              message.warning(
                `${defaultData.accountName} is not a registered subcontractor!`
              );
            }
          }
        } else {
          form.setFieldValue("dynamicSelection", true);
        }
      })
      .catch((err) => console.log("Error fetching accounts: ", err));

    if (!fleetFields?.length > 0) {
      API.get("fleetFields", `/fleetFields`)
        .then((res) => dispatch(updateFleetFields(res)))
        .catch((err) => console.log("Error fetching Fleet Fields: ", err));
    }
  }, []);

  useEffect(() => {
    if (programFields?.length) {
      const fieldsObj = programFields.reduce(
        (acc, { fieldName, fieldOptions }) => ({
          ...acc,
          [fieldName]: fieldOptions,
        })
      );
      setRole(fieldsObj["Crew Position"]);
      setDriverRatingOptions(
        programFields
          ?.find((item) => item.fieldName === "Statuses Of Categories")
          .fieldOptions["Drivers"].filter(({ status }) => status)
      );
    }

    if (fleetFields?.length) {
      const fieldOptionsObj = fleetFields.reduce(
        (acc, cur) => ({ ...acc, [cur.fieldName]: cur.fieldOptions }),
        {}
      );
      setDriverStatus(fieldOptionsObj["Driver Status"]);
    }
  }, [userConfiguration, programFields, fleetFields]);

  return (
    <>
      <Modal
        open={visible}
        title={
          <CustomModalHeader
            title={`Create a New ${title || "Crew Member"}`}
            onClick={() => {
              setTourOpen(true);
            }}
          />
        }
        closable={true}
        centered={true}
        closeIcon={<XIcon />}
        className={`crewModalContainer ${
          isDarkMode && "crewModalContainerDark"
        }`}
        destroyOnClose={true}
        data-testid="new-crew-modal"
        onCancel={() =>
          !!hasValuesChanged(initialValues, form.getFieldsValue())
            ? setVisible(false)
            : setCancelModalVisible(true)
        }
        footer={footerButtons({
          onCancel: () =>
            !!hasValuesChanged(initialValues, form.getFieldsValue())
              ? setVisible(false)
              : setCancelModalVisible(true),
          onCreateMember: createTeam
            ? () => setCreateTeamModal(true)
            : onCreateMember,
          suffix: createTeam ? (
            <NextIcon />
          ) : (
            <TickIcon width={17} height={17} />
          ),
          cancelRef,
          saveRef,
          employeeType,
          createTeam,
        }).map((btn, i) =>
          btn?.type === "checkbox" ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                margin: "0px 10px",
              }}
            >
              <AnimatedCheckBox
                label={btn?.text}
                primaryClassName="greenContainer"
                checkBoxProps={{
                  setValue: () => {
                    form.validateFields().then(() => {
                      setCreateTeam(true);
                    });
                  },
                  value: createTeam,
                }}
              />
            </div>
          ) : (
            <div ref={i ? saveRef : cancelRef}>
              <MondayButton
                key={i}
                className={btn?.className}
                onClick={btn?.onClick}
                Icon={btn.cancel ? <XIcon /> : btn?.suffix}
                tooltipCategory={btn.tooltipCategory}
                tooltipKey={btn.tooltipKey}
              >
                {btn.text}
              </MondayButton>
            </div>
          )
        )}
      >
        <div className="newCrewHeaderInfo">
          <InfoCircleFilled style={{ color: "#0F5C97", fontSize: 20 }} />
          <p className="newCrewHeaderText">
            Complete the form to create a new crew member by entering their
            details, assigning a department and role,and setting up login
            credentials.
          </p>
        </div>
        <Form
          form={form}
          initialValues={{ foreman: false }}
          data-testid="form-instance"
        >
          {!selectedEmployeeType ? (
            <InputComponent
              type="select"
              label="Employee type"
              placeholder="Select employee type..."
              formItemName={"selectedEmployeeType"}
              dropdownClassName={isDarkMode ? "darkDropDown" : ""}
              onChange={(value) => setSelectedEmployeeType(value)}
              customOptions={[
                {
                  value: "crews",
                  label: "Crew",
                },
                {
                  value: "engineers",
                  label: "Engineer",
                },
                {
                  value: "architects",
                  label: "Architect",
                },
                {
                  value: "drivers",
                  label: "Drivers",
                },
              ]}
            />
          ) : null}

          {selectedEmployeeType === "engineers" && (
            <>
              <section className="formSection">
                {RenderDynamicComponents(engineerFieldsJSON.slice(0, 3), form)}
              </section>
              <section className="formSection">
                {RenderDynamicComponents(engineerFieldsJSON.slice(3, 6), form)}
              </section>
            </>
          )}
          {selectedEmployeeType === "architects" && (
            <>
              <section className="formSection">
                {RenderDynamicComponents(architectFieldsJSON.slice(0, 3), form)}
              </section>
              <section className="formSection">
                {RenderDynamicComponents(architectFieldsJSON.slice(3, 6), form)}
              </section>
            </>
          )}
          {selectedEmployeeType === "drivers" && (
            <>
              <section className="formSection">
                {RenderDynamicComponents(driverFieldsJSON.slice(0, 3), form)}
              </section>
              <section className="formSection">
                {RenderDynamicComponents(driverFieldsJSON.slice(3, 7), form)}
              </section>
            </>
          )}
          {selectedEmployeeType === "crews" && (
            <>
              <section className="fingerCheckFetch">
                <InputComponent
                  {...{
                    label: "Subcontractor",
                    type: "select",
                    required: true,
                    inputRef: selectRef,
                    placeholder: "Select subcontractor...",
                    formItemName: "accountName",
                    className: "createCrewSubcontractor",
                    dropdownClassName: `${
                      newSubcontractor ? "hidden-index" : ""
                    } ${isDarkMode && "darkDropDown"}`,
                    dropdownRender: (menu) => {
                      return (
                        <div data-testid={"subcontractor-dropdown"}>
                          {menu}
                          <div className={`addOnSelectContainer`}>
                            <MondayButton
                              Icon={<PlusOutlined />}
                              data-testid="add-subcontractor-btn"
                              onClick={() => {
                                selectRef?.current?.blur?.();
                                setNewSubcontractor(true);
                              }}
                            >
                              Add Subcontractor
                            </MondayButton>
                          </div>
                        </div>
                      );
                    },
                    options: subcontractor?.map(({ accountName }, key) => (
                      <Select.Option
                        key={key}
                        value={accountName}
                        // data-testid={accountName}
                      >
                        <div data-testid={accountName}>{accountName}</div>
                      </Select.Option>
                    )),
                    // customOptions: (subcontractor || [])?.map(
                    //   ({ accountName }, key) => ({
                    //     key,
                    //     label: accountName,
                    //     value: accountName,
                    //   })
                    // ),
                    getPopUpContainer: document.body,
                  }}
                />
                {/* <span className="employeeNumberText">
                  Get Employee from Employee Nr.
                </span> */}
                {/* {RenderDynamicComponents(formFieldsJSON.slice(0, 1), form)} */}
                <InputComponent
                  {...{
                    label: "Get Employee from Employee Nr.",
                    type: "input",
                    placeholder: "Enter here...",
                    formItemName: "employeeNumber",
                    className: "employeeNumberInput",
                    disabled: !accountName,
                  }}
                />
                <div
                  className={
                    !employeeNumber ? "employeeDataDisable" : "employeeDataDiv"
                  }
                  style={{
                    cursor: !employeeNumber ? "not-allowed" : "pointer",
                  }}
                >
                  <span
                    onClick={onFetchEmployee}
                    className="employeeNumberUpdate"
                  >
                    Update employee data
                  </span>
                  <VectorIcon
                    className={
                      !employeeNumber ? "vectorIconDisable" : "vectorIcon"
                    }
                    onClick={onFetchEmployee}
                    data-testid="get-employee-data-btn"
                  />
                </div>
              </section>

              <section className="formSection">
                {RenderDynamicComponents(formFieldsJSON.slice(0, 3), form)}
              </section>
              <section className="firstFormSection">
                {RenderDynamicComponents(formFieldsJSON.slice(3, 7), form)}
              </section>
            </>
          )}
          {/* <section className="formSection">
            {RenderDynamicComponents(formFieldsJSON.slice(5, 7), form)}
          </section> */}
          {/* <section className="lastFormSection">
            {RenderDynamicComponents(formFieldsJSON.slice(7, 9), form)}
          </section> */}
        </Form>
      </Modal>
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        onKeyPress={(e) => onEnterPress(e)}
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningTriangle />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setCancelModalVisible(false)}
              Icon={<XIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton onClick={onCancelModal} Icon={<TickIcon />}>
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
      {open && (
        <DynamicTeamModal
          {...{
            open,
            setOpen,
            selectedTeam,
            setSelectedTeam,
            ClearOptions,
            isNextStep,
          }}
        />
      )}
      {newSubcontractor && (
        <ClientModal
          {...{
            visible: newSubcontractor,
            setVisible: setNewSubcontractor,
            type: "Subcontractors",
            isNextStep,
          }}
        />
      )}
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={dbSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
      {createTeamModal && (
        <CreateTeamModal
          open={createTeamModal}
          onCancel={() => {
            setCreateTeam(false);
            setCreateTeamModal(false);
          }}
          foremanData={form.getFieldsValue()}
          members={members}
          refreshTable={refreshTable}
          closeMemberModal={() => setVisible(false)}
        />
      )}
    </>
  );
};
