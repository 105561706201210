import FormItem from "../../components/FormItems/FormItem";
import StyledButton from "../../components/StyleButton/StyledButton";
import _, { set } from "lodash";
import "./Information.scss";
import {
  fetchData,
  getCoordinatesAndZip,
  validateForm,
} from "../../../Fleet/utils";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  keys,
  state,
} from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import { ReactComponent as CloseIcon } from "../../../../SidebarPages/DynamicView/src/close.svg";
import { ReactComponent as Tick } from "../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { ReactComponent as WarningIcon } from "../../../../../icons/warningTriangle.svg";
import WarningModal from "../../../../commonComponents/WarningModal/WarningModal";
import { MondayButton } from "../../../../commonComponents";
import { API } from "aws-amplify";
import { XIcon } from "../../../Communication/assets";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import { parseInTz } from "../../../Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { useSelector } from "react-redux";
import { getSalesTax } from "../../../../commonComponents/NextSteps/helpers/getSalesTax";

const Information = (prop) => {
  const {
    params: { rowData: data, onEditRowData },
    form,
    getEditedValue,
    setEditMode,
    getFormChanged = () => {},
    setFormChanged = () => {},
  } = prop;

  let formList = {};
  const [rowData, setRowData] = useState([]);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prev, setPrev] = useState();
  const [Contacts, setContacts] = useState([]);
  const [initialValue, setInitialValue] = useState(null);
  const location = useLocation();
  const path = location?.pathname;
  const ProjectId = path.split("/")[2];
  // const id = JSON.stringify(path.split("/")[2]);

  useEffect(() => {
    setRowData(data);
    setPrev(() => _.cloneDeep(prop));
  }, [JSON.stringify(prop)]);

  const onCancel = () => {
    setEditMode(false);
    setCancelModalVisible(false);
  };

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onCancel();
    }
  };

  useEffect(() => {
    if (!!rowData.length) {
      document.informationDetail = rowData?.reduce(
        (ac, { formItemName, value }) => {
          if (
            formItemName !== "createdBy" &&
            formItemName !== "lastModifiedBy"
          ) {
            if (ac.formItemName) {
              formList[ac.formItemName] = ac.value;
            }
            formList[formItemName] = value;
          }
          return formList;
        }
      );
    }
  }, [rowData]);

  // const getTaxRateAndCoordinates = async () => {
  //   const locInfo = await getCoordinatesAndZip(
  //     document.getElementById("mainAddress").innerText
  //   );
  //   if (!!locInfo.zipCode) {
  //     const taxRate = await getSalesTax({
  //       zipCode: locInfo.zipCode,
  //     });
  //     return await axios
  //       .post(
  //         "https://leadmanager-express-backend.ue.r.appspot.com/api/salesTax",
  //         { postalCode: locInfo.zipCode }
  //       )
  //       .then(async (res) => {
  //         const resTaxRate = parseFloat(res.data?.results[0]?.taxSales) * 100;
  //         return resTaxRate;
  //       })
  //       .catch((err) => {
  //         console.log("getTaxRateAndCoordinates ~ err:", { err });
  //         return undefined;
  //       });
  //   }
  // };

  const getTaxExept = async (value) => {
    if (value === "Yes") {
      rowData.find((e) => e.formItemName === "taxRate").value = 0;
      form.setFieldsValue({
        taxRate: 0,
        taxExempt: "Yes",
      });
      setRowData(state(rowData));
    } else {
      setLoading(true);
      const locInfo = await getCoordinatesAndZip(
        document.getElementById("mainAddress").innerText
      );
      let taxRate = await getSalesTax({
        zipCode: locInfo.zipCode,
      });
      console.log("taxRate", taxRate);

      // getTaxRateAndCoordinates().then((res) => {
      //   if (res === undefined) {
      //     setLoading(false);
      //     return;
      //   }
      rowData.find((e) => e.formItemName === "taxRate").value = taxRate;
      form.setFieldsValue({
        taxRate: taxRate,
        taxExempt: "No",
      });
      setRowData(state(rowData));
      setLoading(false);
    }
  };
  const validateFields = (onSuccessFunc, onError) => {
    let isTaxExempt = form.getFieldsValue()?.taxExempt === "Yes";
    form.setFieldsValue({
      taxRate: isTaxExempt ? 0 : form.getFieldValue("taxRate") / 100,
    });
    let allFields = form.getFieldsValue();
    console.log("allFields", allFields);
    validateForm(form, onSuccessFunc, onError);
  };
  const status = document
    ?.getElementById("stepperStatus")
    ?.getElementsByClassName("ant-steps-item-active")[0]
    ?.getElementsByClassName("ant-steps-item-title")[0]?.innerHTML;

  const getContacts = () => {
    fetchData("contacts").then((r) => setContacts(r));
  };

  useEffect(() => {
    if (path.split("/")[1] === "projects") {
      getContacts();
    }
  }, [location]);

  const UpdateListOfProjectsIds = async () => {
    if (path.split("/")[1] === "projects") {
      let getContactsData = form.getFieldsValue("contacts").contacts;
      let findContactsId = getContactsData
        ?.map?.(({ id }) => id)
        ?.filter(({ id }) => id !== undefined);
      let prevData = Contacts?.filter((el) => {
        return findContactsId.some((f) => {
          return f === el.contactId;
        });
      });
      let oldData = prevData?.filter?.((el) => {
        return el.listOfProjectsIds?.length < 1;
      });
      let oldDataWithID = prevData?.filter((el) => {
        return (
          el.listOfProjectsIds?.length > 0 &&
          !el.listOfProjectsIds?.some?.((r) => r === ProjectId)
        );
      });
      if (oldData?.length > 0) {
        oldData?.forEach(
          async (ids) =>
            await API.put("contacts", `/contacts/${ids.contactId}`, {
              body: {
                listOfProjectsIds: [ProjectId],
              },
            }).then(() => console.log("done without id "))
        );
      }
      if (oldDataWithID?.length > 0) {
        oldDataWithID?.forEach(
          async (ids) =>
            await API.put("contacts", `/contacts/${ids.contactId}`, {
              body: {
                listOfProjectsIds: [...ids.listOfProjectsIds, ProjectId],
              },
            }).then(() => console.log("done with id"))
        );
      }

      let removeID = initialValue?.filter((el) => {
        return !getContactsData.some((f) => {
          return el.value === f.id;
        });
      });
      if (removeID?.length > 0) {
        removeID?.forEach(async (ids) => {
          let temp = Contacts?.find((con) => con.contactId === ids.value);
          if (!!temp) {
            await API.put("contacts", `/contacts/${ids.value}`, {
              body: {
                listOfProjectsIds: [
                  ...temp.listOfProjectsIds?.filter?.((id) => id !== ProjectId),
                ],
              },
            }).then(() => console.log("REMOVE with id"));
          }
        });
      }
    }
  };

  useEffect(() => {
    setInitialValue(data?.find?.((l) => l.label === "Contacts")?.customOptions);
  }, []);

  return (
    <div className="InformationBodyEdit">
      {rowData
        ?.filter(
          ({ label, type }) =>
            label !== "Created By" && label !== "Last Modified By" && type
        )
        ?.map(
          (
            {
              label,
              value,
              formItemName,
              type,
              options = [],
              customOptions,
              disabled,
              disabledDate,
              rules,
              format,
              status,
              showNow,
              disabledTime,
              newDate,
              isDarkMode,
            },
            i
          ) => (
            <div key={i} className="InforamtionBodyElementContainer">
              {type !== "CheckBox" && (
                <span className="InformationBodyElementContainerTitle">
                  {label}
                </span>
              )}
              {label === "Unqualified Reason"
                ? FormItem[type]({
                    name: formItemName,
                    value: status === "Unqualified" ? value : "",
                    form,
                    rules,
                    options,
                    label,
                    customOptions,
                    onChange() {
                      setFormChanged(true);
                    },
                    disabled: status === "Unqualified" ? false : true,
                    getTaxExept: getTaxExept,
                  })
                : label === "Tax Rate"
                ? FormItem[type]({
                    name: formItemName,
                    value: value * 100,
                    form,
                    rules,
                    options,
                    label,
                    customOptions,
                    disabled,
                    onChange() {
                      setFormChanged(true);
                    },
                    getTaxExept: getTaxExept,
                  })
                : FormItem[type]({
                    name: formItemName,
                    value: type === "TimePicker" ? dayjsNY(value) : value,
                    form,
                    rules,
                    options,
                    label,
                    disabledDate,
                    format,
                    status,
                    showNow,
                    disabledTime,
                    newDate,
                    onChange() {
                      setFormChanged(true);
                    },
                    customOptions: customOptions?.filter((e) => {
                      return (
                        e?.value !== form.getFieldValue("primaryContact")?.id
                      );
                    }),
                    disabled,
                    getTaxExept: getTaxExept,
                  })}
            </div>
          )
        )}
      <div className="confirmContainer">
        <MondayButton
          onClick={() => {
            if (getFormChanged()) {
              setCancelModalVisible(true);
            } else {
              onCancel();
            }
          }}
          className="cancelButton mondayButtonRed"
          Icon={<XIcon />}
        >
          Cancel
        </MondayButton>
        <MondayButton
          disabled={loading}
          onClick={(res) => {
            const changedValues = form.getFieldsValue();
            const newProp = state(prop);
            validateFields(
              () => {
                // if (onEditRowData) {
                //   onEditRowData({
                //     changedKeys: keys?.(changedValues)?.map((e) => {
                //       newProp.params.rowData.find(
                //         ({ formItemName }) => formItemName === e
                //       ).value = changedValues[e];
                //       return newProp;
                //     })[0],
                //     changedValues,
                //     document: document.informationDetail,
                //     prev,
                //   });
                // }
                // else {
                getEditedValue(
                  changedValues,
                  undefined,
                  document.informationDetail,
                  prev,
                  keys?.(changedValues)?.map((e) => {
                    newProp.params.rowData.find(
                      ({ formItemName }) => formItemName === e
                    ).value = changedValues[e];
                    return newProp;
                  })[0]
                );
                // }
                UpdateListOfProjectsIds();
                setEditMode(false);
              },
              () => {}
            );
            // if (onEditRowData) {
            //   onEditRowData({
            //     changedKeys: keys?.(changedValues)?.map((e) => {
            //       newProp.params.rowData.find(
            //         ({ formItemName }) => formItemName === e
            //       ).value = changedValues[e];
            //       return newProp;
            //     })[0],
            //     changedValues,
            //     document: document.informationDetail,
            //     prev,
            //   });
            // }
          }}
          className="confirmButton"
          Icon={<Tick width={20} height={20} />}
        >
          Confirm
        </MondayButton>
      </div>
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        onKeyPress={(e) => onEnterPress(e)}
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setCancelModalVisible(false)}
              Icon={<CloseIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton onClick={onCancel} Icon={<Tick />}>
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
    </div>
  );
};
export default Information;
