import {
  useMemo,
  useState,
  forwardRef,
  useContext,
  useImperativeHandle,
} from "react";
import { Dayjs } from "dayjs";
// import { groupBy } from "lodash";
import { utils, read } from "xlsx";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { MenuOutlined, ReloadOutlined } from "@ant-design/icons";
import { Form, Popconfirm, Tooltip, Statistic, message } from "antd";

import { useResponsive } from "src/hooks";
// import { getBulkCoordinates, similarityAddressScore } from "src/utils";
import {
  BackIcon,
  RightArrow,
  DownloadIcon,
} from "src/components/SidebarPages/BasePage/src";
import { FullScreenIcon } from "src/assets/icons";
import { matchSchedulesWithExcelData } from "../../utils";
import PayrollLiveContext from "../../PayrollLiveContext";
import PopoverLegend from "../PopoverLegend/PopoverLegend";
import UploadExcelSchedule from "../../modals/UploadExcelSchedule";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { MondayButton, HoverButton } from "src/components/commonComponents";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
// import { stringSimilarity } from "src/components/SidebarPages/Fleet/fleetsLive/utils";
import {
  DEG_DATE_FORMAT,
  DEG_TIME_FORMAT,
} from "../../../Payroll/Tabs/DEG/components/modalComponents/utils/cellFunctions";
import { StoreType } from "src/components/SidebarPages/FleetMaintenanceView/types";
import LastPunchOverview from "src/components/pages/PayrollLive/modals/LastPunchOverview";
// import { withinRadius } from "../../../Payroll/Tabs/Activity/components/payrollActivityModalData";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { updateProgramField } from "src/components/pages/Settings/settingsComponents/ClientsPayrollConfig/clientsPayrollConfigUtils";

import "./PayrollLiveControlPanel.scss";

interface Props {}

const { Countdown } = Statistic;

const timeout = 300_000; // five minutes in seconds

const PayrollLiveControlPanel = forwardRef((props: Props, ref) => {
  const {} = props;
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);
  const programFields = useSelector(
    (store: StoreType) => store.programFields.programFields
  );

  const {
    jobsites,
    schedules,
    sidebarRef,
    setFilters,
    clearFilters,
    goFullScreen,
    setSchedules,
    clientConfigs,
    clientCompany,
    controlPanelForm,
    getEmployeeReports,
  } = useContext(PayrollLiveContext);

  const [countDown, setCountDown] = useState<number>(
    JSON.parse(localStorage.getItem("lastReportFetch")) + timeout || 0
  );
  const [lastFetch, setLastFetch] = useState<number>(
    JSON.parse(localStorage.getItem("lastReportFetch")) || 0
  );
  const [uploadVisible, setUploadVisible] = useState<boolean>(false);
  const [lastPunchOverview, setLastPunchOverview] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useResponsive();

  function sidebarToggle() {
    if (sidebarRef?.current) {
      sidebarRef.current.toggleSidebar();
    }
  }

  function onTimeChange(time: Date, formItemName: string) {
    setFilters((prev) => ({
      ...prev,
      [formItemName]: !!time ? time.valueOf() : null,
    }));
  }

  function resetFields() {
    controlPanelForm.resetFields();
    resetTimeFilter();
    setFilters((prev) => ({
      ...prev,
      punchTimeEnd: null,
      punchTimeStart: null,
    }));
  }

  function resetTimeFilter() {
    controlPanelForm.setFieldValue("punchTimeEnd", null);
    controlPanelForm.setFieldValue("punchTimeStart", null);
    setFilters((prev) => ({
      ...prev,
      punchTimeEnd: null,
      punchTimeStart: null,
    }));
  }

  function updateReportData() {
    const button = document.getElementById("loading-button");
    const nowTime = Date.now();
    const deadline = nowTime + timeout;
    setLastFetch(nowTime);
    setCountDown(deadline);
    button.classList.add("animation");
    getEmployeeReports(true);
    setTimeout(() => {
      button.classList.remove("animation");
    }, timeout);
  }

  function onLastPunchClose() {
    setLastPunchOverview(false);
    controlPanelForm.setFieldValue("selectedDate", dayjsNY().startOf("d"));
  }

  function disabledDates(current: Dayjs) {
    return (
      current &&
      parseInTz(current).startOf("d").valueOf() >
        parseInTz().startOf("d").valueOf()
    );
  }

  function onDateChange(e: Dayjs) {
    if (
      parseInTz(e).startOf("d").valueOf() !== dayjsNY().startOf("d").valueOf()
    ) {
      setLastPunchOverview(true);
    }
  }

  function onFileUpload(event: UploadRequestOption<any> & { file: any }) {
    const selectedClient = clientConfigs.find(
      (el) => el.configId === clientCompany
    );

    let fileReader = new FileReader();
    message.loading({
      key: "uplMsg",
      content: "Uploading file...",
      duration: 0,
    });
    fileReader.addEventListener("load", async () => {
      let { Sheets } = read(fileReader.result, {
        sheets: "Sheet1",
      });

      if (Sheets?.["Sheet1"]) {
        const excelData = utils.sheet_to_json(Sheets["Sheet1"]);

        const matchedJobs = await matchSchedulesWithExcelData(
          jobsites,
          schedules,
          excelData
        );

        setSchedules((prev) =>
          prev.map((sch) => {
            const matchedIndex = matchedJobs?.schedulesIncluded.findIndex(
              (el) => el.scheduleId === sch.scheduleId
            );
            if (matchedIndex > -1) {
              return matchedJobs?.schedulesIncluded[matchedIndex];
            } else {
              return sch;
            }
          })
        );

        const field = {
          clientName: selectedClient?.clientName,
          clientKey: selectedClient?.clientKey,
          fileName: event?.file?.name || "Unknown",
          excelData,
        };
        const updatedFields =
          todayScheduleField.fieldOptions.findIndex(
            (el) => el.clientName === selectedClient.clientName
          ) > -1
            ? todayScheduleField.fieldOptions.map((el) =>
                el.clientName === selectedClient?.clientName ? field : el
              )
            : todayScheduleField.fieldOptions.concat(field);

        updateProgramField({
          dispatch,
          fieldOptions: updatedFields,
          fieldId: todayScheduleField?.fieldId,
          fieldName: todayScheduleField?.fieldName,
        });

        message.success({
          key: "uplMsg",
          content: "File Uploaded",
          duration: 1.8,
        });
        return event.onSuccess(Sheets);
      } else {
        message.error({
          key: "uplMsg",
          content: "Sheet Crew composition was not found",
          duration: 2.2,
        });
        return event.onError({
          message: "Sheet Crew composition was not found",
          name: "Upload Error",
        });
      }
    });
    fileReader.readAsArrayBuffer(event?.file);
  }

  const lastFetchTimeFormat =
    width > 1080 ? DEG_DATE_FORMAT + " " + DEG_TIME_FORMAT : "hh:mm A";

  const customOptions = useMemo(() => {
    controlPanelForm.setFieldValue(
      "clientCompany",
      clientConfigs.find((el) => el.clientName === "GMNY Construction")
        ?.configId
    );
    return clientConfigs.map((el, key) => ({
      key,
      value: el.configId,
      label: el.clientName,
    }));
  }, [clientConfigs]);

  const todayScheduleField = useMemo(() => {
    if (!programFields?.length) {
      return;
    }
    let index = programFields.findIndex(
      (el) => el.fieldName === "Today's Scheduling"
    );
    const selectedField = programFields?.[index];
    return selectedField;
  }, [programFields]);

  const timeFilter = useMemo(() => {
    return (
      <Form form={controlPanelForm}>
        <div className="time-range">
          <InputComponent
            secondaryDarkMode
            type="customTimeInput"
            form={controlPanelForm}
            formItemName="punchTimeStart"
            onChange={(e: Date) => onTimeChange(e, "punchTimeStart")}
          />
          <RightArrow
            fill={darkMode ? "#fff" : "#323338"}
            height={13}
            width={13}
          />
          <InputComponent
            secondaryDarkMode
            type="customTimeInput"
            form={controlPanelForm}
            formItemName="punchTimeEnd"
            onChange={(e: Date) => onTimeChange(e, "punchTimeEnd")}
          />
          <button
            type="button"
            onClick={resetTimeFilter}
            className="clear-time-btn"
          >
            <XIcon height={10} width={10} />
          </button>
        </div>
        {width < 1400 ? (
          <InputComponent
            type="select"
            allowClear={false}
            form={controlPanelForm}
            formItemName="clientCompany"
            customOptions={customOptions}
            placeholder="Chose Subcontractor..."
          />
        ) : null}
      </Form>
    );
  }, [controlPanelForm, darkMode, setFilters, width]);

  const getDataBtnContent = useMemo(() => {
    const countDownActive = countDown - Date.now();
    if (countDownActive >= 0) {
      return (
        <Countdown
          format="mm:ss"
          value={countDown}
          onFinish={() => setCountDown(0)}
        />
      );
    } else {
      return <span className="btn-content">Get Latest Data</span>;
    }
  }, [countDown]);

  useImperativeHandle(
    ref,
    () => {
      return {
        resetFields,
        setLastFetch,
      };
    },
    []
  );

  return (
    <section
      className={`payroll-live-control-panel ${
        darkMode ? "payroll-live-control-panel-dark" : ""
      }`}
    >
      <div className="title-section">
        {width > 1180 ? (
          <div className="menu-icon" onClick={sidebarToggle}>
            <MenuOutlined />
          </div>
        ) : null}
        <span className="title-text">Payroll Live</span>
      </div>
      <div className="control-section">
        <Form form={controlPanelForm} className="control-panel-form">
          <span className="today-date">
            <InputComponent
              type="datePicker"
              allowClear={false}
              onChange={onDateChange}
              formItemName="selectedDate"
              disabledDate={disabledDates}
              defaultValue={dayjsNY().startOf("d")}
            />
          </span>

          <Popconfirm
            title={null}
            placement="bottom"
            description={timeFilter}
            rootClassName={`popup-filter ${
              darkMode ? "popup-filter-dark" : ""
            }`}
          >
            <HoverButton
              type="action"
              text={width > 1400 ? "Time Filter" : "Filter"}
              hasIcon={false}
              onClick={() => {}}
            />
          </Popconfirm>

          <HoverButton
            type="decline"
            hasIcon={false}
            onClick={() => {
              clearFilters();
              resetTimeFilter();
            }}
            text="Clear Filters"
          />
          {width > 1400 ? (
            <InputComponent
              type="select"
              allowClear={false}
              label={"Company: "}
              form={controlPanelForm}
              formItemName="clientCompany"
              customOptions={customOptions}
              placeholder="Chose Subcontractor..."
            />
          ) : null}
          <MondayButton
            Icon={<DownloadIcon />}
            className="mondayButtonBlue upload-btn"
            onClick={() => setUploadVisible(true)}
          >
            {width > 1300 ? "Today's Schedule" : null}
          </MondayButton>
        </Form>
        {lastFetch ? (
          <Tooltip title="Last updated reports">
            <span className="last-updated-reports">
              {dayjsNY(lastFetch).format(lastFetchTimeFormat)}
            </span>
          </Tooltip>
        ) : null}
        <div className="buttons-wrapper">
          <PopoverLegend />
          <button
            id="loading-button"
            className="loading-button"
            onClick={updateReportData}
            disabled={countDown >= Date.now()}
          >
            {width > 1200 ? <div className="progress"></div> : null}
            {width > 1200 ? getDataBtnContent : <ReloadOutlined />}
          </button>
          {width > 1200 ? (
            <Tooltip title="Full Screen">
              <MondayButton
                className={`mondayButton${
                  darkMode ? "Blue" : "White"
                } full-screen-btn`}
                Icon={<FullScreenIcon />}
                onClick={() => {
                  goFullScreen();
                }}
              />
            </Tooltip>
          ) : null}
          <Tooltip title="Back to Payroll">
            <MondayButton
              className={`mondayButton${darkMode ? "Blue" : "White"} back-btn`}
              hasIcon={false}
              onClick={() => navigate("/projectCost?tab=DEG")}
            >
              <BackIcon fill={darkMode ? "#fff" : "#231f20"} />
            </MondayButton>
          </Tooltip>
        </div>
      </div>
      {lastPunchOverview ? (
        <LastPunchOverview
          open={lastPunchOverview}
          onCancel={onLastPunchClose}
        />
      ) : null}
      {uploadVisible ? (
        <UploadExcelSchedule
          open={uploadVisible}
          onCancel={() => setUploadVisible(false)}
        />
      ) : null}
    </section>
  );
});

export default PayrollLiveControlPanel;
