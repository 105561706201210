import {
  useRef,
  Fragment,
  useState,
  useEffect,
  forwardRef,
  useContext,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Select, Tooltip, DatePicker, Form } from "antd";
import dayjs from "dayjs";
import { HistoryOutlined, MenuOutlined } from "@ant-design/icons";

import { FleetsLiveContext, loadLivePreference } from "../../utils";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { REFRESH_VALUES } from "../../data";
import { MondayButton, WarningModal } from "../../../../../commonComponents";
import { RefreshIcon, XIcon } from "../../../../Communication/assets";
import { OpenLinkIcon } from "../../../../../../assets";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { ReactComponent as WarningIcon } from "../../../../../../icons/warningTriangle.svg";

import "./FleetLiveHeader.scss";

const FleetLiveHeader = forwardRef((_, ref) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const {
    lastUpdate,
    isWritable,
    broadcastUpdates,
    showWritableInfo,
    changeDateHandler,
    changeRefreshRate,
    manualRefreshHandler,
    toggleSiderCollapsed,
  } = useContext(FleetsLiveContext);

  const [warningVisible, setWarningVisible] = useState(false);
  const modeRef = useRef(null);
  const updateRef = useRef(null);

  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    clearInterval(updateRef?.current);

    if (!lastUpdate) {
      return;
    }

    let el = document.getElementById("update-span");
    if (!el) {
      return;
    }

    el.innerText = getLastUpdate();

    updateRef.current = setInterval(() => {
      el.innerText = getLastUpdate();
    }, 60000);

    return () => {
      clearInterval(updateRef?.current);
    };
  }, [lastUpdate]);

  function getLastUpdate() {
    let diff = null;
    let text = "";
    if (lastUpdate) {
      diff = dayjsNY().diff(dayjsNY(lastUpdate), "second");
      if (diff < 60) {
        text = "Updated a few seconds ago";
      } else {
        let t = parseInt(diff / 60);
        let p = t !== 1;
        text = `Updated ${t} minute${p ? "s" : ""} ago`;
      }
    }
    return text;
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        getWriteUser() {
          if (modeRef?.current) {
            return modeRef?.current?.getEditingUser();
          }
        },
        changeRate(rate) {
          form.setFieldValue("refresh", rate);
        },
        changeDate(date) {
          form.setFieldValue("date", date);
        },
      };
    },
    [modeRef]
  );

  return (
    <Fragment>
      <Form
        form={form}
        className={`fleet-live-header ${isDarkMode ? "live-header-dark" : ""}`}
      >
        <div className="title-section">
          <div className="live-title">
            <div className="menu-icon" onClick={toggleSiderCollapsed}>
              <MenuOutlined />
            </div>
            <span className="title-text">Fleets Live</span>
          </div>
        </div>
        <div className="control-section">
          <span id="update-span"></span>
          <Tooltip title={"Update Data"} placement="bottom">
            <MondayButton
              {...{
                className: "mondayButtonBlue",
                Icon: <RefreshIcon />,
                onClick() {
                  if (!isWritable) {
                    showWritableInfo();
                  } else {
                    manualRefreshHandler();
                    broadcastUpdates({ request: "update-data" });
                  }
                },
                disabled: !lastUpdate,
              }}
            >
              {""}
            </MondayButton>
          </Tooltip>
          <Tooltip title={"Automatic update timer"}>
            <Form.Item
              name="refresh"
              initialValue={loadLivePreference("refreshRate")}
            >
              <Select
                {...{
                  allowClear: false,
                  popupMatchSelectWidth: false,
                  showSearch: false,
                  onSelect(e) {
                    changeRefreshRate(e);
                    broadcastUpdates({ request: "rate-change", rate: e });
                  },
                  onClick() {
                    if (!isWritable) {
                      showWritableInfo();
                    }
                  },
                  disabled: !isWritable,
                  style: { width: 150, outline: "none" },
                  suffixIcon: <HistoryOutlined />,
                  popupClassName: isDarkMode ? "darkDropDown" : undefined,
                  options: Object.keys(REFRESH_VALUES).map((key) => ({
                    label: key,
                    value: REFRESH_VALUES[key],
                  })),
                }}
              />
            </Form.Item>
          </Tooltip>
          <Form.Item
            name="date"
            initialValue={dayjsNY(loadLivePreference("liveSelectedDate"))}
          >
            <DatePicker
              {...{
                allowClear: false,
                inputReadOnly: true,
                format: "MMM DD, YYYY",
                popupClassName: isDarkMode ? "darkDateDropDown" : undefined,
                disabled: !isWritable,
                onClick() {
                  if (!isWritable) {
                    showWritableInfo();
                  }
                },
                disabledDate(date) {
                  return dayjs(date).valueOf() > dayjs().endOf("D").valueOf();
                },
                onChange(date) {
                  changeDateHandler(date);
                  broadcastUpdates({
                    request: "date-change",
                    date: dayjsNY(date).startOf("D").format(),
                  });
                },
              }}
            />
          </Form.Item>
          <MondayButton
            {...{
              Icon: <OpenLinkIcon />,
              className: "mondayButtonBlue",
              onClick() {
                setWarningVisible(true);
              },
            }}
          >
            Go to Fleet Management
          </MondayButton>
        </div>
      </Form>
      {warningVisible && (
        <WarningModal
          visible={warningVisible}
          setVisible={setWarningVisible}
          darkMode={isDarkMode}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
        >
          <div className="logout-modal-body">
            <span>
              <WarningIcon />
            </span>
            <p style={{ textAlign: "center" }}>
              Are you sure you want to leave the page?
            </p>
            <div className="buttons">
              <MondayButton
                onClick={() => setWarningVisible(false)}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  setWarningVisible(false);
                  navigate("/fleets/overview");
                }}
                Icon={<TickIcon />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
    </Fragment>
  );
});

export default FleetLiveHeader;
