import { message } from "antd";
import { useSelector } from "react-redux";
import { Dispatch, SetStateAction, useContext, useMemo } from "react";

import PayrollLiveContext from "../../PayrollLiveContext";
import {
  StoreType,
  JobsiteType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import { EmployeeReportType, ScheduleType } from "../../payrollLiveTypes";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

import "./EmployeeSidebarItem.scss";

const statusColors = {
  In: "#00a464",
  Out: "#787677",
  ["No Punch"]: "#f04f4e",
  ["Don't Match"]: "#e9c466",
};

function EmployeeSidebarItem(
  props: EmployeeReportType & {
    isForeman?: boolean;
    selectedEmployees: Array<string>;
    setSelectedEmployees: Dispatch<SetStateAction<Array<string>>>;
  }
) {
  const {
    color,
    punchTime,
    isForeman,
    liveStatus,
    employeeName,
    crewTeamName,
    employeeNumber,
    selectedEmployees,
    setSelectedEmployees,
  } = props;
  const { mapRef, employeesReport, setEmployeesInfo, jobsites, schedules } =
    useContext(PayrollLiveContext);
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);

  const matchedJobsite: JobsiteType | ScheduleType = useMemo(() => {
    if (props?.projectId) {
      let index = schedules.findIndex(
        (el) => el.projectId === props?.projectId
      );
      return schedules?.[index];
    } else if (props?.jobsiteId) {
      let index = jobsites.findIndex((el) => el.jobsiteId === props?.jobsiteId);
      return jobsites?.[index];
    }
  }, [jobsites, schedules, props?.jobsiteId, props?.projectId]);

  function onEmployeeFilter() {
    if (!props?.punchCoordinates?.lat) {
      message.error({
        key: "coordinatesError",
        content: "This employee does not have coordinates.",
        duration: 2,
      });
      return;
    }

    let filteredEmployees = selectedEmployees;

    if (filteredEmployees.includes(employeeNumber)) {
      filteredEmployees = filteredEmployees.filter(
        (emp) => emp !== employeeNumber
      );
      setEmployeesInfo(undefined);
    } else {
      filteredEmployees = [employeeNumber];
      setEmployeesInfo(employeeNumber);
    }

    let employeesCoordinates = employeesReport.flatMap((emp) =>
      emp.punchCoordinates?.lat &&
      filteredEmployees.includes(emp.employeeNumber)
        ? emp.punchCoordinates
        : []
    );

    if (mapRef.current && filteredEmployees.includes(employeeNumber)) {
      if (matchedJobsite?.addressPosition?.lat) {
        employeesCoordinates = employeesCoordinates.concat(
          matchedJobsite?.addressPosition
        );
      }
      mapRef.current.fitBoundsToMarkers(employeesCoordinates);
    } else {
      mapRef.current.fitBoundsToMarkers([]);
    }
    setSelectedEmployees(filteredEmployees);
  }

  return (
    <section
      key={employeeNumber}
      onClick={onEmployeeFilter}
      className={`emp-sidebar-item ${darkMode ? "emp-sidebar-item-dark" : ""}
      ${selectedEmployees.includes(employeeNumber) ? "active" : ""}
      `}
    >
      <div className="emp-data">
        <span className="emp-name">{employeeName}</span>
        <div className="emp-status">
          <div
            className="status-pin"
            style={{
              backgroundColor: !!color ? color : statusColors?.[liveStatus],
            }}
          ></div>
          <span>{liveStatus}</span>
        </div>
      </div>
      <div className="emp-data">
        <span>
          {crewTeamName}{" "}
          {isForeman ? <span style={{ color: "#acacac" }}>(Foreman)</span> : ""}
        </span>
        {punchTime ? (
          <span className="emp-punch-time">
            {parseInTz(punchTime).format("hh:mm A")}
          </span>
        ) : null}
      </div>
      {matchedJobsite ? (
        <div className="emp-data">
          <span>
            {(matchedJobsite as JobsiteType)?.jobAddress ||
              (matchedJobsite as ScheduleType)?.scheduleAddress}
          </span>
        </div>
      ) : (
        <div className="emp-data">
          <span>No Jobsite Match</span>
        </div>
      )}
    </section>
  );
}

export default EmployeeSidebarItem;
