import { API } from "aws-amplify";
import createNewTask from "./createNewTask";
import { sendSafetyCreateNotifs } from "../../../Safety/helpers";
import broadcastNotification from "../../../../../helpers/controllers/broadcastNotification";
import { message } from "antd";
import { getChangedData } from "../../../Accounting/components/utilities";
import { getCognitosForNotification } from "../../../../../utils";
import { deleteNotifications } from "../../../../../utils/notificationHelpers";
import { deleteAllRelatedToDos } from "../../../ToDos/forms/helpers";
import {
  docDefinition,
  imageForPdf,
  incidentPdfBody,
  mapImages,
  pdfBorderedSection,
  separateWitnessForPdf,
} from "./incidentsPdf";
import { blobToBase64, compareIncluding } from "../../../utils";
import createPDF from "../../../../../integrations/createPDF";
// import { savePdfToDrive } from "../../../Safety/safetyPdf";
import { driveApi } from "../../../../../integrations/DriveRequest";
import { companyDetails } from "../../../../../helpers/constants";
import { preventDuplicate } from "../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { groupBy } from "lodash";
import { getBase64 } from "./getBase64Pdf";
import axios from "axios";
import Swal from "sweetalert2";
import { b64toBlob } from "../../../../ProposalBuilder/components/ProposalPages/MergePdfs/MergePdfs";
import { mockData } from "./mockData";
import { devUrl, prodUrl } from "../../../../../utils/mergePdfDocsUrl";
// import { savePdfToDrive } from "../../../Contacts/ContactFomModal/helpers";

export async function createIncident({
  dispatch,
  accessToken = "",
  tmpBody = {},
  nameKey = "",
  mentions = [],
  notesObj = {},
  category = "",
  rowObject = {},
  requestId = "",
  assignedTo = [],
  preferences = {},
  saveDraft = false,
  createAction = "",
  loggedOnTasks = [],
  // onClose = () => {},
  programFields = [],
  requestUploads = [],
  userConfiguration = {},
  authenticatedUser = {},
  folderDriveKeyName = "",
  refreshTable = () => {},
  setSafetyData = () => {},
  addressSelectionInfo = {},
  // setConfirmationModal = () => {},
  handleSavedRecord,
  setVisibleCreationProgress = () => {},
  updateProgressStatus = () => {},
  hideProgress = () => {},
  afterSaveRequest = async () => {},
  saveAddedLogs,
}) {
  // check if is allowed in automations configuration to create task for
  const postCreationFieldOptions = programFields?.find(
    (prog) => prog.fieldName === "Automated Tasks"
  )?.fieldOptions;
  setVisibleCreationProgress(true);
  updateProgressStatus({ updatingRecord: "executing" });

  (!postCreationFieldOptions?.Incidents || saveDraft) &&
    hideProgress("creatingTask");

  await API.post("incidents", "/incidents", {
    body: {
      ...tmpBody,
      incidentCategory: category,
      createdBy: {
        name: userConfiguration?.nameOfUser,
        id: userConfiguration?.cognitoUserId,
      },
    },
  })
    .then(async (res) => {
      updateProgressStatus({ updatingRecord: "finished" });
      setVisibleCreationProgress(res); // save datas to generate logs in case of any fail

      handleSavedRecord({
        ...tmpBody,
        incidentCategory: category,
        createdBy: {
          name: userConfiguration?.nameOfUser,
          id: userConfiguration?.cognitoUserId,
        },
      });

      if (postCreationFieldOptions.Incidents && !saveDraft) {
        updateProgressStatus({ creatingTask: "executing" });
        await createNewTask({
          category: "Incidents",
          taskAssignedTo: assignedTo?.map((user) => ({
            cognitoUserId: user.cognitoUserId,
            nameOfUser: user.label,
            userName: user?.userName,
          })),
          taskRelatedTo: res?.incidentName,
          taskTopicId: res?.incidentId,
          taskSubTopic: category,
          userConfiguration,
          calendarPreference: preferences?.calendarPreference,
          loggedOnTasks,
          dispatch,
        })
          .then(() => {
            updateProgressStatus({ creatingTask: "finished" });
          })
          .catch((err) => {
            console.log({ err });
            updateProgressStatus({ creatingTask: "hasError" });
          });
      }

      refreshTable(res);

      if (!!requestId) {
        let requestObject = rowObject;
        delete requestObject?.incidentObject;
        delete requestObject?.incidentAddress;

        await afterSaveRequest({
          newRecordId: res?.incidentId,
          // requestId,
          path: "incidents",

          moveFilesParams: {
            filesToMove: requestUploads,
            newParentId: res?.googleDriveFolderIds?.[folderDriveKeyName],
            accessToken,
          },
        });

        // await moveRequestFiles({
        //   requestId,
        // });
      }

      if (!saveDraft) {
        setSafetyData({
          ...addressSelectionInfo,
          safetyApplicationObject: tmpBody?.incidentObject,
          safetyApplicationStatus: "Draft",
          incidentId: res?.incidentId,
          teamsConfiguration: res?.teamsConfiguration,
        });
      }

      // if (saveDraft) {
      //   onClose();
      // }

      updateProgressStatus({ sendingNotification: "executing" });
      if (!saveDraft) {
        sendSafetyCreateNotifs({
          userConfiguration,
          authenticatedUser,
          res,
          mentions,
          createAction,
          category,
          tmpBody,
          table: "incidents",
        })
          .then((notificationSent) => {
            updateProgressStatus({
              sendingNotification: !!notificationSent ? "finished" : "hasError",
            });
          })
          .catch((error) => {
            console.error("Error sending notification:", error);
          });
      } else {
        broadcastNotification(
          "42",
          "onSaveDraft",
          [
            { common: userConfiguration?.nameOfUser },
            {
              userName: userConfiguration?.nameOfUser,
              currentUser: authenticatedUser?.sub,
            },
          ],
          res?.incidentId
        ).then((notificationSent) => {
          updateProgressStatus({
            sendingNotification: !!notificationSent ? "finished" : "hasError",
          });
        });
      }
      await saveAddedLogs({
        recordId: res?.incidentId,
        recordName: res?.[nameKey],
        category: `${category?.replace("Incident", "").trim()} Incident`,
        topic: res?.[nameKey],
      });

      if (Object.keys(notesObj)?.length !== 0) {
        await API.post("notes", "/notes", {
          body: {
            notesObj,
            linkTo: `/incidents/${res?.incidentId}`,
            recordId: res?.incidentId,
          },
        }).catch((err) => {
          console.error("Error creating notes", err);
        });
      }

      message.success({
        content: "Saved Successfully!",
        key: "safetyLoading",
        duration: 3,
      });
    })
    .catch((err) => {
      updateProgressStatus({ updatingRecord: "hasError" });
      console.error("Error creating incident", err);
      message.error({
        content: "Something Went Wrong While Saving",
        key: "safetyLoading",
      });
    });
}

export async function editIncident({
  tmpBody = {},
  nameKey = "",
  category = "",
  rowObject = {},
  editAction = "",
  authenticatedUser = {},
  userConfiguration = {},
  customLogObj = () => {},
  refreshTable = () => {},
  updateProgressStatus = () => {},
  saveAddedLogs,
}) {
  updateProgressStatus({ updatingRecord: "executing" });
  await API.put("incidents", `/incidents/${rowObject?.incidentId}`, {
    body: {
      ...tmpBody,
      lastModifiedBy: {
        name: userConfiguration?.nameOfUser,
        id: userConfiguration?.cognitoUserId,
      },
    },
  })
    ?.then(async () => {
      updateProgressStatus({
        updatingRecord: "finished",
        sendingNotification: "executing",
      });

      message.success({
        content: "Saved Successfully!",
        key: "safetyLoading",
        duration: 3,
      });

      let { incidentObject: prevObj } = rowObject;
      let { incidentObject: currObj } = tmpBody;

      const newAssignedToIds = currObj?.assignedTo
        ?.filter(({ cognitoUserId: id }) => {
          return !prevObj?.assignedTo?.some(
            ({ cognitoUserId }) => cognitoUserId === id
          );
        })
        ?.map(({ cognitoUserId }) => cognitoUserId)
        ?.filter(Boolean);

      let tmpPrev = {
        ...prevObj,
        ...customLogObj(prevObj),
      };

      let tmpCurr = {
        ...currObj,
        ...customLogObj(currObj),
      };

      refreshTable({ ...rowObject, tmpBody }, "Edit");

      let changes = getChangedData(tmpCurr, tmpPrev);

      if (!!changes) {
        await saveAddedLogs({
          recordId: rowObject?.incidentId,
          recordName: rowObject?.incidentObject?.[nameKey],
          category: `${category} Incident`,
          actionType: "Edit",
          topic: rowObject?.incidentObject?.[nameKey],
          currentData: changes?.curr || {},
          previousData: changes?.prev || {},
          updatedKeys: ["See Details"],
        });
      }
      newAssignedToIds?.length > 0 &&
        broadcastNotification(
          "43",
          "onIncidentAssignation",
          [
            {
              common: userConfiguration?.nameOfUser,
              category: category,
            },
            {
              userName: userConfiguration?.nameOfUser,
              currentUser: authenticatedUser?.sub,
              cognitos: newAssignedToIds || [],
            },
          ],
          rowObject?.incidentId
        );

      await broadcastNotification(
        "43",
        editAction,
        [
          { common: userConfiguration?.nameOfUser },
          {
            userName: userConfiguration?.nameOfUser,
            currentUser: authenticatedUser?.sub,
            cognitos: getCognitosForNotification(
              userConfiguration,
              rowObject?.teamsConfiguration
            ),
          },
        ],
        rowObject?.incidentId
      ).then((notificationSent) => {
        updateProgressStatus({
          sendingNotification: !!notificationSent ? "finished" : "hasError",
        });
      });
    })
    ?.catch((err) => {
      updateProgressStatus({ updatingRecord: "hasError" });
      console.error("Error editing incident", err);
      message.error({
        content: "Something Went Wrong While Saving",
        key: "safetyLoading",
      });
    });
}

export async function deleteIncident({
  dispatch = () => {},
  nameKey = "",
  category = "",
  rowObject = {},
  deleteAction = "",
  // onClose = () => {},
  notifications = [],
  userConfiguration = {},
  authenticatedUser = {},
  refreshTable = () => {},
  setVisibleCreationProgress = () => {},
  updateProgressStatus = () => {},
}) {
  setVisibleCreationProgress(true);
  updateProgressStatus({ updatingRecord: "executing" });

  await API.del("incidents", `/incidents/${rowObject?.incidentId}`)
    .then(async () => {
      updateProgressStatus({
        updatingRecord: "finished",
        sendingNotification: "executing",
      });

      refreshTable(rowObject, "Delete");
      deleteNotifications(notifications, rowObject?.incidentId, dispatch);
      deleteAllRelatedToDos({ recordId: rowObject?.incidentId });

      message.success({
        content: "Deleted Successfully!",
        key: "safetyLoading",
        duration: 3,
      });

      let deletePostLogsBody = {
        recordId: rowObject?.incidentId,
        recordName: rowObject?.incidentObject?.[nameKey],
        category: `${category} Incident`,
        actionType: "Delete",
        label: "",
        topic: rowObject?.incidentObject?.[nameKey],
        currentData: {},
        previousData: {},
        member: userConfiguration?.nameOfUser,
        nameOfUser: userConfiguration.nameOfUser,
        cognitoUserId: userConfiguration?.cognitoUserId,
        memberCognitoUserId: userConfiguration?.cognitoUserId,
        id: userConfiguration?.cognitoUserId,
        createdAt: Date.now(),
        updatedKeys: [],
      };

      setVisibleCreationProgress({ deletePostLogsBody });

      await API.del("notes", `/notes/${rowObject?.incidentId}`).catch((err) => {
        console.error("Error deleting notes", err);
      });

      broadcastNotification("43", deleteAction, [
        {
          common: userConfiguration?.nameOfUser,
        },
        {
          userName: userConfiguration?.nameOfUser,
          currentUser: authenticatedUser?.sub,
          cognitos: getCognitosForNotification(
            userConfiguration,
            rowObject?.teamsConfiguration
          ),
        },
      ]).then((notificationSent) => {
        updateProgressStatus({
          sendingNotification: !!notificationSent ? "finished" : "hasError",
        });
      });

      // onClose({ deleteAction: true, deletePostLogsBody });
    })
    .catch((err) => {
      updateProgressStatus({ updatingRecord: "hasError" });
      console.error("Error deleting incident", err);
      message.error({
        content: "Something Went Wrong While Deleting",
        key: "messageDeleting",
      });
    });
}

export const createPdfBody = ({
  dynamicFields,
  selectedTeam,
  crews,
  userConfiguration,
  defaultId,
  witnessCount,
  witnessSignings,
  rowObject,
  rowObjectKey,
}) => {
  const foremen = crews
    ?.filter((e) => e.foreman === true)
    ?.map(({ crewId, crewName, members }) => ({
      label: crewName,
      value: crewId,
      members,
    }));

  const crewMembers = crews
    ?.filter((e) => e.foreman !== true)
    ?.map(({ crewId, crewName }) => ({
      label: crewName,
      value: crewId,
    }));

  let tmpFields = [
    ...dynamicFields,
    {
      label: "Team Members",
      value: selectedTeam?.map((el) => el?.members?.map((e) => e?.nameOfUser)),
      category: "General Information",
    },
  ];

  const configPdfData = Object.keys(groupBy(tmpFields, "category"))
    ?.filter(
      (key) =>
        key !== "undefined" &&
        !!key &&
        key !== null &&
        key !== "" &&
        key !== undefined
    )
    ?.map((key) => {
      return pdfBorderedSection({
        title: key,
        body: incidentPdfBody({
          title: key,
          fieldsJSON: tmpFields,
          rowObject,
          foremen,
          crewMembers,
          userConfiguration,
        }),
      });
    });

  const witnessList = separateWitnessForPdf({
    rowObject,
    defaultId,
    witnessCount,
    witnessSignings,
    rowObjectKey,
  });

  const safetyDirectorSignatureSection = pdfBorderedSection({
    title: "Safety Director Signature",
    body: [
      {
        key: "Safety Director",
        value:
          rowObject?.incidentObject?.safetyDirectorSignature?.nameOfUser || "",
      },
      {
        key: "Signature",
        value: rowObject?.incidentObject?.safetyDirectorSignature?.src || "N/A",
      },
    ],
  });

  const witnessPdfData = witnessList?.map((el) => {
    if (
      el?.every(
        ({ value }) =>
          !value || value === "" || value === null || value === undefined
      )
    ) {
      return null;
    }
    return pdfBorderedSection({
      title: "Witnesses",
      body: el,
    });
  });

  const toConcat = [
    ...(!!witnessList?.length ? [witnessPdfData] : []),
    ...(rowObject?.incidentObject?.hasOwnProperty("safetyDirectorSignature")
      ? [safetyDirectorSignatureSection]
      : []),
  ];

  return configPdfData?.concat(toConcat);
};

export async function incidentPdf({
  base64,
  pdfId = "",
  accessToken,
  category = "",
  driveRequest,
  rowObject = {},
  driveFunctions,
  setPdfId = () => {},
  folderDriveKeyName = "",
  createPdfParams = {
    dynamicFields: [],
    selectedTeam: [],
    crews: [],
    userConfiguration: {},
    defaultId: "",
    witnessCount: null,
    witnessSignings: [],
  },
  rowObjectKey,
}) {
  Swal.fire({
    title: "<strong>Generating PDF</strong>",
    icon: "info",
    html: "Please wait to generate PDF...!",
    didOpen: () => {
      Swal.showLoading();
    },
  });

  const images = await imageForPdf({ rowObject, accessToken });

  const imagePdfData = mapImages(images);

  let companyLogo = base64?.find(({ fileName }) =>
    compareIncluding(fileName, "Core Logo Black")
  )?.base64;

  const createDocDef = docDefinition(
    `${rowObject?.incidentName} ${category} Form`,
    createPdfBody({ ...createPdfParams, rowObject, rowObjectKey })?.concat(
      imagePdfData
    ),
    companyLogo
  );
  const pdfDocGenerator = pdfMake.createPdf(createDocDef);

  let fileList = [];

  try {
    createPDF({
      action: "open",
      docDefinition: docDefinition(
        `${rowObject?.incidentName} ${category} Form`,
        createPdfBody({ ...createPdfParams, rowObject })?.concat(imagePdfData),
        companyLogo
      ),
    });
    Swal.close();
  } catch (error) {
    Swal.fire({
      title: "<strong>Error</strong>",
      icon: "error",
      html: "There was an error while generating PDFs! ",
    });
  }

  return;

  const existedObject = await getBase64({
    pdfDocGenerator,
    name: rowObject?.projectName,
  });

  fileList.push(existedObject);

  await Promise.allSettled(
    rowObject?.googleDriveUpload?.map(async (element) => {
      let pdfCondition = element.mimeType?.includes("application/pdf");
      let blob;
      let size;
      let file;
      if (pdfCondition) {
        await driveRequest
          .getImageSrc(element?.id)
          .then((r) => r)
          .then((res) => (blob = res?.src));

        const fileSrc = new Promise((resolve, reject) => {
          resolve(blob);
        });

        file = {
          fileSrc: fileSrc,
          name: element?.name,
          originFileObj: {
            uid: element?.id,
          },
          percent: 0,
          size: size,
          status: "uploading",
          type: "local",
          uid: element?.id,
        };

        fileList.push(file);
      }
    })
  );

  await axios
    .post(process.env.NODE_ENV === "production" ? prodUrl : devUrl, {
      originalPdf: [
        ...(await Promise.all(fileList?.map((el) => el?.fileSrc))),
      ]?.filter((el) => typeof el === "string"),
      configuration: {
        fileList: fileList,
        pagesConfig: {},
      },
    })
    .then(async ({ data: mergedPdf }) => {
      Swal.close();

      var blob = b64toBlob(mergedPdf, "application/pdf");
      const blobURL = URL.createObjectURL(blob);

      window.open(blobURL);

      await savePdfToDrive({
        blobData: mergedPdf,
        title: `Property Damage ${rowObject?.projectName}`,
        driveRequest,
        folderId:
          rowObject?.googleDriveFolderIds?.[folderDriveKeyName] ||
          driveFunctions.parentFolderId,
      }).then((res) => {
        if (!!pdfId) {
          driveApi({ accessToken }).deleteDriveItem(pdfId);
        }
        setPdfId(res?.id);

        API.put("incidents", `/incidents/${rowObject?.incidentId}`, {
          body: {
            incidentObject: {
              ...rowObject?.incidentObject,
              gDrivePdfId: res?.id,
            },
          },
        }).catch((err) => console.error("Error Updating Safety: ", err));
      });
    })
    .catch(() => {
      Swal.fire({
        title: "<strong>Error</strong>",
        icon: "error",
        html: "There was an error while generating PDFs! ",
      });
    });
}

export async function savePdfToDrive({
  blobData,
  title,
  driveRequest,
  folderId,
}) {
  return new Promise(async (resolve, reject) => {
    try {
      const dataBase64 = blobData;

      const uploadFile = await driveRequest.uploadFile(
        dataBase64,
        "application/pdf",
        {
          name: title,
          parents: folderId,
          mimeType: "application/pdf",
        },
        true
      );

      const { id } = await uploadFile.json();

      const expirationTime = new Date();

      expirationTime.setDate(expirationTime.getDate() + 1);

      await driveRequest.setFilePermission(id, {
        type: "anyone",
        role: "reader",
        expirationTime: expirationTime.toISOString(),
      });

      resolve({ id, name: title });
    } catch (error) {
      console.error("Error saving PDF to Drive:", error);
      reject(error);
    }
  });
}

export async function handleEmailAttachments({
  base64,
  accessToken,
  category = "",
  driveRequest,
  rowObject = {},
  driveFunctions,
  folderDriveKeyName = "",
  createPdfParams = {
    dynamicFields: [],
    selectedTeam: [],
    crews: [],
    userConfiguration: {},
    defaultId: "",
    witnessCount: null,
    witnessSignings: [],
  },
  setAttachments,
}) {
  const images = await imageForPdf({ rowObject, accessToken });
  const imagePdfData = mapImages(images);
  let companyLogo = base64?.find(({ fileName }) =>
    compareIncluding(fileName, "Core Logo Black")
  )?.base64;
  const createDocDef = docDefinition(
    `${rowObject?.incidentName} ${category} Form`,
    createPdfBody({ ...createPdfParams, rowObject })?.concat(imagePdfData),
    companyLogo
  );
  const pdfDocGenerator = pdfMake.createPdf(createDocDef);

  const existedObject = await getBase64({
    pdfDocGenerator,
    name: rowObject?.projectName,
  });

  let fileList = [];

  fileList.push(existedObject);

  const pdfFiles = await Promise.all(
    fileList?.map(async (el) => {
      const resolvedFileSrc = await (typeof el.fileSrc === "string"
        ? Promise.resolve(el.fileSrc)
        : el.fileSrc);

      return {
        src: resolvedFileSrc,
        id: el.uid,
        name: el.name,
        size: el.size,
      };
    })
  );

  for (const file of pdfFiles) {
    const blob = base64ToBlob(file.src, "application/pdf");

    try {
      const res = await driveRequest.uploadFile(
        file.src.split(",")[1],
        blob.type,
        {
          name: `Property Damage ${rowObject?.projectName || ""}`,
          mimeType: blob.type,
          writersCanShare: true,
          parents: [
            driveRequest,
            rowObject?.googleDriveFolderIds?.[folderDriveKeyName] ||
              driveFunctions.parentFolderId,
          ],
        },
        true
      );

      const data = await res.json();
      const { id, mimeType, name } = data;

      if (blob && id && mimeType && name) {
        setAttachments((prev) => [
          ...prev,
          {
            id: file.id,
            type: mimeType,
            name,
            blob: blob,
            size: blob?.size,
          },
        ]);
      }
    } catch (error) {
      console.error(["incidents_modal_handle_email_attachments"], error);
    }
  }
}

export function base64ToBlob(base64, mimeType = "") {
  const byteCharacters = atob(base64.split(",")[1]);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
}

export async function handleGoogleDriveUpload(file, driveRequest) {
  try {
    const res = await driveRequest.getImageSrc(file.id);
    const blob = base64ToBlob(res.src, "application/pdf");
    const uploadedFile = await driveRequest.getDriveItem(file.id);
    const data = await uploadedFile.json();
    const { id, name, mimeType } = data;

    if (!id || !data) {
      return;
    }

    return {
      id,
      type: mimeType,
      name,
      blob: blob,
      size: blob?.size,
    };
  } catch (error) {
    console.error("[incidents_helpers_getting_uploaded_files_src]", error);
  }
}
