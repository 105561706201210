import moment from "moment";
import axios from "axios";
import * as FileSaver from "file-saver";
import { groupBy } from "lodash";
import { Button, Badge, message, Tooltip, Popover, Checkbox } from "antd";
import { TruckOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { API } from "aws-amplify";

import { emptyDays } from "../Subcomponents/emptyDays";
import { DayStatus } from "../Subcomponents/DayStatusChangeModal/DayStatuses";
import {
  JUMP_MAXIMAL_PROGRESS,
  get_ProgressDimensionsByServiceId,
  hoist_dimension,
} from "../../DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import { getCognitosForNotification, openInNewTab } from "../../../../../utils";
import { typeOfWorkColors } from "../DataAgGrid/columnDefs";
import { breakdown_dimension } from "../PLIPerService/componentsForPli/Breakdown/breakdown";
import dayjs from "dayjs";
import { checkIfServiceIsHoist } from "../../../../SidebarPages/Estimations/DataEntryGrid/models/Service";
import { ProgressStatistics } from "../../../../SidebarPages/Scheduling/helpers/totals";
import { forceToNumber } from "../../../../SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { MondayButton, ProgressBar } from "../../../../commonComponents";
import { get_new_progressByDay } from "../../../../SidebarPages/Scheduling/helpers/setters";
import broadcastNotification from "../../../../../helpers/controllers/broadcastNotification";
import {
  Driver,
  Truck,
} from "../../../../SidebarPages/Scheduling/GanttChart/assets/DispatchIcons";
import { formatFullDate, formatShortTime } from "../helpers/creators";
import { isOffDay } from "../helpers/calculators";
import CustomProgressBar from "../../../../commonComponents/CustomProgress/CustomProgressBar";
import { XIcon } from "../../../../SidebarPages/Communication/assets";

import { EyeIcon } from "../../../../SidebarPages/DynamicView/src";
import PopupToAddFloorsToJump from "../PLIPerService/componentsForPli/HoistElements/JumpsConfig/PopupToAddFloorsToJump";
import { InfoIconBlue } from "../Subcomponents/SelectView/newModals/TrucksModal/HelpingComponents/icons";
import { quickStatistics_Schedule_and_Project } from "../../../../SidebarPages/Scheduling/helpers/quickStatistics";
import ScheduleDay from "../models/ScheduleDay";
import {
  getServiceLegacyPrice,
  getTotalServicesPrice,
} from "../../../../SidebarPages/Projects/Accounting/calculations/servicePrices";
import SelfCheck from "../../../../SidebarPages/Estimations/DataEntryGrid/subcomponents/HoistForm/subcomponents/SelfCheck/SelfCheck";
import { removeHTMLTags } from "../../../../../utils/removeHTMLTags";
import { parseInTz } from "../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { CrewsIcon } from "../../../../../assets";
import { CameraIcon } from "../../../../pages/ScheduleProgress/assets/images";

export const logicChangeCrews = (createdAt) => createdAt > 1717056000;
export const addNrOfDays = (date) => {
  let add = 1;
  if (dayjs(date).day() === 5) {
    add = 5;
  }
  return add;
};

export const onlyEstWithScopeFalse = (
  estimation = {},
  serviceDefinitions = []
) => {
  return Object.entries(estimation || {})?.reduce?.((prev, [key, value]) => {
    prev[key] =
      serviceDefinitions?.length > 0
        ? value?.filter((service) => {
            const serviceDefinition = serviceDefinitions?.find(
              (def) =>
                def?.serviceId?.toString() === service?.serviceId?.toString()
            );
            if (
              !!serviceDefinition?.isSchedulable ||
              !serviceDefinition?.isScope
            ) {
              return service;
            }
          })
        : value?.filter((service) => !service?.isScope);
    if (!prev?.[key]?.length) {
      delete prev[key];
    }
    return prev || {};
  }, {});
};

export const firstServiceDay = (services = [], type = "") => {
  let date = "";
  services?.forEach?.((item) => {
    if (!date || item?.[type + "potentialStartDate"] < date) {
      date = item?.[type + "potentialStartDate"];
    }
  });
  return date ? dayjsNY(date).format("MM/DD/YYYY") : "Unknown";
};

export const firstScheduleDay = (
  schedules = [],
  typeOfWork = "",
  fullWeatherArray = []
) => {
  const lastSchedule =
    schedules
      ?.filter((schedule) => schedule?.typeOfWork === typeOfWork)
      ?.sort(
        (a, b) =>
          dayjs(b?.createdAt)?.valueOf() - dayjs(a?.createdAt)?.valueOf()
      )
      ?.shift() || {};

  let lastScheduleDay =
    lastSchedule?.scheduleDays?.slice(-1)?.[0] || new ScheduleDay(0);

  if (Object.keys(lastSchedule || {}).length > 0) {
    lastScheduleDay.day = 0;
  }

  return [ScheduleDay.NextScheduleDay(lastScheduleDay, fullWeatherArray)];
};

export const isElevationCompletedInPrevSchedule = (elevation) =>
  elevation?.items?.every((pli) => !!prevPliCompleted(pli));

export const isServiceCompletedInPrevSchedule = (service) =>
  service?.serviceOptions?.[0]
    ?.flatMap(({ items = [] }) => items)
    ?.every((pli) => prevPliCompleted(pli));

// ALL PROGRESS DAYS IN A SCHEDULE
export const getDaysWithProgress = (
  scheduleId = undefined,
  scheduleDays = [],
  toBeScheduled = {},
  typeOfWork = ""
) => {
  const daysWithProgress = [];
  const pushProgress = (day = "", values = {}) => {
    if (scheduleDays?.find((sd) => sd?.id === day)) {
      daysWithProgress.push({
        day,
        scheduleId,
        startDate: dayjs(
          scheduleDays?.find((sd) => sd?.id === day)?.startDate
        ).format("MM/DD/YYYY"),
        typeOfWork,
        ...values,
      });
    }
  };
  const processBreakdown = (
    breakdown = {},
    breakdown_dimension = "",
    commonKeys = {}
  ) => {
    breakdown?.progressByDay?.forEach?.((day) => {
      if (!scheduleDays?.find((sd) => sd?.id === day?.day)) return;
      const dayHasProgress = day?.[breakdown_dimension] > 0;
      if (dayHasProgress) {
        pushProgress(day?.day, {
          [breakdown_dimension]: day?.[breakdown_dimension],
          ...commonKeys,
          breakdownName: breakdown?.name,
          breakdownRate: breakdown?.rate,
        });
      }
    });
  };
  const processSubPli = (subPli = {}, d1 = "", d2 = "", commonKeys = {}) => {
    subPli?.progressByDay?.forEach?.((day) => {
      if (!scheduleDays?.find((sd) => sd?.id === day?.day)) return;
      const dayHasProgress = day?.[d1] > 0 || day?.[d2] > 0;
      if (dayHasProgress) {
        const values = !d2
          ? {
              day: day?.day,
              [d1]: day?.[d1],
              subPli: subPli?.id,
              ...commonKeys,
            }
          : {
              day: day?.day,
              [d1]: day?.[d1],
              [d2]: day?.[d2],
              subPli: subPli?.id,
              ...commonKeys,
            };
        pushProgress(day?.day, values);
      }
    });
  };
  Object.entries(toBeScheduled || {})?.forEach?.(([key, value]) => {
    value?.forEach?.((service) => {
      service?.serviceOptions?.[0]?.forEach?.((elevation) => {
        elevation?.items?.forEach?.((pli) => {
          const {
            typeOfProgress = "",
            progressByDay = [],
            breakdownValue = [],
            subPli = [],
          } = pli || {};
          const commonKeys = {
            estimationId: key,
            serviceId: service?.serviceId,
            elevationId: elevation?.elevationId,
            elevationName: elevation?.elevationLabel,
            serviceName: service?.label,
            totalPliProgress: pli?.totalProgress,
            pliId: pli?.id,
            typeOfProgress,
          };

          const { d1, d2 } = get_ProgressDimensionsByServiceId(
            service?.serviceId
          );

          if (typeOfWork === "Inspection") {
            breakdownValue?.forEach?.((breakdown) => {
              if (breakdown?.progressByDay?.length > 0) {
                breakdown?.progressByDay?.forEach?.((day) => {
                  const dayHasProgress = day?.[breakdown_dimension] > 0;
                  if (dayHasProgress) {
                    pushProgress(day?.day, {
                      [breakdown_dimension]: day?.[breakdown_dimension],
                      ...commonKeys,
                      floor: pli?.floor,
                      breakdownName: breakdown?.name,
                    });
                  }
                });
              }
            });
          } else if (
            typeOfProgress === "jumpBreakdown" &&
            progressByDay?.length > 0
          ) {
            progressByDay?.forEach?.((progressFloor) => {
              const { progress = [], day = "" } = progressFloor || {};
              if (!scheduleDays?.find((sd) => sd?.id === day)) return;

              progress?.forEach?.((pr) => {
                if (pr?.progress > 0 && pr?.floor === pli?.floor) {
                  pushProgress(day, {
                    floorProgress: pr?.progress,
                    floor: pr?.floor,
                    ...commonKeys,
                  });
                }
              });
            });
          } else if (typeOfProgress === "dimensions") {
            progressByDay?.forEach?.((day) => {
              const dayHasProgress = day?.[d1] > 0 || day?.[d2] > 0;
              if (dayHasProgress) {
                const values = d2
                  ? {
                      day: day?.day,
                      [d1]: day?.[d1],
                      [d2]: day?.[d2],
                      ...commonKeys,
                    }
                  : { day: day?.day, [d1]: day?.[d1], ...commonKeys };
                pushProgress(day?.day, values);
              }
            });
          } else if (typeOfProgress === "breakdown") {
            breakdownValue?.forEach?.((breakdown) =>
              processBreakdown(breakdown, breakdown_dimension, commonKeys)
            );
          } else if (typeOfProgress === "subPli" || subPli?.length > 0) {
            subPli?.forEach?.((subPliItem) =>
              processSubPli(subPliItem, d1, d2, commonKeys)
            );
          }
        });
      });
    });
  });
  return daysWithProgress?.sort(
    (a, b) => dayjs(a?.startDate).valueOf() - dayjs(b?.startDate).valueOf()
  );
};

export const checkSchedulesWDuplicateDaysInPli = (schedules) => {
  let toReturn = [];
  schedules?.forEach?.((schedule) => {
    const { toBeScheduled = {} } = schedule;
    Object.values(toBeScheduled)
      .flat()
      ?.forEach?.((service) => {
        const { serviceOptions = [] } = service;
        serviceOptions?.[0]?.forEach?.((elevation) => {
          const { items = [] } = elevation;
          items?.forEach((pli) => {
            const progressByDay = pli?.progressByDay || [];
            const uniqueProgressByDay = preventDuplicate(progressByDay, "day");
            if (progressByDay?.length !== uniqueProgressByDay?.length) {
              toReturn.push({
                scheduleId: schedule?.scheduleId,
                scheduleName: schedule?.scheduleName,
                scheduleAddress: schedule?.scheduleAddress,
                serviceId: service?.serviceId,
                serviceName: service?.label,
                elevation: elevation?.elevationLabel,
                days: pli?.days,
                progressByDay: pli?.progressByDay,
              });
            }
          });
        });
      });
  });
  return toReturn;
};

export const scheduleProgress = (toBeScheduled = {}) => {
  const { scheduleTotalProgressPercentage = 0 } =
    quickStatistics_Schedule_and_Project({
      project: {},
      toBeScheduled,
      schedules: [],
      typeOfWork: "",
    }) || {};

  return Number(scheduleTotalProgressPercentage) || 0;
};

export const entities = (
  mainKey,
  secondKey,
  rowData,
  gridApiForProjects,
  gridApiForContact,
  gridApiForEstimates,
  gridApiForOpp
) => [
  createEntity(
    "projects",
    "projectId",
    "projectObject",
    "projectId",
    {
      [secondKey]: rowData?.[secondKey],
    },
    gridApiForProjects
  ),
  createEntity(
    "contacts",
    "contactId",
    "contactsObject",
    "contactId",
    {
      contactAccountName: rowData?.[secondKey],
    },
    gridApiForContact
  ),
  createEntity(
    "estimations",
    "estimationId",
    undefined,
    undefined,
    {
      accountName: rowData?.[secondKey],
    },
    gridApiForEstimates
  ),
  createEntity(
    "opportunities",
    "opportunityId",
    "opportunitiesObject",
    "oppObject",
    {
      [secondKey]: mainKey === "accountId" ? rowData?.[secondKey] : "",
      accountName: rowData?.[secondKey],
    },
    gridApiForOpp
  ),
];

export const createEntity = (
  apiName = "",
  entityIdKey = "",
  parentDriveKey = "",
  childrenDriveKey = "",
  additionalBody = {},
  gridApi = {}
) => {
  return {
    data: gridApi?.getSelectedRows() || [],
    apiName,
    entityIdKey,
    parentDriveKey,
    childrenDriveKey,
    additionalBody,
  };
};

export const txtForBadgeRibbonMigrate = (fetchedLength = 0, table = "") => {
  let txt = "";
  if (table === "opportunities") {
    txt = fetchedLength === 1 ? "opportunity" : "opportunities";
  } else {
    txt = fetchedLength === 1 ? table?.slice(0, -1) : table;
  }
  return fetchedLength + " " + txt;
};

export const txtForAlertMigrate = (mainKey = "") => {
  let txt = "Selection of";
  if (mainKey === "accountId") {
    txt += ` opportunities and estimations`;
  } else {
    txt += ` estimations`;
  }
  txt += ` pertinent to a ${
    mainKey === "accountId" ? "project" : "opportunity"
  } will derive exclusively from the ${
    mainKey === "accountId" ? "project" : "opportunity"
  } itself.`;
  return txt;
};

export const updateBothMainKeys = (mainKey = "", row = {}, rowData = {}) => {
  let toReturn = {};
  if (mainKey === "leadId") {
    if ("accountId" in row) {
      toReturn["accountId"] =
        row?.accountId?.length > 0 ? rowData?.accountId : row?.accountId;
    } else {
      toReturn = {};
    }
  } else {
    if ("leadId" in row) {
      toReturn["leadId"] =
        row?.leadId?.length > 0 ? rowData?.leadId : row?.leadId;
    } else {
      toReturn = {};
    }
  }
  return toReturn;
};

export const handleLogicForMigrateData = (
  mainKey = "",
  mainApi = {},
  estimations = [],
  opportunities = [],
  keyToFilter = "",
  gridApiForEstimates = [],
  gridApiForOpp = []
) => {
  mainApi?.addEventListener("rowSelected", () => {
    const selectedRows = mainApi?.getSelectedRows() || [];

    const { est, opp } = selectedRows?.reduce?.(
      (acc, selected) => {
        const filterBySelectedKey = (data = []) =>
          data?.filter?.(
            (item) => item?.[keyToFilter] === selected?.[keyToFilter]
          );

        acc.est = [...acc.est, ...filterBySelectedKey(estimations)];
        acc.opp = [...acc.opp, ...filterBySelectedKey(opportunities)];
        return acc;
      },
      { est: [], opp: [] }
    );

    const txt = generateToastText(mainKey, opp, est, selectedRows?.length);

    if (txt) {
      swalToast({
        icon: "success",
        title: txt,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }

    const handleCheckboxArgs =
      mainKey === "leadId"
        ? [gridApiForEstimates, est, "estimationId", "opportunityId"]
        : [gridApiForOpp, opp, "opportunityId", "projectId"];

    handleCheckboxSelection(...handleCheckboxArgs);
    if (mainKey !== "leadId") {
      handleCheckboxSelection(
        gridApiForEstimates,
        est,
        "estimationId",
        "projectId"
      );
    }
  });
};

export const generateToastText = (
  mainKey = "",
  opp = [],
  est = [],
  selectedRowsLength = 0
) => {
  let txt = "";

  if (mainKey === "accountId" && (opp?.length > 0 || est?.length > 0)) {
    if (opp?.length > 0) {
      txt += `${opp?.length} ${
        opp?.length > 1 ? "opportunities" : "opportunity"
      }`;
    }
    if (est?.length > 0) {
      txt += `${opp?.length > 0 ? " and " : ""}${est?.length} ${
        est?.length > 1 ? "estimations" : "estimation"
      }`;
    }
    txt += ` automatically checked for the ${
      selectedRowsLength === 1 ? "project" : "projects"
    } selected!`;
  } else if (est?.length > 0) {
    txt += `${opp?.length > 0 ? " and " : ""}${est?.length} ${
      est?.length > 1 ? "estimations" : "estimation"
    } automatically checked for the ${
      selectedRowsLength === 1 ? "opportunity" : "opportunities"
    } selected!`;
  }

  return txt;
};

export const handleCheckboxSelection = (
  api = {},
  rows = [],
  idKey = "",
  key = ""
) => {
  const getOnlyRowsToUpdate = api?.getRenderedNodes?.()?.filter?.((row) => {
    const rowId = row?.data?.[idKey];
    return rows?.some?.((el) => el?.[idKey] === rowId);
  });
  if (getOnlyRowsToUpdate?.length > 0) {
    getOnlyRowsToUpdate?.forEach?.((row) => {
      row?.setSelected?.(true);
    });
  } else {
    const rowsThatAreControled = api
      ?.getRenderedNodes()
      ?.filter((row) => !!row?.data?.[key]);
    rowsThatAreControled?.forEach?.((row) => {
      row?.setSelected?.(false);
    });
  }
};

export const swalToast = (props) => {
  const { title = "", icon = "", ...rest } = props || {};
  Swal.mixin({
    toast: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
    ...rest,
  }).fire({
    icon,
    title,
  });
};

export const disableOpportunity = (opp = {}) =>
  ["Awarded", "Awarded Change Order"]?.includes?.(opp?.opportunityStage);

export const arraysAreEqual = (arr1 = [], arr2 = []) => {
  if (arr1?.length !== arr2?.length) {
    return false;
  }
  arr1?.forEach?.((_, i) => {
    if (!objectsHaveSameKeys(arr1?.[i], arr2?.[i])) {
      return false;
    }
  });
  return true;
};

export const objectsHaveSameKeys = (obj1 = {}, obj2 = {}) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1?.length !== keys2?.length) {
    return false;
  }
  keys1?.forEach((el) => {
    if (keys1?.[el] !== keys2?.[el]) {
      return false;
    }
  });
  return true;
};

export const canFloorBeSelected = (
  availableFloors = [],
  selectedFloors = [],
  floor = 0
) => {
  const allFloors = availableFloors;
  let canBeSelected = false;

  if (selectedFloors?.length === 0) {
    canBeSelected = true;
  } else {
    canBeSelected = allFloors?.some((el) => el?.floor === floor);
  }
  return canBeSelected;
};

export const remainingPliProgress = (
  pli = {},
  d1 = undefined,
  d2 = undefined
) =>
  !d2
    ? pli?.[d1] - pli?.totalProgress
    : pli?.[d2] * pli?.[d1] - pli?.totalProgress;

export const calculateIsJumpCompleted = (floors = [], items = []) => {
  return (
    items
      ?.filter((el) => floors?.some((a) => a?.floor === el?.floor))
      ?.every(({ totalProgress = 0 }) => totalProgress === 100) || false
  );
};

export const serviceOptionsForJumps = (opt = {}, breakdown_dimension = "") => {
  opt.jumps = (opt?.jumps || [])?.map((s) => ({
    ...s,
    isJumpCompletedInThePreviousSchedule: calculateIsJumpCompleted(
      s?.floors,
      opt?.items
    ),
    progressByDay: (s?.progressByDay || [])?.map((p) => ({ day: "", ...p })),
  }));

  opt.items = (opt?.items || [])?.map((s) => ({
    ...s,
    breakdownValue: (s?.breakdownValue || [])?.map((breakdown) => ({
      ...breakdown,
      isBreakdownCompletedInThePreviousSchedule: calculateIsBreakdownCompleted(
        breakdown,
        breakdown_dimension
      ),
    })),
  }));

  return opt;
};

export const calculateIsBreakdownCompleted = (
  breakdown = {},
  breakdown_dimension = ""
) => {
  return (
    breakdown?.progressByDay?.reduce(
      (acc, curr) => acc + curr?.[breakdown_dimension],
      0
    ) === 100 || false
  );
};

export const calculateIsSubPliCompleted = (
  sub = {},
  d1 = undefined,
  d2 = undefined
) => {
  return (
    (!d2
      ? sub?.totalProgress === sub?.[d1]
      : sub?.totalProgress === sub?.[d1] * sub?.[d2]) || false
  );
};

export const transformOption = (
  opt = {},
  breakdown_dimension = "",
  d1 = undefined,
  d2 = undefined
) => {
  opt.breakdownValue = (opt?.breakdownValue || [])?.map((a) => ({
    ...a,
    isBreakdownCompletedInThePreviousSchedule: calculateIsBreakdownCompleted(
      a,
      breakdown_dimension
    ),
  }));
  opt.items = (opt?.items || [])?.map((s) => {
    s.breakdownValue = (s?.breakdownValue || [])?.map((a) => ({
      ...a,
      isBreakdownCompletedInThePreviousSchedule: calculateIsBreakdownCompleted(
        a,
        breakdown_dimension
      ),
    }));
    s.subPli = (s?.subPli || [])?.map((sub) => ({
      ...sub,
      isSubPliCompletedInThePreviousSchedule: calculateIsSubPliCompleted(
        sub,
        d1,
        d2
      ),
      prevDays: [...new Set([...(sub?.prevDays || []), ...(sub?.days || [])])],
    }));
    s.isPliCompletedInThePreviousSchedule = {
      completed: calculateIsSubPliCompleted(s, d1, d2),
      prevDays: [
        ...new Set([
          ...(s?.isPliCompletedInThePreviousSchedule?.prevDays || []),
          ...(s?.days || []),
        ]),
      ],
    };
    return s;
  });

  return opt;
};

// removes unnecessary keys for schedule from a service
export const cleanService = (service = {}) => {
  const propertiesToDelete = [
    "columnDefinitions",
    "additionalRentalTerms",
    "PLIAddonScheme",
    "alternateRent",
    "isTaxable",
    "borderColor",
    "priceScheme",
    "rent",
    "rentalPaymentTerms",
    "taxRate",
    "totalities",
    "excludes",
    "visible",
    "includes",
    "priceSheetDataSrc",
    "pricePerUnit",
    "documentationAddons",
    "invoices",
    "charges",
    "amounts",
  ];
  propertiesToDelete?.forEach((property) => delete service?.[property]);
};

export const initializeElevation = (
  serviceDefinitions,
  elevation,
  serviceId,
  breakdown_dimension
) => {
  // const breakdownsForService =
  //   breakdownsServiceId(
  //     serviceDefinitions || [],
  //     "breakdowns",
  //     serviceId,
  //     "inspections",
  //     "elevation"
  //   ) || [];
  elevation.breakdownValue = [];
  // breakdownsForService?.map((breakdown) => ({
  //   ...breakdown,
  //   days: [],
  //   progressByDay: [],
  //   totalProgress: 0,
  //   [breakdown_dimension]: 100,
  //   isApplied: true,
  // })) || [];

  const propertiesToDelete = [
    "desiredPrice",
    "planks",
    "taxAmount",
    "addonsTotal",
    "price",
    "taxRate",
    "pricing",
    "totalPrice",
    "stops_excluding",
    "stops_including",
  ];

  propertiesToDelete?.forEach((property) => delete elevation?.[property]);
};

export const processPli = (
  pli,
  hoist_dimension,
  JUMP_MAXIMAL_PROGRESS,
  service,
  serviceId
) => {
  if (checkIfServiceIsHoist(service)) {
    if (isNaN(pli?.[hoist_dimension])) {
      pli[hoist_dimension] = JUMP_MAXIMAL_PROGRESS;
    }
  }

  const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);

  let itemWithDimensions = { [d1]: pli?.[d1] };
  if (d2 !== undefined) {
    itemWithDimensions[d2] = pli?.[d2];
    itemWithDimensions["surface"] = parseInt(pli?.[d1]) * parseInt(pli?.[d2]);
  }

  if (checkIfServiceIsHoist(service)) {
    itemWithDimensions.floor = pli?.floor;
  }

  let pliToWork = {
    ...itemWithDimensions,
    id: pli?.id,
    breakdownValue: pli?.breakdownValue || [],
    originalPli: "originalPli" in pli ? pli?.originalPli : pli || {},
    subPli: [],
    days: [],
    progressByDay: [],
    usedScheduleDays: {},
    typeOfProgress: "",
    totalProgress: 0,
    note: "",
  };

  return pliToWork;
};

export const processEstimation = (
  estimationId = "",
  servicesToWork = {},
  serviceDefinitions = []
) => {
  const services = servicesToWork?.[estimationId] || [];
  services?.forEach?.((service) => {
    cleanService(service);
    const {
      serviceId = "",
      serviceOptions = [],
      serviceAddons = [],
    } = service || {};
    if (serviceOptions?.[0]?.length > 0) {
      serviceOptions?.[0]?.forEach?.((elevation) => {
        const { items = [] } = elevation || {};
        initializeElevation(
          serviceDefinitions,
          elevation,
          serviceId,
          breakdown_dimension
        );
        items?.forEach?.((pli) => {
          const pliToWork = processPli(
            pli,
            hoist_dimension,
            JUMP_MAXIMAL_PROGRESS,
            service,
            serviceId
          );
          pli = pliToWork;
        });
      });
    }

    if (serviceAddons?.length > 0) {
      serviceAddons?.forEach?.((addon) => {
        delete addon["totalPrice"];
        delete addon["createdBy"];
        delete addon["createdTime"];
        delete addon["includedTax"];
        delete addon["lastModified"];
        delete addon["charged"];
        delete addon["charges"];
        addon["progressByDay"] = [];
      });
    }
  });
};

export const mainProcessing = (
  estimationIdList = [],
  servicesToWork = {},
  serviceDefinitions = []
) => {
  estimationIdList?.forEach?.((estimationId) => {
    processEstimation(estimationId, servicesToWork, serviceDefinitions);
  });
};

export const getRemoveButton = (
  tooltipTitle = "",
  onClickAction = () => {},
  isDisabled = false
) => (
  <Tooltip title={tooltipTitle}>
    <div>
      <Button
        onClick={onClickAction}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
          width: "32px",
          height: "32px",
          background: "#FE4C4A 0% 0% no-repeat padding-box",
          borderRadius: "5px",
          opacity: 1,
        }}
        disabled={isDisabled}
      >
        X
      </Button>
    </div>
  </Tooltip>
);

export const calculateMissingDays = (scheduleDays = [], dayIndex = 0) => {
  if (scheduleDays?.length <= 1 || dayIndex === scheduleDays?.length - 1) {
    return [];
  }
  const startDate = scheduleDays?.[dayIndex]?.startDate;
  const nextStartDate = scheduleDays?.[dayIndex + 1]?.startDate;
  const emptyDaysArray = emptyDays(startDate, nextStartDate) || [];
  const missingDates = emptyDaysArray?.filter?.((el) => {
    const formattedDate = dayjs(el).startOf("day").format("MM/DD/YYYY");
    return !scheduleDays?.some(
      (sd) =>
        formattedDate ===
        dayjs(sd?.startDate).startOf("day").format("MM/DD/YYYY")
    );
  });

  return missingDates?.map?.((date) => ({
    text: `${formatFullDate(date)} ${formatShortTime(
      dayjs(date).set("hour", 7).set("minute", 0)
    )} to ${formatShortTime(dayjs(date).set("hour", 16).set("minute", 0))}`,
    suggestedStartDate: dayjs(date).set("hour", 7).set("minute", 0),
    suggestedEndDate: dayjs(date).set("hour", 16).set("minute", 0),
    weekend: !!isOffDay(date),
  }));
};

export const schedulingDaysOverview = (schedules = []) => {
  const overview = [];
  for (const schedule of schedules || []) {
    const days = availableDays(schedule?.scheduleDays || []);
    for (const day of days || []) {
      const startDate = dayjsNY(day?.startDate);
      overview.push({
        date: startDate.startOf("day").format("MM/DD/YYYY"),
        startDate: day?.startDate,
        startTime: startDate.format("h:mm a"),
        endTime: dayjsNY(day?.endDate).format("h:mm a"),
        crews: day?.crews || [],
        fleet: day?.fleet || [],
        scheduleAddress: schedule?.scheduleAddress,
        scheduleId: schedule?.scheduleId,
        scheduleName: schedule?.scheduleName,
        typeOfWork: schedule?.typeOfWork,
        pe: schedule?.projectManagers,
        toBeScheduled: schedule?.toBeScheduled || {},
        id: day?.id,
        day: day?.day,
        scopeOfWork:
          dayDetail(schedule?.toBeScheduled || {}, [day?.id])?.[
            day?.id
          ]?.flatMap?.(({ services = [] }) =>
            services?.map?.(({ serviceName = "" }) => serviceName)
          ) || [],
      });
    }
  }
  return overview;
};

export const availableDays = (days = []) => {
  let toReturn = [];
  toReturn = days?.filter(
    (day) =>
      day?.status !== "Postponed" &&
      day?.status !== "Skipped" &&
      day?.status !== "Canceled"
  );
  return toReturn?.sort(
    (a, b) => dayjs(b?.startDate).valueOf() - dayjs(a?.startDate).valueOf()
  );
};

export const availableEstimations = (estimations = {}) =>
  Object.fromEntries(
    Object.entries(estimations || {})?.filter(
      ([key, value]) => value?.length > 0
    )
  ) || {};

// //* gets called when saving a schedule,
// //if action is "nextSchedules" it will update all the next schedules that come after the one we are saving
// // if action is "prevSchedules" it will update all the previous schedules that are before the one we are saving
// //! multiple schedules saved at once
// export const updatePrevAndNextSchedules = (
//   schedulesToBeSaved = [],
//   schedule = {},
//   action = "",
//   rowData = {},
//   toBeScheduled = {}
// ) => {
//   let schedules =
//     schedulesToBeSaved?.filter((el) =>
//       el?.projectId === rowData?.projectId &&
//       el?.typeOfWork === rowData?.typeOfWork &&
//       el?.scheduleId !== rowData?.scheduleId &&
//       action === "prevSchedules"
//         ? dayjs(el?.createdAt).isBefore(dayjs(rowData?.createdAt))
//         : dayjs(el?.createdAt).isAfter(dayjs(rowData?.createdAt))
//     ) || [];
//   console.log("schedulesss", { schedules, rowData });

//   schedules =
//     schedules?.map((el) => ({
//       ...el,
//       toBeScheduled:
//         Object.entries(el?.toBeScheduled || {})?.reduce(
//           (prev, [key, value]) => {
//             const currentToBeScheduled = toBeScheduled?.[key];
//             prev[key] = value?.map((el, index) => {
//               const { d1, d2 } = get_ProgressDimensionsByServiceId(
//                 el?.serviceId
//               );
//               const currentService = currentToBeScheduled?.[index];
//               el.serviceOptions = [
//                 el?.serviceOptions?.[0]?.map((opt, optIndex) => {
//                   const currentElevation =
//                     currentService?.serviceOptions?.[0]?.[optIndex];
//                   if (checkIfServiceIsHoist(el)) {
//                     opt.jumps = opt?.jumps?.map((s, jumpIndex) => {
//                       const currentJump = currentElevation?.jumps?.[jumpIndex];
//                       if (
//                         compareJumps({
//                           curr: s,
//                           prev: currentJump,
//                         })
//                       ) {
//                         s = currentJump;
//                       }
//                       return s;
//                     });
//                   }
//                   opt.items = opt?.items?.map((s, pliIndex) => {
//                     const currentPli = currentElevation?.items?.[pliIndex];
//                     if (action === "prevSchedules") {
//                       if (
//                         comparePlis({
//                           curr: s,
//                           prev: currentPli,
//                         })
//                       ) {
//                         s.editedFrom = {
//                           scheduleId: schedule?.scheduleId || "",
//                           scheduleName: schedule?.scheduleName || "",
//                         };
//                         s.days = [];
//                         s.progressByDay = [];
//                         s.typeOfProgress = currentPli?.typeOfProgress || "";
//                         s.usedScheduleDays = currentPli?.usedScheduleDays || {};
//                       }
//                     } else {
//                       const isCurrentPliCompleted = !d2
//                         ? currentPli?.totalProgress === currentPli?.[d1]
//                         : currentPli?.totalProgress ===
//                           currentPli?.[d1] * currentPli?.[d2];
//                       if (
//                         comparePlis({
//                           curr: currentPli,
//                           prev: s,
//                         })
//                       ) {
//                         if (!!currentPli?.editedFrom) {
//                           s = s;
//                         } else {
//                           s = {
//                             ...currentPli,
//                             isPliCompletedInThePreviousSchedule: {
//                               completed: isCurrentPliCompleted,
//                               prevDays: [
//                                 ...new Set([
//                                   ...(currentPli
//                                     ?.isPliCompletedInThePreviousSchedule
//                                     ?.prevDays || []),
//                                   ...(currentPli?.days || []),
//                                 ]),
//                               ],
//                             },
//                             breakdownValue: currentPli?.breakdownValue?.map(
//                               (a) => {
//                                 a.isBreakdownCompletedInThePreviousSchedule =
//                                   a?.progressByDay?.reduce(
//                                     (acc, curr) =>
//                                       acc + curr?.[breakdown_dimension],
//                                     0
//                                   ) === 100 || false;
//                                 return a;
//                               }
//                             ),
//                             subPli: currentPli?.subPli?.map((sub) => {
//                               sub.isSubPliCompletedInThePreviousSchedule =
//                                 (d2 === undefined
//                                   ? sub?.totalProgress === sub?.[d1]
//                                   : sub?.totalProgress ===
//                                     sub?.[d1] * sub?.[d2]) || false;
//                               return sub;
//                             }),
//                           };
//                         }
//                       }
//                     }

//                     return s;
//                   });
//                   return opt;
//                 }),
//               ];
//               return el;
//             });
//             return prev;
//           },
//           {}
//         ) || {},
//     })) || [];

//   return schedules;
// };

export const groupedServices = (services = []) =>
  groupBy(
    services?.map((el) => ({
      ...el,
      quickbooksNumber:
        el?.quickbooksNumber || el?.estimaionNumber || el?.estimationId,
    })),
    "quickbooksNumber"
  );
// services?.reduce((prev, service, index) => {
//   const group = services?.filter(
//     (el) =>
//       !el?.isScope &&
//       typeof el !== "string" &&
//       (el?.quickbooksNumber === service?.quickbooksNumber ||
//         el?.estimationNumber === service?.estimationNumber ||
//         el?.estimationId === service?.estimationId)
//   );
//   return {
//     ...prev,
//     [service?.quickbooksNumber ||
//     service?.estimationNumber ||
//     service?.estimationId ||
//     "Not added!"]: group,
//   };
// }, {});

export const estimationServices = (estimation = {}, type = "") => {
  let toReturn = {};
  if (type === "multiple") {
    let structEst = structuredClone(estimation);
    Object.keys(structEst).forEach((key) => {
      if (Array.isArray(structEst[key] && structEst[key].length === 0)) {
        delete structEst[key];
      }
    });

    toReturn = Object.entries(structEst).reduce((prev, [key, value], index) => {
      let keyToFind =
        value?.[0]?.quickbooksNumber ||
        value?.[0]?.estimationNumber ||
        "Not added!";
      // if (prev[keyToFind]?.services?.length > 0) {
      //   keyToFind = `${keyToFind} - ${index + 1}`;
      // }
      prev[keyToFind] = {
        estimationId: key || "",
        isChangeOrder: value?.isChangeOrder || false,
        services: value
          ?.map((service) => {
            if (typeof service === "string") {
              return null;
            } else {
              return service?.label;
            }
          })
          ?.filter(Boolean),
      };
      return prev;
    }, {});
  } else {
    toReturn[
      estimation?.quickbooksNumber ||
        estimation?.estimationNumber ||
        "Not added!"
    ] = {
      estimationId: estimation?.estimationId || "",
      isChangeOrder: estimation?.isChangeOrder || false,
      services: estimation?.services
        ?.map((el) => {
          if (typeof el === "string" || el?.isScope) {
            return null;
          } else {
            return el?.label;
          }
        })
        ?.filter(Boolean),
    };
  }
  return toReturn;
};

export const compareObjects = (obj1 = {}, obj2 = {}) => {
  let areEqual = false;
  if (Object.keys(obj1)?.length === Object.keys(obj2)?.length) {
    areEqual = Object.keys(obj1)?.every((key) => {
      if (obj1?.[key] === obj2?.[key]) {
        return true;
      } else if (Array.isArray(obj1?.[key]) && Array.isArray(obj2?.[key])) {
        return compareArrays(obj1?.[key], obj2?.[key]);
      }
      return false;
    });
  }
  return areEqual;
};

export const allServicesUsedInEstimation = (projectServices = {}) =>
  Object.values(projectServices)
    ?.flat()
    ?.filter((service) => !service?.isScope)
    ?.map((service) => service?.label);

export const notificationForProgressChange = (
  userConfiguration = {},
  schedule = {},
  updatedToBeScheduled = {},
  authenticatedUser = {},
  serviceDefinitions = []
) => {
  const progressForEstimationChanged =
    compareEstimations(schedule, updatedToBeScheduled, serviceDefinitions) ||
    {};
  if (Object.keys(progressForEstimationChanged)?.length > 0) {
    Object.values(progressForEstimationChanged)
      ?.flat()
      ?.forEach((change) => {
        return sendNotificationsAndPostLogs(
          userConfiguration,
          change,
          authenticatedUser,
          schedule
        );
      });
  } else {
    API.post("editLogs", "/editLogs", {
      body: {
        recordId: schedule?.scheduleId,
        recordName: `${schedule?.scheduleName} - ${schedule?.typeOfWork}`,
        category: "Scheduling",
        actionType: "Edit",
        topic: schedule?.scheduleAddress,
        currentData: {},
        member: `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`,
        nameOfUser: `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`,
        cognitoUserId: userConfiguration?.cognitoUserId,
        previousData: {},
        updatedAt: dayjs().valueOf(),
        updatedKeys: [],
      },
    }).catch((err) => console.log("Error Creating Logs: ", err));
  }
};

const sendNotificationsAndPostLogs = (
  userConfiguration = {},
  change = {},
  authenticatedUser = {},
  schedule = {}
) => {
  broadcastNotification(
    "14",
    "onProgressScheduleChange",
    [
      {
        common: `${userConfiguration?.nameOfUser} has changed progress for ${
          schedule?.scheduleAddress
        } for ${change?.serviceName} from ${change?.progress?.prev}% to ${
          change?.progress?.now
        }% in estimation ${
          change?.quickbooksNumber || change?.estimationNumber || 1
        }!`,
      },
      {
        userName: userConfiguration?.nameOfUser,
        currentUser: authenticatedUser?.sub,
        cognitos: getCognitosForNotification(
          userConfiguration,
          schedule?.teamsConfiguration
        ),
      },
    ],
    schedule?.scheduleId
  );
  API.post("editLogs", "/editLogs", {
    body: {
      recordId: schedule?.scheduleId,
      recordName: `${schedule?.scheduleName} - ${schedule?.typeOfWork}`,
      category: "Scheduling",
      actionType: "progressChanged",
      topic: `${userConfiguration?.nameOfUser} has changed progress for ${schedule?.scheduleAddress} for ${change?.serviceName} from ${change?.progress?.prev}% to ${change?.progress?.now}% in estimation ${change?.estimationNumber}!`,
      currentData: {
        [`Estimation ${change?.estimationNumber || 1}`]: {
          [change?.serviceName]: { progress: change?.progress?.now },
        },
      },
      member: `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`,
      nameOfUser: `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`,
      previousData: {
        [`Estimation ${change?.estimationNumber || 1}`]: {
          [change?.serviceName]: { progress: change?.progress?.prev },
        },
      },
      cognitoUserId: authenticatedUser?.sub,
      updatedAt: dayjs().valueOf(),
      updatedKeys: ["toBeScheduled"],
    },
  }).catch((err) => console.log("Error Creating Logs: ", err));
};

export const compareEstimations = (
  schedule = {},
  updatedToBeScheduled = {}
) => {
  let prev = onlyEstWithScopeFalse(schedule?.toBeScheduled || {}),
    toReturn = {};
  Object.keys(prev)?.forEach((key) => {
    toReturn[key] = {
      prev: neededDataForService(prev?.[key]),
      now: neededDataForService(updatedToBeScheduled?.[key]),
    };
  });
  toReturn = Object.entries(toReturn)?.reduce((prev, [key, value]) => {
    const changes = value?.prev?.filter(
      (service, index) =>
        (value?.now?.[index]?.progress || 0) !== (service?.progress || 0)
    );
    if (changes?.length) {
      prev[key] = changes?.map((service) => ({
        ...service,
        scheduleId: schedule?.scheduleId,
        progress: {
          prev: service?.progress,
          now: value?.now?.find(
            (el) => el?.serviceName === service?.serviceName
          )?.progress,
        },
      }));
    } else {
      delete prev[key];
    }
    return prev;
  }, {});
  return toReturn;
};

export const neededDataForService = (services = []) =>
  services
    ?.filter((a) => !a?.isScope)
    ?.map((service) => ({
      serviceName: service?.label,
      serviceId: service?.serviceId,
      estimationNumber: service?.estimationNumber,
      progress: progressForService(service) || 0,
    }));

// is day able to be postponed
export const canDayBePostponed = (scheduleDay = {}, scheduleDays = []) => {
  const postponedDays = scheduleDays?.filter(
    (el) => el?.status === "Postponed"
  );
  return (
    postponedDays?.length > 0 &&
    dayjs(postponedDays?.[postponedDays?.length - 1]?.startDate).valueOf() >
      dayjs(scheduleDay?.startDate).valueOf()
  );
};

// // AUTOMATIC POSTPONE FROM THE DATEPICKER (SCHEDULING DAYS)
// export const automaticPostpone = (
//   days = [],
//   dayPostponedTo = {},
//   daysToPostpone = {}
// ) => {
//   let totalDays = [];
//   totalDays = days?.concat(dayPostponedTo);
//   const postponedDays = totalDays?.filter((el) => el?.status === "Postponed");
//   if (
//     postponedDays?.length > 0 &&
//     dayjs(postponedDays?.[postponedDays?.length - 1]?.startDate).valueOf() >
//       dayjs(daysToPostpone?.startDate).valueOf()
//   ) {
//     message.error(
//       `You can't postpone a day that is before the other postponed days!`
//     );
//     return;s
//   }
//   if (false) {
//     // if (!!hasSameId) {
//     //   //* If we have a service that uses a weekend day we show a warning
//     //   return message.error(
//     //     "You cannot skip weekend days that are already used in services!"
//     //   );
//     // }
//     // if (
//     //   dayjs(mainPostponedDay?.startDate)
//     //     .tz("America/New_York")
//     //     .format("MM/DD/YYYY") ===
//     //   dayjs(daysToPostpone?.startDate)
//     //     .tz("America/New_York")
//     //     .format("MM/DD/YYYY")
//     // ) {
//     //   return swal("You cannot postpone a day in the same day as it is!", {
//     //     icon: "error",
//     //     timer: 2500,
//     //     buttons: false,
//     //   });
//   } else {
//     const oldScheduleDays = _.cloneDeep(days);
//     const startDatesTemp = {};
//     const endDatesTemp = {};
//     for (const day of totalDays) {
//       if (day?.day >= daysToPostpone?.day && day?.status !== "Postponed") {
//         startDatesTemp[day?.id] = dayjs(
//           oldScheduleDays?.find((el) => el?.day === day?.day)?.startDate
//         )
//           .tz("America/New_York")
//           .clone();
//         endDatesTemp[day?.id] = dayjs(
//           oldScheduleDays?.find((el) => el?.day === day?.day)?.endDate
//         )
//           .tz("America/New_York")
//           .clone();
//       }
//     }
//     const newDates = Object.fromEntries(
//       addBusinessDaysArray(
//         Object.entries(startDatesTemp),
//         0,
//         dayjs(
//           totalDays?.find(
//             (t) =>
//               t?.day === daysToPostpone?.day &&
//               t?.status !== DayStatus?.Postponed
//           )?.startDate
//         )
//           .clone()
//           .tz("America/New_York")
//       )
//     );
//     totalDays?.forEach((day, i) => {
//       if (!!newDates[day?.id]) {
//         const diff = _.round(
//           dayjs(newDates[day?.id])
//             .tz("America/New_York")
//             .diff(startDatesTemp[day?.id], "days", true)
//         );
//         day.startDate = dayjs(startDatesTemp[day?.id])
//           .tz("America/New_York")
//           .add(diff, "days")
//           .toDate();
//         day.endDate = dayjs(endDatesTemp[day?.id])
//           .tz("America/New_York")
//           .add(diff, "days")
//           .toDate();
//       }
//     });

//     return totalDays;
//   }
// };
// // };

export const breakdownDays = (breakdowns = []) =>
  breakdowns?.flatMap?.(({ days = [] }) => days) || [];

export const unablePli = (pli = {}) =>
  prevPliCompleted(pli) || pliEditedFromNextSchedules(pli);

export const countDate = (lastDay) => {
  let count = 1;
  if (dayjs(lastDay)?.day() === 5) {
    count = 3;
  } else {
    count = count;
  }
  return count;
};

export const checkIfServiceIsOtherScope = (service = {}) => service?.isScope;

export const getDisabledRows = (gridApiData = []) =>
  gridApiData
    ?.filter((row) => !row?.selectable)
    ?.flatMap(({ data = {} }) => data);

export const firstAndLastDateOfAnElevation = (elevation = {}) => {
  let toReturn = {};
  if (!!elevation && !!elevation?.items) {
    const plis = elevation?.items || [];
    const pliDays = plis
      ?.flatMap((a) => Object.values(a?.usedScheduleDays || {})?.flat())
      ?.sort((a, b) => dayjs(a?.startDate) - dayjs(b?.startDate));
    toReturn = {
      firstDate: pliDays?.[0]?.startDate,
      lastDate: pliDays?.[pliDays?.length - 1]?.startDate,
    };
  }
  return toReturn;
};

export const autoCompletePlis = (
  daysToPlis = [],
  pli = {},
  d1 = undefined,
  d2 = undefined
) => {
  let progressByDay = [];
  progressDaysScheduling(pli?.progressByDay, daysToPlis)?.forEach((day) => {
    if (!d2) {
      progressByDay.push({
        day: day?.id,
        [d1]: pli[d1],
      });
    } else {
      progressByDay.push({
        day: day?.id,
        [d1]: pli[d1],
        [d2]: pli[d2],
      });
    }
  });
  return splitProgressByDay(pli, progressByDay, d1, d2);
};

export const daysInBreakdownsOrSubPliShortcutLogic = (
  pli = {},
  breakdownsOrSubPlis = [],
  daysToPlis = [],
  action = "",
  type = "",
  d1 = undefined,
  d2 = undefined,
  serviceId = "",
  autoFunction = false
) => {
  const processBreakdownOrSubPli = (breakdownOrSubPli) => {
    if (
      prevBreakdownCompleted(breakdownOrSubPli) ||
      !breakdownOrSubPli?.isApplied
    ) {
      return breakdownOrSubPli;
    }
    const updatedDays = preventDuplicateStrings([
      ...(breakdownOrSubPli?.days || []),
      ...(daysToPlis?.map((el) => el?.id) || []),
    ]);
    const updatedProgressByDay = progressByDayForBreakdownsOrSubPli(
      pli,
      action,
      breakdownOrSubPli?.progressByDay,
      daysToPlis,
      type,
      d1,
      d2,
      autoFunction
    );
    if (action === "add") {
      breakdownOrSubPli.days = updatedDays;
      breakdownOrSubPli.progressByDay = updatedProgressByDay;
    } else {
      breakdownOrSubPli.days = breakdownOrSubPli?.days?.filter(
        (el) => !daysToPlis?.some((day) => el === day?.id)
      );
      breakdownOrSubPli.progressByDay = updatedProgressByDay;
      breakdownOrSubPli.totalProgress =
        type === "subPli"
          ? totalProgressPli(breakdownOrSubPli, serviceId)
          : breakdownOrSubPli?.totalProgress;
    }
    return breakdownOrSubPli;
  };
  return breakdownsOrSubPlis?.map?.(processBreakdownOrSubPli);
};

export const daysInElevationShortcutLogic = (
  elevation = {},
  daysToPlis = [],
  action = ""
) => {
  const elevationDays = elevation?.items?.flatMap(({ days = [] }) => days);
  let daysToReturn = [];
  if (action === "add") {
    daysToReturn = [
      ...(elevationDays || []),
      ...(daysToPlis?.map((el) => el?.id) || []),
    ];
  } else {
    daysToReturn = elevationDays?.filter(
      (el) => !daysToPlis?.some((day) => el === day?.id)
    );
  }
  return preventDuplicateStrings(daysToReturn);
};

export const daysInPliShortcutLogic = (
  pli = {},
  daysToPlis = [],
  action = ""
) => {
  let daysToReturn = [];
  if (action === "add") {
    daysToReturn = [
      ...(pli?.days || []),
      ...(daysToPlis?.map?.((el) => el?.id) || []),
    ];
  } else {
    daysToReturn = pli?.days?.filter(
      (el) => !daysToPlis?.some((day) => el === day?.id)
    );
  }
  return preventDuplicateStrings(daysToReturn);
};

export const splitProgressByDay = (
  pli = {},
  progressByDay = [],
  d1 = undefined,
  d2 = undefined
) => {
  let toReturn = [];
  let progressForD1 = pli?.[d1] - sumOfDimension(pli?.progressByDay, d1),
    progressForD2 = pli?.[d2] - sumOfDimension(pli?.progressByDay, d2);

  if (!d2) {
    let numbers = shareRates(progressByDay, progressForD1);
    toReturn = progressByDay?.map((el, i) => ({
      day: el?.day,
      [d1]: Math.round(numbers?.[i]),
    }));
  } else {
    let numbersForD1 = shareRates(progressByDay, progressForD1);
    let numbersForD2 = shareRates(progressByDay, progressForD2);
    toReturn = progressByDay?.map((el, i) => ({
      day: el?.day,
      [d1]: Math.round(numbersForD1[i]),
      [d2]: Math.round(numbersForD2[i]),
    }));
  }
  return [...(pli?.progressByDay || []), ...toReturn];
};

export const sumOfDimension = (progressByDay = [], dimension = "") =>
  progressByDay?.reduce((prev, arr) => prev + arr?.[dimension], 0);

export const sumOfEachDayForTwoDimensionPlis = (
  d1 = undefined,
  d2 = undefined,
  count = 0,
  lengthOfArray = 0
) => {
  const createArray = Array.from({
    length: lengthOfArray,
  });
  let sum = 0;
  let toReturn = createArray?.map((el, i) => {
    if (i === createArray?.length - 1) {
      return count - sum;
    } else {
      let temp = forceToNumber((count / lengthOfArray).toFixed(2));
      sum += temp;
      return temp;
    }
  });
  return toReturn;
};

export const compareArrays = (arr1 = [], arr2 = []) => {
  if (arr1?.length !== arr2?.length) return false;
  return arr1?.every?.((item, index) => item?.includes(arr2?.[index]));
};

// elevation days
export const getElevationDays = (elevation = {}) =>
  elevation?.items?.flatMap(({ days = [] }) => days);

export const progressByDayForBreakdownsOrSubPli = (
  pli = {},
  action = "",
  progressByDay = [],
  daysToPlis = [],
  type = "",
  d1 = undefined,
  d2 = undefined,
  autoFunction = false
) => {
  let toReturn = [];
  if (action === "add") {
    if (type === "breakdown") {
      toReturn = [
        ...(progressByDay || []),
        ...progressDaysScheduling(progressByDay, daysToPlis)?.map(
          (el, index) => ({
            day: el?.id,
            [breakdown_dimension]: !!autoFunction
              ? shareRates(
                  progressDaysScheduling(progressByDay, daysToPlis),
                  100 -
                    progressByDay?.reduce(
                      (prev, arr) => prev + arr?.[breakdown_dimension],
                      0
                    )
                )?.[index]
              : 0,
          })
        ),
      ];
    } else {
      let progressByDaySubPlis = get_new_progressByDay(
        pli?.days,
        progressByDay,
        {
          d1,
          d2,
        }
      );
      toReturn = !autoFunction
        ? progressByDaySubPlis
        : pli?.subPli?.forEach((subPli, index) => {
            progressByDaySubPlis = progressByDaySubPlis?.map((day, index) => {
              if (!d2) {
                let numbers = shareRates(progressByDaySubPlis, subPli[d1]);
                return {
                  ...day,
                  [d1]: numbers[index],
                };
              } else {
                let numbersForD1 = shareRates(progressByDaySubPlis, subPli[d1]);
                let numbersForD2 = shareRates(progressByDaySubPlis, subPli[d2]);
                return {
                  ...day,
                  [d1]: numbersForD1[index],
                  [d2]: numbersForD2[index],
                };
              }
            });
          });
      toReturn = progressByDaySubPlis;
    }
  } else {
    toReturn = progressByDay?.filter(
      (el) => !daysToPlis?.some((day) => el?.day === day?.id)
    );
  }
  return toReturn;
};

export const progressDaysScheduling = (
  progressByDay = [],
  scheduleDays = []
) => {
  return scheduleDays?.filter(
    (el) => !progressByDay?.some((day) => el?.id === day?.day)
  );
};

//
// export const updateAllSchedulesFromProjects = (
//   schedules = [],
//   serviceDefinitions = []
// ) => {
//   // const prjs = projects?.filter((a) =>
//   //   schedules?.some((b) => b?.projectId === a?.projectId)
//   // );
//   schedules?.forEach((schedule) => {
//     // const project = prjs?.find((a) => a?.projectId === schedule?.projectId);
//     // if (project) {
//     return API.put("scheduling", `/scheduling/${schedule?.scheduleId}`, {
//       body: {
//         toBeScheduled: Object.entries(schedule?.toBeScheduled || {})?.reduce(
//           (prev, [key, value]) => {
//             // const currentEstimation = project?.services?.[key];
//             prev[key] = value?.map((service) => {
//               // const currentService = currentEstimation?.find(
//               //   (el) => el?.serviceId === service?.serviceId
//               // );
//               service.serviceOptions = [
//                 service?.serviceOptions?.[0]?.map((elevation) => {
//                   // const currentElevation =
//                   //   currentService?.serviceOptions?.[0]?.find(
//                   //     (el) => el?.elevationId === elevation?.elevationId
//                   //   );
//                   // elevation.items = elevation?.items?.map((pli) => {
//                   //   // const currentPli = currentElevation?.items?.find(
//                   //   //   (el) => el?.id === pli?.id
//                   //   // );
//                   //   pli.originalPli = currentPli;
//                   //   return pli;
//                   // });
//                   elevation.breakdownValue = breakdownsServiceId(
//                     serviceDefinitions,
//                     "breakdowns",
//                     service?.serviceId,
//                     "inspections",
//                     "elevation"
//                   )?.map((breakdowns) => ({
//                     ...breakdowns,
//                     days: [],
//                     progressByDay: [],
//                     totalProgress: 0,
//                     [breakdown_dimension]: 100,
//                     isApplied: true,
//                   }));
//                   return elevation;
//                 }),
//               ];
//               return service;
//             });
//             return prev;
//           },
//           {}
//         ),
//       },
//     })
//       .then((res) => {
//         message.success("Schedules updated successfully!");
//       })
//       .catch((err) => {
//         message.error("Something went wrong!");
//         console.log(err);
//       });
//   });
// };

// set hours for today date coming from scheduleDay (SCHEDULING)
export const setDateTime = (time) =>
  parseInTz()
    .set("hour", parseInTz(time)?.startOf("hour")?.hour())
    .set("minute", parseInTz(time)?.startOf("minute")?.minute())
    .set("second", 0);

// shows all address that have crew conflicts (Same crews in a same day in diffrent addresses)
export const addressConflicts = (schedules = []) => {
  let toReturn = {};
  schedules?.forEach((schedule) => {
    schedule?.scheduleDays
      ?.filter(
        (scheduleDay) =>
          scheduleDay?.status !== "Canceled" &&
          scheduleDay?.status !== "Postponed" &&
          scheduleDay?.status !== "Skipped"
      )
      ?.forEach((day) => {
        day?.crews?.forEach((crew) => {
          if (toReturn[crew?.crewName]) {
            toReturn[crew?.crewName].push({
              scheduleName: schedule?.scheduleName,
              scheduleAddress: schedule?.scheduleAddress,
              scheduleId: schedule?.scheduleId,
              typeOfWork: schedule?.typeOfWork,
              crewPosition: crew?.crewPosition,
              date: dayjsNY(day?.startDate).startOf("day").format("MM/DD/YYYY"),
              startTime: crew?.startTime,
              endTime: crew?.endTime,
            });
          } else {
            toReturn[crew?.crewName] = [
              {
                scheduleName: schedule?.scheduleName,
                scheduleAddress: schedule?.scheduleAddress,
                scheduleId: schedule?.scheduleId,
                typeOfWork: schedule?.typeOfWork,
                crewPosition: crew?.crewPosition,
                date: dayjsNY(day?.startDate)
                  .startOf("day")
                  .format("MM/DD/YYYY"),
                startTime: crew?.startTime,
                endTime: crew?.endTime,
              },
            ];
          }
        });
      });
  });
  toReturn = Object.entries(toReturn)?.reduce?.((prev, [key, value]) => {
    if (value?.length > 1) {
      prev[key] = preventDuplicate(
        value?.filter?.((el) =>
          value?.some?.(
            (a) =>
              a?.date === el?.date &&
              a?.scheduleId !== el?.scheduleId &&
              a?.scheduleAddress !== el?.scheduleAddress &&
              (dayjs(a?.startTime).isBetween(el?.startTime, el?.endTime) ||
                dayjs(a?.endTime).isBetween(el?.startTime, el?.endTime) ||
                dayjs(el?.startTime).isBetween(a?.startTime, a?.endTime) ||
                dayjs(el?.endTime).isBetween(a?.startTime, a?.endTime))
          )
        ),
        "scheduleId"
      );
    } else {
      delete prev[key];
    }
    return prev;
  }, {});

  return toReturn;
};

export const repeatedElementSum = (array = [], key = "") => {
  let toReturn = {};
  array?.forEach((element = {}) => {
    if (Array.isArray(element[key])) {
      element[key]?.forEach?.((el) => {
        if (toReturn[el]) {
          toReturn[el]++;
        } else {
          toReturn[el] = 1;
        }
      });
    } else if (toReturn[element[key]]) {
      toReturn[element[key]]++;
    } else if (!element[key]) {
      delete toReturn[element[key]];
    } else {
      toReturn[element[key]] = 1;
    }
  });
  return toReturn;
};

// not allowing same crew in a same day in different addresses
// used when postponing
export const checkIfCrewIsAlreadyScheduled = (
  schedules = [],
  postponedDay = {},
  newDay = {}
) => {
  const schedulesWithNewDay = schedules?.filter?.((el) =>
    el?.scheduleDays?.some?.(
      (a) => dayjsNY(a?.startDate).isSame(newDay?.startDate),
      "day"
    )
  );
  const sameCrews = (schedulesWithNewDay || [])?.reduce?.((prev, arr) => {
    const findDay = (arr?.scheduleDays || [])?.find?.((el) =>
      dayjsNY(el?.startDate).isSame(newDay?.startDate, "day")
    );
    if (
      findDay?.crews?.some((a) =>
        postponedDay?.crews?.some?.(
          (b) =>
            b?.crewId === a?.crewId &&
            (dayjs(b?.startTime).isBetween(a?.startTime, a?.endTime) ||
              dayjs(b?.endTime).isBetween(a?.startTime, a?.endTime) ||
              dayjs(a?.startTime).isBetween(b?.startTime, b?.endTime) ||
              dayjs(a?.endTime).isBetween(b?.startTime, b?.endTime))
        )
      )
    ) {
      prev[`${arr?.scheduleName} - ${arr?.scheduleAddress}`] = {
        scheduleId: arr?.scheduleId,
        crews: findDay?.crews,
      };
    }
    return prev;
  }, {});
  return sameCrews || {};
};

function shiftDate(date, days) {
  const shiftedDate = dayjs(date).add(days, "days");
  return shiftedDate;
}
export function fixPostponedDates(dates = []) {
  const fixedDates = [...dates];
  for (let i = 0; i < fixedDates?.length; i++) {
    if (fixedDates[i].status === "Postponed") {
      const postponedDate = dayjs(fixedDates[i].startDate);
      let daysToShift = 0;
      for (let j = i + 1; j < fixedDates?.length; j++) {
        const currentDate = dayjs(fixedDates[j].startDate);
        if (
          currentDate > postponedDate &&
          fixedDates[j].status !== "Postponed"
        ) {
          daysToShift++;

          const shiftedDate = shiftDate(currentDate, daysToShift);
          fixedDates[j].startDate = dayjs(shiftedDate)
            .set("hour", 7)
            .toISOString();
          fixedDates[j].endDate = dayjs(shiftedDate)
            .set("hour", 16)
            .toISOString();
        }
      }
    }
  }
  return fixedDates;
}

export const shareRates = (arr = [], nr = 0) => {
  const randomNumbers = Array.from(
    { length: arr?.length },
    () => nr / arr?.length
  );
  let tempCalc = nr;
  return randomNumbers?.map((el, i) => {
    if (i === randomNumbers?.length - 1) {
      return tempCalc;
    } else {
      let temp = forceToNumber(el.toFixed(2));
      tempCalc -= temp;
      return temp;
    }
  });
};

export const allDaysInElevation = (elevation = {}) => {
  const { items = [] } = elevation;
  const allDays = items?.flatMap(({ days = [] }) => days) || [];
  return [...new Set([...allDays])];
};

export const upperCaseFirstLetter = (string = "") =>
  string?.charAt(0)?.toUpperCase() + string?.slice(1);

// information about each day in the schedule (USAGE IN SERVICE)
export const dayDetail = (
  toBeScheduled = {},
  daysIds = [],
  thisForeman = false
) => {
  const result = {};
  daysIds?.forEach?.((dayId) => {
    result[dayId] = Object.entries(toBeScheduled || {})
      ?.map?.(([key, value], index) => {
        const services = value
          ?.map?.((el) => {
            let service = el;
            let onlyForemanService = false;
            if (!!thisForeman) {
              onlyForemanService = {
                ...service,
                serviceOptions: [
                  service?.serviceOptions?.[0]?.map((elevation) => {
                    return {
                      ...elevation,
                      items: elevation?.items?.filter((pli) =>
                        pli?.days?.includes(dayId)
                      ),
                      // ?.map((pli) => {
                      //   const { typeOfProgress = "" } = pli;
                      //   if (
                      //     typeOfProgress === "dimensions" ||
                      //     pli?.subPli?.length > 0
                      //   ) {
                      //     return {
                      //       ...pli,
                      //       progressByDay: pli?.progressByDay?.filter(
                      //         (el) => el?.day === dayId
                      //       ),
                      //     };
                      //   } else if (typeOfProgress === "breakdown") {
                      //     return {
                      //       ...pli,
                      //       breakdownValue: pli?.breakdownValue?.map(
                      //         (breakdown) => {
                      //           return {
                      //             ...breakdown,
                      //             progressByDay:
                      //               breakdown?.progressByDay?.filter(
                      //                 (el) => el?.day === dayId
                      //               ),
                      //           };
                      //         }
                      //       ),
                      //     };
                      //   } else {
                      //     return pli;
                      //   }
                      // }),
                    };
                  }),
                ],
              };
            }
            const serviceOptions = service?.serviceOptions?.[0]
              ?.map?.((q) => {
                let onlyForemanEl = false;
                if (!!thisForeman) {
                  onlyForemanEl = {
                    ...q,
                    items: q?.items?.filter((d) => d?.days?.includes(dayId)),
                  };
                }
                const items = q?.items?.filter?.((d) =>
                  d?.days?.includes(dayId)
                );
                if (items && items?.length > 0) {
                  return {
                    elevationLabel: q?.elevationLabel + " " + q?.elevationId,
                    jumps: q?.jumps,
                    elevationProgress: ProgressStatistics.elevationTotal2(
                      thisForeman ? onlyForemanEl : q,
                      service?.serviceId
                    )?.progressPercentage,
                    items,
                    elevationId: q?.elevationId,
                  };
                }
                return null;
              })
              ?.filter(Boolean);
            let serviceAddon = service?.serviceAddons || [];
            if (serviceAddon?.length > 0) {
              serviceAddon = serviceAddon?.filter((el) =>
                el?.progressByDay?.some((e) => e?.id === dayId)
              );
            }
            if (
              (serviceOptions && serviceOptions?.length > 0) ||
              serviceAddon?.length > 0
            ) {
              return {
                serviceProgress: progressForService(
                  thisForeman ? onlyForemanService : service
                ),
                serviceName: service?.label,
                serviceId: service?.serviceId,
                isHoist: checkIfServiceIsHoist(service),
                serviceOptions,
                serviceAddon,
                options: service?.serviceOptions,
                serviceToUpdate: service,
                estimationNumber:
                  service?.quickbooksNumber || service?.estimationNumber || "",
              };
            }

            return null;
          })
          ?.filter(Boolean);
        if (services && services?.length > 0) {
          return {
            estimationId: key,
            estimationProgress:
              ProgressStatistics.estimationTotal(value)?.progressPercentage ||
              0,
            estimationNumber:
              value?.[0]?.quickbooksNumber ||
              value?.[0]?.estimationNumber ||
              index + 1,
            services,
          };
        }
        return null;
      })
      ?.filter(Boolean);
  });

  return result;
};

export const isHoliday = (holidays, day) =>
  holidays?.some(
    (date) =>
      dayjsNY(date?.date * 1000)
        .startOf("day")
        .format("MM/DD/YYYY") ===
      dayjsNY(day?.startDate).startOf("day").format("MM/DD/YYYY")
  );

export const progressForSpecificDay = (pli, day) => {
  const typeOfProgress = pli?.typeOfProgress || "";
  let progress = 0;
  if (!typeOfProgress) {
    progress = 0;
  } else if (typeOfProgress === "dimensions") {
    const findDay = pli?.progressByDay?.find((el) => el?.day === day)?.progress;
    progress = findDay;
  } else if (typeOfProgress === "breakdown") {
    const findDay = pli?.breakdownValue
      ?.flatMap(({ progressByDay = [] }) => progressByDay)
      ?.find((el) => el?.day === day)?.[breakdown_dimension];
    progress = findDay;
  } else if (typeOfProgress === "subPli") {
    const findDay = pli?.subPli
      ?.find((el) => el?.progressByDay?.some((e) => e?.day === day))
      ?.progressByDay?.find((el) => el?.day === day)?.progress;
    progress = findDay;
  }
  return progress;
};

export const daysScheduledInServices = (
  pli = {},
  scheduleDays = [],
  scheduleName = "",
  description = ""
) => {
  const tempUsedScheduleDays = pli?.usedScheduleDays || {};
  Object.keys(tempUsedScheduleDays)?.forEach?.((key) => {
    if (![...new Set(pli?.days)]?.includes(key)) {
      delete tempUsedScheduleDays[key];
    }
  });
  const result = [...new Set(pli?.days)]?.reduce?.(
    (prev, arr) => {
      if (!!prev[arr] && arr) return prev;
      if (!!prev[arr]) return prev;
      const startDate = dayjs.tz(
        scheduleDays?.find?.((d) => d?.id === arr)?.startDate
      );
      const endDate = dayjs.tz(
        scheduleDays?.find?.((d) => d?.id === arr)?.endDate
      );
      return {
        ...prev,
        [arr]: {
          startDate,
          endDate,
          reason: "",
          hours:
            dayjs(endDate).diff(dayjs(startDate), "hours") >= 9
              ? dayjs(endDate).diff(dayjs(startDate), "hours") - 1
              : dayjs(endDate).diff(dayjs(startDate), "hours"),
          scheduleName,
          description,
        },
      };
    },
    { ...tempUsedScheduleDays }
  );
  return result;
};

export const progressGiven = (toBeScheduled = {}) => {
  let result = {};
  Object.entries(toBeScheduled || {})?.map(([key, value]) => {
    result[key] = value?.map((el) => ({
      [el?.serviceId]: el?.serviceOptions?.[0]?.map((q, index) => ({
        [q?.elevationId]: q?.items?.filter((d) => d?.totalProgress > 0),
      })),
    }));
  });
  return result || {};
};

export const progressForEstimation = (estimation = []) =>
  ProgressStatistics.estimationTotal(estimation).progressPercentage || 0;

export const isServiceCompleted = (service = {}) =>
  progressForService(service) >= 100;

export const progressForService = (service = {}) =>
  ProgressStatistics.serviceTotal(service)?.progressPercentage || 0;

export const remainingProgressPercForService = (service = {}) =>
  ProgressStatistics?.serviceTotal(service)?.remainingPercentage || 0;

export const compareSchedules = (
  currentSchedule = {},
  previousSchedule = {},
  elevationIndex = "",
  pliIndex = ""
) => {
  let result = {};
  Object.entries(currentSchedule)?.map?.(([key, value]) => {
    let currentServices = value;
    let prevServices = previousSchedule?.[key] || [];
    currentServices?.forEach?.((el, i) => {
      let prevService = prevServices?.[i];
      result[el?.serviceId] = {
        curr: el?.serviceOptions?.[0]?.[elevationIndex]?.items?.[pliIndex],
        prev: prevService?.serviceOptions?.[0]?.[elevationIndex]?.items?.[
          pliIndex
        ],
      };
    });
  });
  return result;
};

//progress for a jump (HOIST)
export const progressForJump = (jump = {}) => {
  return jump?.progressByDay
    ?.flatMap(({ progress = [] }) => progress)
    ?.reduce((prev, { progress = 0 }) => prev + progress, 0);
};

export const comparePlis = (plis = {}) => {
  const { curr = {}, prev = {} } = plis;
  const isDifferent =
    prev?.totalProgress !== curr?.totalProgress &&
    typeof prev === "object" &&
    Object.keys(prev)?.length > 0;
  return isDifferent;
};

export const pliDidNotChange = (plis = []) => {
  const { curr = {}, prev = {} } = plis || {};

  return curr?.totalProgress === prev?.totalProgress;
};

export const compareJumps = (jumps = {}) => {
  const { curr = {}, prev = {} } = jumps;
  return (
    curr?.name === prev?.name && progressForJump(curr) !== progressForJump(prev)
  );
};

export const addonProgress = (addon) => {
  let progress =
    addon?.progressByDay?.length > 0
      ? Number(
          addon?.progressByDay
            ?.flatMap((prg) => prg?.progress)
            ?.reduce((prev, curr) => prev + curr, 0) /
            addon?.progressByDay?.length
        )
      : 0;
  return isNaN(progress) ? 0 : progress;
};

export const compareAddons = (addons = {}) => {
  const { curr = {}, prev = {} } = addons;
  return (
    addonProgress(curr) !== addonProgress(prev) && addonProgress(prev) === 0
  );
};

export const addonDidNotChange = (addons = {}) => {
  const { curr = {}, prev = {} } = addons;
  return addonProgress(curr) === addonProgress(prev);
};

export const jumpDidNotChange = (jumps = {}) => {
  const { curr = {}, prev = {} } = jumps || {};
  return (
    curr?.name === prev?.name && progressForJump(curr) === progressForJump(prev)
  );
};

export const newServicesAdded = (currentServices = {}, newServices = {}) => {
  if (Object.keys(currentServices)?.length === 0) return {};
  let newServicesIds = {};
  Object.entries(newServices)?.map?.(([key, value]) => {
    newServicesIds[key] = value
      ?.filter?.(
        (el) =>
          !currentServices?.[key]?.find(
            (e) => e?.serviceId?.toString() === el?.serviceId?.toString()
          )
      )
      ?.map((el) => el?.serviceId?.toString());
  });

  return newServicesIds;
};

export const newEstimationsAdded = (
  currentEst = {},
  newEst = {},
  estId = ""
) => {
  if (Object.keys(currentEst)?.length === 0) return false;
  let newEstimations = [];
  let curr = Object.keys(currentEst) || [];
  let newE = Object.keys(newEst) || [];
  newE?.forEach((el) => {
    if (!curr?.includes(el)) {
      newEstimations?.push(el);
    }
  });
  return newEstimations?.includes(estId) || false;
};

//! Adds new estimations, services, elevations added from the project to the schedule
export const newChangesFromEstimationForSchedule = (
  scheduleEstimations = {},
  projectEstimations = {}
) => {
  const dataToSchedule = {};
  for (const [key, value] of Object.entries(projectEstimations || {})) {
    let existingServices = scheduleEstimations?.[key] || [];
    const newServicesAdded = value?.filter?.((el) => {
      const findService = existingServices?.find?.(
        (e) => e?.serviceId?.toString() === el?.serviceId?.toString()
      );

      if (
        !findService?.serviceOptions?.[0]?.length ||
        el?.serviceId?.toString() !== findService?.serviceId?.toString()
      ) {
        existingServices = existingServices?.filter(
          (e) => e?.serviceId?.toString() !== el?.serviceId?.toString()
        );
        return true;
      }
      if (
        findService?.serviceOptions?.[0]?.length !==
        el?.serviceOptions?.[0]?.length
      ) {
        const newElevationsAdded = el?.serviceOptions?.[0]?.filter?.(
          (e) =>
            !findService?.serviceOptions?.[0]?.find?.(
              (el) => el?.elevationId === e?.elevationId
            )
        );
        if (newElevationsAdded?.length > 0) {
          existingServices = existingServices?.map?.((el) => {
            if (
              el?.serviceId?.toString() === findService?.serviceId?.toString()
            ) {
              return {
                ...el,
                serviceOptions: [
                  [...el?.serviceOptions?.[0], ...(newElevationsAdded || [])],
                ],
              };
            }
            return el;
          });
        }
      }
    });

    dataToSchedule[key] = [
      ...(existingServices || []),
      ...scheduleStructureForNewDataAdded(newServicesAdded),
    ];
  }
  return Object.fromEntries(
    Object.entries(dataToSchedule)?.filter(([key, value]) => value?.length > 0)
  );
};

export const scheduleStructureForNewDataAdded = (newData = []) => {
  const updatedData = newData?.map?.((service) => {
    const { serviceId } = service || {};
    for (const elevation of service?.serviceOptions?.[0] || []) {
      for (const pli of elevation?.items || []) {
        if (checkIfServiceIsHoist(service)) {
          if (isNaN(pli[hoist_dimension])) {
            pli[hoist_dimension] = JUMP_MAXIMAL_PROGRESS;
          }
        }
        const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);
        const itemWithDimensions = { [d1]: pli?.[d1] || 0 };
        if (d2 !== undefined) {
          itemWithDimensions[d2] = pli?.[d2];
          itemWithDimensions["surface"] =
            parseInt(pli?.[d1]) * parseInt(pli?.[d2]);
        }
        if (checkIfServiceIsHoist(service)) {
          itemWithDimensions.floor = pli?.floor;
        }
        const pliToWork = {
          ...itemWithDimensions,
          id: pli?.id,
          breakdownValue: [],
          originalPli: pli?.originalPli || pli,
          subPli: pli?.subPli || [],
          days: pli?.days || [],
          totalProgress: pli?.totalProgress || 0,
          progressByDay: pli?.progressByDay || [],
          usedScheduleDays: pli?.usedScheduleDays || {},
          typeOfProgress: pli?.typeOfProgress || "",
          note: "",
        };
        elevation.items[elevation?.items?.indexOf(pli)] = pliToWork;
      }
      delete elevation.desiredPrice;
      delete elevation.planks;
      delete elevation.taxAmount;
      delete elevation.addonsTotal;
      delete elevation.price;
      delete elevation.taxRate;
      delete elevation.pricing;
      delete elevation.totalPrice;
      delete elevation?.["stops_excluding"];
      delete elevation?.["stops_including"];
    }
    cleanService(service);
    return service;
  });

  return updatedData;
};

export const getScheduleServices = (toBeScheduled = {}) =>
  Object.entries(toBeScheduled || {})
    ?.map?.(
      ([key, value], index) =>
        value?.map(({ label = "", serviceId }) => ({
          label: label || "",
          serviceId: serviceId || "",
          estimationId: key || "",
          estimationNumber: value?.[0]?.estimationNumber,
        })) || []
    )
    .flat() || [];

export const getDaysForService = (toBeScheduled = {}, serviceId = "") =>
  getDaysForSpecificService(
    Object.values(toBeScheduled || {})
      ?.flat()
      ?.find((e) => e?.serviceId?.toString() === serviceId?.toString()) || []
  );

export const getDaysForSpecificService = (serviceOptions = []) =>
  Array.from(
    new Set(
      serviceOptions?.[0]
        ?.flatMap(({ items = [] }) => items)
        ?.flatMap((el) => el?.days) || []
    )
  ) || [];

export const getServiceOptionForService = (
  toBeScheduled,
  serviceId = "",
  estimationId = ""
) => {
  return (
    Object.entries(toBeScheduled || {})
      ?.map?.(([key, value]) => {
        if (key === estimationId) {
          return value?.filter(
            (el) => el?.serviceId?.toString() === serviceId?.toString()
          );
        }
      })
      .flat()
      .filter(Boolean) || []
  );
};

// gets progress in % from lnf
export const progressInPerc = (lnf = 0, d1, d2, pli = {}) => {
  const progress =
    Object.keys(pli || {})?.length > 0
      ? !d2
        ? (lnf / pli?.[d1]) * 100
        : (lnf / (pli?.[d1] * pli?.[d2])) * 100
      : 0;
  return Math.round(Number(progress)) || 0;
};

export const progressByDayForOverview = (
  typeOfProgress,
  item,
  id,
  typeOfWork
) => {
  let progress = 0;

  const findProgressByDay = item?.progressByDay?.find((el) => el?.day === id);
  const findSpecificSubPli = item?.subPli?.find((el) =>
    el?.progressByDay?.some((a) => a?.day === id)
  );
  switch (typeOfProgress) {
    case "jumpBreakdown":
      if (typeOfWork === "Inspection") {
        progress = findProgressByDay?.inspectionProgress || 0;
      } else {
        const floors = findProgressByDay?.progress || [];
        const totalProgress = floors?.reduce?.(
          (acc, el) => acc + (el?.progress || 0),
          0
        );
        progress = floors?.length > 0 ? totalProgress / floors?.length : 0;
      }
      break;
    case "breakdown":
      const usedProgress =
        item?.used
          ?.filter((g) => g?.days?.includes(id))
          ?.flatMap((el) => el?.progressByDay?.find((e) => e?.day === id))
          ?.reduce((a, b) => a + b?.[breakdown_dimension], 0) || 0;

      progress =
        Array.isArray(item?.used) && item?.used?.length > 0
          ? "Progress " + usedProgress
          : 0;
      break;
    case "subPli":
      const { d1, d2 } = get_ProgressDimensionsByServiceId(item?.serviceId);
      const tProgressPercentage =
        d2 === undefined
          ? (findSpecificSubPli?.totalProgress / findSpecificSubPli?.[d1]) * 100
          : (findSpecificSubPli?.totalProgress /
              (findSpecificSubPli?.[d1] * findSpecificSubPli?.[d2])) *
            100;
      const progressByDay =
        Object.keys(findProgressByDay || {}).length > 0
          ? d2 === undefined
            ? findProgressByDay?.[d1]
            : findProgressByDay?.[d1] * findProgressByDay?.[d2]
          : 0;

      progress = `${progressByDay} lf (${Math.round(tProgressPercentage)}%)`;
      break;
    default:
      break;
  }
  return progress;
};

// Postponing a day which is used in services, the day is postponed in all services to the new day
export const postponeDaysInServices = (
  services = {},
  prevId = "",
  newDay = {}
) => {
  const newToBeScheduledObject = {};
  Object.entries(services || {})?.map(([key, value]) => {
    newToBeScheduledObject[key] = value?.map((el) => {
      el.serviceOptions = [
        el?.serviceOptions[0]?.map((q) => {
          q.items = q?.items?.map((d) => {
            d.progressByDay = d?.progressByDay?.map((p) => {
              if (p?.day === prevId) {
                p.day = newDay?.id;
              }
              return p;
            });
            d.days = d?.days?.map((a) => {
              if (a === prevId) {
                a = newDay?.id;
              }
              return a;
            });
            d.breakdownValue = d?.breakdownValue?.map((breakdown) => {
              breakdown.days = breakdown?.days?.map((a) => {
                if (a === prevId) {
                  a = newDay?.id;
                }
                return a;
              });
              breakdown.progressByDay = breakdown?.progressByDay?.map((p) => {
                if (p?.day === prevId) {
                  p.day = newDay?.id;
                }
                return p;
              });
              return breakdown;
            });
            d.subPli = d?.subPli?.map((subPli) => {
              subPli.days = subPli?.days?.map((a) => {
                if (a === prevId) {
                  a = newDay?.id;
                }
                return a;
              });
              subPli.progressByDay = subPli?.progressByDay?.map((p) => {
                if (p?.day === prevId) {
                  p.day = newDay?.id;
                }
                return p;
              });
              return subPli;
            });
            d.usedScheduleDays = Object.entries(
              d?.usedScheduleDays || {}
            )?.reduce((prev, [key, value]) => {
              if (key === prevId) {
                prev[newDay?.id] = {
                  ...value,
                  startDate: dayjs(newDay?.startDate),
                  endDate: dayjs(newDay?.endDate),
                };
              } else {
                prev[key] = value;
              }
              return prev;
            }, {});
            return d;
          });
          return q;
        }),
      ];
      el.serviceAddons = el?.serviceAddons?.map((addon) => {
        addon.progressByDay = addon?.progressByDay?.map((p) => {
          if (p?.day === prevId) {
            p.day = newDay?.id;
          }
          return p;
        });
        return addon;
      });
      return el;
    });
  });
  return newToBeScheduledObject;
};

// Schedule Settings ---> Filter Services by Type
export const toBeScheduledPreferences = (key = "", toBeScheduled = {}) => {
  if (!key) {
    return toBeScheduled;
  } else {
    Object.entries(toBeScheduled)?.map((prev, [k, v]) => {
      if (key === "pli") {
        prev[k] = v?.map((el) => {
          const { d1, d2 } = get_ProgressDimensionsByServiceId(el?.serviceId);
          el.serviceOptions = [
            el?.serviceOptions[0]?.map((q) => {
              q.items = q?.items?.filter((el) =>
                d2 === undefined
                  ? el?.totalProgress !== el?.[d1]
                  : el?.totalProgress !== el?.[d1] * el?.[d2]
              );
              return q;
            }),
          ];
          return el;
        });
      }
    });
    return prev;
  }
};

// Progress for pli (Breakdowns)
export const totalProgressForPliBreakdowns = (
  pli = {},
  breakdowns = [],
  typeOfWork = "",
  d1 = undefined,
  d2 = undefined
) =>
  breakdowns
    ?.map?.(({ progressByDay = [], rate = 0 }) =>
      breakInspProgress(typeOfWork, progressByDay, rate)
    )
    ?.reduce?.((a, b) => a + b, 0) *
  (d2 === undefined ? pli?.[d1] / 100 : (pli?.[d1] * pli?.[d2]) / 100);

// Progress for Pli or Sub Pli (dimensions (LF))
export const totalProgressPli = (pli = {}, serviceId = "") => {
  const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);
  let totalProgress = 0;

  if (d2 === undefined) {
    totalProgress =
      pli?.progressByDay?.reduce((acc, prev) => acc + (prev?.[d1] || 0), 0) ||
      0;
  } else {
    pli?.progressByDay?.forEach((el) => {
      if (el) {
        el.progress = (el?.[d1] || 0) * (el?.[d2] || 0);
      }
    });

    totalProgress =
      pli?.progressByDay?.reduce(
        (acc, prev) => acc + (prev?.progress || 0),
        0
      ) || 0;
  }

  return totalProgress || 0;
};

export const filterScopeOfWork = (el, key, value) =>
  el?.scheduled?.length > 0 &&
  el?.scheduled?.some?.((el) => el?.serName === value);

export const sumProgressByDay = (progressByDay = []) =>
  progressByDay?.reduce((a, b) => a + b?.[breakdown_dimension], 0) || 0;

export const onlyAppliedBreakdowns = (breakdowns = []) =>
  breakdowns?.filter?.((el) => !!el?.isApplied) || [];

export const calculateBreakdownsRate = (breakdowns = []) => {
  const breakdownsRate = breakdowns
    ?.filter((breakdown) => !!breakdown?.isApplied)
    ?.reduce((acc, prev) => acc + prev?.rate, 0);
  return breakdownsRate || 0;
};

export const preventDuplicate = (arr = [], key = "") => {
  const seen = new Set();
  return arr?.filter((item) => {
    const duplicate = seen?.has(item?.[key]);
    seen.add(item?.[key]);
    return !duplicate;
  });
};

export const preventDuplicateStrings = (arr = []) => [...new Set(arr)];

export const filterRangeScheduledDays = (el, value) =>
  dayjsNY(el?.startDate).format("MM/DD/YYYY") >=
    dayjsNY(value?.[0]).format("MM/DD/YYYY") &&
  dayjsNY(el?.startDate).format("MM/DD/YYYY") <=
    dayjsNY(value?.[1]).format("MM/DD/YYYY");

export const dayIdsForInspectionTypes = (days, value) => {
  const obj = {};
  const groupIds = days?.reduce?.((prev, arr) => {
    return {
      ...prev,
      [arr?.dayId]:
        arr?.scheduled
          ?.flatMap(({ work }) => work)?.[0]
          ?.used?.filter((b) => b?.days?.includes(arr?.dayId))
          ?.map(({ name }) => name)
          ?.filter(Boolean) || [],
    };
  }, {});
  Object.entries(groupIds || {})?.map(([key, val]) => {
    if (!val?.includes(value)) {
      return;
    } else {
      obj[key] = val;
    }
  });
  return obj;
};

export const pliEditedFromNextSchedules = (pli = {}) =>
  "editedFrom" in (pli || {}) &&
  typeof pli?.editedFrom === "object" &&
  Object.keys(pli?.editedFrom || {})?.length > 0;

export const prevPliCompleted = (pli = {}) =>
  pli?.isPliCompletedInThePreviousSchedule?.completed || false;

export const prevJumpCompleted = (jump = {}) =>
  jump?.isJumpCompletedInThePreviousSchedule || false;

export const prevBreakdownCompleted = (breakdown = {}) =>
  breakdown?.isBreakdownCompletedInThePreviousSchedule || false;

export const prevSubPliCompleted = (subPli = {}) =>
  subPli?.isSubPliCompletedInThePreviousSchedule || false;

export const breakdownsServiceId = (
  serviceDefinitions = [],
  element = "",
  serviceId = "",
  type = "",
  origin = ""
) =>
  serviceDefinitions
    ?.find?.((el) => el?.serviceId?.toString() === serviceId?.toString())
    ?.[element]?.filter?.((a) => !!a?.[type]?.[origin]?.value) || [];

export const getServiceInstalledVal = ({
  totalProgress = 0,
  length = 0,
  width = 0,
  surface = 0,
  height = 0,
  hoist_dimension = 0,
  serviceLabel,
}) => {
  const result = {
    Barriers: totalProgress / length ?? 0,
    "Debris Chute": totalProgress / length ?? 0,
    Fence: totalProgress / length ?? 0,
    Hoist: totalProgress / hoist_dimension ?? 0,
    "Horizontal Netting": totalProgress / length ?? 0,
    "Needle Beam": totalProgress / length ?? 0,
    "Overhead Protection": totalProgress / length ?? 0,
    "Platform Scaffold": totalProgress / (length * height) ?? 0,
    "Plywood Enclosure": totalProgress / length ?? 0,
    "Roof Protection": totalProgress / length ?? 0,
    Scaffold: totalProgress / (length * height) ?? 0,
    Shoring: totalProgress / length ?? 0,
    "Sidewalk Shed": totalProgress / length ?? 0,
    "Stair Tower": totalProgress / length ?? 0,
    "System Scaffold": totalProgress / surface ?? 0,
    "Vertical Netting": totalProgress / (height * length) ?? 0,
    "Window Protection": totalProgress / (height * length) ?? 0,
    "Working Platform": totalProgress / (height * length) ?? 0,
  };
  Object.freeze(result);
  return !!result[serviceLabel] ? result[serviceLabel] : 0;
};

export const calcData = (serviceOptions = [], key = "") => {
  const value = serviceOptions?.reduce?.(
    (a, b) => a + (Number(b?.[key]) || 0),
    0
  );
  return isNaN(value) ? "-" : value;
};

export const columnDefsConfigurationJumps = [
  {
    headerName: "Jump",
    field: "jump",
  },
  {
    headerName: "Floors",
    field: "floors",
  },
  {
    headerName: "Actions",
    field: "actions",
  },
];

export const typeOfWorkTexts = {
  Inspection: "inspected",
  Installation: "installed",
  Removal: "removed",
  Repair: "repaired",
  "Deliver material": "installed",
  "Pick up material": "installed",
  Maintenance: "maintained",
};

export const configuratonOfJumps = (jumps = [], jump = 0, typeOfWork = "") =>
  jumps?.slice(
    sliceNumbers(jump, jumps?.length)?.[typeOfWork]?.first,
    sliceNumbers(jump, jumps?.length)?.[typeOfWork]?.last
  );

export const jumpHasProgress = (el = {}, typeOfWork = "") => {
  let hasProgress = false;
  if (typeOfWork === "Inspection") {
    hasProgress = el?.breakdownValue?.some((floor) => {
      return floor?.progressByDay?.some((el) => el?.[breakdown_dimension] > 0);
    });
  } else {
    hasProgress = el?.progressByDay?.some((floor) => {
      const progress = floor?.progress;
      return progress?.some((p) => p?.progress > 0);
    });
  }
  return hasProgress;
};

export const rowDataConfigurationJumps = (jumps, onFloorChange, typeOfWork) => {
  return jumps?.map((element, i) => {
    return {
      jump: {
        value: (
          <Badge
            text={element?.name}
            color={repeatelem(colors, jumps?.length - 1)[i]}
          />
        ),
        style: {
          width: 170,
          color: "#323338",
          fontWeight: 600,
          paddingLeft: 5,
          border: "1px solid #DDE3E5",
        },
      },
      floors: {
        value: `${element?.floors
          ?.filter((s) => s?.floor !== 0)
          ?.map((floor) => floor?.floor)
          ?.join(", ")}`,
        style: {
          width: 140,
          alignItems: "center",
          paddingLeft: 5,
          border: "1px solid #DDE3E5",
        },
      },
      actions: {
        value: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.2rem",
              padding: 5,
              flexWrap: "wrap",
            }}
          >
            {jumpHasProgress(element, typeOfWork) && (
              <span
                style={{
                  color: "red",
                  fontSize: 13,
                  fontWeight: 600,
                }}
              >
                {" "}
                You can not change floors of this jump because progress is
                already set!
              </span>
            )}
            {i !== 0 && (
              <Button
                style={{
                  borderRadius: 5,
                  border: "none",
                  backgroundColor: "#f5f5f5",
                }}
                onClick={() => onFloorChange(element?.name, "up")}
                disabled={jumpHasProgress(element, typeOfWork)}
              >
                Move Up
              </Button>
            )}
            {i !== jumps?.length - 1 && (
              <Button
                style={{
                  borderRadius: 5,
                  border: "none",
                  backgroundColor: "#f5f5f5",
                }}
                onClick={() => onFloorChange(element?.name, "down")}
                disabled={jumpHasProgress(element, typeOfWork)}
              >
                Move Down
              </Button>
            )}
          </div>
        ),
        style: {
          textAlign: "center",
          width: 140,
          paddingLeft: 5,
          border: "1px solid #DDE3E5",
        },
      },
    };
  });
};

export const colors = [
  "black",
  "darkred",
  "pink",
  "red",
  "yellow",
  "orange",
  "cyan",
  "green",
  "blue",
  "purple",
  "geekblue",
  "magenta",
  "volcano",
  "gold",
  "lime",
  "black",
  "lightblue",
  "lightgreen",
];

export const prevSchedulesDataHeader = (setPreview, setOpenDetails) => [
  {
    headerName: "Name",
    field: "scheduleName",
  },
  {
    headerName: "Type Of Work",
    field: "typeOfWork",
    cellRendererFramework: (params) => {
      const { typeOfWork = "" } = params?.data || {};
      return (
        <span style={{ color: typeOfWorkColors[typeOfWork] }}>
          {typeOfWork}
        </span>
      );
    },
  },
  {
    headerName: "Date",
    field: "date",
  },
  {
    headerName: "Start Time",
    field: "startTime",
  },
  {
    headerName: "Pe/Pm",
    field: "pePm",
  },
  {
    headerName: "Client Name",
    field: "clientName",
  },
  {
    headerName: "Scope of work",
    field: "toBeScheduled",
    cellRendererFramework: (params) => {
      const {
        toBeScheduled = {},
        id = "",
        typeOfWork = "",
        scheduleId,
      } = params?.data || {};
      const servicesForThisDay =
        dayDetail(toBeScheduled || {}, [id])
          ?.[id]?.flatMap?.(({ services = [] }) =>
            services?.map?.(({ serviceName = "" }) => serviceName)
          )
          .join(", ") || [];
      return !servicesForThisDay?.length ? (
        <span style={{ color: "red" }}>Not Scheduled!</span>
      ) : (
        <b
          onClick={() =>
            setPreview({
              preview: true,
              scheduleDays: [params?.data],
              toBeScheduled: toBeScheduled || {},
              typeOfWork: typeOfWork || "",
              scheduleId,
            })
          }
          style={{
            color: "#1065A4",
            cursor: "pointer",
          }}
        >
          {servicesForThisDay}
        </b>
      );
    },
  },
  {
    headerName: "Note",
    field: "notes",
    width: 100,
    cellRendererFramework: (params) => {
      const notesForThisDay = params?.data?.notes?.length > 0;
      return notesForThisDay ? (
        <b
          onClick={() => {
            setOpenDetails({
              open: true,
              details: {
                description:
                  params?.data?.notes?.map((text) => text?.text)?.join(",") ||
                  "",
                startDate: params?.data?.startDate,
              },
            });
          }}
          style={{
            color: "#1065A4",
            cursor: "pointer",
          }}
        >
          See note
        </b>
      ) : (
        <span>-</span>
      );
    },
  },
];

// export const prevSchedulesDataHeader = [
//   "Schedule Name",
//   "Type Of Work",
//   "Dates",
//   "Start Time",
//   "Day",
//   "Pe/Pm",
//   "Client Name",
//   "Scope Of Work",
//   "Notes",
// ];

export const typeOfWorkTypes = [
  "Installation",
  "Removal",
  "Repair",
  "Inspection",
  "Pick up material",
  "Deliver material",
  "Maintenance",
];

export const analyticsFilters = (schedules = []) => {
  const allData = (key) => [...new Set([...schedules?.map((el) => el?.[key])])];
  return [
    {
      label: "Client",
      field: "accountName",
      placeholder: "Client",
      options: allData("accountName"),
      width: 270,
    },
    {
      label: "Project Address",
      field: "projectAddress",
      placeholder: "Project Address",
      options: allData("projectAddress"),
      width: 270,
    },
    {
      label: "Type Of Work",
      field: "typeOfWork",
      placeholder: "Type Of Work",
      options: typeOfWorkTypes,
      width: 165,
    },
    {
      label: "Scope Of Work",
      field: "sow",
      placeholder: "Scope Of Work",
      options: allServices(schedules),
      width: 165,
    },
    // {
    //   label: "Schedule By",
    //   field: "scheduleBy",
    //   placeholder: "Schedule By",
    //   options: ["dimensions", "breakdown", "subPli", "Floor & Breakdown"],
    //   width: 150,
    // },
    {
      label: "Crews",
      field: "crews",
      secondField: "crewName",
      placeholder: "Crews",
      options:
        [
          ...new Set(
            schedules?.flatMap((el) => el?.crews)?.map((el) => el?.crewName)
          ),
        ] || [],

      width: 172,
    },
    {
      label: "Fleet",
      field: "fleet",
      secondField: "fleetName",
      placeholder: "Fleet",
      options:
        [
          ...new Set(
            schedules?.flatMap((el) => el?.fleet)?.map((el) => el?.fleetName)
          ),
        ] || [],
      width: 172,
    },
    {
      label: "Drivers",
      field: "drivers",
      secondField: "driverName",
      placeholder: "Drivers",
      options:
        [
          ...new Set(
            schedules?.flatMap((el) => el?.fleet)?.map((el) => el?.driverName)
          ),
        ] || [],
      width: 172,
    },
  ];
};

export const columnDefsForScheduleDaysOverview = ({ setPreview }) => {
  return [
    {
      headerName: "Date",
      field: "date",
      cellStyle: {
        display: "flex",
        alignItems: "start",
        height: "100%",
      },
    },

    {
      headerName: "Start Time",
      field: "startTime",
      cellStyle: {
        display: "flex",
        alignItems: "start",
        height: "100%",
      },
    },
    {
      headerName: "End Time",
      field: "endTime",
      cellStyle: {
        display: "flex",
        alignItems: "start",
        height: "100%",
      },
    },
    {
      headerName: "Crews",
      field: "crews",
      width: 300,
      cellStyle: {
        whiteSpace: "normal",
        lineHeight: "20px",
        textAlign: "left",
        height: "100%",
      },
      cellRendererFramework: ({ value = [] }) => {
        return !!value ? (
          <Tooltip
            title={
              value?.length > 0
                ? value?.map((crew, index) => {
                    return (
                      <div className="fleet-tooltip">
                        <div className="fleet-tooltip__truck">
                          <Badge color={"lightgreen"} />
                          <span style={{ fontWeight: "bold" }}>
                            Crew Name :
                          </span>
                          <span>{crew?.crewName || "-"}</span>
                          <span style={{ fontWeight: "bold" }}>
                            Crew Position:{" "}
                          </span>
                          <span>{crew?.crewPosition || "-"}</span>
                        </div>
                      </div>
                    );
                  })
                : "No Crews Assigned"
            }
            overlayClassName="fleet-tooltip-dailySchedule"
          >
            <MondayButton
              // onClick={() => setVisible(true)}
              {...{
                Icon: (
                  <Driver style={{ fill: "#fff", transform: "scale(0.8)" }} />
                ),
                className: "mondayButtonBlue",
              }}
            >
              {value?.length?.toString()}
            </MondayButton>
          </Tooltip>
        ) : (
          <></>
        );
      },
    },
    {
      headerName: "Fleet",
      field: "fleet",

      cellStyle: {
        whiteSpace: "normal",
        lineHeight: "20px",
        textAlign: "left",
        height: "100%",
      },
      cellRendererFramework: ({ value = [] }) => {
        return (
          <Tooltip
            overlayClassName="fleet-tooltip-dailySchedule"
            title={
              value?.length > 0
                ? value?.map((truck, index) => {
                    return (
                      <div className="fleet-tooltip">
                        <div className="fleet-tooltip__truck">
                          <Badge color={"lightgreen"} />
                          <span style={{ fontWeight: "bold" }}>Truck :</span>
                          <span>{truck?.fleetName}</span>
                          <span style={{ fontWeight: "bold" }}>Driver: </span>
                          <span>{truck?.driverName}</span>
                        </div>
                      </div>
                    );
                  })
                : "No Trucks Assigned"
            }
          >
            <MondayButton
              {...{
                Icon: (
                  <Truck style={{ fill: "#fff", transform: "scale(0.7)" }} />
                ),
                className: "mondayButtonBlue",
              }}
            >
              {value?.length?.toString() || 0}
            </MondayButton>
          </Tooltip>
        );
      },
    },
    {
      headerName: "Schedule Address",
      field: "scheduleAddress",
      // flex: 2,
      cellStyle: {
        display: "flex",
        alignItems: "start",
        height: "100%",
      },
      cellRendererFramework: ({ data = {}, value = "" }) => {
        return !!data?.scheduleId ? (
          <span
            onClick={() => openInNewTab(`/scheduling/${data?.scheduleId}`)}
            style={{
              cursor: "pointer",
              color: "#1264a3",
              fontWeight: 600,
            }}
          >
            {value}
          </span>
        ) : (
          <>{value}</>
        );
      },
    },
    {
      headerName: "Schedule Name",
      field: "scheduleName",
      cellStyle: {
        display: "flex",
        alignItems: "start",
        height: "100%",
      },
    },
    {
      headerName: "Type Of Work",
      field: "typeOfWork",
      cellStyle: {
        display: "flex",
        alignItems: "start",
        height: "100%",
      },
      cellRendererFramework: ({ value = "" }) => {
        return (
          <div
            style={{
              fontWeight: 600,
              textAlign: "center",
              color: typeOfWorkColors?.[value],
            }}
          >
            {value}
          </div>
        );
      },
    },

    {
      headerName: "Pe/Pm",
      field: "pe",
      cellStyle: {
        display: "flex",
        alignItems: "start",
        height: "100%",
      },
    },
    {
      headerName: "Scope Of Work",
      field: "scopeOfWork",
      cellStyle: {
        display: "flex",
        alignItems: "start",
        height: "100%",
      },
      cellRendererFramework: (params) => {
        return (
          <span
            onClick={() => {
              setPreview({
                preview: true,
                scheduleDays: [params?.data],
                toBeScheduled: {},
                typeOfWork: params?.data?.typeOfWork || "",
                scheduleId: params?.data?.scheduleId,
              });
            }}
            style={{
              cursor: "pointer",
              fontWeight: 600,
              color: "#1264a3",
            }}
          >
            See Services
          </span>
        );
        // !Array.isArray(params?.value) ? (
        //   <></>
        // ) : !params?.value?.length ? (
        //   <span
        //     style={{
        //       color: "red",
        //       fontWeight: 600,
        //     }}
        //   >
        //     Not Scheduled!
        //   </span>
        // ) : (
        //   <span
        // onClick={() => {
        //   setPreview({
        //     preview: true,
        //     scheduleDays: [params?.data],
        //     toBeScheduled: params?.data?.toBeScheduled || {},
        //     typeOfWork: params?.data?.typeOfWork || "",
        //     scheduleId: params?.data?.scheduleId,
        //   });
        // }}
        // style={{
        //   cursor: "pointer",
        //   fontWeight: 600,
        //   color: "#1264a3",
        // }}
        //   >
        //     <Tooltip title="Click for more details!">
        //       {params?.value?.map((s) => s)?.join(",")}
        //     </Tooltip>
        //   </span>
        // );
      },
    },
  ];
};

export const migrateColumns = (mainKey = "") => {
  const parentKey = mainKey === "accountId" ? "projectId" : "opportunityId";
  return {
    estimations: [
      {
        headerName: "Select estimations",
        checkboxSelection: (params) => {
          return !params?.node?.data?.[parentKey];
        },
        showDisabledCheckboxes: true,
      },
      {
        headerName: "Jobsite Address",
        field: "jobSiteAddress",
      },
      {
        headerName: "Estimation Status",
        field: "estSTATUS",
      },
      {
        headerName: "Project Executive",
        field: "projectExecutive",
      },
    ],
    projects: [
      {
        headerName: "Select projects",
        headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
      },
      {
        headerName: "Project Name",
        field: "projectName",
      },
      {
        headerName: "Project status",
        field: "projectStatus",
      },
      {
        headerName: "Project Managers",
        field: "projectManager",
        cellRendererFramework: (params) => {
          const { projectManager = [] } = params?.data;
          return (
            <span>
              {projectManager?.map((pr) => pr?.nameOfUser)?.join(", ") || ""}
            </span>
          );
        },
      },
    ],

    opportunities: [
      {
        headerName: "Select opportunities",
        headerCheckboxSelection: mainKey === "leadId",
        checkboxSelection: (params) => {
          return !params?.node?.data?.projectId;
        },
        showDisabledCheckboxes: true,
        cellRenderer: (params) => {
          let textToDisplayIfRowIsDisabled = "";
          if (disableOpportunity(params?.data)) {
            textToDisplayIfRowIsDisabled = params?.data?.opportunityStage || "";
          }
          return (
            <span style={{ color: "#323338", fontWeight: 600 }}>
              {textToDisplayIfRowIsDisabled}
            </span>
          );
        },
      },
      {
        headerName: "Opportunity Address",
        field: "opportunityAddress",
      },
      {
        headerName: "Opportunity Status",
        field: "opportunityStatus",
      },
      {
        headerName: "Project Executive",
        field: "projectExecutive",
      },
    ],

    contacts: [
      {
        headerName: "Select contacts",
        headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
      },
      {
        headerName: "Contact Name",
        field: "contactFirstName",
      },
      {
        headerName: "Contact Last Name",
        field: "contactLastName",
      },
      {
        headerName: "Contact Email",
        field: "contactEmail",
      },
    ],
  };
};

export const swalTexts = {
  Installation: "All previous jumps must be installed to proceed the work!",
  Removal: "Removal of hoist starts from the top floor!",
  Repair: "Repair previous jump!",
  Inspection: "All previous jumps must be inspected to proceed the work!",
  "Pick up material": "Pick up material!",
  "Deliver material": "Deliver material!",
  Maintenance: "Maintenance!",
};

export const statusTypes = [
  "Draft",
  "Confirmed",
  // "Pre-Schedule",
  "In Progress",
  "Completed",
];

// repeats an element n times and returns an array
export const repeatelem = (elem, n) => {
  let arr = [];
  for (let i = 0; i <= n; i++) {
    arr = arr.concat(elem);
  }
  return arr;
};

export const roundNr = (nr) => Math.round(nr);

export const createColumn = (
  key = "",
  x = {},
  o = {},
  d1 = undefined,
  d2 = undefined,
  cl = {},
  i = 0,
  serviceOptions = [],
  type = ""
) => {
  const getValue = () => {
    switch (key) {
      case "estimate":
        return `Est ${x?.estimationNumber || i + 1}`;
      case "service":
        return type === "pli" ? (i === 0 ? x?.label : undefined) : x?.label;
      case "l":
        return type === "pli"
          ? roundNr(o?.length) || "-"
          : calcData(serviceOptions, "length");
      case "h":
        return type === "pli"
          ? roundNr(o?.height) || "-"
          : calcData(serviceOptions, "height");
      case "w":
        return type === "pli"
          ? roundNr(o?.width) || "-"
          : calcData(serviceOptions, "width");
      case "sqf":
        return type === "pli"
          ? roundNr(o?.surface) || "-"
          : calcData(serviceOptions, "surface");
      case "item":
        return type === "pli"
          ? roundNr(o?.totalProgress) || "-"
          : calcData(serviceOptions, "totalProgress");
      case "remainInstall":
        return type === "pli"
          ? (!d2 ? o[d1] - o?.totalProgress : o?.surface - o?.totalProgress) ||
              0
          : (!d2
              ? calcData(serviceOptions, [d1])
              : calcData(serviceOptions, "surface") || 0) -
              calcData(serviceOptions, "totalProgress") || 0;
      case "itemPerc":
        return type === "pli"
          ? progressInPerc(o?.totalProgress, d1, d2, o)
          : progressForService(x);
      case "remainInstallPerc":
        return type === "pli"
          ? 100 - progressInPerc(o?.totalProgress, d1, d2, o)
          : remainingProgressPercForService(x);
      default:
        return o?.[key.toLowerCase()] || "-";
    }
  };

  return {
    [key]: {
      value: getValue(),
      style: {
        border: `1px solid ${cl?.color}`,
        paddingLeft: 5,
      },
    },
  };
};

export const columns = [
  "estimate",
  "service",
  "l",
  "h",
  "w",
  "sqf",
  "item",
  "remainInstall",
  "itemPerc",
  "remainInstallPerc",
];

export const schedulePliDetails = (
  x = {},
  o = {},
  d1 = undefined,
  d2 = undefined,
  cl = {},
  i = 0,
  serviceOptions = [],
  type = ""
) =>
  columns?.reduce?.(
    (acc, key) => ({
      ...acc,
      ...createColumn(key, x, o, d1, d2, cl, i, serviceOptions, type),
    }),
    {}
  );

export const getDays = (first, last) => emptyDays(first, last);

export const prevBreakdowns = (
  prevSchToGetBreakdowns,
  estimationId,
  serviceId,
  e,
  pli,
  pliId
) => {
  let prevPli = prevSchToGetBreakdowns?.[estimationId]
    ?.find?.((serv) => serv?.serviceId?.toString() === serviceId?.toString())
    ?.serviceOptions?.[0]?.[e]?.items?.find?.((pli) => pli?.id === pliId);

  prevPli = {
    ...prevPli,
    breakdownValue: prevPli?.breakdownValue?.map?.((br, index) => {
      br.days = [];
      br.progressByDay = [];
      br.breakdown_dimension =
        pli?.breakdownValue[index]?.history?.sort(
          (a, b) => moment(b?.createdAt) - moment(a?.createdAt)
        )[0]?.pliTotalProgress === 100
          ? 0
          : 100;
      br.totalProgress = pli?.breakdownValue[index]?.history?.sort(
        (a, b) => moment(b?.createdAt) - moment(a?.createdAt)
      )[0]?.pliTotalProgress;
      return br;
    }),
  };

  return prevPli;
};
// Used to get the progress of the jump we are working on
export const checkWhichJumpWeWorking = (elevation, name) =>
  Object.entries(
    (elevation?.jumps || {})?.reduce?.((prev, arr) => {
      return {
        ...prev,
        [arr?.name]: arr?.floors
          ?.filter((w) => w?.floor !== 0)
          ?.map?.((el) => {
            const flProgress = elevation?.items?.find?.(
              (item) => item?.floor === el?.floor
            );
            return {
              floor: el?.floor,
              progress: flProgress?.totalProgress || 0,
            };
          }),
      };
    }, {})
  )?.map?.(([key, value]) => {
    return {
      exactStuff: key === name ? value : null,
      allStuff: value,
    };
  });

// decides the queue of the jumps to be worked on based on the type of work (HOIST)
export const sliceNumbers = (jumpWeWorking, length) => {
  return {
    Installation: {
      first: 0,
      last: jumpWeWorking,
    },
    Removal: {
      first: jumpWeWorking + 1,
      last: length,
    },
    Repair: {
      first: 0,
      last: jumpWeWorking,
    },
    Inspection: {
      first: 0,
      last: jumpWeWorking,
    },
    "Pick up material": {
      first: 0,
      last: jumpWeWorking,
    },
    "Deliver material": {
      first: 0,
      last: jumpWeWorking,
    },
    Maintenance: {
      first: 0,
      last: jumpWeWorking,
    },
  };
};

// Used to check if the previous jumps are completed
export const checkProgressForHoist = (arePrevJumsCompleted) =>
  arePrevJumsCompleted?.length === 0
    ? true
    : arePrevJumsCompleted?.every?.(({ progress = 0 }) => progress === 100);

export const colorsText = {
  "Not Started": "#FFCB00",
  "In Progress": "#B3D99B",
  Installed: "#7AC14D",
  "Over installed": "rgb(79 1 5)",
  Removed: "#F04F4E",
  Repaired: "rgb(112 227 39)",
  Inspected: "#7AC14D",
  Completed: "rgb(112 227 39)",
};

export const backgroundForProgress = (progress = 0, typeOfWork = "") => {
  let background = "";
  let progressNum = isNaN(progress) ? 0 : progress;
  if (progressNum === 0) background = "#FFCB00";
  if (progressNum > 0 && progressNum < 100) background = "#B3D99B";
  if (progressNum === 100) background = typeOfWorkColors?.[typeOfWork] || "";
  if (progressNum > 100) background = "rgb(79 1 5)";
  return background;
};

export const statusTextAgGrid = {
  Installation: {
    notStarted: "Not Started",
    inProgress: "In Progress",
    finished: "Installed",
    over: "Over installed",
  },
  Removal: {
    notStarted: "Not Started",
    inProgress: "In Progress",
    finished: "Removed",
    over: "Over Removed",
  },
  Repair: {
    notStarted: "Not Started",
    inProgress: "In Progress",
    finished: "Repaired",
    over: "Over Repaired",
  },
  Inspection: {
    notStarted: "Not Started",
    inProgress: "In Progress",
    finished: "Inspected",
    over: "Over Inspected",
  },
  "Pick up material": {
    notStarted: "Not Started",
    inProgress: "In Progress",
    finished: "Completed",
    over: "Over Completed",
  },
  "Deliver material": {
    notStarted: "Not Started",
    inProgress: "In Progress",
    finished: "Completed",
    over: "Over Completed",
  },
  Maintenance: {
    notStarted: "Not Started",
    inProgress: "In Progress",
    finished: "Completed",
    over: "Over Completed",
  },
};

export const typeOfProgressPli = ["dimensions", "breakdown", "subPli"];

//Details about every day of the schedules
export const getSpecificId = (otherSchedules = []) => {
  const result = otherSchedules
    ?.flatMap((el) => {
      const filteredScheduleDays = availableDays(el?.scheduleDays);
      return filteredScheduleDays?.map((s) => {
        const scheduled = Object.values(el?.toBeScheduled || {})
          ?.flat(1)
          ?.map((b) => {
            let dataUsed = preventDuplicate(getDataUsed(s, b), "uniqueName");
            return {
              serName: b?.label,
              work: dataUsed,
              serviceProgress: progressForService(b) + " " + "%",
            };
          })
          ?.filter((a) => a?.work?.length > 0);
        return {
          dayId: s?.id,
          startDate: s?.startDate,
          endDate: s?.endDate,
          fleet: s?.fleet || [],
          crews: s?.crews || [],
          startTimeHours: moment(s?.startDate).format("h:mm a"),
          endTimeHours: moment(s?.endDate).format("h:mm a"),
          status: s?.status || "",
          nr: `${s?.day} / ${filteredScheduleDays?.length}`,
          notes: s?.notes
            ?.map(({ text = "" }) => text)
            ?.filter(Boolean)
            ?.join(", "),
          scheduleName: el?.scheduleName || "",
          typeOfWork: el?.typeOfWork || "",
          scheduleAddress: el?.scheduleAddress || "",
          label: el?.label || [],
          scheduleId: el?.scheduleId || "",
          unScheduledDays: el?.unScheduledDays || [],
          scheduleName: el?.scheduleName || "",
          uncompletedInspections: el?.uncompletedInspections || [],
          date: moment(s?.startDate).format("MM/DD/YYYY"),
          pe: el?.projectManagers?.map((q) => q)?.join(", ") || "",
          scheduled,
        };
      });
    })
    ?.sort((a, b) => moment(b?.startDate) - moment(a?.startDate));
  return result;
};
export const getDataUsed = (s, b) => {
  return b?.serviceOptions?.[0]?.flatMap((arr) => {
    const temp = arr?.items?.filter((ids) => ids?.days?.includes(s?.id));
    return temp?.map((a) => {
      let name, used;
      if (a?.typeOfProgress === "jumpBreakdown") {
        const jumpName = arr?.jumps?.find((el) =>
          el?.floors?.some(({ floor }) => floor === a?.floor)
        );
        const floors =
          `${jumpName?.name} - Floors: ${jumpName?.floors
            ?.map((r) => r?.floor)
            ?.join(", ")}` || "";
        name = floors;
        used = a?.breakdownValue;
      } else if (a?.typeOfProgress === "breakdown") {
        name = `Pli ${a?.id}`;
        used = a?.breakdownValue;
      } else if (a?.typeOfProgress === "dimensions") {
        name = `Pli ${a?.id}`;
        used = "";
      } else if (a?.typeOfProgress === "subPli") {
        name = `Pli ${a?.id}`;
        used = a?.subPli;
      } else {
        name = `Pli ${a?.id}`;
        used = "";
      }
      const elevation = `${arr?.elevationLabel} ${arr?.elevationId}`;
      return {
        name,
        elevation,
        used,
        serviceId: b?.serviceId,
        uniqueName: `${name} - ${elevation}`,
      };
    });
  });
};
//  all scheduleIds without planned days
export const getIdsOfSchedulesWithoutScheduledDays = (schedules = []) => [
  ...new Set(
    getSpecificId(schedules)
      ?.filter((el) => el?.scheduled?.length === 0)
      ?.map(({ scheduleId }) => scheduleId)
  ),
];

//  all scheduleIds with planned days
export const schedulesWithPlannedDayes = (schedules) => [
  ...new Set(
    getSpecificId(schedules)
      ?.filter((el) => el?.scheduled?.length > 0)
      ?.map(({ scheduleId }) => scheduleId)
  ),
];

// information = Either 90 Day Drop Test or Monthly Maintenance
// Exists only in schedules with type of work inspection
export const scheduleIdsWithout90DayDropTest = (
  rowData = [],
  information = ""
) => {
  const hoistRaports = {};
  const inspectionSchedules = rowData?.filter(
    (el) => el?.typeOfWork === "Inspection"
  );
  for (const schedule of getSpecificId(inspectionSchedules || []) || []) {
    const checkFor90Day = schedule?.scheduled?.filter((el) =>
      el?.work?.some((a) =>
        a?.breakdownValue?.some(
          (b) =>
            b?.name
              ?.toLowerCase()
              ?.trim()
              ?.includes?.(information?.toLowerCase()?.trim()) &&
            b?.progressByDay?.reduce(
              (a, b) => a + b?.breakdown_dimension,
              0
            ) !== 100
        )
      )
    );
    if (checkFor90Day?.length > 0) {
      hoistRaports[schedule?.scheduleId] = checkFor90Day;
    }
  }
  return [...new Set(Object.keys(hoistRaports))];
};
// all schedule address currently in the table
export const allSchAddresses = (rowData = []) =>
  [
    ...new Set(rowData?.map(({ scheduleAddress = "" }) => scheduleAddress)),
  ]?.filter(Boolean);
// all Type Of Works currently in the table
export const allTypeOfWork = (rowData = []) =>
  [...new Set(rowData?.map(({ typeOfWork = "" }) => typeOfWork))]?.filter(
    Boolean
  );

// all services currently in the table
export const allServices = (rowData = []) => {
  const temp = rowData?.flatMap((el) =>
    Object.values(
      onlyEstWithScopeFalse(el?.toBeScheduled || el?.services || {})
    )?.flat()
  );
  return [...new Set(temp?.map(({ label = "" }) => label))]?.filter(Boolean);
};
// all project managers currently in the table
export const allProjectManagers = (rowData = []) =>
  [
    ...new Set(rowData?.flatMap(({ projectManagers = "" }) => projectManagers)),
  ]?.filter(Boolean);
// all schedules without planned days currently in the table
export const allUncheduledDaysSchedules = (rowData = []) =>
  preventDuplicate(
    rowData?.filter(
      (el) => !Object.values(el?.scopeOfWork || {})?.flat()?.length
    ),
    "scheduleId"
  );
// all days without uncompleted inspections currently in the table
export const unCompletedHoistInspections = (rowData = []) => [
  ...new Set(
    rowData?.flatMap(
      ({ uncompletedInspections = [] }) => uncompletedInspections
    )
  ),
];

// gets all the schedules that have the date selected
export const getNumberOfSchedules = (rowData = [], date = "") =>
  rowData?.filter((el) =>
    el?.scheduleDays?.some(
      (a) =>
        dayjs(a?.startDate).format("MM/DD/YYYY") ===
        dayjs(date).format("MM/DD/YYYY")
    )
  );

// gets all the schedules that have dates between the range selected
export const getNumberOfSchedulesBetweenDates = (
  rowData = [],
  startDate = "",
  endDate = ""
) =>
  rowData?.filter((el) =>
    el?.scheduleDays
      ?.filter(
        ({ status = "" }) =>
          status !== "Canceled" &&
          status !== "Postponed" &&
          status !== "Skipped"
      )
      ?.some(
        (a) =>
          dayjsNY(a?.startDate).valueOf() >= dayjsNY(startDate).valueOf() &&
          dayjsNY(a?.endDate).valueOf() <= dayjsNY(endDate).valueOf()
      )
  );

// gets all the schedules with the scheduleAddress selected
export const getNumberOfSchedulesByAddress = (rowData = [], address = "") =>
  rowData?.filter((el) => el?.scheduleAddress === address);

// all crews currently in the table along with their position
export const allCrews = (rowData = []) =>
  preventDuplicate(
    rowData
      ?.map(({ crews = [] }) => crews)
      ?.filter((el) => el?.length > 0)
      ?.flatMap((a) => a),
    "crewName"
  );

// all fleet currently in the table along with their drivers
export const allFleet = (rowData = []) =>
  rowData
    ?.map(({ fleet = [] }) => fleet)
    ?.filter((el) => el?.length > 0)
    ?.flatMap((a) => a);

export const breakInspProgress = (
  typeOfWork = "",
  progressByDay = [],
  rate = 0,
  breakdownValue = []
) => {
  const prgByDay = progressByDay?.reduce?.(
    (a, b) => a + b?.breakdown_dimension,
    0
  );
  return typeOfWork === "Inspection"
    ? prgByDay / breakdownValue?.length
    : prgByDay * (rate / 100);
};

export const progressByDayScheduling = (progressByDay = [], keyType = "") => {
  const tempOb = {};
  for (const temp of progressByDay || []) {
    const id = temp?.[keyType];
    if (!tempOb[id]) {
      tempOb[id] = { ...temp };
      delete tempOb[id].id;
    } else {
      for (const [key, value] of Object.entries(temp || {})) {
        if (typeof value === "number" && key !== keyType) {
          tempOb[id][key] += value;
        }
      }
    }
  }
  return Object.values(tempOb);
};

export const hoistRaport = (rowData = {}, days = {}) => {
  const toBeScheduledServices = Object.values(
    availableEstimations(rowData?.toBeScheduled || {})
  ).flat(1);
  const cellStyle = {
    border: "1px solid gray",
    paddingLeft: "5px",
  };
  return toBeScheduledServices?.[0]?.serviceOptions?.[0]?.flatMap((a) => {
    const device = a?.serialNumbers?.map((el) => el)?.join(", ") || "";
    const jumps =
      Object.entries(
        a?.jumps?.reduce?.(
          (prev, arr) => ({
            ...prev,
            [arr?.name]: arr?.floors || [],
          }),
          {}
        ) || {}
      )?.flatMap?.(([key, value]) => repeatelem(key, value?.length - 1)) || "";

    return a?.items?.map((t, i) => {
      const breakdownDays =
        [
          ...new Set(
            t.breakdownValue
              ?.filter(({ days = [] }) => days?.length > 0)
              ?.flatMap((el) => el?.days?.flatMap((q) => days?.[q]))
          ),
        ]?.join(", ") || "";
      const inspectionNames =
        t.breakdownValue
          ?.filter(({ days = [] }) => days?.length > 0)
          ?.map(({ name = "" }) => name)
          ?.join(", ") || "";

      return {
        scheduleAddress: {
          value: rowData?.scheduleAddress || "",
          style: cellStyle,
        },
        ds: { value: a?.type || "", style: cellStyle },
        device: { value: device, style: cellStyle },
        jumps: { value: jumps?.[i] || "", style: cellStyle },
        floor: { value: `Floor ${t?.floor}`, style: cellStyle },
        dateOfJumpUp: { value: breakdownDays, style: cellStyle },
        inspection: { value: inspectionNames, style: cellStyle },
      };
    });
  });
};

export const styleOfTable = {
  fontWeight: 400,
  fontSize: 13,
  color: "#323338",
  border: "1px solid #E5E5E5",
  paddingLeft: "5px",
};

export const agGridPropsDefault = {
  animateRows: true,
  enableCellChangeFlash: true,
  suppressDragLeaveHidesColumns: true,
  suppressRowClickSelection: true,
  rowSelection: "multiple",
  defaultColDef: {
    resizable: true,
    enablePivot: true,
    enableColResize: true,
    enableRowGroup: true,
    editable: false,
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
  },
};

export const scheduleDayObject = {
  crews: [],
  fleet: [],
  weather: [],
  image: "",
  color: "",
  status: DayStatus?.Confirmed,
  linkedDays: {
    comesFrom: undefined,
    postponedTo: undefined,
  },
  notes: [],
};

export const servicesColumnDefs = [
  {
    headerName: "Estimation Number",
    field: "estimationNumber",
  },
  {
    headerName: "Change Order",
    field: "isChangeOrder",
  },
  {
    headerName: "Services",
    field: "services",
  },
];

export const inspectionRaport = (schedule = {}, projectAccountName = "") => {
  return [
    {
      value: schedule?.scheduleAddress,
      label: "Project Address",
    },
    {
      value:
        schedule?.projectExecutive?.split(" ")[0]?.charAt(0) +
        schedule?.projectExecutive?.split(" ")[1]?.charAt(0),
      label: "pe/pm",
    },
    {
      value: projectAccountName,
      label: "Client Name",
    },
    {
      value: schedule?.scheduleDays
        ?.sort((a, b) => moment(a?.startDate) - moment(b?.startDate))
        ?.map((a) => moment(a?.startDate).format("MM/DD/YYYY"))?.[0],
      label: "First Date on Site",
    },
  ];
};

export const columnDefsForTransferDays = [
  {
    headerName: "Select",
    checkboxSelection: true,
    headerCheckboxSelection: true,
  },
  {
    headerName: "Day",
    field: "day",
  },
  {
    headerName: "Date",
    field: "startDate",
    cellRendererFramework: ({ value }) => {
      return !!value ? <>{dayjs(value).format("MM/DD/YYYY")}</> : <></>;
    },
  },
];

export const scheduleAnalyticsColumns = ({ setScheduleTeam }) => [
  {
    headerName: "Project details",
    children: [
      {
        headerName: "Project Name",
        field: "projectAddress",
        cellRenderer: "agGroupCellRenderer",
        cellClass: "cell-left-aligned",
      },
      {
        headerName: "Client",
        field: "accountName",
        cellClass: "cell-left-aligned",
      },
      {
        headerName: "Estimates",
        cellClass: "cell-left-aligned",
        field: "services",
        width: 130,
        valueGetter: (params) => {
          return txtForBadgeRibbonMigrate(
            Object.keys(params?.data?.services || {})?.length,
            "Estimates"
          );
        },
      },
      {
        headerName: "Schedules",
        field: "schedules",
        width: 130,
        cellClass: "cell-left-aligned",

        valueGetter: (params) => {
          return txtForBadgeRibbonMigrate(
            params?.data?.schedules?.length,
            "Schedules"
          );
        },
      },
      {
        headerName: "Period",
        field: "schedules",
        cellClass: "cell-left-aligned",

        valueGetter: (params) => {
          const { schedules = [] } = params?.data || {};
          if (!schedules?.length) return "-";
          const allDays = schedules
            ?.flatMap((el) => el?.scheduleDays)
            ?.sort((a, b) => dayjs(a?.startDate) - dayjs(b?.startDate));
          const firstDay = allDays?.[0]?.startDate;
          const lastDay = allDays?.[allDays?.length - 1]?.endDate;
          return `${dayjs(firstDay).format("MM/DD/YYYY")} - ${dayjs(
            lastDay
          ).format("MM/DD/YYYY")}`;
        },
      },
    ],
  },
  {
    headerName: "Team",
    cellClass: "cell-left-aligned",

    children: [
      {
        headerName: "Crews",
        field: "crews",
        width: 100,

        cellRendererFramework: (params) => {
          return <>{params?.value}</>;
        },
        valueGetter: (params) => {
          const { crews = [] } = params?.data || {};
          return !!params?.data ? (
            <div
              onClick={() => {
                if (!crews?.length) {
                  message.error("No crews scheduled for this project!");
                  return;
                }
                message.loading("Opening crews...", 0);
                setTimeout(() => {
                  setScheduleTeam({
                    visible: true,
                    rowData: crews,
                    keys: ["crewName", "crewPosition", "accountName"],
                    modalTitle: `Crews for ${params?.data?.projectAddress}`,
                    msg: `${txtForBadgeRibbonMigrate(
                      crews?.length,
                      "crews"
                    )} scheduled for ${params?.data?.projectAddress}!`,
                  });
                  message.destroy();
                }, 500);
              }}
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                color: crews?.length > 0 ? "#3887be" : "",
                cursor: "pointer",
              }}
            >
              <span>{<UserOutlined />}</span>
              <span>{crews?.length}</span>
            </div>
          ) : (
            <></>
          );
        },
      },
      {
        headerName: "Fleet",
        field: "fleet",
        width: 100,
        cellClass: "cell-left-aligned",

        cellRendererFramework: (params) => {
          return <>{params?.value}</>;
        },
        valueGetter: (params) => {
          const { fleet = [] } = params?.data || {};
          return !!params?.data ? (
            <div
              onClick={() => {
                if (!fleet?.length) {
                  message.error("No fleet scheduled for this project!");
                  return;
                }
                message.loading("Opening fleets...", 0);
                setTimeout(() => {
                  setScheduleTeam({
                    visible: true,
                    rowData: fleet,
                    keys: ["fleetName", "driverName"],
                    modalTitle: `Fleets for ${params?.data?.projectAddress}`,
                    msg: `${txtForBadgeRibbonMigrate(
                      fleet?.length,
                      "fleets"
                    )} scheduled for ${params?.data?.projectAddress}!`,
                  });
                  message.destroy();
                }, 500);
              }}
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                color: fleet?.length > 0 ? "#3887be" : "",
                cursor: "pointer",
              }}
            >
              <span>{<TruckOutlined />}</span>
              <span>{fleet?.length}</span>
            </div>
          ) : (
            <></>
          );
        },
      },
      {
        headerName: "Drivers",
        field: "drivers",
        width: 110,
        cellClass: "cell-left-aligned",

        cellRendererFramework: (params) => {
          return <>{params?.value}</>;
        },
        valueGetter: (params) => {
          const { drivers = [] } = params?.data || {};
          return !!params?.data ? (
            <div
              onClick={() => {
                if (!drivers?.length) {
                  message.error("No drivers scheduled for this project!");
                  return;
                }
                message.loading("Opening drivers...", 0);
                setTimeout(() => {
                  setScheduleTeam({
                    visible: true,
                    rowData: drivers,
                    keys: ["driverName", "fleetName"],
                    modalTitle: `Drivers for ${params?.data?.projectAddress}`,
                    msg: `${txtForBadgeRibbonMigrate(
                      drivers?.length,
                      "drivers"
                    )} scheduled for ${params?.data?.projectAddress}!`,
                  });
                  message.destroy();
                }, 500);
              }}
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                color: drivers?.length > 0 ? "#3887be" : "",
                cursor: "pointer",
              }}
            >
              <span>{<UserOutlined />}</span>
              <span>{drivers?.length}</span>
            </div>
          ) : (
            <></>
          );
        },
      },
    ],
  },
  {
    headerName: "Planning",
    cellClass: "cell-left-aligned",

    children: [
      {
        headerName: "Potential Start Date",
        field: "potentialStartDate",
        valueGetter: (params) => {
          const { services = {} } = params?.data || {};
          return firstServiceDay(Object.values(services).flat());
        },
      },
      {
        headerName: "Days",
        field: "plannedNrOfDays",
        width: 100,
        valueGetter: (params) => {
          const { services = {} } = params?.data || {};
          return calcData(Object.values(services).flat(), "plannedNrOfDays");
        },
      },
      {
        headerName: "Hours",
        field: "plannedNrOfHours",
        width: 100,
        valueGetter: (params) => {
          const { services = {} } = params?.data || {};
          return calcData(Object.values(services).flat(), "plannedNrOfHours");
        },
      },

      {
        headerName: "Crews",
        field: "plannedNrOfCrews",
        width: 100,
        valueGetter: (params) => {
          const { services = {} } = params?.data || {};
          return calcData(Object.values(services).flat(), "plannedNrOfCrews");
        },
      },
      {
        headerName: "Trucks",
        field: "plannedNrOfTrucks",
        width: 100,
        valueGetter: (params) => {
          const { services = {} } = params?.data || {};
          return calcData(Object.values(services).flat(), "plannedNrOfTrucks");
        },
      },
    ],
  },
];

export const columnDefsForHoistJumps = ({
  jumps = [],
  onRemoveJump = () => {},
  set_jumps = () => {},
  availableFloors = [],
  typeOfWork = "",
  isDarkMode,
  elevation,
}) => {
  let columnDefs = [];
  columnDefs = columnDefs.concat(
    {
      headerName: "Jump",
      field: "name",
      cellRendererFramework: (params) => {
        const lastJump = jumps?.[jumps?.length - 1] || {};
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 15,
            }}
          >
            <Badge
              style={{ color: isDarkMode ? "#fff" : "#333238" }}
              text={params?.data?.name}
              color={repeatelem(colors, jumps?.length - 1)?.[params?.rowIndex]}
            />
            {lastJump?.name === params?.data?.name && (
              <span
                onClick={() => {
                  if (progressForJump(params?.data) === 0) {
                    onRemoveJump(params?.data);
                  } else {
                    message.warning("You can't remove a jump with progress!");
                  }
                }}
                style={{
                  color: "#fe4c4a",
                  fontSize: 14,
                  fontWeight: 600,
                  cursor: "pointer",
                }}
              >
                Remove Jump
              </span>
            )}
          </div>
        );
      },
    },
    {
      headerName: "Floors",
      field: "floors",
      cellRendererFramework: (params) => {
        const sortedFloors = params?.data?.floors?.sort(
          (a, b) => a?.floor - b?.floor
        );
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: 5,
            }}
          >
            {sortedFloors?.map?.((floor) => {
              const shouldDeleteFloor =
                jumps?.[jumps?.length - 1]?.name === params?.data?.name &&
                sortedFloors?.[sortedFloors?.length - 1]?.floor ===
                  floor?.floor;
              return (
                <div>
                  <Tooltip title={shouldDeleteFloor ? "Remove floor" : ""}>
                    <Badge
                      count={
                        shouldDeleteFloor ? (
                          <b
                            onClick={() => {
                              if (progressForJump(params?.data) === 0) {
                                const newJumps = jumps?.map((jump) => {
                                  if (jump?.name === params?.data?.name) {
                                    const newFloors = jump?.floors?.filter(
                                      (el) => el?.floor !== floor?.floor
                                    );
                                    if (newFloors?.length > 0) {
                                      jump.floors = newFloors;
                                    } else {
                                      message.warning(
                                        "You can't remove the last floor!"
                                      );
                                      setTimeout(() => {
                                        message.warning(
                                          "Remove jump instead!",
                                          4
                                        );
                                      }, 1500);
                                    }
                                  }
                                  return jump;
                                });
                                set_jumps(newJumps);
                              } else {
                                message.warning(
                                  "You can't remove a floor from a jump that has progress!"
                                );
                              }
                            }}
                            style={{
                              color: "red",
                              cursor: "pointer",
                            }}
                          >
                            X
                          </b>
                        ) : null
                      }
                    >
                      <Button
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: 26,
                          height: 26,
                          borderRadius: 5,
                          border: "none",
                          backgroundColor: "#D5F1C9",
                        }}
                      >
                        {floor?.floor}
                      </Button>
                    </Badge>
                  </Tooltip>
                </div>
              );
            })}
            {jumps?.[jumps?.length - 1]?.name === params?.data?.name && (
              <Popover
                title={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <span>{<InfoIconBlue />}</span>
                    <span>Please select the floor(s) you want to add to</span>
                    <b>{params?.data?.name}</b>
                  </div>
                }
                placement="right"
                trigger={"click"}
                content={
                  <PopupToAddFloorsToJump
                    {...{
                      set_jumps,
                      jumps,
                      jump: params?.data,
                      availableFloors,
                    }}
                  />
                }
              >
                <div>
                  <span
                    style={{
                      fontSize: 35,
                      fontWeight: 600,
                      color: "#71CF48",
                      cursor: "pointer",
                    }}
                  >
                    +
                  </span>
                </div>
              </Popover>
            )}
          </div>
        );
      },
    },
    {
      headerName: "Floor Addons",
      field: "name",
      cellRendererFramework: (params) => {
        const floorsForJump = elevation?.items?.filter((el) =>
          params?.data?.floors?.some((floor) => floor?.floor === el?.floor)
        );
        return (
          <>
            <SelfCheck
              {...{
                elevation: {
                  ...elevation,
                  items: floorsForJump?.map((floor) => ({
                    ...floor,
                    addons: floor?.originalPli?.addons || [],
                    stop: floor?.originalPli?.stop || false,
                    dual: floor?.originalPli?.dual || false,
                  })),
                },
                darkMode: isDarkMode,
              }}
            />
          </>
        );
      },
    }
    // {
    //   headerName: "Jump Progress",
    //   field: "jumpProgress",
    //   cellRendererFramework: (params) => {
    //     console.log("params:", { params: params?.data, typeOfWork, params });
    //     const jumpProgress =
    //       params?.data?.progressByDay?.reduce?.(
    //         (a, { progress = [] }) =>
    //           a + progress?.reduce?.((b, { progress = 0 }) => b + progress, 0),
    //         0
    //       ) / params?.data?.floors?.length;
    //     return <CustomProgressBar progressDone={Math.round(jumpProgress)} />;
    //   },
    // }
  );
  return columnDefs;
};

export const columnDefsForHoistInspection = ({
  scheduleDays,
  setBreakVisible,
  setSpecificBreakdown,
  onDeleteBreakdown,
}) => {
  let columnDefs = [];
  columnDefs = columnDefs.concat(
    {
      headerName: "Breakdown",
      field: "name",
      editable: true,
      valueGetter: (params) => {
        return params?.data?.name || "";
      },
    },
    {
      headerName: "To Be Scheduled",
      field: "progressByDay",
      cellRendererFramework: ({ value = [] }) => {
        return (
          <CustomProgressBar progressDone={100 - sumProgressByDay(value)} />
        );
      },
    },
    {
      headerName: "Days",
      field: "days",
      cellRendererFramework: (params) => {
        let text = `${
          params?.data?.days?.filter((s) =>
            scheduleDays?.some((q) => q?.id === s)
          )?.length
        }`;
        text += (text === "1" ? " day" : " days") + " " + "selected";
        return (
          <Tooltip
            title={
              prevBreakdownCompleted(params?.data)
                ? "Completed Breakdown"
                : null
            }
          >
            <MondayButton
              disabled={prevBreakdownCompleted(params?.data)}
              onClick={() => {
                setBreakVisible(true);
                setSpecificBreakdown(params?.data);
              }}
              {...{
                className: "mondayButtonBlue",
                hasIcon: false,
              }}
            >
              {text}
            </MondayButton>
          </Tooltip>
        );
      },
    },
    {
      headerName: "Action",
      cellRendererFramework: (params) => {
        return (
          <Tooltip
            title={
              prevBreakdownCompleted(params?.data)
                ? "You can't delete this breakdown because it is completed!"
                : `Delete ${params?.data?.name}`
            }
          >
            <MondayButton
              disabled={prevBreakdownCompleted(params?.data)}
              onClick={() => {
                if (prevBreakdownCompleted(params?.data)) {
                  message.error(
                    "You can't delete this breakdown because it is completed!"
                  );
                } else {
                  onDeleteBreakdown(params?.data?.id);
                }
              }}
              {...{
                className: "mondayButtonRed",
                Icon: <XIcon />,
              }}
            >
              Delete breakdown
            </MondayButton>
          </Tooltip>
        );
      },
    }
  );
  return columnDefs;
};

export const columnDefsForProgressHoist = ({
  calcPrg,
  jump_state,
  scheduleDays,
  setIsModalVisible,
  set_jump_state,
  elevation,
  isDarkMode,
}) => {
  let columnDefs = [];
  columnDefs = columnDefs.concat(
    {
      headerName: "Name",
      field: "name",
    },
    {
      headerName: "To Be Scheduled",
      field: "toBeScheduled",
      cellRendererFramework: (params) => {
        return (
          <CustomProgressBar
            progressDone={Math.round(calcPrg > 100 ? 0 : 100 - calcPrg)}
          />
        );
      },
    },
    {
      headerName: "Days",
      field: "days",
      cellRendererFramework: (params) => {
        let text = `${
          jump_state?.days?.filter((s) =>
            scheduleDays?.some((a) => a?.id === s)
          )?.length
        }`;
        text += (text === "1" ? " day" : " days") + " " + "selected";
        return (
          <Popover
            trigger={"click"}
            title="Add days"
            placement="right"
            content={
              <PopupToAddFloorsToJump
                {...{
                  scheduleDays,
                  jump: jump_state,
                  set_jumps: set_jump_state,
                  comesFromProgress: true,
                }}
              />
            }
          >
            <MondayButton
              {...{
                className: "mondayButtonBlue",
                hasIcon: false,
              }}
            >
              {text}
            </MondayButton>
          </Popover>
        );
      },
    },
    {
      headerName: "Progress By Floor",
      field: "floor",
      cellRendererFramework: (params) => {
        return (
          <MondayButton
            disabled={prevJumpCompleted(jump_state)}
            onClick={() => {
              if (
                jump_state?.progressByDay?.filter(
                  (el) => !!scheduleDays?.some((a) => a?.id === el?.day)
                )?.length === 0
              ) {
                message.error("Please select days first!");
                return;
              } else {
                setIsModalVisible(true);
              }
            }}
            {...{
              Icon: <EyeIcon />,
              className: !prevJumpCompleted(jump_state)
                ? "mondayButtonBlue"
                : "mondayButtonGrey",
            }}
          >
            See Floors
          </MondayButton>
        );
      },
    },
    {
      headerName: "Floor Addons",
      field: "name",
      cellRendererFramework: (params) => {
        const floorsForJump = elevation?.items?.filter((el) =>
          params?.data?.floors?.some((floor) => floor?.floor === el?.floor)
        );
        return (
          <>
            <SelfCheck
              {...{
                elevation: {
                  ...elevation,
                  items: floorsForJump?.map((floor) => ({
                    ...floor,
                    addons: floor?.originalPli?.addons || [],
                    stop: floor?.originalPli?.stop || false,
                    dual: floor?.originalPli?.dual || false,
                  })),
                },
                darkMode: isDarkMode,
              }}
            />
          </>
        );
      },
    }
  );
  return columnDefs;
};

export const columnDefsForBreakdowns = ({
  scheduleDays = [],
  daysForSelectedBreakdown = () => {},
  onDeleteBreakdown = () => {},
  applieBreakdown = () => {},
  isDarkMode = false,
}) => {
  let columnDefs = [];
  columnDefs = columnDefs.concat(
    {
      headerName: "Rate",
      field: "rate",
      editable: true,
      valueGetter: (params) => {
        return params?.data?.rate || 0;
      },
    },
    {
      headerName: "Name",
      field: "name",
      editable: true,
      valueGetter: (params) => {
        return params?.data?.name || "";
      },
    },
    {
      headerName: "To Be Scheduled",
      field: "progressByDay",
      cellRendererFramework: ({ value = [] }) => {
        return (
          <CustomProgressBar
            progressDone={(100 - sumProgressByDay(value)).toFixed(2)}
          />
        );
      },
    },
    {
      headerName: "Days",
      field: "days",
      cellRendererFramework: (params) => {
        let text = `${
          params?.data?.days?.filter((s) =>
            scheduleDays?.some((q) => q?.id === s)
          )?.length
        }`;
        text += (text === "1" ? " day" : " days") + " " + "selected";
        return (
          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            <MondayButton
              disabled={prevBreakdownCompleted(params?.data)}
              onClick={() => {
                console.log("paramssss", { params });
                daysForSelectedBreakdown(params?.data);
              }}
              {...{
                className: "mondayButtonBlue",
                hasIcon: false,
              }}
            >
              {text}
            </MondayButton>
            {!!params?.data?.progressByDay?.some((day) => !!day?.foreman) && (
              <CrewsIcon />
            )}
          </div>
        );
      },
    },
    {
      headerName: "Apply",
      field: "isApplied",
      cellRendererFramework: (params) => {
        return (
          <Checkbox
            checked={params?.data?.isApplied}
            onChange={(e) => {
              applieBreakdown(e?.target?.checked, params?.data);
            }}
            disabled={prevBreakdownCompleted(params?.data)}
          >
            <span style={{ color: isDarkMode ? "#fff" : "" }}>
              {params?.data?.isApplied ? "Applied" : "Unapplied"}
            </span>
          </Checkbox>
        );
      },
    },
    {
      headerName: "Action",
      cellRendererFramework: (params) => {
        return (
          <Tooltip
            title={
              prevBreakdownCompleted(params?.data)
                ? "You can't delete this breakdown because it is completed!"
                : `Delete ${params?.data?.name}`
            }
          >
            <MondayButton
              disabled={prevBreakdownCompleted(params?.data)}
              onClick={() => {
                if (prevBreakdownCompleted(params?.data)) {
                  message.error(
                    "You can't delete this breakdown because it is completed!"
                  );
                } else {
                  onDeleteBreakdown(params?.data?.id);
                }
              }}
              {...{
                className: "mondayButtonRed",
                Icon: <XIcon />,
              }}
            >
              Delete breakdown
            </MondayButton>
          </Tooltip>
        );
      },
    }
  );
  return columnDefs;
};

export const columnDefsForShortcutPliDays = (serviceId) => {
  const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);
  let columnDefs = [];
  columnDefs = columnDefs.concat(
    {
      headerName: "Select All",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      cellRendererFramework: (params) => {
        let textToDisplayIfRowIsDisabled = "";
        if (pliEditedFromNextSchedules(params?.data)) {
          textToDisplayIfRowIsDisabled = `Pli is edited in ${params?.data?.editedFrom?.scheduleName}!`;
        } else if (prevPliCompleted(params?.data)) {
          textToDisplayIfRowIsDisabled = "Pli is completed!";
        } else {
          textToDisplayIfRowIsDisabled = "";
        }
        return <>{textToDisplayIfRowIsDisabled}</>;
      },
    },
    {
      headerName: !d2 && d1 === hoist_dimension ? "Floor" : "Pli",
      field: "id",
    }
  );
  if (!d2) {
    columnDefs = columnDefs.concat({
      headerName: upperCaseFirstLetter(d1),
      field: d1,
    });
  } else {
    columnDefs = columnDefs.concat(
      {
        headerName: upperCaseFirstLetter(d1),
        field: d1,
      },
      {
        headerName: upperCaseFirstLetter(d2),
        field: d2,
      }
    );
  }
  columnDefs = columnDefs.concat(
    {
      headerName: "Current Pli Progress (lf)",
      field: "totalProgress",
    },
    {
      headerName: "Remaining Pli Progress (lf)",
      field: "remainingPliProgress",
      cellRendererFramework: (params) => {
        const remainingPr = !d2
          ? params?.data?.[d1] - params?.data?.totalProgress
          : params?.data?.[d2] * params?.data?.[d1] -
            params?.data?.totalProgress;
        return <span>{isNaN(remainingPr) ? 0 : remainingPr}</span>;
      },
    }
  );
  return columnDefs;
};

export const columnDefsForDetailsConflicts = [
  {
    headerName: "Schedule Address",
    field: "scheduleAddress",
    cellRendererFramework: (params) => {
      return (
        <span
          onClick={() =>
            openInNewTab(`/scheduling/${params?.data?.scheduleId}`)
          }
          style={{
            color: "#1264A3",
            cursor: "pointer",
            fontWeight: 600,
          }}
        >
          {params?.data?.scheduleAddress}
        </span>
      );
    },
  },
  {
    headerName: "Schedule Name",
    field: "scheduleName",
  },
  {
    headerName: "Type of Work",
    field: "typeOfWork",
  },
  {
    headerName: "Start Time",
    field: "startTime",
    cellRendererFramework: ({ value = "" }) => {
      return <>{dayjs(value).format("h:mm a")}</>;
    },
  },
  {
    headerName: "End Time",
    field: "endTime",
    cellRendererFramework: ({ value = "" }) => {
      return <>{dayjs(value).format("h:mm a")}</>;
    },
  },
];

export const columnDefsForAddressConflicts = ({ setScheduleInformation }) => {
  return [
    {
      headerName: "Crew Name",
      field: "crewName",
    },
    {
      headerName: "Crew Position",
      field: "crewPosition",
    },
    {
      headerName: "Date",
      field: "date",
    },
    {
      headerName: "Schedule Information",
      field: "scheduleInformation",
      // cellStyle: {
      //   whiteSpace: "normal",
      //   lineHeight: "25px",
      //   textAlign: "left",
      // },
      cellRendererFramework: (params) => {
        return (
          <>
            <Button
              onClick={() =>
                setScheduleInformation({
                  visible: true,
                  data: params?.data,
                })
              }
              style={{
                background: "#1264A3",
                width: 130,
                borderRadius: 5,
                border: "none",
                color: "#fff",
              }}
            >
              See more
            </Button>
          </>
        );
      },
    },
  ];
};

export const scheduleArray = (
  schedule,
  userConfiguration,
  projectAccountName,
  remainingSchedule,
  date
) => {
  return schedule?.flatMap((el, i) => {
    return {
      [el?.typeOfWork]: [
        {
          value: el?.scheduleAddress,
          label: "Project Address",
        },
        {
          value:
            el?.projectExecutive?.split(" ")[0]?.charAt(0) +
            el?.projectExecutive?.split(" ")[1]?.charAt(0),
          label: "pe/pm",
        },
        {
          value: projectAccountName,
          label: "Client Name",
        },
        {
          value:
            date?.[el?.typeOfWork] ||
            moment(
              el?.scheduleDays?.sort(
                (a, b) =>
                  moment(a?.startDate).valueOf() -
                  moment(b?.startDate).valueOf()
              )?.[0]
            ).format("MM/DD/YYYY"),
          label: "First Date on Site",
        },
        {
          value: `${remainingSchedule?.[i]?.progress}`,
          label: `Overall ${remainingSchedule?.[i]?.typeOfWork} %`,
        },
        {
          value: `${100 - remainingSchedule?.[i]?.progress}`,
          label: "Remaining %",
        },
      ],
    };
  });
};

export const columnDaysForDaysPreview = (d1, d2) => {
  let arr = [];
  if (!d2) {
    if (d1 === "hoist_dimension") {
      arr = arr.concat(upperCaseFirstLetter(d1), "Jumps");
    } else {
      arr = arr.concat(upperCaseFirstLetter(d1));
    }
  } else {
    arr = arr.concat(upperCaseFirstLetter(d1), upperCaseFirstLetter(d2));
  }
  return arr.concat(
    "Current Pli Progress",
    "Day progress",
    "Type of progress",
    "Sub Pli",
    "Breakdowns",
    "Remaining Pli Progress"
  );
};
export const tableHeaderForSubPliBreakdowns = (comesFrom = "") => {
  const headers = {
    Breakdowns: ["Name", "Rate", "Is day used here", "Day Progress %"],
    "Jump Breakdowns": ["Name", "Is day used here", "Day Progress %"],
    default: [
      "Id",
      "Total Sub Pli progress lf",
      "Is day used here",
      "Day Progress lf",
    ],
  };

  return headers?.[comesFrom] || headers?.default;
};

export const tableHeaderForJumps = ["Jump", "Floors", "Is Day Used Here"];
export const floorHeaders = ["Floor", "Progress %"];
export const conflictsCrewsHeader = [
  "Schedule Address",
  "Schedule Name",
  "Type Of Work",
  "Start Time",
  "End Time",
];
export const crewConflictsHeader = [
  "Address",
  "Crew Name",
  "Crew Position",
  "Date",
  "Start Time",
  "End Time",
];

export const serviceAddonsColumnDefs = (values = []) => {
  let toReturn = [];
  let allKeys = [];
  const keysToDelete = [
    "name",
    "description",
    "createdTime",
    "id",
    "createdBy",
    "defaultValue",
    "price",
    "Price",
    "ppu",
    "Ppu",
    "formula",
    "totalPrice",
    "unitePrice",
    // "addonType",
  ];
  values?.forEach((el) => {
    allKeys = allKeys.concat(Object.keys(el));
  });
  allKeys = [...new Set(allKeys)];
  allKeys?.forEach((el) => {
    if (!keysToDelete.includes(el)) {
      toReturn.push({
        headerName: upperCaseFirstLetter(el),
        field: el,
      });
    }
  });

  return [
    {
      headerName: "Name",
      field: "name",
    },
    {
      headerName: "Description",
      field: "description",
      valueGetter: (params) => {
        return removeHTMLTags(params?.data?.description) || "";
      },
    },
    ...toReturn,
  ];
};

export const displayServices = (data = {}, isDarkMode) => {
  return Object.entries(data || {})?.flatMap(([key, value], index) => {
    return value?.services?.map((service, serviceIndex) => {
      const { isChangeOrder = false, services: serviceList = [] } = value;
      const cssConditionsForBackground =
        serviceIndex === 0 && serviceList?.length > 1;
      const backgroundColor =
        serviceList?.length > 1 ? "" : serviceIndex % 2 !== 0 ? "#FCFCFC" : "";
      const commonCellStyle = {
        textAlign: "left",
        paddingLeft: 15,
        border: "1px solid #DDE3E5",
        background: cssConditionsForBackground
          ? isDarkMode
            ? "#2c2d33"
            : "#F5F5F5"
          : backgroundColor,
        borderCollapse: "collapse",
        height: 40,
        borderLeft: "none",
        borderRight: "none",
        borderBottom:
          serviceIndex === serviceList?.length - 1
            ? "none"
            : "1px solid #DDE3E5",
      };
      const estimationNumberCell = {
        value:
          serviceIndex === 0 ? (
            <>
              {key !== "Not added!" && !!value?.estimationId ? (
                <Link
                  target="_blank"
                  to={`/estimations/${value?.estimationId}`}
                >
                  {key}
                </Link>
              ) : (
                key
              )}
            </>
          ) : (
            ""
          ),
        style: commonCellStyle,
      };
      const isChangeOrderCell = {
        value: serviceIndex === 0 ? (isChangeOrder ? "Yes" : "No") : "",
        style: commonCellStyle,
      };
      const servicesCell = {
        value: service,
        style: commonCellStyle,
      };

      return {
        estimationNumber: estimationNumberCell,
        isChangeOrder: isChangeOrderCell,
        services: servicesCell,
      };
    });
  });
};

export const labels = [
  {
    name: "Potential Start Date",
    field: "potentialStartDate",
  },
  {
    name: "Total planned days",
    field: "plannedNrOfDays",
  },
  {
    name: "Total planned hours",
    field: "plannedNrOfHours",
  },
  {
    name: "Total number of crews",
    field: "plannedNrOfCrews",
  },
  {
    name: "Total number of trucks",
    field: "plannedNrOfTrucks",
  },
];

export const exportForecastXlSX = async (
  rowData = {},
  newServices = {},
  typeWeEdit = ""
) => {
  const fileName = `${rowData?.projectAddress}.xlsx`;
  const url = "http://10.0.0.237:8080/api/export/xlsx";
  // "https://leadmanager-express-backend.ue.r.appspot.com/api/export/xlsx";

  let srv = { ...newServices };

  srv = Object.entries(srv)?.reduce((prev, [key, value]) => {
    prev[key] = value?.map((a) => ({
      ...a,
      estimatePrice: getTotalServicesPrice(value) || 0,
      servicePrice: getServiceLegacyPrice(a) || 0,
    }));
    return prev;
  }, {});

  const hideLoading = message.loading("Exporting...", 0);

  const forecastData = {
    projectAddress: rowData?.projectAddress,
    services: srv,
    accountName: rowData?.accountName,
    potentialStartDate: firstServiceDay(
      Object.values(newServices).flat(),
      typeWeEdit
    ),
    projectPrice: getTotalServicesPrice(Object.values(newServices).flat()) || 0,
    plannedNrOfDays: calcData(
      Object.values(newServices).flat(),
      typeWeEdit + "plannedNrOfDays"
    ),
    plannedNrOfHours: calcData(
      Object.values(newServices).flat(),
      typeWeEdit + "plannedNrOfHours"
    ),
    plannedNrOfCrews: calcData(
      Object.values(newServices).flat(),
      typeWeEdit + "plannedNrOfCrews"
    ),
    plannedNrOfTrucks: calcData(
      Object.values(newServices).flat(),
      typeWeEdit + "plannedNrOfTrucks"
    ),
  };
  await axios({
    method: "post",
    url,
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
    data: JSON.stringify({
      forecastData,
      apiKey: "a8e06a62-e134-4a75-943e-f3e3a1094e23",
    }),
  })
    .then((response) => {
      hideLoading();
      FileSaver.saveAs(response.data, fileName);
      message.success("Exported successfully!");
    })
    .catch((error) => {
      hideLoading();
      console.log("errorExporti", error);
      message.error("Export failed!");
    });
};

export const noDimensions = (rowData = []) => {
  let toReturn = [];
  rowData?.forEach((el) => {
    Object.entries(onlyEstWithScopeFalse(el?.toBeScheduled || {}))?.forEach(
      ([key, val]) => {
        val?.forEach((q) => {
          q?.serviceOptions?.[0]?.forEach((t) => {
            t?.items?.forEach((c) => {
              if (Object.keys(c).length === 1) {
                toReturn.push({
                  scheduleAddress: el?.scheduleAddress,
                  scheduleName: el?.scheduleName,
                  scheduleId: el?.scheduleId,
                  estimation: key,
                  service: q?.label,
                  pli: c,
                  createdAt: dayjs(el?.createdAt).format("MM/DD/YYYY"),
                });
              }
            });
          });
        });
      }
    );
  });
  return preventDuplicate(toReturn, "scheduleId");
};

export const typeSch = (schedules) => {
  let toReturn = {
    dimensions: [],
    breakdown: [],
    subPli: [],
  };
  schedules?.forEach((a) => {
    Object.values(a?.toBeScheduled || {})
      .flat()
      ?.forEach((q) => {
        q?.serviceOptions?.[0]?.forEach((z) => {
          z?.items?.forEach((q) => {
            if (q?.typeOfProgress === "dimensions") {
              toReturn.dimensions.push(a);
            } else if (q?.typeOfProgress === "breakdown") {
              toReturn.breakdown.push(a);
            } else if (
              q?.typeOfProgress === "subPli" ||
              q?.subPli?.length > 0
            ) {
              toReturn.subPli.push(a);
            }
          });
        });
      });
  });
  toReturn.dimensions = preventDuplicate(toReturn.dimensions, "scheduleId");
  toReturn.breakdown = preventDuplicate(toReturn.breakdown, "scheduleId");
  toReturn.subPli = preventDuplicate(toReturn.subPli, "scheduleId");
  return toReturn;
};

export const findDayByNote = (rowData = [], note = "") => {
  let toReturn = [];
  rowData?.forEach((a) => {
    a?.scheduleDays?.forEach((q) => {
      if (q?.notes?.some((el) => el?.text === note)) {
        toReturn.push({
          scheduleAddress: a?.scheduleAddress,
          scheduleName: a?.scheduleName,
          scheduleId: a?.scheduleId,
          date: dayjs(q?.startDate).format("MM/DD/YYYY"),
          note: note,
        });
      }
    });
  });
  return toReturn;
};

export const txtForEleAddons = (
  elevations = [],
  serviceAddons = [],
  service = ""
) => {
  let txt = "";
  txt = `${elevations?.length} elevations `;
  if (serviceAddons?.length > 0) {
    txt += `and ${serviceAddons?.length} addons`;
  }
  txt += `in total for ${service}`;
  return txt;
};

export const pliLevelSchedules = (schedules = []) => {
  let output = [];
  schedules?.forEach((schedule) => {
    function generateDataObject(schedule, c) {
      const dataObject = {
        crews: [],
        fleets: [],
        drivers: [],
      };

      schedule?.scheduleDays?.forEach((day) => {
        if (c?.days?.includes(day?.id)) {
          const formattedDate = dayjs(day?.startDate).format("MM/DD/YYYY");

          dataObject.crews.push(
            ...(day?.crews?.map((crew) => ({ ...crew, date: formattedDate })) ||
              [])
          );
          dataObject.fleets.push(
            ...(day?.fleet?.map((fleet) => ({
              ...fleet,
              date: formattedDate,
            })) || [])
          );
          dataObject.drivers.push(
            ...(day?.fleet?.map((driver) => ({
              ...driver,
              date: formattedDate,
            })) || [])
          );
        }
      });
      return dataObject;
    }

    let isSameSchedule = true;
    if (isSameSchedule) {
      output.push({
        scheduleName: schedule?.scheduleName,
        period: `${dayjs(schedule?.scheduleDays?.[0]?.startDate).format(
          "MM/DD/YYYY"
        )} - ${dayjs(
          schedule?.scheduleDays?.[schedule?.scheduleDays?.length - 1]
            ?.startDate
        ).format("MM/DD/YYYY")}`,
        scheduleProgress: schedule?.scheduleProgress,
        typeOfWork: schedule?.typeOfWork,
      });
      isSameSchedule = false;
    }
    Object.entries(schedule?.toBeScheduled || {})?.forEach(
      ([key, value], index) => {
        const estimateLabel =
          "Estimate " +
          (value?.[0]?.quickbooksNumber ||
            value?.[0]?.estimationNumber ||
            index + 1);
        value?.forEach((el) => {
          const isHoist = checkIfServiceIsHoist(el);
          const { d1, d2 } = get_ProgressDimensionsByServiceId(el?.serviceId);
          el?.serviceOptions?.[0]?.forEach((q) => {
            q?.items?.forEach((c) => {
              output.push({
                scheduleAddress: schedule?.scheduleAddress,
                estimation: estimateLabel,
                service: el?.label,
                elevation:
                  q?.elevationLabel?.trim() + " - " + q?.elevationId?.trim(),
                pli: !isHoist ? c?.id : c?.floor,
                jump: q?.jumps?.find((a) =>
                  a?.floors?.find((b) => b?.floor === c?.floor)
                )?.name,
                length: c?.originalPli?.length,
                width: c?.originalPli?.width,
                height: c?.originalPli?.height,
                breakdowns: c?.breakdownValue,
                dimension:
                  isHoist ||
                  !c?.typeOfProgress ||
                  c?.typeOfProgress === "breakdown"
                    ? ""
                    : [upperCaseFirstLetter(d1), upperCaseFirstLetter(d2)]
                        .filter(Boolean)
                        .join(" - "),
                usedScheduleDays: !isHoist
                  ? Object.entries(c?.usedScheduleDays || {})?.reduce(
                      (prev, [key, value]) => {
                        if (
                          !schedule?.scheduleDays?.find((el) => el?.id === key)
                        ) {
                          delete c?.usedScheduleDays?.[key];
                        } else
                          prev[key] = {
                            date: dayjs(value?.startDate).format("MM/DD/YYYY"),
                            breakdowns:
                              c?.breakdownValue
                                ?.filter((el) => el?.days?.includes(key))
                                ?.map((el) => el?.name)
                                ?.join(", ") || "-",
                            note:
                              schedule?.scheduleDays
                                ?.find((el) => el?.id === key)
                                ?.notes?.map((el) => el?.text)
                                ?.join(", ") || "-",
                            progressInLf:
                              c?.typeOfProgress === "dimensions" ||
                              c?.typeOfProgress === "subPli" ||
                              c?.subPli?.length > 0
                                ? (c?.progressByDay?.find(
                                    (el) => el?.day === key
                                  )?.progress || 0) + " lf"
                                : "-",
                            progressPercentage:
                              progressInPerc(
                                c?.progressByDay?.find((el) => el?.day === key)
                                  ?.progress || 0,
                                d1,
                                d2,
                                c
                              ) + " %",
                          };
                        return prev;
                      },
                      {}
                    )
                  : c?.days
                      ?.filter((d) =>
                        schedule?.scheduleDays?.some((e) => e?.id === d)
                      )
                      ?.reduce((prev, curr) => {
                        return {
                          ...prev,
                          [curr]: {
                            date: dayjs(
                              schedule?.scheduleDays?.find(
                                (a) => a?.id === curr
                              )?.startDate
                            ).format("MM/DD/YYYY"),
                            breakdowns:
                              c?.breakdownValue
                                ?.filter((el) => el?.days?.includes(key))
                                ?.map((el) => el?.name)
                                ?.join(", ") || "-",
                            note:
                              schedule?.scheduleDays
                                ?.find((a) => a?.id === curr)
                                ?.notes?.map((a) => a?.text)
                                ?.join(", ") || "-",
                            progressInLf: "-",
                            progressPercentage:
                              c?.progressByDay
                                ?.find((a) => a?.day === curr)
                                ?.progress?.find(
                                  (floor) => floor?.floor === c?.floor
                                )?.progress + " %",
                          },
                        };
                      }, {}),
                surface: c?.originalPli?.sqft,
                isHoist,
                scheduledBy:
                  c?.typeOfProgress === "jumpBreakdown"
                    ? "Floor & Breakdown"
                    : c?.typeOfProgress,
                progressInLf: !isHoist ? c?.totalProgress + " lf" : "",
                progressPercentage:
                  progressInPerc(c?.totalProgress, d1, d2, c) + " %",
                ...generateDataObject(schedule, c),
              });
            });
          });
        });
      }
    );
  });
  return output;
};

export const getDataFromScheduleDays = (
  schedules = [],
  item = "",
  keys = [],
  mainKey = ""
) => {
  let output = [];
  schedules?.forEach((schedule) => {
    schedule?.scheduleDays?.forEach((day) => {
      day?.[item]?.forEach((a) => {
        let obj = {};
        keys?.forEach((key) => {
          obj[key] = a?.[key];
        });
        output.push(obj);
      });
    });
  });
  return preventDuplicate(output, mainKey);
};

export const createButtonRenderer =
  (
    dataKey = "",
    icon = <></>,
    errorMessage = "",
    modalConfig = {},
    setScheduleTeam = () => {}
  ) =>
  (params = {}) => {
    const data = params?.data || {};
    const dataList = data?.[dataKey] || [];

    return dataKey in data ? (
      <div
        onClick={() => {
          if (!dataList?.length) {
            message.error(errorMessage);
            return;
          }
          message.loading(`Opening ${dataKey}...`, 0);
          setTimeout(() => {
            setScheduleTeam({
              visible: true,
              rowData: dataList,
              keys: modalConfig?.keys,
              modalTitle: `${upperCaseFirstLetter(dataKey)} for ${
                params?.data?.scheduleAddress
              }`,
              msg: `${txtForBadgeRibbonMigrate(
                dataList?.length,
                dataKey
              )} scheduled for ${params?.data?.estimation} - ${
                params?.data?.service
              } - ${params?.data?.elevation} - ${
                params?.data?.isHoist ? "Floor" : "Pli"
              } ${params?.data?.pli}!`,
            });
            message.destroy();
          }, 500);
        }}
        style={{
          display: "flex",
          alignItems: "center",
          gap: 5,
          color: dataList?.length > 0 ? "#3887be" : "",
          cursor: "pointer",
        }}
      >
        <span>{icon}</span>
        <span>{dataList?.length}</span>
      </div>
    ) : (
      <></>
    );
  };

export const sowScheduleInfo = (toBeScheduled = {}, scheduleDays = []) => {
  const serviceDays = (serviceOptions = []) => {
    const allDays = getDaysForSpecificService(serviceOptions) || [];
    if (!allDays?.length) return "";
    const daysScheduled =
      scheduleDays
        ?.filter((a) => allDays?.includes(a?.id))
        .sort(
          (a, b) =>
            dayjs(a?.startDate).valueOf() - dayjs(b?.startDate).valueOf()
        ) || [];
    if (!daysScheduled?.length) return "";
    console.log("daysScheduled", { daysScheduled, allDays });
    return `${dayjs(daysScheduled?.[0]?.startDate).format(
      "MM/DD/YYYY"
    )} - ${dayjs(daysScheduled?.[daysScheduled?.length - 1]?.endDate).format(
      "MM/DD/YYYY"
    )}`;
  };

  let output = [];
  Object.entries(toBeScheduled || {})?.forEach(([key, value]) => {
    output.push({
      estimationId: key,
      quickbooksNumber:
        value?.[0]?.quickbooksNumber ||
        value?.[0]?.estimationNumber ||
        "Not Added!",
      isChangeOrder: value?.[0]?.isChangeOrder ? "Yes" : "No",
      estimationProgress:
        ProgressStatistics.estimationTotal(value)?.progressPercentage || 0,
      servicesLength: value?.length,
      services: value?.map((sow) => ({
        label: sow?.label,
        serviceProgress: progressForService(sow) || 0,
        isAppliedForProgress: !sow?.isNotAppliedForProgress ? "Yes" : "No",
        serviceDays: serviceDays(sow?.serviceOptions),

        // elevations: sow?.serviceOptions?.[0]?.map((elevation) => ({
        //   elevationName:
        //     elevation?.elevationLabel + " " + elevation?.elevationId,
        //   elevationProgress: 20,
        //   plis: elevation?.items?.map((pli) => ({
        //     id: pli?.id,
        //     pliProgress: 30,
        //   })),
        // })),
      })),
    });
  });
  return output;
};

export const columnDefsForsowScheduleInfo = () => {
  return [
    {
      headerName: "Estimation Quickbooks Number",
      field: "quickbooksNumber",
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        suppressDoubleClickExpand: true,
        innerRenderer: (params) => {
          const { estimationId = 0, quickbooksNumber = "" } = params?.data;
          return quickbooksNumber !== "Not Added!" ? (
            <Link target="_blank" to={`/estimations/${estimationId}`}>
              {quickbooksNumber}
            </Link>
          ) : (
            <>{quickbooksNumber}</>
          );
        },
      },
    },
    {
      headerName: "Change Order",
      field: "isChangeOrder",
    },
    {
      headerName: "Estimation Progress",
      field: "estimationProgress",
      cellRendererFramework: (params) => <>{params?.value}</>,
      valueGetter: (params) => {
        return (
          <ProgressBar percent={params?.data?.estimationProgress} type="line" />
        );
      },
    },
    {
      headerName: "Services",
      field: "servicesLength",
    },
  ];
};

export const detailMasterForSowSchedule = () => {
  return {
    detailGridOptions: {
      rowHeight: 32,
      headerHeight: 32,
      columnDefs: [
        {
          headerName: "Service",
          field: "label",
          // cellRenderer: "agGroupCellRenderer",
        },
        {
          headerName: "Service Progress",
          field: "serviceProgress",
          cellRendererFramework: (params) => <>{params?.value}</>,
          valueGetter: (params) => {
            return (
              <ProgressBar
                percent={params?.data?.serviceProgress}
                type="line"
              />
            );
          },
        },
        { field: "isAppliedForProgress" },
        {
          field: "serviceDays",
        },
      ],
      defaultColDef: {
        resizable: true,
        enablePivot: true,
        enableColResize: true,
        enableRowGroup: true,
        editable: false,
        sortable: true,
        flex: 1,
        filter: true,
        pivot: true,
        enableRowGroup: true,
        enableValue: true,
      },
    },
    getDetailRowData: (params) => {
      params.successCallback(params?.data?.services);
    },
  };
};

export const optionsForUndoProgress = [
  {
    label: "Reset only progress",
    onlyProgress: true,
  },
  {
    label: "Reset all days",
    onlyProgress: false,
  },
];

export const resetProgressJumps = (
  jump = {},
  onlyProgress = false,
  scheduleDays = []
) => {
  const dayIds = scheduleDays?.map((a) => a?.id);
  if (onlyProgress) {
    if (jump?.progressByDay?.length > 0) {
      jump.progressByDay = jump?.progressByDay?.map((day) => {
        if (dayIds?.includes(day?.day) && day?.progress?.length > 0) {
          day.progress = day?.progress?.map((progress) => {
            progress.progress = 0;
            return progress;
          });
        }
        return day;
      });
    }
  }
};

export const resetProgressPli = (
  pli = {},
  d1 = undefined,
  d2 = undefined,
  onlyProgress = false,
  scheduleDays = []
) => {
  const { typeOfProgress = "", usedScheduleDays = {} } = pli || {};

  const daysToUndo = (
    !d2 && d1 === hoist_dimension
      ? pli?.days
      : Object.keys(usedScheduleDays || {})
  )?.filter((day) => scheduleDays?.some((a) => a?.id === day));

  if (!daysToUndo?.length) return;

  if (!onlyProgress) {
    pli.usedScheduleDays = Object.entries(usedScheduleDays || {})?.reduce(
      (prev, [key, value]) => {
        if (!daysToUndo?.includes(key)) {
          delete prev[key];
        }
        return prev;
      },
      {}
    );

    pli.days = pli?.days?.filter((day) => !daysToUndo?.includes(day));
    if (typeOfProgress === "dimensions") {
      pli.progressByDay = pli?.progressByDay?.filter(
        (day) => !daysToUndo?.includes(day?.day)
      );
      pli.totalProgress = pli?.progressByDay?.reduce(
        (prev, curr) => prev + curr?.progress,
        0
      );
    }

    if (typeOfProgress === "breakdown") {
      pli.breakdownValue = pli?.breakdownValue?.map((breakdown) => {
        breakdown.progressByDay = breakdown?.progressByDay?.filter(
          (day) => !daysToUndo?.includes(day?.day)
        );
        breakdown.days = breakdown?.days?.filter(
          (day) => !daysToUndo?.includes(day)
        );
        return breakdown;
      });
    }

    pli.typeOfProgress = !pli?.days?.length ? "" : typeOfProgress;
  } else {
    if (typeOfProgress === "jumpBreakdown") {
      pli.progressByDay = pli?.progressByDay?.map((day) => {
        if (daysToUndo?.includes(day?.day) && day?.progress?.length > 0) {
          day.progress = day?.progress?.map((progress) => {
            progress.progress = 0;
            return progress;
          });
        }
        return day;
      });
      pli.totalProgress = pli?.progressByDay
        ?.flatMap?.((el) => el?.progress)
        ?.reduce?.((a, b) => a + b?.progress, 0);
    }
    if (typeOfProgress === "dimensions") {
      pli.progressByDay = pli?.progressByDay?.map((day) => {
        if (daysToUndo?.includes(day?.day)) {
          day.progress = 0;
          if (!d2) {
            day[d1] = 0;
          } else {
            day[d1] = 0;
            day[d2] = 0;
          }
        }

        return day;
      });
      pli.totalProgress = pli?.progressByDay?.reduce(
        (prev, curr) => prev + curr?.progress,
        0
      );
      return;
    }

    if (typeOfProgress === "breakdown") {
      pli.breakdownValue = pli?.breakdownValue?.map((breakdown) => {
        breakdown.progressByDay = breakdown?.progressByDay?.map((day) => {
          if (daysToUndo?.includes(day?.day)) {
            day.breakdown_dimension = 0;
          }
          return day;
        });

        return breakdown;
      });
      pli.totalProgress = totalProgressForPliBreakdowns(
        pli,
        pli?.breakdownValue,
        "Installation",
        d1,
        d2
      );
    }
    if (typeOfProgress === "subPli" || pli?.subPli?.length > 0) {
      pli.subPli = pli?.subPli?.map((subPli) => {
        subPli.days = subPli?.days?.filter((day) => !daysToUndo?.includes(day));
        subPli.progressByDay = subPli?.progressByDay?.map((day) => {
          if (daysToUndo?.includes(day?.day)) {
            day.progress = 0;
            if (!d2) {
              day[d1] = 0;
            } else {
              day[d1] = 0;
              day[d2] = 0;
            }
          }
          subPli.totalProgress = subPli?.progressByDay?.reduce(
            (prev, curr) => prev + curr?.progress,
            0
          );
          return day;
        });

        return subPli;
      });
      pli.progressByDay = pli?.progressByDay?.map((day) => {
        if (daysToUndo?.includes(day?.day)) {
          day.progress = 0;
          if (!d2) {
            day[d1] = 0;
          } else {
            day[d1] = 0;
            day[d2] = 0;
          }
        }
        return day;
      });

      pli.totalProgress = pli?.progressByDay?.reduce(
        (prev, curr) => prev + curr?.progress,
        0
      );
    }
  }
};

export const resetProgressElevation = (
  items = [],
  d1 = undefined,
  d2 = undefined,
  onlyProgress = false,
  scheduleDays = [],
  jumps = []
) => {
  let newItems = [],
    newJumps = [];
  if (Array.isArray(items) && items?.length > 0) {
    newItems = items?.map((el) => {
      resetProgressPli(el, d1, d2, onlyProgress, scheduleDays);
      return el;
    });
  }

  if (Array.isArray(jumps) && jumps?.length > 0) {
    newJumps = jumps?.map((jump) => {
      resetProgressJumps(jump, onlyProgress, scheduleDays);
      return jump;
    });
  }

  return { newItems, newJumps };
};

export const resetProgressService = (
  service = {},
  onlyProgress = false,
  scheduleDays = []
) => {
  const { d1, d2 } = get_ProgressDimensionsByServiceId(service?.serviceId);
  service?.serviceOptions?.[0]?.forEach((el) => {
    resetProgressElevation(
      el?.items,
      d1,
      d2,
      onlyProgress,
      scheduleDays,
      el?.jumps
    );
  });
};
export const resetProgressEstimation = (
  estimation = [],
  onlyProgress = false,
  scheduleDays = []
) =>
  estimation?.map((service) => {
    resetProgressService(service, onlyProgress, scheduleDays);
    return service;
  });

export const reset = (
  serviceId = "",
  data = undefined,
  type = "",
  onlyProgress = false,
  scheduleDays = [],
  jumps = []
) => {
  const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);

  let FUNCTION = {};

  FUNCTION.resetProgressElevation = () =>
    resetProgressElevation(data, d1, d2, onlyProgress, scheduleDays, jumps);

  FUNCTION.resetProgressService = () =>
    resetProgressService(data, onlyProgress, scheduleDays);

  FUNCTION.resetProgressEstimation = () =>
    resetProgressEstimation(data, onlyProgress, scheduleDays);

  return FUNCTION[type]();
};

// mask numbers after $ sign
export const maskNumbers = (text = "") =>
  text.replace(/\$\d+(,\d+)?(\.\d+)?/g, "#") || "";

export const detailedComparison = (
  scheduleEstimation = [],
  projectEstimation = [],
  serviceDefinitions = [],
  estimationId = ""
) => {
  let output = [];
  projectEstimation?.forEach((service) => {
    cleanService(service);
    const { serviceId, serviceOptions = [], label = "" } = service || {};

    const doesServiceExistInSchedule = scheduleEstimation?.find(
      (el) => el?.serviceId?.toString() === serviceId?.toString()
    );

    if (!doesServiceExistInSchedule) {
      output.push({
        label,
        description: "Service not found in schedule",
        parameters: [service],
        function: "addAnewService",
        category: "New data added",
        estimationId,
        serviceId,
      });
      return;
    }
    serviceOptions?.[0]?.forEach((elevation, elevationIndex) => {
      initializeElevation(
        serviceDefinitions,
        elevation,
        serviceId,
        breakdown_dimension
      );
      const {
        elevationLabel = "",
        elevationId = "",
        items = [],
      } = elevation || {};
      const doesElevationExistInSchedule =
        doesServiceExistInSchedule?.serviceOptions?.[0]?.find(
          (el) =>
            el?.elevationLabel?.trim() === elevationLabel?.trim() &&
            el?.elevationId?.trim() === elevationId?.trim()
        );
      if (!doesElevationExistInSchedule) {
        output.push({
          label,
          elevationLabel,
          elevationId,
          description: "Elevation not found in schedule",
          parameters: [serviceId, elevation],
          function: "addAnewElevation",
          category: "New data added",
          estimationId,
          serviceId,
        });
        return;
      }
      items?.forEach((pli, pliIndex) => {
        const { id = "" } = pli || {};
        const doesPliExistInSchedule =
          doesElevationExistInSchedule?.items?.find(
            (el) => el?.id?.toString() === id?.toString()
          );
        if (!doesPliExistInSchedule) {
          output.push({
            label,
            elevationLabel,
            elevationId,
            pliId: id,
            description: "Pli not found in schedule",
            parameters: [serviceId, elevationIndex, pli],
            function: "addAnewPli",
            category: "New data added",
            estimationId,
            serviceId,
          });
          return;
        } else {
          const dimensions = ["length", "width", "height"];
          dimensions.forEach((dimension) => {
            if (!pli?.[dimension]) return;
            if (
              dimension in pli &&
              pli?.[dimension] !==
                (doesPliExistInSchedule?.originalPli?.[dimension] ||
                  doesPliExistInSchedule?.originalPli?.originalPli?.[dimension])
            ) {
              output.push({
                label,
                elevationLabel,
                elevationId,
                pliId: id,
                dimension,
                mainEstDim: pli?.[dimension],
                scheduleDim:
                  doesPliExistInSchedule?.originalPli?.[dimension] ||
                  doesPliExistInSchedule?.originalPli?.originalPli?.[dimension],
                description: "Dimension mismatch",
                parameters: [
                  serviceId,
                  elevationIndex,
                  pliIndex,
                  dimension,
                  pli?.[dimension],
                ],
                function: "dimensionMatch",
                category: "Dimension mismatch",
                estimationId,
                serviceId,
              });
            }
          });
        }
      });
    });
  });
  scheduleEstimation?.forEach((service) => {
    const { serviceId, serviceOptions, label } = service || {};

    const doesServiceExistInEstimation = projectEstimation?.find(
      (el) => el?.serviceId?.toString() === serviceId?.toString()
    );

    if (!doesServiceExistInEstimation) {
      output.push({
        label,
        description: "Service deleted from estimation",
        parameters: [serviceId],
        function: "removeServiceFromSchedule",
        category: "Data removed",
        estimationId,
        serviceId,
      });
      return;
    }

    serviceOptions?.[0]?.forEach((elevation, elevationIndex) => {
      const {
        elevationLabel = "",
        elevationId = "",
        items = [],
      } = elevation || {};
      const doesElevationExistInEstimation =
        doesServiceExistInEstimation?.serviceOptions?.[0]?.find(
          (el) =>
            el?.elevationLabel?.trim() === elevationLabel?.trim() &&
            el?.elevationId?.trim() === elevationId?.trim()
        );
      if (!doesElevationExistInEstimation) {
        output.push({
          label,
          elevationLabel,
          elevationId,
          serviceId,
          description: "Elevation deleted from estimation",
          parameters: [serviceId, elevationId],
          function: "removeElevationFromSchedule",
          category: "Data removed",
          estimationId,
        });
        return;
      }
      items?.forEach((pli, pliIndex) => {
        const { id = "" } = pli || {};
        const doesPliExistInEstimation =
          doesElevationExistInEstimation?.items?.find(
            (el) => el?.id?.toString() === id?.toString()
          );
        if (!doesPliExistInEstimation) {
          output.push({
            label,
            elevationLabel,
            elevationId,
            pliId: id,
            serviceId,
            description: "Pli deleted from estimation",
            parameters: [serviceId, elevationIndex, id],
            function: "removePliFromSchedule",
            category: "Data removed",
            estimationId,
          });
        }
      });
    });
  });
  return output;
};

export const plisDimensionMisMatch = (pli1 = {}, pli2 = {}) => {
  let dimensions = ["length", "width", "height"];
  let diff = dimensions.filter(
    (dimension) => pli1?.[dimension] !== pli2?.[dimension]
  );
  return diff;
};

export const getStateSizeInMB = (state) => {
  const jsonString = JSON.stringify(state);
  const bytes = new TextEncoder().encode(jsonString).length;
  const megabytes = bytes / (1024 * 1024);
  return megabytes;
};

export const getStateSizeInKB = (state) => {
  const jsonString = JSON.stringify(state);
  const bytes = new TextEncoder().encode(jsonString).length;
  const kilobytes = bytes / 1024;
  return kilobytes;
};

export const postEstimatesInAnotherTable = async (schedules) => {
  if (!schedules || !Array.isArray(schedules)) {
    console.error("Invalid schedules input", { schedules });
    return;
  }

  try {
    await Promise.all(
      schedules.map(async (schedule) => {
        // const fetchEst = await filterTables(
        //   "toBeScheduled",
        //   "scheduleId",
        //   schedule?.scheduleId
        // );

        // if (!fetchEst?.length) {

        //   console.log(
        //     `No estimates found for scheduleId: ${schedule?.scheduleId}`
        //   );
        //   return;
        // }

        const postData = Object.entries(schedule?.toBeScheduled || {}).reduce(
          (acc, [key, value]) => {
            acc.push(
              ...value?.map((service) => ({
                ...service,
                estimationId: key,
                scheduleId: schedule?.scheduleId,
              }))
            );
            return acc;
          },
          []
        );

        const response = await API.post("toBeScheduled", "/toBeScheduled", {
          body: postData,
        });

        // console.log("Post response:", response);

        message.success("Estimates posted successfully!");
      })
    );
  } catch (err) {
    console.error("Error posting estimates:", err);
    message.error(`Error posting estimates!`);
  }
};

export const postEstimateInAnotherTable = async (schedule) => {
  return await API.post("toBeScheduled", "/toBeScheduled", {
    body: Object.entries(schedule?.toBeScheduled || {}).reduce(
      (acc, [key, value]) => {
        acc.push(
          ...value?.map((service) => ({
            ...service,
            estimationId: key,
            scheduleId: schedule?.scheduleId,
          }))
        );
        return acc;
      },
      []
    ),
  })
    .then((res) => {
      message.destroy("estimates");
      message.success("Estimates created successfully");
    })
    .catch((err) => {
      message.error("Error creating To Be Scheduled");
      console.log("Error creating To Be Scheduled: ", err);
    });
};

export const updateNextSchedules = (schedule, toBeScheduled, groupEst) => {
  schedule.toBeScheduled =
    Object.entries(groupEst || {})?.reduce((prev, [key, value]) => {
      const currentToBeScheduled = toBeScheduled?.[key];
      if (!currentToBeScheduled) {
        prev[key] = value;
        return prev;
      }

      const isInTheFuture = dayjs(
        schedule?.scheduleDays?.[0]?.startDate
      ).isSameOrAfter(dayjs());

      const { servicesNotInPrev = [], servicesNotInCurr = [] } =
        currentToBeScheduled?.reduce(
          (acc, service) => {
            if (
              !value?.find(
                (prevService) =>
                  prevService?.serviceId?.toString() ===
                  service?.serviceId?.toString()
              )
            ) {
              acc.servicesNotInPrev.push(service);
            }
            return acc;
          },
          {
            servicesNotInPrev: [],
            servicesNotInCurr: value?.reduce((acc, service) => {
              if (
                !currentToBeScheduled?.find(
                  (prevService) =>
                    prevService?.serviceId?.toString() ===
                    service?.serviceId?.toString()
                )
              ) {
                acc.push(service);
              }
              return acc;
            }, []),
          }
        );

      let services = value || [];

      if (servicesNotInPrev?.length > 0 && isInTheFuture) {
        // message.success("New services were added to the next schedules.");
        services = [
          ...value,
          ...servicesNotInPrev?.map((service) => {
            const { d1, d2 } = get_ProgressDimensionsByServiceId(
              service?.serviceId
            );
            service.serviceOptions = [
              service?.serviceOptions?.[0]?.map((opt) =>
                transformOption(opt, breakdown_dimension, d1, d2)
              ),
            ];
            return service;
          }),
        ];
      }

      if (servicesNotInCurr?.length > 0 && isInTheFuture) {
        // message.success(
        //   "Some services were removed from the next schedules."
        // );
        services = services?.filter(
          (service) =>
            !servicesNotInCurr?.find(
              (prevService) =>
                prevService?.serviceId?.toString() ===
                service?.serviceId?.toString()
            )
        );
      }

      prev[key] = services?.map((el, index) => {
        const { d1, d2 } = get_ProgressDimensionsByServiceId(el?.serviceId);

        const currentService = currentToBeScheduled?.[index];
        if (!currentService) return el;
        let elevations = el?.serviceOptions?.[0] || [];
        const elevationsContent = currentService?.serviceOptions?.[0]?.reduce(
          (acc, service) => {
            if (
              !el?.serviceOptions?.[0]?.find(
                (prevService) =>
                  prevService?.elevationId?.toString() ===
                  service?.elevationId?.toString()
              )
            ) {
              acc.elevationsNotFoundInNextSchs.push(service);
            }
            return acc;
          },
          {
            elevationsNotFoundInNextSchs: [],
            elevationsNotInCurr: el?.serviceOptions?.[0]?.reduce(
              (acc, service) => {
                if (
                  !currentService?.serviceOptions?.[0]?.find(
                    (prevService) =>
                      prevService?.elevationId?.toString() ===
                      service?.elevationId?.toString()
                  )
                ) {
                  acc.push(service);
                }
                return acc;
              },
              []
            ),
          }
        );

        if (
          elevationsContent?.elevationsNotFoundInNextSchs?.length > 0 &&
          isInTheFuture
        ) {
          elevations = [
            ...elevations,
            ...elevationsContent?.elevationsNotFoundInNextSchs?.map((opt) =>
              transformOption(opt, breakdown_dimension, d1, d2)
            ),
          ];
        }

        if (
          elevationsContent?.elevationsNotInCurr?.length > 0 &&
          isInTheFuture
        ) {
          // message.success(
          //   "Some elevations were removed from the next schedules."
          // );
          elevations = elevations?.filter(
            (service) =>
              !elevationsContent?.elevationsNotInCurr?.find(
                (prevService) =>
                  prevService?.elevationId?.toString() ===
                  service?.elevationId?.toString()
              )
          );
        }

        if (elevations?.length > 0) {
          el.serviceOptions = [
            elevations?.map?.((opt, optIndex) => {
              const currentElevation =
                currentService?.serviceOptions?.[0]?.[optIndex];
              if (!currentElevation) return opt;

              const { plisNotFoundInNextSchs = [], plisNotInCurr = [] } =
                currentElevation?.items?.reduce(
                  (acc, pli) => {
                    const foundInOpt = opt?.items?.find(
                      (prevPli) =>
                        prevPli?.id?.toString() === pli?.id?.toString()
                    );

                    if (!foundInOpt) {
                      acc.plisNotFoundInNextSchs.push(pli);
                    }

                    return acc;
                  },
                  {
                    plisNotFoundInNextSchs: [],
                    plisNotInCurr:
                      opt?.items?.filter(
                        (pli) =>
                          !currentElevation?.items?.find(
                            (prevPli) =>
                              prevPli?.id?.toString() === pli?.id?.toString()
                          )
                      ) || [],
                  }
                );

              let plis = opt?.items || [];
              if (plisNotFoundInNextSchs?.length > 0 && isInTheFuture) {
                // message.success(
                //   "New plis were added to the next schedules."
                // );
                plis = [
                  ...plis,
                  ...plisNotFoundInNextSchs?.map((pli) =>
                    processPli(
                      pli,
                      hoist_dimension,
                      JUMP_MAXIMAL_PROGRESS,
                      el,
                      el?.serviceId
                    )
                  ),
                ];
              }

              if (plisNotInCurr?.length > 0 && isInTheFuture) {
                // message.success(
                //   "Some plis were removed from the next schedules."
                // );
                plis = plis?.filter(
                  (pli) =>
                    !plisNotInCurr?.find(
                      (prevPli) =>
                        prevPli?.id?.toString() === pli?.id?.toString()
                    )
                );
              }

              if (checkIfServiceIsHoist(el)) {
                opt.jumps = opt?.jumps?.map((jump, jumpIndex) => {
                  if (
                    compareJumps({
                      curr: currentElevation?.jumps?.[jumpIndex],
                      prev: jump,
                    })
                  ) {
                    if (
                      pliEditedFromNextSchedules(
                        currentElevation?.jumps?.[jumpIndex]
                      )
                    )
                      jump = jump;
                    else
                      jump = {
                        ...currentElevation?.jumps?.[jumpIndex],
                        isJumpCompletedInThePreviousSchedule:
                          calculateIsJumpCompleted(
                            currentElevation?.jumps?.[jumpIndex]?.floors,
                            currentElevation?.items
                          ),
                      };
                  }
                  return jump;
                });
              }
              opt.items = plis?.map((pli, pliIndex) => {
                let currentPli = currentElevation?.items?.[pliIndex];
                let differentDimensions =
                  plisDimensionMisMatch(pli, currentPli) || [];
                if (
                  comparePlis({
                    curr: currentPli,
                    prev: pli,
                  })
                ) {
                  if (pliEditedFromNextSchedules(currentPli)) {
                    pli = pli;
                  } else {
                    pli = {
                      ...currentPli,
                      isPliCompletedInThePreviousSchedule: {
                        completed: calculateIsSubPliCompleted(
                          currentPli,
                          d1,
                          d2
                        ),
                        prevDays: [
                          ...new Set([
                            ...(currentPli?.isPliCompletedInThePreviousSchedule
                              ?.prevDays || []),
                            ...(currentPli?.days || []),
                          ]),
                        ],
                      },
                      breakdownValue: currentPli?.breakdownValue?.map((a) => {
                        a.isBreakdownCompletedInThePreviousSchedule =
                          calculateIsBreakdownCompleted(a, breakdown_dimension);
                        return a;
                      }),
                      subPli: currentPli?.subPli?.map((sub) => {
                        sub.isSubPliCompletedInThePreviousSchedule =
                          calculateIsSubPliCompleted(sub, d1, d2);

                        return sub;
                      }),
                    };
                  }
                }
                if (differentDimensions?.length > 0 && isInTheFuture) {
                  // message.success(
                  //   "Some dimensions were changed in the next schedules."
                  // );
                  differentDimensions?.forEach((dim) => {
                    pli[dim] = currentPli?.[dim];
                    pli.originalPli[dim] = currentPli?.[dim];
                  });
                }
                return pli;
              });
              opt.breakdownValue = currentElevation?.breakdownValue?.map(
                (a) => {
                  a.isBreakdownCompletedInThePreviousSchedule =
                    calculateIsBreakdownCompleted(a, breakdown_dimension);
                  return a;
                }
              );
              return opt;
            }),
          ];
        }
        if (el?.serviceAddons?.length > 0) {
          el.serviceAddons = el?.serviceAddons?.map((addon, addonIndex) => {
            const currentAddon = currentService?.serviceAddons?.[addonIndex];
            if (
              compareAddons({
                curr: currentAddon,
                prev: addon,
              })
            ) {
              addon = currentAddon;
            }
            return addon;
          });
        }
        return el;
      });
      return prev;
    }, {}) || {};
};

export const updatePrevSchedules = (
  schedule,
  toBeScheduled,
  groupEst,
  schId,
  scheduleName
) => {
  schedule.toBeScheduled =
    Object.entries(groupEst || {})?.reduce((prev, [key, value]) => {
      const currentToBeScheduled = toBeScheduled?.[key];
      if (!currentToBeScheduled) {
        prev[key] = value;
        return prev;
      }

      prev[key] = value?.map((el, index) => {
        const currentService = currentToBeScheduled?.[index];
        if (!currentService) return el;
        if (el?.serviceOptions?.[0]?.length > 0) {
          el.serviceOptions = [
            el?.serviceOptions?.[0]?.map((opt, optIndex) => {
              const currentElevation =
                currentService?.serviceOptions?.[0]?.[optIndex];
              if (checkIfServiceIsHoist(el)) {
                opt.jumps = opt?.jumps?.map((jump, jumpIndex) => {
                  const currentJump = currentElevation?.jumps?.[jumpIndex];
                  if (pliEditedFromNextSchedules(jump)) {
                    if (
                      jumpDidNotChange({
                        curr: currentJump,
                        prev: jump,
                      })
                    ) {
                      delete jump.editedFrom;
                    }
                  } else if (
                    compareJumps({
                      curr: currentJump,
                      prev: jump,
                    })
                  ) {
                    jump.editedFrom = {
                      scheduleId: schId || "",
                      scheduleName: scheduleName || "",
                    };
                  }
                  return jump;
                });
              }
              opt.items = opt?.items?.map((pli, pliIndex) => {
                const currentPli = currentElevation?.items?.[pliIndex];
                if (pliEditedFromNextSchedules(pli)) {
                  if (
                    pliDidNotChange({
                      curr: currentPli,
                      prev: pli,
                    })
                  ) {
                    delete pli.editedFrom;
                  }
                } else if (
                  comparePlis({
                    curr: currentPli,
                    prev: pli,
                  })
                ) {
                  pli.editedFrom = {
                    scheduleId: schId || "",
                    scheduleName: scheduleName || "",
                  };
                  pli.usedScheduleDays = currentPli?.usedScheduleDays || {};
                }
                return pli;
              });
              return opt;
            }),
          ];
        }
        if (el?.serviceAddons?.length > 0) {
          el.serviceAddons = el?.serviceAddons?.map((addon, addonIndex) => {
            const currentAddon = currentService?.serviceAddons?.[addonIndex];
            if (pliEditedFromNextSchedules(addon)) {
              if (addonDidNotChange({ curr: currentAddon, prev: addon })) {
                delete addon.editedFrom;
              }
            } else if (compareAddons({ curr: currentAddon, prev: addon })) {
              addon.editedFrom = {
                scheduleId: schedule?.scheduleId || "",
                scheduleName: schedule?.scheduleName || "",
              };
            }
            return addon;
          });
        }
        return el;
      });
      return prev;
    }, {}) || {};
};

export const columnDefsForPliData = ({
  d1 = "",
  d2 = "",
  hasUploaded = () => {},
}) => {
  let toReturn = [];
  if (!d2) {
    toReturn.push({
      headerName:
        d1 === hoist_dimension ? "Floor" : d1.toUpperCase().slice(0, 1),
      field: d1 === hoist_dimension ? "floor" : d1,
      flex: 2,
    });
  } else {
    toReturn.push(
      {
        headerName: d1.toUpperCase().slice(0, 1),
        field: d1,
        flex: 2,
      },
      {
        headerName: d2.toUpperCase().slice(0, 1),
        field: d2,
        flex: 2,
      },
      {
        headerName: "Lnf.",
        field: "surface",
        flex: 3,
      }
    );
  }
  toReturn.push(
    {
      headerName: `${d1 === hoist_dimension ? "Floor" : "Pli"} Progress %`,
      field: "totalProgress",
      flex: 5,
      cellRendererFramework: (params) => (
        <div
          style={{
            display: "flex",
            gap: 5,
            alignItems: "center",
            width: "100%",
          }}
        >
          <ProgressBar percent={params?.value} />
          {hasUploaded(params?.data) && <CameraIcon />}
        </div>
      ),
      valueGetter: (params) => {
        if (!!params) {
          return (
            progressInPerc(
              params?.data?.progressByDay?.[0]?.foreman
                ? !d2
                  ? params?.data?.progressByDay?.[0]?.foreman?.[d1]
                  : params?.data?.progressByDay?.[0]?.foreman?.[d1] *
                    params?.data?.progressByDay?.[0]?.foreman?.[d2]
                : params?.data?.totalProgress,
              d1,
              d2,
              params?.data
            ) || 0
          );
        } else {
          return 0;
        }
      },
    },
    d1 !== hoist_dimension && {
      headerName: "Sqf",
      field: "totalProgress",
      flex: 3,
    }
  );
  return toReturn.filter(Boolean);
};

export const columnDefsForProgressTracking = (
  view = "",
  setViewCrews = () => {}
) =>
  [
    {
      field: "startDate",
      cellRendererFramework: (params) => <>{params?.value}</>,
      valueGetter: (params) => {
        return !!params?.data
          ? dayjs(params?.data?.startDate).format("MM/DD/YYYY")
          : "";
      },
    },
    {
      headerName: "Crew Members",
      field: "crews",
      cellStyle: {
        alignItems: "center",
      },
      cellRendererFramework: (params) => {
        const crews = params?.data?.crews || {};
        return !Array.isArray(crews) ? (
          Object.keys(crews).length > 0 ? (
            <MondayButton
              onClick={() => setViewCrews({ visible: true, crews })}
              {...{
                className: "mondayButtonBlue",
                Icon: <CrewsIcon />,
              }}
            >
              View Crews
            </MondayButton>
          ) : (
            <></>
          )
        ) : (
          <></>
        );
      },
    },
    view !== "forSchedule" && {
      field: "scheduleAddress",
    },
    view !== "forSchedule" && {
      field: "scheduleName",
    },
    view !== "forSchedule" && {
      field: "typeOfWork",
    },
    {
      field: "estimation",
      width: 140,
    },
    {
      field: "serviceName",
    },
    {
      field: "elevation",
    },
    {
      field: "pli",
      width: 75,
    },
    { field: "length", width: 105 },
    { field: "height", width: 105 },
    { field: "width", width: 105 },
    { field: "surface", width: 115 },
    { field: "totalPli", width: 115 },

    {
      headerName: "Pli Progress (lf)",
      field: "pliProgress",
      width: 155,
    },
    // {
    //   headerName: "Pli Progress (%)",
    //   field: "pliProgressPercentage",
    //   cellRendererFramework: ({ value }) => <>{value}</>,
    //   valueGetter: (params) => params.value || 0,

    //   width: 140,
    // },
    {
      field: "pliRemainingProgress",
      width: 250,
    },
    {
      field: "typeOfProgress",
    },
    {
      field: "dayProgress",
      width: 170,
    },
    {
      field: "user",
      width: 125,
    },
    {
      field: "progressTimeGiven",
      width: 215,
      cellRendererFramework: (params) => <>{params?.value}</>,
      valueGetter: (params) => {
        return !!params?.data
          ? new Date(params?.data?.progressTimeGiven).toLocaleString()
          : "";
      },
    },
  ].filter(Boolean);

export const progressFilterFields = () => [
  { label: "Date", field: "startDate", type: "datepicker" },
  {
    label: "Schedule Address",
    field: "scheduleAddress",
    type: "select",
  },
  {
    label: "Schedule Name",
    field: "scheduleName",
    type: "select",
  },
  {
    label: "Type of Work",
    field: "typeOfWork",
    type: "select",
  },
  {
    label: "Crew Members",
    field: "crews",
    type: "select",
  },
  {
    label: "Estimation",
    field: "estimation",
    type: "select",
  },
  {
    label: "Service",
    field: "serviceName",
    type: "select",
  },
  {
    label: "Elevation",
    field: "elevation",
    type: "select",
  },
  {
    label: "Pli",
    field: "pli",
    type: "select",
  },
  {
    label: "Length",
    field: "length",
    type: "select",
  },
  {
    label: "Height",
    field: "height",
    type: "select",
  },
  {
    label: "Width",
    field: "width",
    type: "select",
  },
  {
    label: "Surface",
    field: "surface",
    type: "select",
  },
  {
    label: "Jump",
    field: "jump",
    type: "select",
  },
  {
    label: "Floor",
    field: "floor",
    type: "select",
  },
  {
    label: "Pli Progress",
    field: "pliProgress",
    type: "select",
  },
  // {
  //   label: "Uncompleted Pli",
  //   field: "uncompletedPli",
  //   type: "select",
  // },
  {
    label: "Files",
    field: "files",
    type: "select",
  },
  {
    label: "Type of progress",
    field: "typeOfProgress",
    type: "select",
  },
  {
    label: "Breakdown",
    field: "breakdown",
    type: "select",
  },
  {
    label: "User",
    field: "user",
    type: "select",
  },

  {
    label: "Progress Time Given",
    field: "progressTimeGiven",
    type: "datepicker",
  },
];

export const convertCamelCaseToTitleCase = (camelCaseString = "") => {
  const titleCaseString = camelCaseString
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());

  return titleCaseString;
};
