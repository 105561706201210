import { API } from "aws-amplify";

/**
 * Function used to create multiple records at once
 * supports up to 100 new records.
 *
 * ======= IMPORTANT =======
 * You need to give the id manually in the body
 * of the new item you want to create
 *
 * @typedef BatchPostParam
 * @property {string} tableName
 * @property {Record<string, any>[]} items
 *
 * @param {BatchPostParam} param
 */
async function bathPost({ items, tableName }) {
  let response = [];
  let ExclusiveStartKey = undefined;

  do {
    await API.post("batchPost", "/batchPost", {
      body: {
        items,
        tableName,
      },
    }).then(({ items: resultItems, LastEvaluatedKey }) => {
      response = response.concat(resultItems);
      ExclusiveStartKey = LastEvaluatedKey;
    });
  } while (ExclusiveStartKey);

  return response;
}

export default bathPost;
