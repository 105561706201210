import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useReducer,
  useContext,
  useCallback,
} from "react";
import { uniq } from "lodash";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import { Modal, message, Form, notification } from "antd";

import {
  DegStep,
  UploadStep,
  OverviewStep,
  DegShiftStep,
} from "./modalComponents";
import {
  FILTER_REDUCER,
  ENTRIES_REDUCER,
  createEntryLogs,
  ANALYTICS_REDUCER,
  getShiftAnalytics,
  getNotFoundEmployees,
  getEmployeeAnalytics,
  distributeNoJobsiteCost,
} from "./modalComponents/utils";
import {
  Stepper,
  MondayButton,
  WarningModal,
  MultiLevelTreeLogs,
} from "../../../../../commonComponents";
import ACTIONS_REGISTER_REDUCER, {
  ACTIONS_REGISTER_INIT,
} from "./modalComponents/utils/actionsRegister";
import {
  DeleteIcon,
  WarningTriangle,
} from "../../../../../SidebarPages/DynamicView/src";
import PayrollContext from "../../../PayrollContext";
import {
  FILTER_INIT,
  REDUCER_INIT,
  CHANGED_ENTRIES_INIT,
} from "./modalComponents/utils/reducerInitialStates";
import { useEditLogs } from "../../../../../../hooks";
import { addDataToArray } from "./modalComponents/utils";
import DegModalContext from "./modalComponents/DegModalContext";
import { OVERHEAD_ENTRY_TYPES, footerButtons } from "./degModalData";
import { keysToValidate } from "./modalComponents/utils/cellFunctions";
import { DEG_DATE_FORMAT } from "./modalComponents/utils/cellFunctions";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { preferences as setPreferences } from "../../../../../../actions";
import { TickIcon } from "../../../../Settings/settingsComponents/Roles/src";
import { lazyFetch, fetchByList, fetchAllData } from "../../../../../../utils";
import { withinRadius } from "../../Activity/components/payrollActivityModalData";
import { getChangedData } from "src/components/SidebarPages/Accounting/components/utilities";
import {
  parseInTz,
  setHourMinute,
} from "../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import broadcastNotification from "../../../../../../helpers/controllers/broadcastNotification";
import { camelCaseToTitleCase } from "../../../../Settings/settingsComponents/ProjectsOverviewConfig/utils";

import "./DegModal.scss";

const Steps = {
  0: UploadStep,
  1: DegStep,
  2: DegShiftStep,
  3: OverviewStep,
};

let shiftDegFilterObj = {
  payrollType: [],
  shiftStatus: [],
  employeeRole: [],
  selectedJobsites: [],
  selectedEmployees: [],
  timeFrame: undefined,
};

function DegModal({
  open,
  onCancel,
  editMode,
  rowToEdit,
  refreshTable,
  degStepFilters,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { preferences } = useSelector((state) => state.preferences);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const allServiceOptions = useSelector((state) => state.serviceDefinitions);

  const { degPayrolls, crewTeams } = useContext(PayrollContext);

  const [crews, setCrews] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [foremans, setForemans] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [jobsites, setJobsites] = useState([]);
  const [notFound, setNotFound] = useState({});
  const [editLogs, setEditLogs] = useState([]);
  const [emptyData, setEmptyData] = useState([]);
  const [degGridApi, setDegGridApi] = useState();
  const [uploading, setUploading] = useState(false);
  const [entriesLogs, setEntriesLogs] = useState({});
  const [degColumnApi, setDegColumnApi] = useState();
  const [shiftsGridApi, setShiftsGridApi] = useState();
  const [jobsiteServices, setJobsiteServices] = useState({});
  const [warningVisible, setWarningVisible] = useState(false);
  const [scheduleMatching, setScheduleMatching] = useState({});
  const [logsModalVisible, setLogsModalVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(rowToEdit ? 1 : 0);
  const [modalStatusVisible, setModalStatusVisible] = useState(false);
  const [filtersFromSelection, setFiltersFromSelection] = useState({});
  const [degStatus, setDegStatus] = useState(rowToEdit?.degStatus || "Draft");
  const [defaultServicesPerProject, setDefaultServicesPerProject] = useState(
    {}
  );
  const [shiftExternalFilter, updateShiftExternalFilter] =
    useState(shiftDegFilterObj);
  const [modalSteps] = useState([
    { title: "Upload", disabled: true },
    { title: "DEG", disabled: true },
    { title: "Shifts", disabled: true },
    { title: "Overview", disabled: true },
  ]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { saveAddedLogs } = useEditLogs();

  const saveButton = useRef();
  const initialRowData = useRef([]);
  const stopUploadingButton = useRef();

  const [analytics, analyticsUpdate] = useReducer(
    ANALYTICS_REDUCER,
    REDUCER_INIT
  );

  const [warningFilters, filtersDispatch] = useReducer(
    FILTER_REDUCER,
    FILTER_INIT
  );

  const [entriesActions, addEntryAction] = useReducer(
    ENTRIES_REDUCER,
    CHANGED_ENTRIES_INIT
  );

  const [actionsRegister, addActionToRegister] = useReducer(
    ACTIONS_REGISTER_REDUCER,
    ACTIONS_REGISTER_INIT
  );

  const formDegName = Form.useWatch("degName", form);
  const accountName = Form.useWatch("accountName", form);

  const deleteAccessRight = useMemo(() => {
    const routeConfig = userConfiguration?.routeConfig || [];
    let projectCostIndex = routeConfig.findIndex(
      ({ title }) => title === "Project Cost"
    );

    let activityIndex = routeConfig[projectCostIndex]?.children.findIndex(
      ({ title }) => title === "Activity"
    );

    let deleteAccessIndex = routeConfig[projectCostIndex].children[
      activityIndex
    ].children.findIndex(({ title }) => title === "Delete");

    return routeConfig[projectCostIndex].children[activityIndex].children[
      deleteAccessIndex
    ]?.write;
  }, [userConfiguration]);

  const degName = formDegName
    ? formDegName
    : rowToEdit?.degName
      ? rowToEdit?.degName
      : analytics?.dateRange?.[0]
        ? `Week(${dayjsNY(analytics?.dateRange?.[0]).week()}) (${dayjsNY(
          analytics?.dateRange?.[0]
        ).format(DEG_DATE_FORMAT)} - ${dayjsNY(analytics?.dateRange?.[1]).format(
          DEG_DATE_FORMAT
        )})`
        : "New Deg";

  const isLastStep = currentStep === 3;

  const newEditLog = {
    recordId: rowToEdit?.degId,
    recordName: rowToEdit?.degName,
    category: "DEG Logs",
    actionType: "Edit",
    currentData: {},
    previousData: {},
  };

  /**
   * @param {boolean} show
   */
  function toggleNotifications(show) {
    const hideNotificationsPref = {
      ...preferences,
      preferences: {
        ...preferences?.preferences,
        notifications: {
          ...preferences?.preferences.notifications,
          allowNotifications: show,
        },
      },
    };
    dispatch(setPreferences(hideNotificationsPref));
  }

  async function getEntryLogs(entryId) {
    message.loading({
      key: "getEntryLogs",
      content: "Getting logs...",
      duration: 5,
    });
    try {
      const logs = await fetchAllData({
        endpoint: "editLogs",
        resultPosition: "editLogs",
        resultId: "logId",
        otherStringParams: {
          getMaxLimit: "true",
          filters: JSON.stringify([
            {
              conditions: [
                {
                  column: "recordId",
                  value: entryId,
                  formula: "is",
                },
              ],
            },
          ]),
        },
      });
      setEntriesLogs((prev) => ({ ...prev, [entryId]: logs }));
      message.success({
        key: "getEntryLogs",
        content: "Logs fetched successfully!",
        duration: 1.5,
      });
    } catch (err) {
      console.log("Error getting entryLogs: ", err);
      message.error({
        key: "getEntryLogs",
        content: "There was a problem getting Logs for this entry",
        duration: 1.5,
      });
    }
  }

  async function getEntriesInEdit(rowToEdit) {
    let tmpData = [];
    const tableName = "degEntries";
    // rowToEdit?.degStatus === "Completed" ? "costAnalytics" : "degEntries";
    const appliedFilter = [
      {
        conditions: [
          {
            column: "degId",
            columnType: "string",
            dataType: "string",
            formula: "is",
            id: uuidv4(),
            operator: "AND",
            value: rowToEdit?.degId,
          },
        ],
        id: uuidv4(),
        operator: "AND",
      },
    ];

    try {
      const res = await API.get(tableName, `/${tableName}`, {
        queryStringParameters: {
          ExclusiveStartKey: undefined,
          withPagination: "true",
          getMaxLimit: "true",
          filters: JSON.stringify(appliedFilter),
        },
      });

      tmpData = tmpData.concat(res?.[tableName] || res || []);
    } catch (error) {
      message.error(
        `There was a problem getting ${camelCaseToTitleCase(tableName)}`
      );
      console.log("error: ", error);
    }

    return tmpData;
  }

  // #region Fetch initial data
  useEffect(() => {
    const originalNotification = { ...notification };

    Object.assign(notification, {
      open: function () {},
      info: function () {},
      error: function () {},
      config: function () {},
      success: function () {},
    });

    Promise.allSettled([
      lazyFetch({
        tableName: "accounts",
        listOfKeys: ["accountName", "accountId", "accountRecordType"],
        filterKey: "accountRecordType",
        filterValue: "Subcontractors",
      }),
      API.get("crews", "/crews"),
      API.get("jobsites", "/jobsites"),
      rowToEdit?.degId &&
        fetchAllData({
          endpoint: "editLogs",
          resultPosition: "editLogs",
          resultId: "logId",
          otherStringParams: {
            getMaxLimit: "true",
            filters: JSON.stringify([
              {
                conditions: [
                  {
                    column: "recordId",
                    value: rowToEdit?.degId,
                    formula: "is",
                  },
                ],
              },
            ]),
          },
        }),
    ])
      .then(
        ([
          { value: accountsRes },
          { value: crewsRes },
          { value: jobsitesRes },
          { value: editLogsRes },
        ]) => {
          setAccounts(accountsRes);
          setForemans(crewsRes.filter(({ members }) => !!members?.length));
          setCrews(
            crewsRes.filter(
              (crew) => !!crew?.employeeId && crew?.crewStatus === "Active"
            )
          );
          setJobsites(
            jobsitesRes.map((jobsite) => {
              if (Array.isArray(jobsite?.services)) {
                return jobsite;
              } else {
                return { ...jobsite, services: [] };
              }
            })
          );
          setEditLogs(editLogsRes || []);
        }
      )
      .catch((err) => {
        console.log("Could not get data: ", err);
      });

    function unloadHandler(e) {
      e.preventDefault();
      e.returnValue = "";
    }
    toggleNotifications(false);

    window.addEventListener("beforeunload", unloadHandler);

    return () => {
      window.removeEventListener("beforeunload", unloadHandler);
      Object.assign(notification, originalNotification);
    };
  }, []);

  //#region Get services from schedules or projects that are connected with the jobsites in this payroll and checks for empty data
  useEffect(() => {
    let tmpList = [];
    let jobsiteIdsIncluded = [];

    for (const key in rowData) {
      let tmpRow = rowData?.[key];
      let condition = false;

      // Get all unique jobsiteId-s included in payroll rowData
      if (
        !jobsiteIdsIncluded.includes(tmpRow?.jobsiteId) &&
        !!tmpRow?.jobsiteId
      ) {
        jobsiteIdsIncluded.push(
          tmpRow?.jobsiteId ? tmpRow?.jobsiteId : tmpRow?.jobSiteId
        );
      }

      if (tmpRow?.["punchType"] !== "HR") {
        for (const entry of keysToValidate) {
          if (typeof tmpRow?.[entry] === "string") {
            tmpRow[entry] = tmpRow?.[entry].trim();
          }

          if (!tmpRow?.[entry]) {
            condition = true;
            break;
          }
        }
        if (condition) {
          tmpList.push(tmpRow?.["entryId"]);
        }
      }
    }

    if (
      !!jobsites?.length &&
      !!rowData?.length &&
      !Object.keys(jobsiteServices)?.length
    ) {
      // Object that will store jobsite connection with projects
      // where projectId will be the key and jobsiteId will be the value {[projectId]: jobsiteId}
      const projectsIncluded = {};
      const tmpJobsiteServices = Object.keys(jobsiteServices);

      // for each jobsite included in payroll get projectId of that jobsite and add to the projectsIncluded Object
      for (let jobsite of jobsites) {
        if (
          jobsiteIdsIncluded.includes(jobsite?.jobsiteId) &&
          !tmpJobsiteServices.includes(jobsite?.jobsiteId) &&
          !!jobsite?.projectId
        ) {
          Object.assign(projectsIncluded, {
            [jobsite?.projectId]: jobsite?.jobsiteId,
          });
        }
      }

      message.loading({
        content: "Getting Services for all Job Sites...",
        duration: 8,
        key: "fetchByList",
      });

      // Get all schedules that are connected to the projects of jobsites included in this payroll
      fetchByList("scheduling", "projectId", Object.keys(projectsIncluded))
        .then((schedules) => {
          if (!schedules?.length) {
            return;
          }
          message.success({
            content: "Services fetched successfully!",
            duration: 1,
            key: "fetchByList",
          });

          // eslint-disable-next-line no-undef
          const jobsiteServicesInstance = structuredClone(jobsiteServices);
          // eslint-disable-next-line no-undef
          const scheduleMatchInstance = structuredClone(scheduleMatching);
          for (const schedule of schedules) {
            // jobsiteId that will connect to this schedule
            let scheduleJobsiteId = projectsIncluded[schedule?.projectId];

            // map schedule per jobsiteId match
            Object.assign(scheduleMatchInstance, {
              [scheduleJobsiteId]: schedule,
            });

            let dateRange = [];
            let scheduleServices = [];
            let estimationNumber;
            const { scheduleDays, toBeScheduled } = schedule;

            // Get time range of this schedule
            for (let day of scheduleDays) {
              dateRange.push(dayjsNY(day?.startDate).valueOf());
              dateRange.push(dayjsNY(day?.endDate).valueOf());
            }

            // get services included in this schedule and estimation number
            for (let estimation in toBeScheduled) {
              scheduleServices = scheduleServices.concat(
                toBeScheduled[estimation]?.map(({ label }) => label)
              );
              estimationNumber =
                toBeScheduled[estimation]?.[0]?.estimationNumber;
            }

            dateRange = [Math.min(...dateRange), Math.max(...dateRange)];
            const scheduleServicesData = {
              dateRange,
              scheduleServices,
              estimationNumber,
            };
            // for this jobsite id add the schedule services for the dateRange of the schedule
            Object.assign(jobsiteServicesInstance, {
              [scheduleJobsiteId]: (
                jobsiteServicesInstance?.[scheduleJobsiteId] || []
              ).concat(scheduleServicesData),
            });
          }

          setJobsiteServices(jobsiteServicesInstance);
          setScheduleMatching(scheduleMatchInstance);
        })
        .catch((err) => {
          console.log("err: ", err);
          message.destroy();
        });

      // Get services per project for all projects included in this payroll
      fetchByList("projects", "projectId", Object.keys(projectsIncluded))
        .then((projects) => {
          if (!projects?.length) {
            return;
          }
          // Container that stores services per jobsiteId {[jobsiteId]: <services: string>[]}
          const tmpServicesPerProject = {};
          for (let project of projects) {
            // jobsiteId connected to this project
            let projectJobsiteId = projectsIncluded[project.projectId];
            // if project has services add services for this jobsiteId
            if (Object.keys(project?.services || {})?.length) {
              let tmpServices = [];
              Object.keys(project?.services)?.forEach((estimation) => {
                let estServices = (project?.services?.[estimation] || [])?.map(
                  ({ label }) => label
                );
                tmpServices = tmpServices.concat(estServices || []);
              });
              Object.assign(tmpServicesPerProject, {
                [projectJobsiteId]: uniq(tmpServices),
              });
            } else {
              Object.assign(tmpServicesPerProject, {
                [projectJobsiteId]: [],
              });
            }
          }
          setDefaultServicesPerProject(tmpServicesPerProject);
        })
        .catch((err) => {
          console.log("Default project services Error: ", err);
        });
    }
    setEmptyData(
      tmpList.filter((entry) => OVERHEAD_ENTRY_TYPES.includes(entry?.punchType))
    );

    updateStatisticsHandler();
  }, [rowData, jobsites]);

  // #region Updates DEG on edit
  useEffect(() => {
    if (rowToEdit) {
      form.setFieldValue("degName", rowToEdit?.degName);
      if (rowToEdit?.degId && !!jobsites?.length) {
        // Get entries or costAnalytics if the Payroll is Completed
        getEntriesInEdit(rowToEdit)
          .then((res) => {
            let dates = [];
            const editRowData = res?.map((data) => {
              if (!isNaN(Number(data?.punchTimeStamp))) {
                dates.push(data?.punchTimeStamp);
              }

              // Find distance and duration for ID entries
              if (
                data?.punchCoordinates?.lat &&
                data?.jobsiteId &&
                data?.punchType === "ID" &&
                !data?.duration
              ) {
                const jobsiteIndex = jobsites.findIndex(
                  ({ jobsiteId }) => jobsiteId === data.jobsiteId
                );
                const jobsiteCoordinates =
                  jobsites?.[jobsiteIndex]?.addressPosition;

                if (jobsiteCoordinates?.lat) {
                  Object.assign(data, {
                    distanceFromJob: withinRadius(
                      jobsiteCoordinates,
                      data?.punchCoordinates
                    ).distanceInFeet,
                    duration:
                      withinRadius(jobsiteCoordinates, data?.punchCoordinates)
                        .distanceInFeet / 3.5,
                  });
                }
              }
              const employeeRate = data?.employeeRate
                ? data?.employeeRate
                : crews.find(({ employeeId }) => {
                  let employeeIdSplit = employeeId.split("-");
                  let modifiedEmployeeId = `${
                    employeeIdSplit[0]
                  }-${parseFloat(employeeIdSplit[1])}`;
                  const employeeMatch =
                      data?.employeeId === employeeId ||
                      data?.employeeId === modifiedEmployeeId;

                  return employeeMatch;
                })?.employeeRate;

              if (!data?.company) {
                const company = data?.employeeId?.split?.("-")?.[0];

                return {
                  ...data,
                  company,
                  employeeRate,
                  companyName: company,
                  punchTime: data?.punchTime && dayjsNY(data.punchTime),
                  punchDate: data?.punchDate && dayjsNY(data.punchDate),
                };
              } else {
                return {
                  ...data,
                  employeeRate,
                  punchTime: data?.punchTime && dayjsNY(data.punchTime),
                  punchDate: data?.punchDate && dayjsNY(data.punchDate),
                };
              }
            });

            setRowData((prev) => {
              const newRowData = editRowData.concat(prev);
              initialRowData.current = newRowData;
              return newRowData;
            });

            if (degGridApi) {
              degGridApi.hideOverlay();
            }
            analyticsUpdate({
              dateRange: [Math.min(...dates), Math.max(...dates)],
            });
          })
          .catch((err) => {
            console.log("Error getting data for this deg: ", err);
            if (degGridApi) {
              degGridApi.hideOverlay();
            }
          });
      }
    }
  }, [rowToEdit, jobsites]);

  // #region Checks for employees that does not match with any of the employees in the system
  useEffect(() => {
    if (!Object.keys(notFound).filter((el) => el !== "noEmployeeId")?.length) {
      let notFoundList = getNotFoundEmployees({
        gridApi: degGridApi,
        crews,
      });

      const { noEmployeeId = [] } = notFoundList;

      let notFoundIds = Object.keys(notFoundList)?.filter(
        (key) => key !== "noEmployeeId"
      );
      setNotFound(notFoundList);

      if (notFoundIds?.length || noEmployeeId?.length) {
        message.warning({
          content: "Careful, some employee names are not registered!",
          key: "notFoundWarning",
          duration: 4,
        });
      }
    }
  }, [degGridApi, crews, rowData]);

  /**
   * Function that creates a delay in runtime.
   *
   * @param {number} delay Delay time in milliseconds.
   */
  function sleep(delay) {
    return new Promise((resolve) => setTimeout(resolve, delay));
  }

  // #region onSave function
  /**
   * Function that saves DEG entries based on status.
   *
   * When status is Completed all entries will be saved in costAnalytics table.
   *
   * When status is not Completed all entries will be saved in degEntries and can be edited later.
   *
   * DEG entries can be saved with status Completed only when all entries have the status Completed
   *
   * @param {string} status
   */
  const onSave = useCallback(
    async function (status = degStatus) {
      if ((degName || "")?.length < 3) {
        message.error("Please add a DEG name (min. 3 characters)");
        return;
      }

      setUploading(true);

      message.loading({
        content: "Saving DEG...",
        duration: 0,
        key: "degSaveMessage",
      });

      // Create an Id used for new DEG creation
      const degId = uuidv4();

      // Initial data of the DEG body
      const bodyObj = {
        degId: rowToEdit?.degId ? rowToEdit?.degId : degId,
        degName: degName,
        degStatus: status,
        overtimeNames: [],
        fromDate: analytics.dateRange[0],
        toDate: analytics.dateRange[1],
        createdBy: {
          nameOfUser: userConfiguration.nameOfUser,
          identityId: userConfiguration.identityId,
        },
      };

      // on Edit mode
      if (rowToEdit) {
        delete bodyObj?.degId;
        // Get all entries to be deleted
        const deleteEntries = (entriesActions?.removedEntries || [])
          .concat(entriesActions?.editedEntries || [])
          ?.map(({ entryId }) => entryId);

        // Get all entries to be Created
        const postEntries = (entriesActions?.newEntries || [])
          .concat(entriesActions?.editedEntries || [])
          .map((entry) => {
            if (entry?.userId) {
              // eslint-disable-next-line no-unused-vars
              let { userId, employeeNumber, ...rest } = entry;
              return { ...rest, degId: rowToEdit?.degId };
            } else {
              // eslint-disable-next-line no-unused-vars
              let { employeeNumber, ...rest } = entry;
              return { ...rest, degId: rowToEdit?.degId };
            }
          });

        const editEntriesLogs = (entriesActions?.editedEntries || []).flatMap(
          (entry) =>
            createEntryLogs(
              "edit",
              entry,
              initialRowData?.current,
              userConfiguration
            ) || []
        );
        const addEntriesLogs = (entriesActions?.newEntries || []).flatMap(
          (entry) =>
            createEntryLogs(
              "add",
              entry,
              initialRowData?.current,
              userConfiguration
            ) || []
        );

        const removeEntriesLogs = (
          entriesActions?.removedEntries || []
        ).flatMap(
          (entry) =>
            createEntryLogs(
              "remove",
              entry,
              initialRowData?.current,
              userConfiguration
            ) || []
        );

        // Split entires into chunks for smaller API calls
        const deleteChunks = [];
        const postChunks = [];
        const editLogsChunks = [];
        const addLogsChunks = [];
        const removeLogsChunks = [];

        for (let i = 0; i < deleteEntries?.length; i += 500) {
          deleteChunks.push(deleteEntries.slice(i, i + 500));
        }

        for (let i = 0; i < postEntries?.length; i += 500) {
          postChunks.push(postEntries.slice(i, i + 500));
        }

        for (let i = 0; i < editEntriesLogs?.length; i += 500) {
          editLogsChunks.push(editEntriesLogs.slice(i, i + 500));
        }

        for (let i = 0; i < addEntriesLogs?.length; i += 500) {
          addLogsChunks.push(addEntriesLogs.slice(i, i + 500));
        }

        for (let i = 0; i < removeEntriesLogs?.length; i += 500) {
          removeLogsChunks.push(removeEntriesLogs.slice(i, i + 500));
        }

        try {
          // DELETE all deleteEntries
          for (let i = 0; i < deleteChunks?.length; i++) {
            if (i) {
              await sleep(1000);
            }

            await API.del("degEntries", "/degEntries/123", {
              body: {
                entries: deleteChunks?.[i] || [],
              },
            });
          }
          // POST all postEntires
          for (let i = 0; i < postChunks?.length; i++) {
            await sleep(1000);

            await API.post("degEntries", "/degEntries", {
              body: {
                entries: postChunks?.[i],
              },
            });
          }

          // POST edit and remove logs for entries
          await saveAddedLogs(
            [...editLogsChunks, ...addLogsChunks, ...removeLogsChunks].filter(
              Boolean
            )
          );

          // Update DEG
          await API.put("deg", `/deg/${rowToEdit?.degId}`, {
            body: bodyObj,
          });

          // Get edit logs for DEG
          let currentObject = {};
          let previousObject = {};

          for (let key in bodyObj) {
            if (
              key.includes("Id") ||
              key === "createdAt" ||
              key === "createdBy"
            ) {
              continue;
            } else if (key.includes("Date")) {
              Object.assign(currentObject, {
                [key]: dayjsNY(bodyObj?.[key]).format(DEG_DATE_FORMAT),
              });
              Object.assign(previousObject, {
                [key]: dayjsNY(rowToEdit?.[key]).format(DEG_DATE_FORMAT),
              });
            } else {
              Object.assign(currentObject, { [key]: bodyObj?.[key] });
              Object.assign(previousObject, { [key]: rowToEdit?.[key] });
            }
          }

          const results = getChangedData(currentObject, previousObject);
          newEditLog.currentData = results.curr;
          newEditLog.previousData = results.prev;

          saveAddedLogs(newEditLog);

          broadcastNotification("112", "onPayrollUpdate", [
            {
              common: userConfiguration?.nameOfUser,
              degName: bodyObj?.degName,
              degStatus: bodyObj?.degStatus,
            },
            {
              userName: userConfiguration?.nameOfUser,
              currentUser: userConfiguration?.cognitoUserId,
            },
          ]);

          // Update DEG grid with the updated data
          refreshTable({ ...bodyObj, degId: rowToEdit?.degId }, "edit");
          message.success({
            content: `${degName} have been saved successfully!`,
            key: "degSaveMessage",
          });
        } catch (err) {
          message.error({
            content: "Something went wrong while trying to update the payroll",
            key: "degSaveMessage",
          });
          console.log("Error updating entries: ", err);
        }

        setUploading(false);
        toggleNotifications(true);
        onCancel();
      } else {
        const bodyOfDeg = { ...bodyObj };

        // Split entries into chunks of 500 for smaller API calls
        let entriesChunks = [];
        const rowEntries = rowData.map((data) => {
          // eslint-disable-next-line no-unused-vars
          const { employeeNumber, ...entry } = data;
          // Add degId in all entries to connect them with this DEG
          return Object.assign(entry, {
            degId: rowToEdit?.degId ? rowToEdit?.degId : degId,
          });
        });

        for (let i = 0; i < rowEntries?.length; i += 500) {
          entriesChunks.push(rowEntries.slice(i, i + 500));
        }

        // Post all entries
        for (let i = 0; i < entriesChunks?.length; i++) {
          const chunk = entriesChunks?.[i] || [];
          if (i !== 0) {
            await sleep(1000);
          }
          await API.post("degEntries", `/degEntries`, {
            body: {
              entries: chunk,
            },
          })
            .then((res) => console.log("degEntries saved: ", res))
            .catch((err) => console.log("Error: ", err));
        }

        // Create New DEG
        API.post("deg", "/deg", {
          body: bodyOfDeg,
        })
          .then((res) => {
            // Get edit logs of this DEG
            let currentObject = {};
            for (let key in res) {
              if (
                key.includes("Id") ||
                key === "createdAt" ||
                key === "createdBy"
              ) {
                continue;
              } else if (key.includes("Date")) {
                Object.assign(currentObject, {
                  [key]: dayjsNY(res?.[key]).format(DEG_DATE_FORMAT),
                });
              } else {
                Object.assign(currentObject, { [key]: res?.[key] });
              }
            }
            const results = getChangedData(currentObject, {});
            newEditLog.currentData = results?.curr;
            newEditLog.previousData = results?.prev;
            newEditLog.actionType = "Create";

            saveAddedLogs(newEditLog);

            message.destroy();
            message.success({
              content: "DEG saved successfully!",
              key: "degSaveMessage",
            });

            broadcastNotification("112", "onPayrollCreation", [
              {
                common: userConfiguration?.nameOfUser,
                degName: bodyObj?.degName,
              },
              {
                userName: userConfiguration?.nameOfUser,
                currentUser: userConfiguration?.cognitoUserId,
              },
            ]);

            // Update DEG Grid with the newly created DEG
            refreshTable([res]);
            toggleNotifications(true);
            onCancel();
            setUploading(false);
          })
          .catch((err) => {
            message.error({
              content: "There was a problem saving this DEG!",
              key: "degSaveMessage",
            });
            console.log("Error: ", err);
          });
      }
    },
    [entriesActions, rowToEdit, userConfiguration, degName, analytics, rowData]
  );

  /**
   * Function that deletes the Payroll
   */
  // #region onDelete function
  function onDelete() {
    API.del("deg", `/deg/${rowToEdit?.degId}`)
      .then(async () => {
        message.loading({
          content: "Deleting Entries...",
          duration: 0,
          key: "deletingEntries",
        });
        // Ged edit logs of the deletion
        let previousObject = {};
        for (let key in rowToEdit) {
          if (
            key.includes("Id") ||
            key === "createdAt" ||
            key === "createdBy"
          ) {
            continue;
          } else if (key.includes("Date")) {
            Object.assign(previousObject, {
              [key]: dayjsNY(rowToEdit?.[key]).format(DEG_DATE_FORMAT),
            });
          } else {
            Object.assign(previousObject, { [key]: rowToEdit?.[key] });
          }
        }
        const results = getChangedData({}, previousObject);
        newEditLog.currentData = results.curr;
        newEditLog.previousData = results.prev;
        newEditLog.actionType = "Delete";

        // Split entries into chunks of 1000 for smaller API calls
        let entriesChunks = [];
        for (let i = 0; i < rowData?.length; i += 1000) {
          entriesChunks.push(rowData.slice(i, i + 1000));
        }

        // Delete all entries of the deleted DEG
        for (let i = 0; i < entriesChunks?.length; i++) {
          const chunk =
            entriesChunks?.[i]?.map?.(({ entryId }) => entryId) || [];
          if (i > 0) {
            await sleep(1000);
          }
          await API.del("degEntries", `/degEntries/123`, {
            body: {
              entries: chunk,
            },
          })
            .then((res) => console.log("Entries deleted successfully: ", res))
            .catch((err) => console.log("Error: ", err));
        }
        saveAddedLogs(newEditLog);

        broadcastNotification("112", "onPayrollDelete", [
          {
            common: userConfiguration?.nameOfUser,
            degName: rowToEdit?.degName,
          },
          {
            userName: userConfiguration?.nameOfUser,
            currentUser: userConfiguration?.cognitoUserId,
          },
        ]);

        // Updates the DEG grid with the removed DEG
        refreshTable(rowToEdit, "delete");
        setWarningVisible(false);
        toggleNotifications(true);
        message.success({
          content: "DEG deleted successfully!",
          duration: 1.5,
          key: "deletingEntries",
        });
        onCancel();
      })
      .catch((err) => {
        console.log("Error: ", err);
        message.error({
          content: "There was a problem deleting this DEG!",
          duration: 1.5,
          key: "deletingEntries",
        });
      });
  }

  // async function onDeleteAnalytics() {
  //   let entriesChunks = [];
  //   for (let i = 0; i < rowData?.length; i += 1000) {
  //     entriesChunks.push(rowData.slice(i, i + 1000));
  //   }

  //   try {
  //     // Delete all entries of the deleted DEG
  //     for (let i = 0; i < entriesChunks?.length; i++) {
  //       const chunk = entriesChunks?.[i]?.map?.(({ entryId }) => entryId) || [];
  //       if (i) {
  //         await sleep(1000);
  //       }
  //       await API.del("costAnalytics", `/costAnalytics/123`, {
  //         body: {
  //           entries: chunk,
  //         },
  //       });
  //     }
  //     message.success("Analytics Deleted Successfully!");
  //   } catch (err) {
  //     message.error("There was a problem deleting Analytics!");
  //     console.log("Error deleting Analytics: ", err);
  //   }
  // }

  /**
   * This function changes the current step of the Stepper
   *
   * @param {number} step The next step
   */
  function stepChangeHandler(step) {
    setCurrentStep(step);

    if (step === 2 && degGridApi && currentStep < 2) {
      let tmp = [];
      let dates = [];

      // Get all nodes of entries grid and updates the date range of the Payroll and rowData with the grid state
      degGridApi.forEachNode((node) => {
        tmp.push({
          ...node.data,
          punchTime: node?.data?.punchTime && parseInTz(node?.data?.punchTime),
          punchTimeStamp:
            node?.data?.punchTime && parseInTz(node?.data?.punchTime).valueOf(),
        });
        if (!isNaN(Number(node?.data?.punchTimeStamp))) {
          dates.push(node?.data?.punchTimeStamp);
        }
      });
      setRowData(tmp);
      // recalculates analytics for all entries
      analyticsUpdate({
        employeeList: crews,
        dateRange: [Math.min(...dates), Math.max(...dates)],
      });
    }

    if (emptyData?.length) {
      message.warning({
        content: "Careful, there is incomplete data in the DEG!",
        key: "uncompletedData",
        duration: 3,
      });
    }
  }

  // /**
  //  *
  //  * @param {string} action add or subtract Action that will modify the punchTime
  //  * @param {Object} entry Entry that will be modified
  //  * @param {string} unit Unit that will be modified hour or minutes ["hour", "minute", "h", "m"]
  //  * @param {number} value Value that will modify the current punchTime
  //  * @param {boolean} registerActions Boolean to register this single action
  //  */
  // function updatePunchTime(action, entry, unit, value, registerActions) {
  //   if (!["add", "subtract"].includes(action)) {
  //     return;
  //   }
  //   const newPunchTime = parseInTz(
  //     entry.punchTime?.[action](parseFloat(value), unit)
  //   );
  //   const newEntry = {
  //     ...entry,
  //     punchTime: newPunchTime,
  //     punchTimeStamp: newPunchTime.valueOf(),
  //   };

  //   if (registerActions) {
  //     addEntryAction({ type: "edit", entry: [newEntry] });
  //     addActionToRegister({
  //       type: "edit",
  //       editActions: [{ curr: newEntry, prev: entry }],
  //     });
  //     degGridApi.applyTransaction({ update: [newEntry] });
  //   }
  //   return newEntry;
  // }

  /**
   * Updates entry with the given data
   *
   * @param {Object} data Entry object that will update the entry that matches the entryId
   */
  function updateData(data) {
    if (degGridApi) {
      if (data?.punchType !== "HR") {
        const emptyFields = keysToValidate.filter(
          (key) => !data[key] && !data[key]?.length && data[key] !== 0
        );
        if (emptyFields?.length) {
          setEmptyData(emptyData.concat(data.entryId));
        } else {
          const emptyDataToFilter = emptyData.filter(
            (entryId) => entryId !== data.entryId
          );
          setEmptyData(emptyDataToFilter);
        }
      }
      if (data?.employeeId) {
        updateStatisticsHandler([data?.employeeId]);
      }
      const shift =
        analytics?.employeesHoursPerDay?.[data.employeeId]?.[
          dayjsNY(data.punchDate).format("MM/DD/YYYY")
        ];

      let shiftEntries = [];
      let oldShiftEntries = [];
      if (shift) {
        rowData.forEach((activity) => {
          if (
            activity.entryId !== data.entryId &&
            activity?.employeeId === data?.employeeId &&
            dayjsNY(activity.punchDate).format("MM/DD/YYYY") ===
              dayjsNY(shift?.firstClockIn).format("MM/DD/YYYY") &&
            dayjsNY(shift.firstClockIn).valueOf() <=
              dayjsNY(activity?.punchTime).valueOf() <=
              dayjsNY(shift.firstClockIn)
                .add(
                  shift.breakHours + shift.overtimeHours + shift?.workHours,
                  "Hour"
                )
                .valueOf()
          ) {
            shiftEntries.push(activity);
            oldShiftEntries.push(activity);
          }

          if (activity.entryId === data.entryId) {
            oldShiftEntries.push(activity);
          }
        });
      }

      let editActions = [];
      const dataToUpdate = shiftEntries
        .map((entry) => Object.assign(entry, { sow: data?.sow }))
        .concat(data);

      dataToUpdate.forEach((data) => {
        const prevIndex = oldShiftEntries.findIndex(
          (el) => el.entryId === data.entryId
        );
        const prev = oldShiftEntries[prevIndex];
        editActions.push({ type: "edit", curr: data, prev });
      });
      addEntryAction({ type: "edit", entry: dataToUpdate });
      addActionToRegister({ type: "edit", editActions });
      setRowData((prev) =>
        prev.map((el) => (el?.entryId === data?.entryId ? data : el))
      );
    }
  }

  function massUpdateHandler(data) {
    // data the object that will give the values to all the selectedNodes
    if (degGridApi) {
      let listOfEmptyData = [];
      let listOfRemovedEmptyData = [];
      let employeesToUpdate = [data?.employeeId];
      // get list of nodes that will be edited
      let editedNodes = [];
      let selectedNodesData = degGridApi
        .getSelectedNodes()
        ?.map(({ data: activity }) => {
          employeesToUpdate.push(data?.employeeId);
          return activity;
        });

      // get empty fields of each edited node
      for (let i = 0; i < selectedNodesData?.length; i++) {
        const node = selectedNodesData[i];
        const oldNode = { ...node };
        // delete unedited keys
        Object.keys(data).forEach((key) => {
          if (key === "jobsiteMatch") {
            if (!data?.jobsiteMatch?.jobAddress) {
              delete data[key];
            }
          } else {
            if (!data[key] && !data[key] !== 0) {
              delete data[key];
            }
          }
        });
        const jobsiteIndex = jobsites.findIndex(
          ({ jobsiteId }) => jobsiteId === data?.jobsiteId
        );

        const distanceFromJob = withinRadius(
          jobsites?.[jobsiteIndex]?.addressPosition,
          node?.punchCoordinates
        ).distanceInFeet;

        const duration = distanceFromJob / 3.5;
        // add edited values to the node object
        if (data?.timeModifier) {
          // eslint-disable-next-line no-unused-vars
          const { timeModifier, value, unit, action, ...rest } = data;
          Object.assign(node, { ...rest, distanceFromJob, duration });
        } else {
          Object.assign(node, { ...data, distanceFromJob, duration });
        }

        if (!!data["punchDate"] && !data["punchTime"] && !!node["punchTime"]) {
          let newPunch = setHourMinute(
            data["punchDate"],
            parseInTz(node["punchTime"])
          );
          node["punchTime"] = newPunch;
          node["punchTimeStamp"] = newPunch.valueOf();
        }

        if (!data["punchDate"] && !!data["punchTime"] && !!node["punchDate"]) {
          let newPunch = setHourMinute(
            node["punchDate"],
            parseInTz(data["punchTime"])
          );
          node["punchTime"] = newPunch;
          node["punchTimeStamp"] = newPunch.valueOf();
        }

        const scheduleMatch =
          scheduleMatching?.[jobsites?.[jobsiteIndex] || node?.jobsiteId];
        if (scheduleMatch?.scheduleId) {
          const scheduleDay = (scheduleMatch?.scheduleDays || []).find(
            (day) =>
              dayjsNY(day?.startDate).format(DEG_DATE_FORMAT) ===
              dayjsNY(node?.punchDate).format(DEG_DATE_FORMAT)
          );

          Object.assign(node, {
            scheduleId: scheduleMatch?.scheduleId,
            scheduleAddress: scheduleMatch?.scheduleAddress,
            scheduleDayId: scheduleDay?.id,
          });
        }

        if (!node?.crewTeamId) {
          const team = crewTeams.find((el) => {
            const isForeman = el?.crewForeman?.crewId === node?.crewId;
            const isMember =
              (el?.crewMembers || []).findIndex(
                (member) => member?.crewId === node?.crewId
              ) > -1;
            return isForeman || isMember;
          });
          if (team?.crewTeamId) {
            Object.assign(node, {
              crewTeamId: team?.crewTeamId,
              crewTeamName: team?.crewTeamName,
            });
          }
        }

        const emptyFields = keysToValidate.filter(
          (key) => !node[key] && !node[key]?.length && node[key] !== 0
        );
        if (!!emptyFields?.length && data?.punchType !== "HR") {
          listOfEmptyData.push(node.entryId);
        } else {
          listOfRemovedEmptyData.push(node.entryId);
        }
        editedNodes.push({ curr: node, prev: oldNode });
      }

      if (listOfEmptyData?.length) {
        //add nodes that have empty fields
        setEmptyData(emptyData.concat(listOfEmptyData));
      }
      if (listOfRemovedEmptyData?.length) {
        const emptyDataToFilter = emptyData.filter(
          (entryId) => !listOfRemovedEmptyData.includes(entryId)
        );
        // remove completed nodes from empty data
        setEmptyData(emptyDataToFilter);
      }

      degGridApi.applyTransaction({
        update: selectedNodesData,
      });
      addEntryAction({
        type: "edit",
        entry: selectedNodesData,
      });
      addActionToRegister({ type: "edit", editActions: editedNodes });
      updateStatisticsHandler();
    }
  }

  function updateJobsitesHandler(newJob) {
    if (degGridApi) {
      let array = [];
      degGridApi.forEachNode(({ data }) => {
        array.push(data);
      });
      let newRows = addDataToArray({
        array,
        makeCall: false,
        jobsites: [...jobsites, newJob],
        crews,
      });
      degGridApi.applyTransaction({
        update: newRows,
      });
      setJobsites([...jobsites, newJob]);
      updateStatisticsHandler();
    }
  }

  /**
   * Creates a new Employee from DEG
   *
   * @param {Object} crew New Employee object
   * @param {Object} notFoundEntry Entry that contains the not found Employee
   */
  function updateCrewHandler(crew, notFoundEntry) {
    if (degGridApi) {
      setCrews([...crews, crew]);

      let rowsForEmployee = [];
      degGridApi.forEachNode(({ data }) => {
        let pushCondition = false;
        if (notFoundEntry?.employeeId) {
          if (data?.employeeId === notFoundEntry?.employeeId) {
            pushCondition = true;
          }
        } else {
          if (
            !data?.employeeId &&
            data?.employeeFullName === notFoundEntry?.employeeFullName
          ) {
            pushCondition = true;
          }
        }

        if (pushCondition) {
          let employeeRate = crew?.employeeRate;
          let employeeRole = crew?.crewRole;
          let payrollType = undefined;
          if (data?.jobsiteMatch?.jobsiteId) {
            let jobMatched = jobsites?.find(
              ({ jobsiteId }) => jobsiteId === data?.jobsiteMatch?.jobsiteId
            );
            if (jobMatched) {
              payrollType = jobMatched?.payrollType;
              if (
                jobMatched?.payrollType !== "Open Shop" &&
                !!jobMatched["rates"][employeeRole]
              ) {
                employeeRate = Number(
                  parseFloat(jobMatched["rates"][employeeRole]).toFixed(2)
                );
              }
            }
          }
          rowsForEmployee.push({
            ...data,
            employeeId: crew?.employeeId,
            employeeRate,
            crewId: crew?.crewId,
            employeeFullName: crew?.crewName,
            role: crew?.crewPosition,
            salaryType: crew?.salaryType || "Hourly",
            payrollType,
          });
        }
      });

      degGridApi.applyTransaction({
        update: rowsForEmployee,
      });

      updateStatisticsHandler([crew?.employeeId]);
    }
  }

  /**
   * Updates analytics data for the employees inside employeeList
   * or all employees if employeeList is undefined.
   *
   * @param {Array | undefined} employeeList List of employees to update analytics
   */
  function updateStatisticsHandler(employeeList = undefined) {
    getEmployeeAnalytics({
      employeeList,
      degGridApi,
      analyticsUpdate,
      degRows: rowData,
    });
  }

  /**
   * Updates the shift data for the employees inside employeeList
   * or all employees if employeeList is undefined
   *
   * @param {Array | undefined} employeeList
   */
  function updateShiftStatistics(employeeList) {
    getShiftAnalytics({
      gridApi: shiftsGridApi,
      employeeList,
      analyticsUpdate,
    });
  }

  let incorrectData = [];
  for (const key in analytics?.employeeIncorrectShifts || {}) {
    incorrectData = [
      ...incorrectData,
      ...(analytics?.employeeIncorrectShifts?.[key] || []),
    ];
  }

  function updateWarningFilters(type) {
    filtersDispatch({ type });
  }

  const ActiveStep = Steps[currentStep];

  // Updates the deg status
  useEffect(() => {
    if (degStatus !== "Pending") {
      let editedActivities = rowData?.some(
        (data) =>
          data?.activityStatus === "Pending" ||
          data?.activityStatus === "Completed"
      );
      if (editedActivities) {
        setDegStatus("Pending");
      }
    }
  }, [rowData, emptyData]);

  useEffect(() => {
    if (Object.keys(scheduleMatching)?.length) {
      let scheduledRowData = [];
      let tmpScheduleDaysIncluded = [];

      for (let i = 0; i < rowData?.length; i++) {
        const entry = rowData[i];
        const scheduleMatched = scheduleMatching?.[entry?.jobsiteId];

        let scheduleDayMatch;

        for (let j = 0; j < scheduleMatched?.scheduleDays?.length; j++) {
          const sDay = scheduleMatched?.scheduleDays[j];
          if (
            dayjsNY(entry?.punchDate).format(DEG_DATE_FORMAT) ===
            dayjsNY(sDay.startDate).format(DEG_DATE_FORMAT)
          ) {
            scheduleDayMatch = sDay;
            if (
              tmpScheduleDaysIncluded.findIndex((day) => day.id === sDay.id) ===
              -1
            ) {
              tmpScheduleDaysIncluded.push(sDay);
            }
            break;
          }
        }
        scheduledRowData.push({
          ...entry,
          scheduleId: scheduleMatched?.scheduleId,
          scheduleAddress: scheduleMatched?.scheduleAddress,
          scheduleDayId: scheduleDayMatch?.id,
        });
      }

      setRowData(scheduledRowData);
    }
  }, [JSON.stringify(scheduleMatching)]);

  return (
    <DegModalContext.Provider
      value={{
        form,
        crews,
        rowData,
        degName,
        jobsites,
        setCrews,
        foremans,
        notFound,
        accounts,
        crewTeams,
        degStatus,
        emptyData,
        analytics,
        uploading,
        rowToEdit,
        setRowData,
        degGridApi,
        isLastStep,
        isDarkMode,
        updateData,
        degPayrolls,
        currentStep,
        accountName,
        setNotFound,
        entriesLogs,
        getEntryLogs,
        degColumnApi,
        setEmptyData,
        setUploading,
        setDegGridApi,
        incorrectData,
        shiftsGridApi,
        degStepFilters,
        warningFilters,
        addEntryAction,
        entriesActions,
        setDegColumnApi,
        analyticsUpdate,
        actionsRegister,
        jobsiteServices,
        scheduleMatching,
        setShiftsGridApi,
        allServiceOptions,
        massUpdateHandler,
        updateCrewHandler,
        setJobsiteServices,
        setScheduleMatching,
        addActionToRegister,
        shiftExternalFilter,
        stopUploadingButton,
        updateWarningFilters,
        filtersFromSelection,
        updateJobsitesHandler,
        updateShiftStatistics,
        updateStatisticsHandler,
        setFiltersFromSelection,
        updateShiftExternalFilter,
        defaultServicesPerProject,
        setDefaultServicesPerProject,
      }}
    >
      <Modal
        {...{
          open,
          onCancel() {
            stopUploadingButton.current.click();
            message.destroy();
            setWarningVisible("CANCEL");
          },
          closeIcon: <XIcon />,
          title: `${degName}`,
          ["data-testid"]: "deg-modal",
          wrapClassName: "payroll-deg-modal-wrap",
          className: `payroll-deg-modal-container ${
            isDarkMode && "payroll-deg-modal-container-dark"
          }`,
          closable: true,
          centered: currentStep === 0,
          destroyOnClose: true,
          footer: footerButtons({
            onCancel() {
              setWarningVisible("CANCEL");
            },
            onNextStep() {
              if (!isLastStep) {
                stepChangeHandler(currentStep + 1);
              }
            },
            onSave() {
              //  else {
              form
                .validateFields()
                .then(() => {
                  let completedActivities = [];
                  let allEntries = currentStep === 1 ? [] : rowData;
                  if (currentStep === 1) {
                    degGridApi.forEachNode((node) => {
                      allEntries.push(node.data);
                      if (node.data.activityStatus === "Completed") {
                        completedActivities.push(node.data);
                      }
                    });
                  } else {
                    completedActivities = rowData.filter(
                      (data) => data?.activityStatus === "Completed"
                    );
                  }

                  if (
                    completedActivities?.length === allEntries?.length &&
                    emptyData?.length === 0 &&
                    modalStatusVisible !== "Completed"
                  ) {
                    setModalStatusVisible(true);
                  } else {
                    onSave();
                  }
                })
                .catch((error) => {
                  message.warning({
                    key: "formValidation",
                    content: error?.errorFields?.[0].errors?.[0],
                    duration: 2,
                  });
                });
              // }
            },
            currentStep,
            isLastStep,
            onShowLogs: () => setLogsModalVisible(true),
            onReduceStep() {
              stepChangeHandler(currentStep - 1);
            },
            onDelete() {
              setWarningVisible("DELETE");
            },
            degName: degName || "",
            emptyData,
            editMode,
            degStatus,
            rowToEdit,
            uploading,
            accountName,
          }),
        }}
      >
        <Stepper
          {...{
            stepperClassName: "deg-stepper",
            stepRenderer: false,
            currentStep: currentStep,
            setCurrentStep: stepChangeHandler,
            steps: modalSteps,
          }}
        />
        <ActiveStep />
      </Modal>
      <WarningModal
        visible={warningVisible}
        setVisible={setWarningVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningTriangle />
          </span>
          <p>
            {warningVisible === "CANCEL"
              ? "Are you sure you want to cancel?"
              : warningVisible === "DELETE"
                ? `Are you sure you want to delete ${rowToEdit?.degName}?`
                : ""}
          </p>
          <div className="buttons">
            <MondayButton
              onClick={() => setWarningVisible(false)}
              Icon={<XIcon />}
              className="mondayButtonRed"
              data-testid={
                warningVisible === "DELETE"
                  ? "decline-delete-btn"
                  : "decline-close-btn"
              }
            >
              {warningVisible === "DELETE" ? "Cancel" : "No"}
            </MondayButton>
            <MondayButton
              data-testid="confirm-close-btn"
              onClick={() => {
                if (warningVisible === "CANCEL") {
                  if (uploading) {
                    stopUploadingButton.current.click();
                    setWarningVisible(false);
                  } else {
                    stopUploadingButton.current.click();
                    toggleNotifications(true);
                    message.destroy();
                    onCancel();
                  }
                } else if (warningVisible === "DELETE") {
                  onDelete();
                }
              }}
              Icon={<TickIcon />}
            >
              {warningVisible === "DELETE" ? "Delete" : "Yes"}
            </MondayButton>
            {deleteAccessRight &&
              rowToEdit?.degStatus === "Completed" &&
              warningVisible === "DELETE" && (
              <MondayButton
                onClick={() => {
                  // onDeleteAnalytics();
                  onDelete();
                }}
                Icon={<DeleteIcon />}
              >
                  Delete With Analytics
              </MondayButton>
            )}
          </div>
        </div>
      </WarningModal>
      {modalStatusVisible !== "Completed" && modalStatusVisible && (
        <WarningModal
          visible={modalStatusVisible}
          setVisible={setModalStatusVisible}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p style={{ textAlign: "center" }}>
              All Activities in this DEG are completed. Do you wish to change
              the status to <b>Completed</b>?
            </p>
            <div className="buttons">
              <MondayButton
                onClick={() => setModalStatusVisible("Completed")}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  distributeNoJobsiteCost({
                    analytics,
                    crews,
                    jobsites,
                    rowData,
                    addEntryAction,
                  });
                  setDegStatus("Completed");
                  setModalStatusVisible(false);
                  setTimeout(() => {
                    saveButton.current.click();
                  }, 100);
                }}
                Icon={<TickIcon />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {logsModalVisible && (
        <MultiLevelTreeLogs
          {...{
            visible: logsModalVisible,
            setVisible: setLogsModalVisible,
            logsData: editLogs || [],
            title: `${rowToEdit ? rowToEdit?.degName : "DEG"} Logs`,
          }}
        />
      )}
      <button
        style={{ display: "none" }}
        ref={stopUploadingButton}
        id="cancelButton"
      ></button>
      <button
        style={{ display: "none" }}
        onClick={() => onSave("Completed")}
        ref={saveButton}
      ></button>
    </DegModalContext.Provider>
  );
}

DegModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  rowToEdit: {
    degId: PropTypes.string.isRequired,
    degName: PropTypes.string.isRequired,
    degStatus: PropTypes.string.isRequired,
  },
  refreshTable: PropTypes.func,
  degStepFilters: PropTypes.object,
};

export default DegModal;
