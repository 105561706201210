import { Form, Tour, message } from "antd";
import { API } from "aws-amplify";
import isEmpty from "lodash/isEmpty";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import broadcastNotification from "../../../../helpers/controllers/broadcastNotification";
import { driveApi } from "../../../../integrations/DriveRequest";
import {
  getCognitosForNotification,
  showErrorMsg,
  showSuccessMsg,
  updateTeamsConfiguration,
} from "../../../../utils";
import {
  DropdownWrapper,
  FullScreenModal,
} from "../../../SidebarPages/Fleet/components";
import {
  apiRoutes,
  getCoordinatesAndZip,
} from "../../../SidebarPages/Fleet/utils";
import { compareIncluding } from "../../../SidebarPages/utils";
import { filterPeopleList } from "../../../SidebarPages/utils/filterPeopleList";
import {
  MondayButton,
  ProgressComponent,
  Stepper,
  WarningModal,
  assignStepStatus,
  isStepDisabled,
} from "../../../commonComponents";
import "./ProjectModal.scss";
import { dobStepFormItemNames } from "./components/DobStep/dobStepData";
import { overviewStepFormItemNames } from "./components/OverviewStep/overviewStepData";
import { subcontractorsStepFormItemNames } from "./components/SubcontractorsStep/subcontractorsStepData";
import { body, steps, stepsMapHelper } from "./projectModalData";
import { ClientModal } from "..";
import { CheckIconModal } from "../../../../assets";
import { useCreateDriveFolders } from "../../../../hooks/useCreateDriveFolders";
import { XIcon } from "../../../SidebarPages/Communication/assets";
import { WarningTriangle } from "../../../SidebarPages/DynamicView/src";
import { compareAddressesWithRgx } from "../../../SidebarPages/utils/compareIncluding";
import CustomModalHeader, {
  PlayVideoTutorial,
  tourTitle,
} from "../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { TickIcon } from "../../../pages/Settings/settingsComponents/Roles/src";
import { NextIconNew } from "../../assets";
import { fetchModalData, filterVisibleSteps } from "./utils";
import { useEditLogs, useProgressComponent } from "../../../../hooks";
import dayjs from "dayjs";
import getStringErrorMessage from "../ClientModal/utils/getErrorMessage";
import usePostEventsAutomation from "../../../pages/Settings/settingsComponents/PostEventsAutomationsConfig/functions/usePostEventsAutomation";

// import { OverviewStep } from "./components";

const ProjectModal = ({
  visible,
  setVisible,
  onModalCancel = () => {},
  defaultData = {},
  refreshTable = () => {},
  completeConversion = () => {},
  opportunityStage,
  leadData,
  requestId = "",
  isNextStep,
  nextStepHandler,
  handleSavedRecord = () => {},
  afterSaveRequest = async () => {},
  ...rest
}) => {
  const { postEventsAutomationHandler } = usePostEventsAutomation();

  const { accountName: proppedAccountName } = defaultData;
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [projectAddress, setProjectAddress] = useState(null);
  const [selfCompKey, setSelfCompKey] = useState(uuidv4());
  const [attorneys, setAttorneys] = useState([]);
  const [electricians, setElectricians] = useState([]);
  const [boomCompanies, setBoomCompanies] = useState([]);
  const [expeditors, setExpeditors] = useState([]);
  const [safetyCompanies, setSafetyCompanies] = useState([]);
  const [insuranceBrokers, setInsuranceBrokers] = useState([]);
  const [clients, setClients] = useState([]);
  const [salesPerson, setSalesPerson] = useState([]);
  const [projects, setProjects] = useState([]);
  const [estimations, setEstimations] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState({});
  const [serviceColors, setServiceColors] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [users, setUsers] = useState([]);
  const [projectExecutives, setProjectExecutives] = useState([]);
  const [taxExemptOptions, setTaxExemptOptions] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [saving, setSaving] = useState(false);
  const [projectAddressInfo, setProjectAddressInfo] = useState();
  const [accountName, setAccountName] = useState();
  const [altAddresses, setAltAddresses] = useState([]);
  const [{ validateStep }, setValidateStep] = useState({});
  const [completedSteps, setCompletedSteps] = useState({});
  const [dobViolations, setDobViolations] = useState([]);
  const [selectedEstimations, setSelectedEstimations] = useState([]);
  const [useExistingData, setUseExistingData] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [clientModalVisible, setClientModalVisible] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [wannaCreateClientModalVisible, setWannaCreateClientModalVisible] =
    useState(false);
  const [existingOpportunityModalVisible, setExistingOpportunityModalVisible] =
    useState(false);
  const [insuranceTypes, setInsuranceTypes] = useState([]);

  const driveFunctions = useCreateDriveFolders("Projects");
  // const [loading, setLoading] = useState(true);
  const [altAddressesInfo, setAltAddressesInfo] = useState([]);
  const [laborTypes, setLaborTypes] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);

  const { programFields } = useSelector((state) => state.programFields);
  const { proposedTypeOfWork } = useSelector(
    (state) => state.proposedTypeOfWork
  );
  const { accessToken } = useSelector((state) => state.accessToken);
  const serviceDefinitions = useSelector((state) => state.serviceDefinitions);
  const [geoFenceInfo, setGeoFenceInfo] = useState(
    defaultData?.geoFenceInfo ? defaultData.geoFenceInfo : []
  );
  const {
    coordinates: { lat: projectLatitude, lng: projectLongitude },
    place_id: projectPlaceId,
  } = projectAddressInfo || { coordinates: {} };

  const user = `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`;

  const [initialValues, setInitialValues] = useState({});

  const [tourOpen, setTourOpen] = useState(false);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const videoTutorialLink = programFields
    ?.find((item) => item.fieldName === "Portal Video Tutorials")
    ?.fieldOptions.find((item) => item.categoryName === "Project Management")
    ?.subCategories[0].videos.find(
      (item) => item.videoName === "New Project"
    )?.videoLink;
  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({ categoryName: "Projects", actionType: "Create" });
  const { saveAddedLogs } = useEditLogs();

  useEffect(() => {
    setServiceColors(proposedTypeOfWork);
  }, [proposedTypeOfWork]);

  useEffect(() => {
    setServices(serviceDefinitions);
  }, [serviceDefinitions]);

  const existingProject = useMemo(() => {
    let tmp = {};
    let addToCompare = projectAddress || form.getFieldValue("projectName");
    let accountToCompare = accountName || form.getFieldValue("accountName");

    if (!!addToCompare && !!accountToCompare) {
      let tmp2 = projects?.find?.(
        ({ projectAddress: oldProjectAddress, accountName: arrAccountName }) =>
          !!addToCompare &&
          compareAddressesWithRgx(oldProjectAddress, addToCompare) &&
          arrAccountName === accountToCompare
      );
      if (!!tmp2) tmp = tmp2;
    }
    return tmp;
  }, [projectAddress, projects, accountName]);

  const existingOpportunity = useMemo(() => {
    if (
      !!defaultData &&
      Object.keys(defaultData)?.length > 0 &&
      defaultData?.opportunityId
    )
      return defaultData;
    else
      return (
        opportunities?.find(
          ({ opportunityAddress, accountName: arrAccountName }) =>
            !!projectAddress &&
            compareAddressesWithRgx(projectAddress, opportunityAddress) &&
            arrAccountName === accountName
        ) || {}
      );
  }, [opportunities, projectAddress, accountName, defaultData]);

  const existingEstimations = estimations?.filter(
    ({ jobSiteAddress, accountName: arrAccountName }) =>
      !!projectAddress &&
      compareAddressesWithRgx(projectAddress, jobSiteAddress) &&
      arrAccountName === accountName
  );

  const {
    googleDriveFolderIds,
    opportunityId,
    isChangeOrder,
    estimations: opportunityEstimations = [],
  } = existingOpportunity;

  const {
    accountId,
    googleDriveFolderIds: { projectObject: projectFolderId },
    teamsConfiguration: clientTeamsConfiguration,
    projectExecutive: clientProjectExecutive,
    leadSalePerson: clientLeadSalePerson,
    cognitoUserId: clientCognitoUserId,
  } = clients?.find(
    ({ accountName: arrAccountName }) => arrAccountName === accountName
  ) || { googleDriveFolderIds: {} };

  const bodyObj = () => body(form);

  const addBorough = () => {
    const formattedAddDetails = projectAddressInfo?.address_components?.reduce(
      (acc, { long_name, types = [] }) => ({
        ...acc,
        [types[0]]: long_name,
      }),
      {}
    );
    return formattedAddDetails?.political || formattedAddDetails?.locality;
  };

  const setValidateStepMethod = (func) => {
    setValidateStep({ validateStep: func });
  };

  const onClientCreated = ([newClient]) => {
    setClients((prev) => [...prev, newClient]);
  };

  const onContactCreated = ([newContact]) => {
    setContacts((prev) => [...prev, newContact]);
  };

  // userAccess for geofence step
  const userAccesRights = userConfiguration?.routeConfig?.find(
    ({ title }) => title === "Projects/View"
  )?.children;
  const geoFenceAccess = userAccesRights
    ?.map(({ title }) => (title?.includes("Geofence") ? title : null))
    ?.filter((title) => title !== null);
  //region STEPS DATA
  const populatedSteps = steps
    ?.filter?.(({ title }) =>
      filterVisibleSteps({
        title,
        useExistingData,
        existingEstimations: existingEstimations ? existingEstimations : [],
      })
    )
    ?.map((step, index) => ({
      ...step,
      ...rest,
      form,
      index,
      routes: apiRoutes,
      clients,
      services,
      contacts,
      salesPerson,
      estimations,
      projects,
      laborTypes,
      insuranceTypes,
      taxExemptOptions,
      projectExecutives,
      existingOpportunity,
      existingEstimations,
      opportunityStage,
      completedSteps,
      altAddresses,
      projectAddress,
      projectAddressInfo,
      dobViolations,
      accountName,
      serviceColors,
      useExistingData,
      selectedEstimations,
      attorneys,
      electricians,
      boomCompanies,
      expeditors,
      safetyCompanies,
      insuranceBrokers,
      proppedAccountName,
      altAddressesInfo,
      onClientCreated,
      onContactCreated,
      setAltAddresses,
      setProjectAddress,
      setProjectAddressInfo,
      setDobViolations,
      setAccountName,
      setEstimations,
      setSelectedEstimations,
      setSelectedServices,
      status: assignStepStatus(completedSteps, index),
      disabled: isStepDisabled(completedSteps, currentStep, index),
      setValidateStepMethod,
      setCompletedSteps,
      setCurrentStep,
      setSelectedTeam,
      selectedTeam,
      setGeoFenceInfo,
      geoFenceInfo,
      userConfiguration,
    }));

  const postErrorLog = async ({ error, reason }) => {
    const message = getStringErrorMessage(error, reason);
    const step = defaultData?.opportunityId
      ? "Opportunity Conversion"
      : "Create Project Error";

    await saveAddedLogs({
      recordId: `${step} - ${dayjs().format("MM/DD/YYYY HH:mm:ss")}`,
      recordName: defaultData
        ? defaultData?.opportunityAddress
        : projectAddress || "Create Project Error",
      actionType: "Error",
      category: "Project Modal Errors",
      topic: reason,
      currentData: { message, path: window.location.pathname, reason, step },
      previousData: {
        message: "-||-",
        path: "-||-",
        reason: "-||-",
        step: "-||-",
      },
      updatedKeys: ["message", "path", "reason", "step"],
    });
  };

  const resetProjectDetails = () => {
    form.resetFields([
      ...overviewStepFormItemNames,
      ...dobStepFormItemNames,
      ...subcontractorsStepFormItemNames,
    ]);
    setSelfCompKey(uuidv4());
    setCurrentStep(0);
    setCompletedSteps({});
    setSelectedServices({});
    setSelectedEstimations([]);
    setDobViolations([]);
    setUseExistingData(false);
    setDobViolations([]);
    setValidateStep({});
    setAltAddresses([]);
    setAccountName("");
    setProjectAddressInfo("");
    setProjectAddress("");
    setSaving(false);
  };
  //added this cause i need to get the data of project addres when a opportunity is converted
  const existingLat = existingOpportunity.opportunityLatitude;
  const existingLng = existingOpportunity.opportunityLongitude;
  const [recordId, setRecordId] = useState();
  //region SAVE PROJECT
  const postProject = async (folders = {}, action) => {
    let res;
    const key = "projectModalLoadingMsg";

    const primaryContact = form.getFieldValue("primaryContact");
    const formContacts = form.getFieldValue("contacts") || [];

    const contactIdObj = contacts.reduce(
      (
        acc,
        {
          contactFirstName = "",
          contactLastName = "",
          contactId,
          contactEmail = "",
          contactRole = "",
        }
      ) => ({
        ...acc,
        [contactId]: {
          id: contactId,
          name: `${contactFirstName} ${contactLastName}`,
          email: contactEmail,
          role: contactRole,
        },
      }),
      {}
    );
    const teamsConfiguration = updateTeamsConfiguration(
      userConfiguration,
      existingOpportunity?.teamsConfiguration || selectedTeam,
      [],
      true
    );
    setSelectedTeam([]);

    // return console.log("BODY OBJECT : ", bodyObj());
    setVisibleCreationProgress({ action });
    updateProgressStatus({ updatingRecord: "executing" });

    const projectBody = bodyObj();
    let projectLat = !!useExistingData ? existingLat : projectLatitude;
    let projectLng = !!useExistingData ? existingLng : projectLongitude;

    if (!projectLat || !projectLng) {
      const projectLocationData = await getCoordinatesAndZip(
        projectBody?.projectAddress
      ).catch((err) => {
        console.log("Error getting coordinates for Opportunity", err);
        message.error("Coordinates for this address were not found.");
        return;
      });
      projectLng = projectLongitude?.coordinates?.lng;
      projectLat = projectLocationData?.coordinates?.lat;
    }

    await API.post(apiRoutes.projects, `/${apiRoutes.projects}`, {
      body: {
        ...projectBody,
        accountId,
        // teamsConfiguration: items,
        requestId,
        borough: addBorough(),
        opportunityId: !!useExistingData && existingOpportunity?.opportunityId,
        binNumber: form.getFieldValue("binNumber"),
        projectTaskList:
          // defaultData?.projectTaskList ||
          programFields.find(
            ({ fieldName }) => fieldName === "Project Management Task List"
          ).fieldOptions,
        alternativeAddresses: altAddressesInfo.map(
          ({
            address,
            coordinates,
            // address_components
          }) => ({
            address,
            ...coordinates,
            // aptNumber: address_components?.find(
            //   (el) => el.types[0] === "subpremise"
            // )?.long_name,
          })
        ),
        estimations:
          !!useExistingData && existingEstimations
            ? existingEstimations.map(({ estimationId }) => estimationId)
            : [],
        // services: selectedServices,
        projectLatitude: projectLat,
        projectLongitude: projectLng,
        projectPlaceId,
        geoFenceInfo,
        googleDriveFolderIds: folders,
        googleDriveUploads: defaultData?.gDriveUploads || [],
        opportunityType: existingOpportunity?.opportunityType,
        workDetail: existingOpportunity?.workDetail,
        buildingDimensions: existingOpportunity?.buildingDimensions,
        googleDriveUploads: existingOpportunity?.googleDriveUploads,
        proposedConstructionServices:
          existingOpportunity?.proposedConstructionServices,
        teamsConfiguration,
        projectManager: existingOpportunity?.projectManager || [],
        stepperStatus: steps[currentStep]?.title,
        // projectDescription:,
        dobViolations,
        primaryContact: contactIdObj?.[primaryContact],
        contacts: formContacts?.map((contact) => contactIdObj?.[contact]),
        cognitoUserId: clientCognitoUserId,
      },
    })
      .then(async (r = {}) => {
        setVisibleCreationProgress({ ...r, action });
        setRecordId(r.recordId);
        updateProgressStatus({
          updatingRecord: "finished",
          sendingNotification: "executing",
        });

        postEventsAutomationHandler({
          automationName: "Send Project Details",
          category: "Projects",
          dataToDisplayInAutomation: r,
          action: "Create",
        });

        await broadcastNotification(
          "4",
          "onProjectCreation",
          [
            { common: user },
            {
              userName: user,
              currentUser: authenticatedUser?.sub,
              cognitos: getCognitosForNotification(
                userConfiguration,
                teamsConfiguration
              ),
            },
          ],
          r.projectId
        ).then((notificationSent) => {
          updateProgressStatus({
            sendingNotification: !!notificationSent ? "finished" : "hasError",
          });
        });

        handleSavedRecord(r);
        await API.post("jobsites", "/jobsites", {
          body: {
            accountName: { accountId, accountName },
            addressPosition: {
              lat: r?.projectLatitude,
              lng: r?.projectLongitude,
            },
            geofenceInfo: geoFenceInfo,
            googleSheetLink: "",
            jobAddress: r?.projectAddress,
            projectId: r?.projectId,
            jobName: r?.projectName,
            locationRadius:
              geoFenceInfo.find((geoInfo) => !!geoInfo?.type === "Circle")
                ?.geoFenceInfo?.[0]?.circleRadius * 3.281 || 300,
            payrollType: r?.laborRequirement?.includes(
              "Standard Labor - Open Shop"
            )
              ? "Open Shop"
              : r?.laborRequirement,
            services: [],
          },
        }).catch(async (error) => {
          message.error("There was a problem saving jobsite");
          console.log("Jobsite post Error: ", error);
          await postErrorLog({
            error,
            reason: "Error saving jobsite (jobsites - post)",
          });
        });

        await saveAddedLogs({
          recordId: r?.projectId,
          recordName: r?.projectName,
          category: "Projects",
          topic: !!opportunityId ? "Opportunity Conversion" : "",
        });

        setTimeout(() => refreshTable([r]), 0);
        // hideLoading();
        showSuccessMsg({ key });
        let tmpStage = isChangeOrder ? "Awarded Change Order" : "Awarded";
        !!opportunityId &&
          (await API.patch(
            apiRoutes.opportunities,
            `/${apiRoutes.opportunities}/${opportunityId}`,
            {
              body: {
                opportunityStatus: "Converted",
                opportunityStage: tmpStage,
                projectId: r?.projectId,
                accountId,
              },
            }
          )
            .then((e) => {
              completeConversion(e, r);
            })
            .catch(async (e) => {
              console.error("ERROR: ", e);
              await postErrorLog({
                error: e,
                reason:
                  "Error updating opportunity to converted status (opportunities - patch)",
              });
              showErrorMsg({
                content: "Something wrong happened during conversion!",
              });
            }));
        resetProjectDetails();
        if (!!defaultData?.opportunityId || !!opportunityId) {
          let tmpArr = [];
          //condition to check if the conversion is happens directly after creating an estimation
          //in this case the default data object does not have the correct estimations array
          if (defaultData?.estimations?.length > 0) {
            tmpArr = defaultData?.estimations;
          } else {
            tmpArr = opportunityEstimations;
          }
          tmpArr?.forEach(
            async (id) =>
              await API.patch(
                apiRoutes.estimations,
                `/${apiRoutes.estimations}/${id}`,
                {
                  body: { projectId: r?.projectId },
                }
              )
          );
        }
        if (!!requestId) {
          // let requestObject = defaultData;
          // delete requestObject?.projectName;
          await afterSaveRequest({
            newRecordId: r?.projectId,
            // requestId,
            path: "projects",
            // cognitoUserId: userConfiguration?.cognitoUserId,
            moveFilesParams: {
              filesToMove: defaultData?.gDriveUploads || [],
              newParentId: folders?.projectId,
              accessToken,
            },
          });
        }
        res = r;

        setSaving(false);
      })
      ?.catch(async (e) => {
        console.error(e);
        showErrorMsg({ key });
        await postErrorLog({
          error: e,
          reason: "Error creating project (projects - post)",
        });
        updateProgressStatus({ updatingRecord: "hasError" });
      });
    return res;
  };

  const createProject = async (action) => {
    let res;
    const key = "projectModalLoadingMsg";

    setSaving(true);
    let allFolders = {};
    await driveFunctions[!!useExistingData ? "update" : "create"]({
      parentFolderName: form.getFieldValue("projectName"),
      parentId: projectFolderId,
      oldFolders: googleDriveFolderIds,
      updateFrom: "Opportunities",
    })
      .then(async ({ folders = {} }) => {
        allFolders = folders;
      })
      .catch(async (e) => {
        console.error(e);
        showErrorMsg({ key });
        allFolders = null;
        await postErrorLog({
          error: e,
          reason: !!useExistingData
            ? "Error updating project folders"
            : "Error creating project folders",
        });
      })
      .finally(async () => {
        res = await postProject(allFolders, action);
      });
    message.destroy("projectDriveFolders");
    message.destroy("projectModalLoadingMsg");
    message.destroy("windowMessages");
    return res;
  };

  const updateSteps = () => {
    setCompletedSteps((curr) => ({ ...curr, [currentStep]: true }));
    !(currentStep > populatedSteps.length - 2) &&
      setCurrentStep((curr) => curr + 1);
  };

  const onNext = () =>
    !!validateStep ? validateStep(updateSteps) : updateSteps();

  const onCancel = () => {
    setVisible(false);
    setCancelModalVisible(false);
    driveFunctions?.reset();
    onModalCancel();
  };

  // const onEnterPress = (event) => {
  //   if (event.charCode === 13) {
  //     onCancel();
  //   }
  // };

  const onOptionClick = ({ key }) => {
    compareIncluding(key, "Save & Close")
      ? validateStep(() => createProject("onSaveAndClose"))
      : validateStep(createProject);
  };

  const confirmationModalContent = existingOpportunityModalVisible
    ? {
        title: "Opportunity Found",
        text: "There is an existing opportunity matching this information.\n Would you like to use the existing data or start over?",
        confirmButtonText: "Use Existing Data",
        cancelButtonText: "Start Over",
        onConfirm: () => {
          setUseExistingData(true);
          setExistingOpportunityModalVisible(false);
        },
        onCancel: () => {
          form.resetFields(["projectAddress", "accountName"]);
          setAccountName("");
          setProjectAddress("");
        },
        setVisible: setExistingOpportunityModalVisible,
      }
    : { onConfirm: onCancel, setVisible: setCancelModalVisible };
  //region FETCH DATA
  useEffect(() => {
    fetchModalData({
      routes: apiRoutes,
      // setLoading,
      setClients,
      setContacts,
      setProjects,
      setAttorneys,
      setExpeditors,
      setEstimations,
      setElectricians,
      setOpportunities,
      setBoomCompanies,
      setSafetyCompanies,
      setInsuranceBrokers,
      setProjectExecutives,
    });
    setInitialValues(form.getFieldsValue());
  }, []);

  useEffect(() => {
    setSelectedServices((prev) =>
      selectedEstimations.reduce(
        (acc, { estimationId }) =>
          !!prev[estimationId]
            ? {
                ...acc,
                [estimationId]: prev[estimationId],
              }
            : acc,
        {}
      )
    );
  }, [JSON.stringify(selectedEstimations)]);
  // console.log("laborTypes: ", laborTypes);
  useEffect(() => {
    if (!!programFields.length) {
      const fieldObj = programFields?.reduce(
        (acc, { fieldName, fieldOptions }) => ({
          ...acc,
          [fieldName]: fieldOptions,
        }),
        {}
      );
      setSalesPerson(fieldObj["Sales person"]);
      setTaxExemptOptions(fieldObj["Tax Exempt options"]);
      setLaborTypes(fieldObj["Labor Types"]);
      setInsuranceTypes(fieldObj["Insurance Types"]);
    }
    setUsers(filterPeopleList(userConfiguration.allUsers?.Items));
  }, [programFields, userConfiguration]);

  useEffect(() => {
    if (!isEmpty(existingProject)) {
      const fieldsToReset = !!proppedAccountName
        ? ["projectAddress", "projectName"]
        : ["accountName", "projectAddress", "projectName"];
      message.warning("There is an existing project with this information!");
      form.resetFields(fieldsToReset);
      if (!proppedAccountName) setAccountName("");
      setProjectAddress("");
      setProjectAddressInfo("");
    }
  }, [JSON.stringify(existingProject)]);

  useEffect(() => {
    if (!!projectAddress) {
      setProjectAddressInfo({
        coordinates: { lat: defaultData.lat, lng: defaultData.lng },
      });
    }
  }, [projectAddress]);

  useEffect(() => {
    if (!defaultData.opportunityId && !!useExistingData) {
      setUseExistingData(false);
    }
  }, [projectAddress]);

  useEffect(() => {
    if (!!proppedAccountName) {
      form.setFieldValue("accountName", proppedAccountName);
      setAccountName(proppedAccountName);
    }

    if (!!defaultData?.opportunityId) {
      form.setFieldsValue({
        ...defaultData,
        projectAddress: defaultData?.opportunityName,
        projectName: defaultData?.opportunityAddress,
        primaryContact: defaultData?.leadObject,
        proposalStartDate: !!defaultData?.proposalStartDate
          ? dayjs(defaultData?.proposalStartDate)
          : null,
      });
      setUseExistingData(true);
      setAltAddresses(
        defaultData?.alternativeAddresses.map(({ address }) => address)
      );
      setProjectAddress(defaultData?.opportunityAddress);
    }
    if (!!defaultData?.projectName) {
      form.setFieldsValue({
        ...defaultData,
      });
      form.setFieldValue("projectName", defaultData?.projectName);

      setProjectAddress(defaultData?.projectName);
    }
  }, [proppedAccountName]);

  useEffect(() => {
    if (!proppedAccountName || !visible || !clients?.length) return;
    const doesClientExist = clients.some(
      ({ accountName }) => accountName === proppedAccountName
    );

    if (!doesClientExist) {
      setWannaCreateClientModalVisible(true);
    }
  }, [proppedAccountName, clients, visible]);

  useEffect(() => {
    if (
      isEmpty(existingProject) &&
      !isEmpty(existingOpportunity) &&
      !defaultData?.opportunityId &&
      projectAddress &&
      accountName &&
      compareAddressesWithRgx(
        projectAddress,
        existingOpportunity?.opportunityAddress
      ) &&
      compareIncluding(accountName, existingOpportunity?.accountName)
    ) {
      setExistingOpportunityModalVisible(true);
    }
  }, [JSON.stringify(existingOpportunity), projectAddress]);

  useEffect(() => {
    (async () => {
      const infos = await Promise.all(
        altAddresses.map(async (address) => {
          if (Object.getPrototypeOf(address).constructor.name === "Object") {
            return {
              address: address.address,
              ...(await getCoordinatesAndZip(address.address)),
            };
          } else {
            return {
              address,
              ...(await getCoordinatesAndZip(address)),
            };
          }
        })
      );
      setAltAddressesInfo(infos);
    })();
  }, [altAddresses]);

  const saveOptions = ["Save & Close", "Save & New"];

  const tourDbSteps =
    programFields
      ?.find(({ fieldName }) => fieldName === "Tutorials Steps")
      ?.fieldOptions?.find(
        ({ categoryName }) => categoryName === "projectModal"
      )?.steps || [];

  function mapTourRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        title: tourTitle(step?.title, () => {
          setShowVideoTutorial(true);
          setTourOpen(false);
        }),
        description: step?.description,
        target: () => stepsMapHelper(step?.title),
        className: `custom-tour-light`,
      };
    });
    return newSteps;
  }

  const tourSteps =
    currentStep === 0
      ? mapTourRefs(tourDbSteps.slice(0, 4))
      : currentStep === 1
      ? mapTourRefs(tourDbSteps.slice(4, 9))
      : currentStep === 2
      ? mapTourRefs(tourDbSteps.slice(9, 11))
      : currentStep === 3
      ? mapTourRefs(tourDbSteps.slice(11, 13))
      : [];
  //region JSX
  return (
    <>
      <FullScreenModal
        {...{
          key: selfCompKey,
          visible,
          title: (
            <CustomModalHeader
              title="New Project"
              onClick={() => {
                setTourOpen(true);
              }}
            />
          ),
          onCancel: () => {
            if (formChanged) {
              setCancelModalVisible(true);
            } else {
              onCancel();
            }
          },

          className: `projectModalContainer ${
            isDarkMode && "projectModalContainerDark"
          }`,
          style: { minWidth: "90vw", maxWidth: "100vw", height: "85vh" },
        }}
      >
        {!!projectAddress && (
          <div className="addressTitle">{projectAddress}</div>
        )}
        <Form
          {...{
            form,
            style: { width: "100%" },
            onFieldsChange() {
              setFormChanged(true);
            },
          }}
        >
          <Stepper
            {...{
              className: "projectStepper",
              currentStep,
              setCurrentStep,
              steps: populatedSteps,
              componentContainerClassName: "projectStep",
              geoFenceAccess,
            }}
          />
        </Form>
        <div className="footerButtonsProject">
          <MondayButton
            className="mondayButtonRed"
            Icon={<XIcon />}
            style={{}}
            onClick={() => {
              if (formChanged) {
                setCancelModalVisible(true);
              } else {
                onCancel();
              }
            }}
            tooltipCategory="Projects"
            tooltipKey="cancel"
          >
            Cancel
          </MondayButton>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {(geoFenceAccess?.length === 0
              ? currentStep !== populatedSteps.length - 2 && (
                  <MondayButton
                    className={
                      projectAddress === null
                        ? "mondayButtonGrey"
                        : "mondayButtonBlue"
                    }
                    Icon={<NextIconNew />}
                    onClick={onNext}
                    disabled={projectAddress === null}
                    tooltipCategory="Projects"
                    tooltipKey="next"
                  >
                    Next
                  </MondayButton>
                )
              : currentStep !== populatedSteps?.length - 1) && (
              <MondayButton
                className={
                  projectAddress === null
                    ? "mondayButtonGrey nextButton"
                    : "mondayButtonBlue nextButton"
                }
                Icon={<NextIconNew />}
                onClick={onNext}
                disabled={projectAddress === null}
                tooltipCategory="Projects"
                tooltipKey="next"
              >
                Next
              </MondayButton>
            )}
            {geoFenceAccess?.length === 0
              ? currentStep === populatedSteps?.length - 2 && (
                  <DropdownWrapper
                    // key={i}
                    dropdownOptions={saveOptions}
                    onClick={onOptionClick}
                    dropdownDisabled={!!saving}
                  >
                    <MondayButton
                      // key={i}
                      Icon={<CheckIconModal />}
                      onClick={() => {
                        createProject("onSaveAndContinue");
                      }}
                      disabled={!!saving}
                      className={`${
                        !!saving ? "disabled" : "secondary"
                      }FooterButton mondayButtonGreen`}
                      style={{
                        textTransform: "capitalize",
                      }}
                      tooltipCategory="Projects"
                      tooltipKey="finish"
                    >
                      Finish
                    </MondayButton>
                  </DropdownWrapper>
                )
              : currentStep === populatedSteps?.length - 1 && (
                  <DropdownWrapper
                    // key={i}
                    dropdownOptions={saveOptions}
                    onClick={onOptionClick}
                    dropdownDisabled={!!saving}
                  >
                    <MondayButton
                      // key={i}
                      Icon={<CheckIconModal />}
                      onClick={() => {
                        createProject("onSaveAndContinue");
                      }}
                      disabled={!!saving}
                      className={`${
                        !!saving ? "disabled" : "secondary"
                      }FooterButton mondayButtonGreen`}
                      style={{
                        textTransform: "capitalize",
                      }}
                      tooltipCategory="Projects"
                      tooltipKey="finish"
                    >
                      Finish
                    </MondayButton>
                  </DropdownWrapper>
                )}
          </div>
        </div>
      </FullScreenModal>
      {/* <ConfirmationModal
        visible={cancelModalVisible || existingOpportunityModalVisible}
        {...confirmationModalContent}
      /> */}
      <WarningModal
        visible={cancelModalVisible || existingOpportunityModalVisible}
        setVisible={confirmationModalContent.setVisible}
        title={confirmationModalContent?.title || "Warning message"}
        closable={true}
        className="logout-warning-modal"
        onKeyPress={(e) => onEnterPress(e)}
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningTriangle />
          </span>
          <p>
            {confirmationModalContent?.text ||
              "Are you sure you want to cancel?"}
          </p>
          <div className="buttons">
            <MondayButton
              onClick={confirmationModalContent?.onCancel}
              Icon={<XIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={confirmationModalContent?.onConfirm}
              Icon={<TickIcon width={17} height={17} />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
      {/* <ConfirmationModal
        {...{
          visible: wannaCreateClientModalVisible,
          setVisible: setWannaCreateClientModalVisible,
          onConfirm: () => setClientModalVisible(true),
          onCancel: () => {
            onModalCancel();
            setVisible(false);
          },
          title: "Create Client",
          text: "We could not find any client with this account name. Do you want to create one?",
        }}
      /> */}
      <WarningModal
        visible={wannaCreateClientModalVisible}
        setVisible={setWannaCreateClientModalVisible}
        title="Create Client"
        closable={true}
        className="logout-warning-modal"
        onKeyPress={(e) => onEnterPress(e)}
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningTriangle />
          </span>
          <p style={{ textAlign: "center" }}>
            We could not find any client with this account name. Do you want to
            create one?
          </p>
          <div className="buttons">
            <MondayButton
              onClick={() => {
                onModalCancel();
                setVisible(false);
              }}
              Icon={<XIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={() => {
                setClientModalVisible(true);
                setWannaCreateClientModalVisible(false);
              }}
              Icon={<TickIcon width={17} height={17} />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
      {clientModalVisible && (
        <ClientModal
          saveOnly
          {...{
            visible: clientModalVisible,
            setVisible: setClientModalVisible,
            refreshTable: onClientCreated,
            creatingOnProjectView: true,
            account: leadData,
            preventContinue: true,
            lead: leadData,
            onContactCreated,
            onCancel: () => {
              onModalCancel();
              setVisible(false);
            },
          }}
        />
      )}
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={tourSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
      {showVideoTutorial && (
        <PlayVideoTutorial
          {...{
            visible: showVideoTutorial,
            setVisible: setShowVideoTutorial,
            url: videoTutorialLink,
            title: "Create Project Tutorial",
          }}
        />
      )}

      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Projects",
            actionType: "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              setVisibleCreationProgress(false);
              const { action, projectId } = visibleCreationProgress;

              if (!!action) {
                setVisible(false);
                if (!!nextStepHandler) {
                  nextStepHandler();
                  return;
                }
                action === "onSaveAndContinue" &&
                  !!!isNextStep &&
                  navigate(`/projects/${projectId || recordId}`);
              }
            },
          }}
        />
      )}
    </>
  );
};

export default ProjectModal;
