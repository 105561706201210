import {
  useRef,
  useMemo,
  useState,
  forwardRef,
  useContext,
  useImperativeHandle,
} from "react";
import { message } from "antd";
import { useSelector } from "react-redux";
import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";

import { StatusColor } from "../payrollLiveTypes";
import PayrollLiveContext from "../PayrollLiveContext";
import {
  StoreType,
  CoordinateType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import ScheduleMarker from "../components/ScheduleMarker/ScheduleMarker";
import { JobsiteMarker, EmployeeMarker, MapStatusCards } from "../components";
import {
  MAP_DARK,
  MAP_DEFAULT,
  NEW_YORK_LOCATION,
} from "src/components/SidebarPages/Scheduling/Tabs/SchedulingMap/schedulingMapData";
import PayrollLiveMapSidebar from "../components/PayrollLiveMapSidebar/PayrollLiveMapSidebar";
import { SmallLoadableComp } from "src/components/Sidebars/components/SmallLoadableComp/SmallLoadableComp";

import "./PayrollLiveMap.scss";

type MapInstanceType = google.maps.Map;

const punchStatusColor = {
  In: "#00a464",
  Out: "#787677",
  "No Punch": "#f04f4e",
};

const PayrollLiveMap = forwardRef((_, ref) => {
  const darkMode = useSelector((state: StoreType) => state.darkMode.isDarkMode);
  const programFields = useSelector(
    (state: StoreType) => state.programFields.programFields
  );
  const { schedules, employeesReport, filteredEmployees, filteredSchedules } =
    useContext(PayrollLiveContext);

  const [map, setMap] = useState<MapInstanceType | undefined>();
  const [directions, setDirections] = useState<
    undefined | google.maps.DirectionsResult
  >();

  const mapComponentRef = useRef(null);

  function handleMapLoad(mapInstance: MapInstanceType) {
    setMap(mapInstance);
  }

  function fitBoundsToMarkers(listOfLocations: Array<CoordinateType>) {
    if (map && listOfLocations.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      listOfLocations.forEach((marker) => {
        if (marker?.lat) {
          bounds.extend(marker);
        }
      });
      map.fitBounds(bounds);
    }
    if (!listOfLocations?.length) {
      const empCoordinates = employeesReport.flatMap((emp) =>
        emp?.punchCoordinates?.lat ? emp.punchCoordinates : []
      );
      const scheduleCoordinates = schedules.flatMap((sch) =>
        sch?.addressPosition?.lat ? sch.addressPosition : []
      );
      const bounds = new window.google.maps.LatLngBounds();
      empCoordinates.concat(scheduleCoordinates).forEach((marker) => {
        bounds.extend(marker);
      });
      map.fitBounds(bounds);
    }
  }

  async function getDirections({
    origin,
    travelMode,
    destination,
  }: {
    origin: CoordinateType;
    destination: CoordinateType;
    travelMode: google.maps.TravelMode;
  }) {
    const direction = new google.maps.DirectionsService();

    return await direction
      .route(
        {
          origin,
          destination,
          travelMode: travelMode || window.google.maps.TravelMode.WALKING,
        },
        (directionRes) => {
          message.destroy();
          setDirections(directionRes);
          return directionRes;
        }
      )
      .catch((err) => {
        message.error({
          content: "Something went wrong while getting directions",
          key: "directionService",
        });
        console.log("Error getting directions: ", err);
      });
  }

  const statusColors: Array<StatusColor> = useMemo(() => {
    if (!programFields?.length) {
      return [];
    }
    return programFields.find(({ fieldName }) => fieldName === "Status colors")
      .fieldOptions.Scheduling;
  }, [programFields]);

  const mapOptions = useMemo(() => {
    return {
      clickableIcons: false,
      fullscreenControl: false,
      styles: darkMode ? MAP_DARK : MAP_DEFAULT,
      mapTypeId: google?.maps?.MapTypeId?.TERRAIN || "terrain",
      mapTypeControlOptions: google?.maps
        ? {
            position: google?.maps?.ControlPosition?.BOTTOM_LEFT,
            style: google?.maps?.MapTypeControlStyle?.HORIZONTAL_BAR,
          }
        : {},
    };
  }, [darkMode, google?.maps]);

  const scheduleMarkers = useMemo(() => {
    return filteredSchedules?.length
      ? filteredSchedules.map((el) => {
          if (!el.addressPosition?.lat) {
            return null;
          }
          return el?.jobsiteId ? (
            <JobsiteMarker data={el} key={el.jobsiteId} onClick={() => {}} />
          ) : (
            <ScheduleMarker
              data={el}
              key={el.scheduleId}
              statusColors={statusColors}
              // onClick={() => onScheduleFilter(el.projectId)}
            />
          );
        })
      : null;
  }, [filteredSchedules, statusColors]);

  const employeeMarkers = useMemo(() => {
    return filteredEmployees?.length
      ? filteredEmployees.flatMap((el) => {
          return el?.punchCoordinates?.lat ? (
            <EmployeeMarker
              data={el}
              key={el?.fingerCheckId}
              onClick={() => {}}
              color={el?.color || punchStatusColor?.[el.liveStatus]}
            />
          ) : (
            []
          );
        })
      : null;
  }, [filteredEmployees]);

  useImperativeHandle(
    ref,
    () => {
      return {
        directions,
        getDirections,
        setDirections,
        fitBoundsToMarkers,
      };
    },
    [map, employeesReport, schedules, directions]
  );

  return (
    <section
      className={`payroll-live-map-container ${
        darkMode ? "payroll-live-map-container-dark" : ""
      }`}
    >
      <PayrollLiveMapSidebar />
      <SmallLoadableComp loading={!employeesReport?.length}>
        <GoogleMap
          zoom={12}
          id="mapContainer"
          options={mapOptions}
          // onClick={onMapClick}
          ref={mapComponentRef}
          onLoad={handleMapLoad}
          center={NEW_YORK_LOCATION}
          mapContainerClassName="payroll-live-map"
        >
          <MapStatusCards />
          {scheduleMarkers}
          {employeeMarkers}
          {!!directions && (
            <DirectionsRenderer
              directions={directions}
              options={{
                suppressMarkers: true,
              }}
            />
          )}
        </GoogleMap>
      </SmallLoadableComp>
    </section>
  );
});

export default PayrollLiveMap;
