import { Form, Modal } from "antd";
import { UserAccessWrites } from "../../../components/pages/Settings/settingsComponents/Management/EditUser/components";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Groups } from "../../../components/pages/Settings/settingsComponents/Roles/RolesData";
import { generateAccessConfiguration } from "../../../components/pages/Settings/settingsComponents/Management/utils";
import { RolesAccessWrites } from "../../../components/pages/Settings/settingsComponents/Roles/components/UserRoles/components";
import { XIcon } from "../../../components/SidebarPages/Communication/assets";
import "./EditNewUserAccess.scss";
import "src/components/pages/Settings/settingsComponents/Roles/components/UserRoles/RolesAccess.scss";
import { TickIcon } from "../../../components/InternalChat/assets/icons";
import { MondayButton } from "../../../components/commonComponents";
import isEmpty from "lodash/isEmpty";

function EditNewUserAccess({
  department,
  currentRole,
  onConfirm = () => {},
  accessDisabled = false,
  proppedAccessConfiguration = {},
}) {
  const [form] = Form.useForm();
  const [foldersAccess, setFoldersAccess] = useState({});
  const [allFoldersAccess, setAllFoldersAccess] = useState({});
  const [accessConfiguration, setAccessConfiguration] = useState({});
  const [unchangedData, setUnchangedData] = useState();

  const [
    { accessToken },
    { userConfiguration },
    { userGroups: userGroupsData },
    { hotCredentials },
    { isDarkMode },
    { notificationSettings },
  ] = useSelector((state) => [
    state.accessToken,
    state.userConfig,
    state.userGroups,
    state.hotCredentials,
    state.darkMode,
    state.notificationSettings,
  ]);

  const departmentGroups = useMemo(
    () =>
      Groups({
        groups: userGroupsData.filter(
          (group) =>
            group.departmentName.toLowerCase() === department.toLowerCase()
        ),
      }),
    [userGroupsData, department]
  );

  const roleData = useMemo(
    () => departmentGroups.find((group) => group[currentRole])[currentRole],
    [departmentGroups, currentRole]
  );

  const gDriveCredentials = hotCredentials?.filter(
    ({ isGDrive = "" }) => !!isGDrive
  );

  const adminDepartmentGroups = Groups({
    groups: userGroupsData.filter(
      (group) =>
        group.departmentName.toLowerCase() === "Executive".toLowerCase()
    ),
  });

  const allSuperAdminRoleData = adminDepartmentGroups.find(
    (group) => group["Super Admin"]
  )["Super Admin"];

  const { ["Notification Preferences"]: _, ...superAdminRoleData } =
    allSuperAdminRoleData;

  useEffect(() => {
    form.setFieldsValue(
      gDriveCredentials.reduce((acc, item) => {
        acc[item.credentialKey] = "none";
        return acc;
      }, {})
    );
  }, []);

  useEffect(() => {
    if (!isEmpty(proppedAccessConfiguration)) {
      setAccessConfiguration(proppedAccessConfiguration);
    } else {
      const newRoleData = generateAccessConfiguration(
        superAdminRoleData,
        roleData
      );

      setUnchangedData(newRoleData);
      setAccessConfiguration(newRoleData);
    }
  }, [roleData, userGroupsData]);

  function confirmChanges() {
    const routeConfig = accessConfiguration.Route.filter((route) => route.read);
    const sidebarConfig = accessConfiguration.Sidebar.filter(
      (sidebar) => sidebar.read
    );
    const driveAccessRights = form.getFieldsValue();

    onConfirm({ routeConfig, sidebarConfig, driveAccessRights });
  }

  return (
    <div
      className={`settingPane roles-access-tab editNewUserAccess ${
        accessDisabled ? "review-step" : ""
      } ${isDarkMode && "settingPaneDark"} `}
    >
      <div className="bodyContainer">
        <RolesAccessWrites
          {...{
            roleData: {
              Route: [],
              Sidebar: [],
              "Drive Access Rights": {},
            },
            accessConfiguration,
            setAccessConfiguration,
            notificationSettings,
            isDarkMode,
            currentRole,

            form,
            gDriveCredentials,
            accessToken,
            disabled: accessDisabled,
          }}
        />
      </div>
    </div>
  );
}

export default EditNewUserAccess;
