import { getInitials } from "../../../../../utils";
import coreIcon from "../../../../commonComponents/ExportPreview/coreIcon.svg";

import emergencyContactsImages from "../assets";
function convertSafetyContactsData(data, pageSize) {
  const dataSections = [];
  if (!Array.isArray(data)) {
    return dataSections;
  }
  const rowData = data.find((section) => section?.title === "rowData")?.body;
  const projectName = {
    class: "safety-contacts-main-header projectName",
    text: {
      paragraphs: [
        {
          text: `Project Name: ${rowData?.projectName}`,
          bold: true,
          customTag: "h4",
        },
      ],
    },
  };
  dataSections.push(projectName);

  const emergencyContacts = data.find(
    (el) => el?.title === "Emergency Contact Information"
  )?.body;

  const emergencyContactsDiv = {
    class: "emergency-contacts-section",
    text: {
      paragraphs: [
        {
          customRender: `<div class='emergency-contacts'>
            <div class='contact-header'>Emergency Contact Information</div>
          <div class='contact-container'>
            ${(emergencyContacts || [])
              ?.map(
                ({ key, value, img }) =>
                  `<div class='contact'>
                <img class='contact-image' src='${emergencyContactsImages[img]}' alt='${key}' />
                <a href='tel:${value}' class='contact-number'>${value}</a>
                <div class='contact-text'><b>${key}</b></div>
                </div>`
              )
              .join("")}
          </div>
          </div>`,
        },
      ],
    },
  };

  dataSections.push(emergencyContactsDiv);

  const safetyContacts =
    data?.find((el) => el?.title === "Safety Contacts")?.body || [];
  if (!!Array.isArray(safetyContacts) && safetyContacts.length > 0) {
    const removeHiddenContacts = safetyContacts.filter((el) => !el.isHidden);
    let rows = [];
    for (const contact of removeHiddenContacts) {
      const { nameOfUser, phoneNumber, userName, groupName, profileImage } =
        contact;
      const singleContact = `<div class='contact c-contact'> 
        <div class='c-avatar'>
        ${
          profileImage
            ? `<img src='${profileImage}' alt='${nameOfUser}' class='avatar-image' />`
            : getInitials(nameOfUser)
        } </div>
          <div class='contact-text'><b>${nameOfUser}</b></div>
          <a class='c-detail' href='tel:${phoneNumber}'>${phoneNumber}</a>
          <a class='c-detail' href='mailto:${userName}'>${userName}</a>
      </div>`;
      rows.push(singleContact);
    }
    const safetyContactsDiv = {
      class: "emergency-contacts-section core-safety-contacts",
      text: {
        paragraphs: [
          {
            customRender: `<div class='emergency-contacts'>
            <div class='contact-header'>Core Safety Contacts</div>
          <div class='core-contact-container'>
            ${rows.join("")}
          </div>
          </div>`,
          },
        ],
      },
    };

    dataSections.push(safetyContactsDiv);
  }

  const nearbyHospitals = data?.find((el) => el?.title === "Hospitals Nearby");
  console.log("convertSafetyContactsData ~ nearbyHospitals:", {
    nearbyHospitals,
  });
  let hospitalSections = [];
  if (nearbyHospitals) {
    const removedAddresses = nearbyHospitals?.removedAddresses;
    const showAll = nearbyHospitals?.showAll;

    if (showAll && nearbyHospitals?.body?.length) {
      const filteredHospitals = nearbyHospitals?.body.filter(
        (el) => !removedAddresses.includes(el.place_id)
      );
      for (const [index, hospital] of filteredHospitals.entries()) {
        const {
          name,
          vicinity,
          distance,
          hours,
          phone,
          directionImage,
          place_id,
          address,
        } = hospital;

        const { googleMapLink, imageLink } = directionImage || {};
        const newHours = !!shortenHours(hours)?.length
          ? shortenHours(hours)?.join(", ")
          : "Open hours not available";

        const hospitalData = `
              <div class='data-section hospital-section hospital-${index}'>
                <div class='emergency-contacts'>
                <div class='contact-header'>${name}</div>
                <div class='hospital-data'>
                  <div><b>Address:</b> ${address}</div>
                  <div><b>Phone Number:</b>  <a href="tel:${
                    phone || ""
                  }" class="h-number">${phone || "Not Available"}</a></div>
                  <div><b>Open Hours:</b> ${newHours}</div>
                  <div><b>Driving Distance:</b> ${
                    distance?.driving?.distance?.text
                  }</div>
                  </div>
                ${
                  imageLink
                    ? `<a href=${googleMapLink}> <img src="${imageLink}" class="route-image" /></a>`
                    : null
                }
                </div>
              </div>`;
        hospitalSections.push(hospitalData);
      }
    }
  }
  const secondPage = {
    text: {
      paragraphs: [
        {
          customRender: `<div class='second-page-container'>
          <img class='headerSection export-preview-header' src='${coreIcon}' class='baseLogo' />
            <h4 class='safety-contacts-main-header nearby-title'>Hospitals Nearby</h4>
            <h4 class='safety-contacts-main-header nearby-title2'> Project Name: ${
              rowData?.projectName
            }</h4>
            ${hospitalSections.join("")}
          </div>`,
        },
      ],
    },
  };

  dataSections.push(secondPage);

  return dataSections;
}

export default convertSafetyContactsData;

function shortenHours(hours) {
  if (!hours) {
    return [];
  }
  const grouped = {};

  hours.forEach((hour) => {
    const [day, time] = hour.split(": ");
    if (time.includes("Open 24 hours")) {
      grouped["Open 24 hours"] = true;
    } else {
      if (!grouped[time]) {
        grouped[time] = [];
      }
      grouped[time].push(day);
    }
  });

  const result = [];
  for (const [time, days] of Object.entries(grouped)) {
    if (time === "Open 24 hours") {
      result.push(`Monday-Sunday: Open 24 hours`);
    } else {
      const dayRange = days.length === 7 ? "Monday-Sunday" : days.join(", ");
      result.push(`${dayRange}: ${time}`);
    }
  }

  return result;
}
