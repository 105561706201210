import { Fragment, memo, useMemo, useState } from "react";
import {
  BorderedTextCard,
  InputComponent,
  LabeledInput,
} from "../../../Fleet/components";
import { RenderDynamicComponents } from "../../../../Header/forms/Components";
import SafetyAssignedTo from "../formData/Components/SafetyAssignedTo";
import { SignControllerButtons } from "../../../Fleet/view/pageView/components/SingleTruckInspections/modals/VehicleInspectionModal/components";
import SafetyTeamSelect from "../formData/Components/SafetyTeamSelect";
import StatementsFields from "./StatementsFields";
import IncidentComments from "../../../Incidents/Components/IncidentComments";
import SafetyUploaderSection from "./SafetyUploaderSection";
import RichTextEditor from "../../../../commonComponents/RichTextEditor/RichTextEditor";
import Signature from "../../../../commonComponents/Signature/Signature";
import { MondayButtonIcon } from "../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import ProjectSafetyReport from "../../../Projects/ProjectSafetyReport/ProjectSafetyReport";
import { SafetyIcon } from "../../../../../assets";

/**
 * Renders the SafetyFormSection component.
 *
 * @component
 * @param {Object} form - The form object.
 * @param {string} sign - The sign image source.
 * @param {Array} [teams=[]] - The array of teams.
 * @param {string} [nameKey=""] - The name key.
 * @param {Object} [rowObject={}] - The row object.
 * @param {Array} [formFields=[]] - The array of form fields.
 * @param {string} [accessToken=""] - The access token.
 * @param {number} [witnessCount=0] - The witness count.
 * @param {Array} [selectedTeam=[]] - The array of selected teams.
 * @param {boolean} [isDarkMode=false] - Indicates if the dark mode is enabled.
 * @param {Array} [uploadedFiles=[]] - The array of uploaded files.
 * @param {Object} [driveFunctions={}] - The drive functions object.
 * @param {Function} [onDelete=() => {}] - The delete function.
 * @param {boolean} [divClickable=false] - Indicates if the div is clickable.
 * @param {Function} [setSign=() => {}] - The set sign function.
 * @param {Array} [witnessSignings=[]] - The array of witness signings.
 * @param {Function} [setChanges=() => {}] - The set changes function.
 * @param {Object} [userConfiguration={}] - The user configuration object.
 * @param {string} [folderDriveKeyName=""] - The folder drive key name.
 * @param {Function} [refreshTable=() => {}] - The refresh table function.
 * @param {Function} [onTeamSelect=() => {}] - The on team select function.
 * @param {Function} [setAssignedTo=() => {}] - The set assigned to function.
 * @param {Function} [onTeamDeselect=() => {}] - The on team deselect function.
 * @param {boolean} [disabledAllFields=false] - Indicates if all fields are disabled.
 * @param {Function} [onPickerSuccess=() => {}] - The on picker success function.
 * @param {Function} [setWitnessCount=() => {}] - The set witness count function.
 * @param {Function} [setWitnessSignings=() => {}] - The set witness signings function.
 * @param {Function} [setTeamModalVisible=() => {}] - The set team modal visible function.
 * @param {Function} [setWhichSignToChange=() => {}] - The set which sign to change function.
 * @param {Function} [setCancelModalVisible=() => {}] - The set cancel modal visible function.
 * @param {Function} [setSignatureModalVisible=() => {}] - The set signature modal visible function.
 * @param {Object} driveFolder - The drive folder object.
 * @param {boolean} hasMissingFolders - Indicates if there are missing folders.
 * @param {Function} [setAddDriveModal=() => {}] - The set add drive modal function.
 * @param {number} setDocumentationModalFiles - State for setting the list of Files that will be added at the Documentation Modal
 * @returns {JSX.Element} The rendered component.
 */
const SafetyFormSection = ({
  form,
  sign,
  teams = [],
  nameKey = "",
  rowObject = {},
  formFields = [],
  accessToken = "",
  witnessCount = 0,
  selectedTeam = [],
  isDarkMode = false,
  uploadedFiles = [],
  driveFunctions = {},
  onDelete = () => {},
  divClickable = false,
  setSign = () => {},
  witnessSignings = [],
  setChanges = () => {},
  userConfiguration = {},
  folderDriveKeyName = "",
  refreshTable = () => {},
  onTeamSelect = () => {},
  setAssignedTo = () => {},
  assignedTo,
  onTeamDeselect = () => {},
  disabledAllFields = false,
  onPickerSuccess = () => {},
  setWitnessCount = () => {},
  setWitnessSignings = () => {},
  setTeamModalVisible = () => {},
  setCancelModalVisible = () => {},
  driveFolder,
  hasMissingFolders,
  setAddDriveModal = () => {},
  safetyDirectorSignature = null,
  setDocumentationModalFiles = () => {},
  handleOnFileUploadSuccess,
}) => {
  const fields = useMemo(() => {
    let tmp = formFields;
    if (tmp.some(({ fields }) => !!fields[0]?.catIndex)) {
      tmp = tmp.sort((a, b) => a.fields[0].catIndex - b.fields[0].catIndex);
    }
    return tmp;
  }, [formFields]);

  return (
    <Fragment>
      <div className="form-section">
        {fields?.map(({ title, fields }) => {
          let sortedFields = fields;
          if (sortedFields.some((el) => !!el?.index)) {
            sortedFields = sortedFields.sort((a, b) => a.index - b.index);
          }

          let inputFields = sortedFields?.filter(
            (el) => el?.type !== "textEditor"
          );
          const addIndex = inputFields.findIndex(
            (el) => el?.formItemName === nameKey
          );

          let textEditorFields = sortedFields?.filter(
            (el) => el?.type === "textEditor"
          );

          const tmpFieldCondition = fields?.some((el) =>
            el?.formItemName?.toLowerCase()?.includes("preparedon")
          );

          return (
            <BorderedTextCard
              key={title}
              {...{
                title,
                isDarkMode,
              }}
            >
              {RenderDynamicComponents(inputFields, { form })}

              {!!textEditorFields?.length &&
                textEditorFields?.map((el) => (
                  <LabeledInput
                    {...{
                      required: el.required,
                      style: { width: "100%" },
                      label: el.label,
                      disabled: disabledAllFields,
                      formItemName: el.formItemName,
                      form,
                    }}
                  >
                    <RichTextEditor
                      {...{
                        form,
                        isDarkMode,
                        formItemName: el.formItemName,
                        disabled: disabledAllFields,
                        // onBlur: (val) => {
                        //   setChanges(true);
                        //   form.setFieldValue(el.formItemName, val);
                        // },
                      }}
                    />
                  </LabeledInput>
                ))}
              {(title?.toLowerCase() === "additional information" ||
                tmpFieldCondition) && (
                <Fragment>
                  <SafetyAssignedTo
                    {...{
                      isDarkMode,
                      userConfiguration,
                      setAssignedTo,
                      disabledAllFields,
                      assignedTo,
                    }}
                  />
                  <Signature
                    {...{
                      signature: sign,
                      setSignature: setSign,
                      signDisabled: disabledAllFields,
                      removeDisabled: disabledAllFields,
                    }}
                  />
                </Fragment>
              )}
              {title?.toLowerCase() === "general information" && (
                <SafetyTeamSelect
                  {...{
                    teams,
                    selectedTeam,
                    isDarkMode,
                    onTeamSelect,
                    disabledAllFields,
                    onTeamDeselect,
                    setTeamModalVisible,
                  }}
                />
              )}
            </BorderedTextCard>
          );
        })}

        <StatementsFields
          {...{
            form,
            fieldCount: witnessCount,
            setFieldCount: setWitnessCount,
            dynamicSignings: witnessSignings,
            setDynamicSignings: setWitnessSignings,
            disableFields: disabledAllFields,
            isDarkMode,
            rowObject,
          }}
        />

        <BorderedTextCard
          title="Safety Director Signature"
          className=""
          isDarkMode={isDarkMode}
        >
          <InputComponent
            {...{
              label: "Safety Director",
              initialValue: safetyDirectorSignature?.nameOfUser,
              disabled: true,
              noFormItem: true,
            }}
          />
          <Signature
            {...{
              signature: safetyDirectorSignature?.src,
              removeDisabled: true,
              signDisabled: true,
            }}
          />
        </BorderedTextCard>

        <BorderedTextCard
          title="Comments"
          className="notesCard"
          isDarkMode={isDarkMode}
        >
          <IncidentComments
            {...{
              form,
              nameKey,
              rowObject,
              setChanges,
              userConfiguration,
              refreshTable,
              table: "safety",
            }}
          />
        </BorderedTextCard>
      </div>
      <SafetyUploaderSection
        {...{
          accessToken,
          rowObject,
          uploadedFiles,
          driveFunctions,
          onDelete,
          divClickable,
          setChanges,
          folderDriveKeyName,
          disabledAllFields,
          onPickerSuccess,
          setCancelModalVisible,
          objectKey: "safetyApplicationObject",
          driveFolder,
          hasMissingFolders,
          setAddDriveModal,
          setDocumentationModalFiles,
          isCreateDocumentEnabled: true,
          handleOnFileUploadSuccess,
        }}
      />
    </Fragment>
  );
};

export default memo(SafetyFormSection);
