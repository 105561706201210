import { OneTimePasswordConfirmation } from "../../../../../../../../containers/Signup/components";
import EditNewUserAccess from "../../../../../../../../containers/Signup/components/EditNewUserAccess";
import { UserInformation } from "../components";

function userModalSteps({
  form,
  populatedFields,
  user,
  setUser,
  setValidateStepMethod,
  stepsFormDisabled,
  setStepsFormDisabled,
}) {
  return [
    {
      title: "User Information",
      Comp: UserInformation,
      form,
      populatedFields,
      setUser,
      setStepsFormDisabled,
      stepsFormDisabled,
      setValidateStepMethod,
    },
    {
      title: "Confirmation Code",
      Comp: OneTimePasswordConfirmation,
      stepsFormDisabled,
      setStepsFormDisabled,
      setValidateStepMethod,
      form,
    },
    // {
    //   title: "Team & Permissions",
    //   Comp: () => <></>,
    // },
    // {
    //   title: "Configure Access Rights",
    //   Comp: EditNewUserAccess,
    //   department: user?.department || "Executive",
    //   currentRole: user?.role || "Admin",
    // },
    // {
    //   title: "Review & Create",
    //   Comp: EditNewUserAccess,
    //   user,
    //   accessDisabled: true,
    //   accessConfiguration: user?.accessConfiguration,
    //   department: user?.department || "Executive",
    //   currentRole: user?.role || "Admin",
    // },
  ];
}

export default userModalSteps;
