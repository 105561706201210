import { Dayjs } from "dayjs";
import { utils, write } from "xlsx";
import { useSelector } from "react-redux";
import { Form, FormInstance, message, Tooltip } from "antd";
import { UIEvent, useMemo, useState, useContext } from "react";

import { ExcelIcon } from "src/assets";
import { convertSecondsToTime } from "../../utils";
import PayrollLiveContext from "../../PayrollLiveContext";
import { MondayButton } from "../../../../commonComponents";
import {
  StoreType,
  JobsiteType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import CardComponent from "src/components/CardComponent/CardComponent";
import { ScheduleType, EmployeeReportType } from "../../payrollLiveTypes";
import { withinRadius } from "../../../Payroll/Tabs/Activity/components/payrollActivityModalData";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

import "./UnmatchedEntries.scss";

interface Props {
  form: FormInstance<{ searchField: string; reportDate?: Array<Dayjs> }>;
}

const initialIndex = {
  start: 0,
  end: 15,
};

function UnMatchedEntries(props: Props) {
  const { form } = props;
  const {
    mapRef,
    jobsites,
    schedules,
    setFilters,
    onPageSelect,
    employeesReport,
    setEmployeesInfo,
  } = useContext(PayrollLiveContext);
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);

  const [exportDisabled, setExportDisabled] = useState<boolean>(false);
  const [sliceIndex, setSliceIndex] = useState<{ start: number; end: number }>(
    initialIndex
  );

  const punchEnd = Form.useWatch("punchEnd", form);
  const punchStart = Form.useWatch("punchStart", form);
  const searchField = Form.useWatch("searchField", form);

  function onScroll(e: UIEvent<HTMLElement>): void {
    const scrollStep = 10;
    const maxStartIndex = unmatchedEntries.length - scrollStep;
    const maxEndIndex = unmatchedEntries.length - 1;
    let indexPosition = e.currentTarget.scrollTop / 128;

    if (indexPosition >= 7.7 && sliceIndex.end === scrollStep) {
      setSliceIndex(() => ({
        start: 0,
        end: 15 + scrollStep,
      }));
    }

    if (indexPosition >= 18 && sliceIndex.end !== maxEndIndex) {
      setSliceIndex((prev) => {
        let startIndex = prev.start + scrollStep;
        let endIndex = prev.end + scrollStep;
        return {
          start: startIndex > maxStartIndex ? maxStartIndex : startIndex,
          end: endIndex > maxEndIndex ? maxEndIndex : endIndex,
        };
      });
    }

    if (indexPosition <= 0.5) {
      setSliceIndex((prev) => {
        return {
          start: prev.start >= scrollStep ? prev.start - scrollStep : 0,
          end: prev.end > scrollStep ? prev.end - scrollStep : scrollStep,
        };
      });
    }
  }

  function onEmpClick(
    entry: EmployeeReportType & {
      duration?: string;
      distance?: number;
      jobsiteAddress?: string;
    }
  ) {
    onPageSelect("map");
    setFilters((prev) => ({
      ...prev,
      liveStatus: [],
      employeeSearch: "",
      employeeNumber: [],
      schedules: [],
    }));
    setTimeout(() => {
      if (entry?.employeeNumber) {
        setEmployeesInfo(entry?.employeeNumber);
      }
      if (mapRef?.current?.fitBoundsToMarkers) {
        mapRef.current.fitBoundsToMarkers([entry?.punchCoordinates]);
      }
    }, 800);
  }

  function onExcelExport() {
    setExportDisabled(true);
    message.loading({ key: "excelExport", content: "Loading", duration: 0 });
    if (!unmatchedData?.length) {
      message.warning({
        key: "excelExport",
        content: "There are no data to export.",
        duration: 2,
      });
      setExportDisabled(false);
      return;
    }

    try {
      const workbook = utils.book_new();
      const sheetData = unmatchedData.map((el) => ({
        "Employee Id": el?.employeeNumber || "",
        "Employee Name": el?.employeeName || "",
        "Punch Time": el?.punchTime
          ? parseInTz(el?.punchTime).format("hh:mm A")
          : "",
        "Punch Location": el?.punchLocation || "",
        "Job Assigned": el?.jobsiteAddress || "",
        Distance: el?.distance ? `${el?.distance} ft` : "",
        Duration: el?.duration || "",
      }));
      const workSheet = utils.json_to_sheet(sheetData);
      const fileTitle = `Unmatched Punches ${parseInTz().format("MM-DD-YYYY")}`;
      utils.book_append_sheet(workbook, workSheet, fileTitle);

      const excelBufferNoJob = write(workbook, {
        bookType: "xls",
        type: "base64",
      });

      const file = {
        name: fileTitle + ".xls",
        blob: `base64,${excelBufferNoJob}`,
        type: "application/vnd.ms-excel",
      };

      const url = `data:${file.type};${file.blob}`;
      const a = document.createElement("a");
      a.href = url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setExportDisabled(false);
      message.success({
        key: "excelExport",
        content: "File exported successfully!",
        duration: 2,
      });
    } catch (error) {
      setExportDisabled(false);
      message.error({
        key: "excelExport",
        content: "Could not create excel file.",
        duration: 2,
      });
    }
  }

  const unmatchedData = useMemo(() => {
    let returnData = [];
    const inData = (employeesReport || []).filter(
      (el) => el?.liveStatus === "In"
    );
    for (let i = 0; i < inData.length; i++) {
      const empReport = inData[i];

      if (empReport?.color !== "#e9c466") {
        continue;
      }

      const schIndex = schedules.findIndex(
        (sch) => sch.projectId === empReport?.projectId
      );
      const jobIndex = jobsites.findIndex(
        (job) => job.jobsiteId === empReport?.jobsiteId
      );
      let jobMatch: JobsiteType | ScheduleType = undefined;
      if (empReport?.jobsiteId) {
        jobMatch = jobsites?.[jobIndex];
      } else if (empReport?.projectId) {
        jobMatch = schedules?.[schIndex];
      }
      const unMatchEntry = { ...empReport };
      if (jobMatch) {
        const distanceData = withinRadius(
          empReport?.punchCoordinates,
          jobMatch.addressPosition
        );
        const duration = Math.round(distanceData?.distanceInFeet / 4.7);
        Object.assign(unMatchEntry, {
          jobsiteAddress:
            (jobMatch as JobsiteType).jobAddress ||
            (jobMatch as ScheduleType).scheduleAddress,
          distance: distanceData?.distanceInFeet || 0,
          duration: convertSecondsToTime(duration),
        });
      }
      returnData.push(unMatchEntry);
    }

    return returnData;
  }, [jobsites, punchEnd, schedules, punchStart, searchField, employeesReport]);

  const unmatchedEntries = useMemo(() => {
    let unMatched = [];
    for (let i = 0; i < unmatchedData.length; i++) {
      let pass = true;
      const entry = unmatchedData[i];

      if (
        searchField?.length &&
        !entry?.employeeName?.toLowerCase()?.includes(searchField.toLowerCase())
      ) {
        if (sliceIndex.start) {
          setSliceIndex(initialIndex);
        }
        pass = false;
      }

      if (punchStart) {
        pass =
          parseInTz(entry?.punchTime).valueOf() >=
          punchStart?.startOf("m")?.valueOf?.();
      }

      if (punchEnd) {
        pass =
          parseInTz(entry?.punchTime).valueOf() <=
          punchEnd?.startOf("m")?.valueOf?.();
      }

      if (pass) {
        unMatched.push(entry);
      }
    }

    return unMatched.map(
      (
        entry: EmployeeReportType & {
          jobsiteAddress?: string;
          distance?: number;
          duration?: string;
        }
      ) => {
        return (
          <div
            key={entry?.punchId}
            className={`unmatched-card ${
              entry?.jobsiteId ? "no-schedule-match" : ""
            }`}
          >
            <div className="unmatched-header">
              <Tooltip title="Go to employee's punch">
                <label onClick={() => onEmpClick(entry)}>
                  {entry?.employeeName}
                </label>
              </Tooltip>
              {entry?.jobsiteId ? <span>No Schedule Match</span> : null}
            </div>
            <div className="unmatched-body">
              <div className="main-data-container">
                <div className="data-row">
                  <span>Punch Time: </span>
                  <div>{parseInTz(entry?.punchTime).format("hh:mm A")}</div>
                </div>
                <div className="data-row">
                  <span>Punch Location:</span>
                  <div>{entry?.punchLocation}</div>
                </div>
                <div className="data-row">
                  <span>Designated Job: </span>
                  <div>{entry?.jobsiteAddress}</div>
                </div>
              </div>
              <div className="distance-data">
                <div className="data-row">
                  <span>Distance: </span>
                  <div>{entry?.distance} ft</div>
                </div>
                <div className="data-row">
                  <span>Duration: </span>
                  <div>{entry?.duration}</div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    );
  }, [unmatchedData]);

  return (
    <CardComponent
      title={
        <div className="custom-header">
          <label>Jobsite Unmatched Entries</label>
          <Tooltip title="Export Unmatched Entries">
            <MondayButton
              onClick={onExcelExport}
              disabled={exportDisabled}
              Icon={<ExcelIcon height={23} width={23} />}
              className={darkMode ? "mondayButtonBlue" : "mondayButtonWhite"}
            />
          </Tooltip>
        </div>
      }
      className="unmatched-entries-card"
    >
      <>
        <section className="card-content" onScroll={onScroll}>
          {unmatchedEntries?.length ? (
            unmatchedEntries.slice(sliceIndex.start, sliceIndex.end)
          ) : (
            <div className="empty-unmatched-entries">
              No Jobsite Unmatched Entries
            </div>
          )}
        </section>
        <div className="unmatched-count">
          {unmatchedEntries?.length || 0} Total unmatched entries
        </div>
      </>
    </CardComponent>
  );
}

export default UnMatchedEntries;
