import { API } from "aws-amplify";

/**
 * Function that returns the data of a table that falls in a specific date range
 * @param {string} tableName The table we want to get the data from
 * @param {number[] | string[] | dayjs[]} dateRange The start and end *NEEDS TO HAVE THE SAME FORMAT AS THE SAVED DATA*
 * @param {string} dateKey The key the date is stored on
 * @param {string} [filterKey] The key to filter by
 * @param {any} [filterValue] The value to filter by
 */
async function fetchByDate(
  tableName,
  dateRange,
  dateKey,
  filterKey = null,
  filterValue = null
) {
  let items = [];
  let ExclusiveStartKey = undefined;

  do {
    await API.post("fetchByDateRange", "/fetchByDateRange", {
      body: {
        tableName,
        dateRange,
        dateKey,
        filterKey,
        filterValue,
      },
    }).then(({ items: responseItems, LastEvaluatedKey }) => {
      items = items.concat(responseItems);
      ExclusiveStartKey = LastEvaluatedKey;
    });
  } while (ExclusiveStartKey);

  return items;
}

export default fetchByDate;
