import { fetchAllData } from "../../../../../../SidebarPages/Fleet/utils/fetchAllData";

export const categoriesWithGeofence = [
  {
    categoryName: "Projects",
    categoryEndpoint: "projects",
    primaryKey: "projectId",
    keyName: "geoFenceInfo",
    addressKey: "projectAddress",
    lat: "projectLatitude",
    lng: "projectLongitude",
    accessTitle: "Projects/View",
    withPagination: "true",
  },
  {
    categoryName: "Opportunities",
    categoryEndpoint: "opportunities",
    primaryKey: "opportunityId",
    keyName: "geoFenceInfo",
    addressKey: "opportunityAddress",
    lat: "opportunityLatitude",
    lng: "opportunityLongitude",
    accessTitle: "Opportunities/View",
    withPagination: "true",
  },
  {
    categoryName: "Jobsites",
    categoryEndpoint: "jobsites",
    primaryKey: "jobsiteId",
    keyName: "geofenceInfo",
    secondKeyName: "geoFenceInfo",
    radius: "locationRadius",
    addressKey: "jobAddress",
    lat: "addressPosition",
    lng: "addressPosition",
    accessTitle: "Project Cost",
  },
];

export const availableGeoFenceShapes = [
  { id: "0", type: "polygon" },
  { id: "1", type: "circle" },
  { id: "2", type: "square" },
];

export const filteredCategoriesWithGeofence = async (dataSourceName) => {
  const geofencesArray = [];

  const dynamicEndpointsKeys =
    dataSourceName === "projectGeofences"
      ? [categoriesWithGeofence[0]]
      : [categoriesWithGeofence[1]];

  await Promise.all(
    dynamicEndpointsKeys.map(
      async ({
        categoryName,
        categoryEndpoint,
        primaryKey,
        keyName,
        secondKeyName,
        addressKey,
        lat,
        lng,
        radius,
      }) => {
        try {
          const [res] = await Promise.all([
            fetchAllData(categoryEndpoint, categoryEndpoint, primaryKey),
          ]);

          const modifiedRecordsWithGeofence = await Promise.all(
            res
              .filter((res) => res[keyName]?.length > 0)
              .map(async (res) => {
                const polygonCoordinates = res[keyName]
                  .concat(res?.[secondKeyName])
                  .filter(Boolean);

                const info = await Promise.all(
                  polygonCoordinates.map(async (item) => ({
                    ...item,
                    geofencesQuantity: polygonCoordinates.length || 0,
                    // image: await generatePolygonImage({
                    //   polygonCoordinates: polygonCoordinates,
                    // }),
                  }))
                );

                return {
                  recordId: res[primaryKey],
                  isDrawedIn: categoryName,
                  recordAddress: res[addressKey],
                  geoFenceInfo: info,
                  radius: res?.[radius] ? parseInt(res?.[radius]) : null,
                  coordinates: {
                    lat: res[lat].lat || res[lat],
                    lng: res[lng].lng || res[lng],
                  },
                  accountName:
                    res?.accountName || res?.accountName?.accountName,
                };
              })
          );

          geofencesArray.push(...modifiedRecordsWithGeofence);
        } catch (err) {
          console.log({ err });
        }
      }
    )
  );

  return geofencesArray;
};
