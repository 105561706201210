import { useState } from "react";
import { message } from "antd";
import {
  handleEmailAttachments,
  handleGoogleDriveUpload,
} from "../../Projects/utils/email-attachments-helpers";
import { useEmailBoxContainer } from "../../Communication/components/EmailBox/providers/EmailBoxContainerProvider";
import { useResponsive } from "../../../../hooks";

const useHandleAttachments = ({
  base64,
  accessToken,
  category,
  driveRequest,
  rowObject,
  driveFunctions,
  folderDriveKeyName,
  dynamicFields,
  selectedTeam,
  crews,
  userConfiguration,
  defaultId,
  witnessCount,
  witnessSignings,
  rowObjectKey,
  pdfFileName,
}) => {
  const [emailBox, setEmailBox] = useState(false);
  const { openEmailBox, setAttachments, attachments, emailBoxes } =
    useEmailBoxContainer();
  const { mobile } = useResponsive();

  const handleAttachments = async (uploadedFiles) => {
    if (emailBoxes.length > 0) {
      message.warning("Email Box is already opened!");
      return;
    }

    message.loading({ content: "Preparing attachments...", duration: 0 });
    try {
      openEmailBox(null, mobile);
      if (uploadedFiles.length > 0) {
        await handleUploadedFiles(uploadedFiles);
      }

      await handleEmailAttachments({
        base64,
        accessToken,
        category,
        driveRequest,
        rowObject,
        driveFunctions,
        folderDriveKeyName,
        createPdfParams: {
          dynamicFields,
          selectedTeam,
          crews,
          userConfiguration,
          defaultId,
          witnessCount,
          witnessSignings,
        },
        setAttachments,
        rowObjectKey,
        pdfFileName,
      });
    } catch (error) {
      console.error("[Error while preparing attachments]", error);
      message.error("Could not attach the file to email");
    } finally {
      message.destroy();
    }
  };

  const handleUploadedFiles = async (files) => {
    let newAttachments = [];

    try {
      for (const file of files) {
        if (!file.id) continue;

        const handledFile = await handleGoogleDriveUpload(file, driveRequest);
        newAttachments.push(handledFile);
      }

      setAttachments((prevAttachments) => {
        const combined = [
          ...prevAttachments.filter(Boolean),
          ...newAttachments.filter(Boolean),
        ];

        return Array.from(new Set(combined));
      });
    } catch (error) {
      message.error("Error while getting the file from Google Drive");
      console.error("[Error while getting uploaded files]", error);
    }
  };

  return {
    handleAttachments,
    handleUploadedFiles,
    emailBox,
    attachments,
    setAttachments,
    setEmailBox,
  };
};

export default useHandleAttachments;
