import { useNavigate } from "react-router-dom";
import { PasswordConfirmation } from "../../../../../../../../containers/Signup/components";
import { InfoText } from "../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { RenderDynamicComponents } from "../../../../../../../Header/forms/Components";
import { AddUserIcon } from "../assets";
import { useEffect } from "react";
import {
  formSubmit,
  validateFields,
} from "../../../../../../../../containers/Signup/utils";
import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../../../../utils";
import { useSelector } from "react-redux";
import { useEditLogs } from "../../../../../../../../hooks";

function UserInformation({
  form,
  populatedFields,
  setUser,
  setStepsFormDisabled,
  stepsFormDisabled,
  setValidateStepMethod,
  index,
}) {
  const navigate = useNavigate();

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { saveAddedLogs } = useEditLogs();

  const formSubmitHandler = (onSuccess) => {
    showLoadingMsg({ content: "Creating new user. Please wait..." });
    formSubmit({ form, userConfiguration, saveAddedLogs })
      .then((result) => {
        setUser(result);
        setStepsFormDisabled((prev) => ({
          ...prev,
          steps: { ...prev?.steps, [index]: true },
        }));
        onSuccess();
        showSuccessMsg({ content: "New user created successfully!" });
      })
      .catch((error) => {
        if (error.name === "UsernameExistsException") {
          showErrorMsg({
            content:
              "An account with the given email already exists. Please use another email.",
          });
          return;
        }
        showErrorMsg({ content: "There was a problem creating user" });
        console.log({ error });
      });
  };

  function validateNewUser(onSuccess) {
    validateFields(
      form,
      () => formSubmitHandler(onSuccess),
      Object.values(populatedFields)
        .flat()
        .map(({ formItemName }) => formItemName)
    );
  }

  useEffect(() => {
    if (stepsFormDisabled.steps?.[index]) {
      setValidateStepMethod("");
    } else {
      setValidateStepMethod(validateNewUser);
    }
  }, []);

  return (
    <>
      <div className={`create-user-details`}>
        <div className="create-user-form-header">
          <AddUserIcon />
          <h2>User Information</h2>
        </div>
        <div className="formInputs">
          {RenderDynamicComponents(populatedFields)?.filter(
            (item) => Number(item.key) < 6
          )}
        </div>
      </div>
      <InfoText
        {...{
          text: (
            <span>
              If you forgot to create a specific department or role, please go
              to{" "}
              <span
                className="roles-access-link"
                onClick={() => {
                  navigate("/settings/rolesAccess");
                }}
              >
                Roles Access
              </span>
            </span>
          ),
        }}
      />

      <PasswordConfirmation
        {...{
          form,
          populatedFields,
          disabled: stepsFormDisabled?.steps?.[index],
        }}
      />
    </>
  );
}

export default UserInformation;
