import React, { createContext, useContext, useState } from "react";

const AttachmentsContext = createContext();

// Custom hook to use the AttachmentsContext, allowing easy access to attachment-related functionality
export const useAttachments = () => {
  return useContext(AttachmentsContext);
};

// Provider component for wrapping parts of the app that need access to the attachments state
export const AttachmentsProvider = ({ children }) => {
  const [attachments, setAttachments] = useState([]);

  const addFile = (file) => {
    setAttachments([...attachments, file]);
  };

  const removeFile = (fileIndex) => {
    const newFiles = [...attachments];
    newFiles.splice(fileIndex, 1);
    setAttachments(newFiles);
  };

  const clearFiles = () => {
    setAttachments([]);
  };

  return (
    <AttachmentsContext.Provider
      value={{
        attachments,
        setAttachments,
        addFile,
        removeFile,
        clearFiles,
      }}
    >
      {children}
    </AttachmentsContext.Provider>
  );
};
