import { Fragment, memo, useMemo } from "react";
import {
  BorderedTextCard,
  InputComponent,
  LabeledInput,
} from "../../Fleet/components";
import { RenderDynamicComponents } from "../../../Header/forms/Components";
import RichTextEditor from "../../../commonComponents/RichTextEditor/RichTextEditor";
import IncidentAssignedTo from "./IncidentAssignedTo";
import SafetyTeamSelect from "../../Safety/SafetyModal/formData/Components/SafetyTeamSelect";
import StatementsFields from "../../Safety/SafetyModal/components/StatementsFields";
import IncidentComments from "./IncidentComments";
import SafetyUploaderSection from "../../Safety/SafetyModal/components/SafetyUploaderSection";
import Signature from "../../../commonComponents/Signature/Signature";

const IncidentFormSection = ({
  form,
  sign,
  teams = [],
  nameKey = "",
  rowObject = {},
  formFields = [],
  accessToken = "",
  witnessCount = 0,
  selectedTeam = [],
  isDarkMode = false,
  uploadedFiles = [],
  driveFunctions = {},
  onDelete = () => {},
  divClickable = false,
  setSign = () => {},
  witnessSignings = [],
  setChanges = () => {},
  userConfiguration = {},
  folderDriveKeyName = "",
  setEditSignIn = () => {},
  refreshTable = () => {},
  onTeamSelect = () => {},
  setAssignedTo = () => {},
  onTeamDeselect = () => {},
  disabledAllFields = false,
  onPickerSuccess = () => {},
  setWitnessCount = () => {},
  setWitnessSignings = () => {},
  setTeamModalVisible = () => {},
  setWhichSignToChange = () => {},
  setCancelModalVisible = () => {},
  setSignatureModalVisible = () => {},
  driveFolder,
  hasMissingFolders,
  setAddDriveModal = () => {},
  setDocumentationModalFiles,
  safetyDirectorSignature = null,
  handleOnFileUploadSuccess,
}) => {
  const dynamicFields = useMemo(() => {
    let tmp = formFields || [];
    if (tmp.some(({ fields }) => !!fields[0]?.catIndex)) {
      tmp = tmp.sort((a, b) => a.fields[0].catIndex - b.fields[0].catIndex);
    }
    return tmp || [];
  }, [formFields]);
  return (
    <Fragment>
      <div className="form-section">
        {dynamicFields?.map(({ title, fields }) => {
          let sortedFields = fields;
          if (sortedFields.some((el) => !!el?.index)) {
            sortedFields = sortedFields.sort((a, b) => a.index - b.index);
          }

          let inputFields = sortedFields?.filter(
            (el) => el?.type !== "textEditor"
          );

          let textEditorFields = sortedFields?.filter(
            (el) => el?.type === "textEditor"
          );

          const tmpFieldCondition = fields?.some((el) =>
            el?.formItemName?.toLowerCase()?.includes("preparedon")
          );

          return (
            <BorderedTextCard
              {...{
                title,
                key: title,
                isDarkMode,
              }}
            >
              {RenderDynamicComponents(inputFields, { form })}

              {!!textEditorFields?.length &&
                textEditorFields?.map((el) => (
                  <LabeledInput
                    {...{
                      required: el?.required,
                      style: { width: "100%" },
                      label: el?.label,
                      disabled: disabledAllFields,
                      formItemName: el?.formItemName,
                      form,
                    }}
                  >
                    <RichTextEditor
                      {...{
                        form,
                        isDarkMode,
                        formItemName: el.formItemName,
                        disabled: disabledAllFields,
                      }}
                    />
                  </LabeledInput>
                ))}

              {(title?.toLowerCase() === "additional information" ||
                tmpFieldCondition) && (
                <Fragment>
                  <IncidentAssignedTo
                    {...{
                      isDarkMode,
                      userConfiguration,
                      setAssignedTo,
                      disabledAllFields,
                    }}
                  />
                  <div className="signings">
                    <Signature
                      {...{
                        signature: sign,
                        setSignature: setSign,
                        signDisabled: disabledAllFields,
                        removeDisabled: disabledAllFields,
                      }}
                    />
                  </div>
                </Fragment>
              )}
              {title?.toLowerCase() === "general information" && (
                <SafetyTeamSelect
                  {...{
                    teams,
                    selectedTeam,
                    isDarkMode,
                    onTeamSelect,
                    disabledAllFields,
                    onTeamDeselect,
                    setTeamModalVisible,
                  }}
                />
              )}
            </BorderedTextCard>
          );
        })}

        <StatementsFields
          {...{
            form,
            fieldCount: witnessCount,
            setFieldCount: setWitnessCount,
            dynamicSignings: witnessSignings,
            setDynamicSignings: setWitnessSignings,
            isDarkMode,
            disableFields: disabledAllFields,
            rowObject,
          }}
        />
        <BorderedTextCard
          title="Safety Director Signature"
          className=""
          isDarkMode={isDarkMode}
        >
          <InputComponent
            {...{
              label: "Safety Director",
              initialValue: safetyDirectorSignature?.nameOfUser,
              disabled: true,
              noFormItem: true,
            }}
          />
          <Signature
            {...{
              signature: safetyDirectorSignature?.src,
              removeDisabled: true,
              signDisabled: true,
            }}
          />
        </BorderedTextCard>
        <BorderedTextCard
          title="Comments"
          className="notesCard"
          isDarkMode={isDarkMode}
        >
          <IncidentComments
            {...{
              form,
              nameKey,
              rowObject,
              setChanges,
              userConfiguration,
              refreshTable,
              table: "safety",
            }}
          />
        </BorderedTextCard>
      </div>
      <SafetyUploaderSection
        {...{
          accessToken,
          rowObject,
          uploadedFiles,
          driveFunctions,
          onDelete,
          divClickable,
          setChanges,
          folderDriveKeyName,
          disabledAllFields,
          onPickerSuccess,
          setCancelModalVisible,
          objectKey: "incidentObject",
          driveFolder,
          hasMissingFolders,
          setAddDriveModal,
          setDocumentationModalFiles,
          isCreateDocumentEnabled: true,
          handleOnFileUploadSuccess,
        }}
      />
    </Fragment>
  );
};

export default memo(IncidentFormSection);
