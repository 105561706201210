import { useState, useEffect, useMemo } from "react";
import { LoadableComp } from "../XComponents";
import BasePage from "../BasePage";
import { columnDefs } from "./AgGridData/columnDefs";
import NewToDo from "./forms/NewToDo";
import { useSelector } from "react-redux";
import { filterTables, getFiltersObject, getPanelObject } from "../../../utils";
import { chartingOptions } from "./AgGridData/chartingOptions";
import { useQuery } from "src/hooks";
import { API } from "aws-amplify";
import PreTodoModal from "./forms/PreTodoModal";
import { useLocation, useNavigate } from "react-router-dom";
import { chartConfigs, getChartingOptions } from "./utils/getChartingOptions";
import { getModifiedChartData } from "../../pages/Settings/settingsComponents/RentalPaymentTermsSettings/components/RentalPaymentTermsSettings/utils/getModifiedChartData";
import SettingsToDoModal from "./SettingsToDoModal/SettingsToDoModal";

/**
 *
 * @param {String} recordId - the id of the record we are viewing the to do's for
 * @param {Boolean} isTab - whether the BasePage is a tab or not
 * @param {Array} propRowData - the data of the to do's it is not necessary to pass this prop as the ToDoBasePage component will fetch the data if it is not passed based on the recordId
 * @param {Function} onDeleteToDo - call back function that is executed when we delete a to do
 * @param {Function} onAddTodo - call back function that is executed when we add a to do
 * @param {Function} onEditTodo - call back function that is executed when we edit a to do
 * @param {String} recordName - the name of the record we are viewing the to do's for
 * @returns
 */
const ToDoBasePage = ({
  recordId = null,
  selectedCategory = null,
  isTab = false,
  propRowData = [],
  onDeleteToDo = () => {},
  onAddTodo = () => {},
  onEditTodo = () => {},
  recordName,
  hideCreateTaskModal = false,
  // redirectId = null,
}) => {
  const queryParam = useQuery();
  const location = useLocation();

  const [{ userConfiguration }, { programFields }, { isDarkMode }] =
    useSelector((state) => [
      state.userConfig,
      state.programFields,
      state.darkMode,
    ]);

  const [visible, setVisible] = useState(false);
  const [rowObject, setRowObject] = useState();
  const [rowData, setRowData] = useState([]);
  const [openClone, setOpenClone] = useState(false);
  const [preTodo, setPreTodo] = useState(false);
  const [settingsToDo, setSettingsToDo] = useState(false);
  const [clonedTodo, setClonedTodo] = useState(); //save current ToDo on edit to clone it

  const panelObject = getPanelObject(programFields, "To Do");
  const filtersObject = getFiltersObject(programFields, "To Do");

  const selectedId = queryParam.get("selectedId");

  const [panelFilter, setPanelFilter] = useState({
    status: panelObject?.Status?.label,
    checked: false,
    visible: true,
  });

  const navigate = useNavigate();

  const fromSettings = window.location.pathname.split("/")[1] === "settings";

  useEffect(() => {
    !!clonedTodo && setOpenClone(true);
  }, [clonedTodo]);

  // visible todo from clicking todo in email
  useEffect(() => {
    if (!!selectedId && !!propRowData.length) {
      setVisible(true);
      setRowObject(propRowData.find(({ todoId }) => todoId === selectedId));
    }
  }, [selectedId, propRowData.length]);

  useEffect(() => {
    if (window.location.pathname.split("/")?.[1] === "todos" || "settings") {
      if (userConfiguration?.groupName === "Admin") {
        API.get("todos", "/todos")
          ?.then((data) => {
            return setRowData(data);
          })
          .catch((error) => {
            console.error("Error getting To Dos", error);
          });
      } else {
        filterTables("todos", "cognitoUserId", userConfiguration?.cognitoUserId)
          ?.then((data) => {
            return setRowData(data);
          })
          ?.catch((error) => {
            console.error("Error getting To Dos", error);
          });
      }
    } else if (!!recordId && !propRowData?.length) {
      filterTables("todos", "todoRecordId", recordId)
        ?.then((data) => {
          if (data) {
            let filteredData = data?.filter((el) => {
              if (userConfiguration?.groupName === "Admin") {
                return true;
              } else {
                return el?.cognitoUserId === userConfiguration?.cognitoUserId;
              }
            });
            return setRowData(filteredData);
          }
        })
        ?.catch((error) => {
          console.error("Error getting To Dos", error);
          return setRowData((prev) => [...prev]);
        });
    } else {
      setRowData(propRowData);
    }
  }, [recordId, propRowData.length]);

  function onNewClick() {
    if (fromSettings && !recordId) {
      setSettingsToDo(true);
    } else if (!recordId) {
      setPreTodo(true);
    } else {
      setVisible(true);
    }
  }

  function onRowDoubleClicked(param) {
    !fromSettings &&
      window.history.pushState(
        null,
        "",
        `${location.pathname}/${param.todoId}`
      );
    setRowObject(param);
    setVisible(true);
  }

  const columnDefsMemo = useMemo(() => {
    return columnDefs(userConfiguration, programFields, navigate);
  }, []);

  const replaceLocation = () => {
    // const uidRegex =
    //   /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;

    // let locArray = Array.from(window.location.pathname.split("/")).filter(
    //   Boolean
    // );

    // let index = locArray.findIndex((e) => e.match(uidRegex));

    // let newLocation = locArray.slice(0, index).join("/");

    const segments = window.location.pathname.split("/");

    if (segments[0] === "") {
      segments.shift();
    }
    segments.pop();

    const newLocation = segments.join("/");

    window.history.replaceState(null, "", `/${newLocation}`);
  };

  const getData = () => {
    if (isTab && !!recordId) {
      return rowData.filter((item) => item.todoRecordId === recordId);
    }

    return fromSettings && window.location.pathname !== "/settings/allTodos"
      ? rowData.filter((item) =>
          recordId
            ? item.todoCategory === selectedCategory &&
              item.recordName === recordName
            : item.todoCategory === selectedCategory
        )
      : rowData.filter((item) =>
          fromSettings
            ? item.hasOwnProperty("fromSettings") && item.fromSettings === true
            : !item.hasOwnProperty("fromSettings") ||
              item.fromSettings === false
        );
  };

  const changePanels = (event) => {
    event
      ? setPanelFilter({
          status: panelObject?.Category.label,
          checked: true,
          visible: true,
        })
      : setPanelFilter({
          status: panelObject?.Status.label,
          checked: false,
          visible: true,
        });
  };

  return (
    <>
      <LoadableComp {...{ loading: !!!rowData }}>
        <BasePage
          {...{
            title: "To Do",
            filtersObject,
            panelObject: panelFilter.checked // used in switch status panel of base page
              ? panelObject?.Category
              : panelObject?.Status,
            rowData: getData(),
            columnDefs: columnDefsMemo,
            setRowData,
            chartingOptions,
            hasNew: !!userConfiguration?.routeConfig?.find(
              (el) => el?.title?.toLowerCase() === "to do"
            )?.write,
            customNew: onNewClick,
            paginationAutoPageSize: 9,
            reloadButton: false,
            onRowDoubleClicked,
            // : (data) => {
            //   setRowObject(data);
            //   setVisible(true);
            // },
            isTab,
            // paginationPageSize: findPaginationSize("agGridId", 40) || 11,
            getChartingOptions: (data) => getChartingOptions(data),
            getModifiedChartData: (data, startDate, endDate) =>
              getModifiedChartData(data, chartConfigs, startDate, endDate),
            panelFilter: panelFilter, // used in switch status panel of base page // no switch inside a DynamicView
            setPanelFilter: changePanels,
          }}
        />
      </LoadableComp>
      {!!visible && (
        <NewToDo
          {...{
            visible,
            setVisible: (val) => {
              setVisible(val);
              setRowObject();
              !fromSettings && replaceLocation();
            },
            rowObject,
            recordId,
            setRowData,
            setRowObject,
            onDeleteToDo,
            onAddTodo,
            onEditTodo,
            clonedTodo,
            setClonedTodo,
            recordName,
            hideCreateTaskModal,
            ...(fromSettings && {
              customLinkTo: window.location.pathname.slice(1),
            }),
            ...(selectedCategory && { category: selectedCategory }),
          }}
        />
      )}
      {!!openClone && (
        <NewToDo
          {...{
            cloneModal: true,
            visible: openClone,
            setVisible: (val) => setOpenClone(val),
            rowObject,
            recordId,
            setRowData,
            setRowObject,
            onDeleteToDo,
            onAddTodo,
            onEditTodo,
            clonedTodo,
            setClonedTodo,
            recordName,
            hideCreateTaskModal,
            ...(fromSettings && {
              customLinkTo: window.location.pathname.slice(1),
            }),
            ...(selectedCategory && { category: selectedCategory }),
          }}
        />
      )}
      {!!preTodo && (
        <PreTodoModal
          {...{
            visible: preTodo,
            setVisible: setPreTodo,
            setRowData,
            selectedCategory,
          }}
        />
      )}
      {!!settingsToDo && (
        <SettingsToDoModal
          {...{
            visible: settingsToDo,
            setVisible: setSettingsToDo,
            setRowData,
            selectedCategory,
          }}
        />
      )}
    </>
  );
};

export default ToDoBasePage;
