import axios from "axios";

import { fingerCheckEndpoints } from "./fingercheckEndpoints";
import { EntryType } from "../../../../PayrollLive/payrollLiveTypes";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";

/**
 * @typedef ClientKey
 * @property {string} iv
 * @property {string} encryptedData
 */

const DATE_FORMAT = "YYYY-MM-DD";

export function getPayrollOnDateRange({
  dateRange,
  clientKey,
  employeeNumber,
}) {
  // const clientKey = clientCompany.toLowerCase().includes("yard")
  //   ? "YARD"
  //   : "GMNY";

  try {
    return axios.get(fingerCheckEndpoints.payroll, {
      params: {
        startDate: dayjsNY(dateRange[0]),
        endDate: dayjsNY(dateRange[1]),
        clientKey,
        employeeNumber,
      },
    });
  } catch (error) {
    return error;
  }
}

export function getPayrollHr({ dateRange, clientKey, employeeNumber }) {
  // const clientKey = clientCompany.toLowerCase().includes("yard")
  //   ? "YARD"
  //   : "GMNY";

  try {
    if (employeeNumber) {
      return axios.get(fingerCheckEndpoints.payrollHr, {
        params: {
          dateRange,
          employeeNumber,
          clientKey,
        },
      });
    } else {
      return axios.get(fingerCheckEndpoints.payrollHr, {
        params: {
          dateRange,
          clientKey,
        },
      });
    }
  } catch (error) {
    return error;
  }
}

/**
 * @param {ClientKey} clientCompany
 * @param {string} employeeNumber
 */
export async function getActiveEmployeesData(clientKey, employeeNumber) {
  try {
    if (employeeNumber) {
      return axios.get(fingerCheckEndpoints.employees, {
        params: {
          employeeNumber,
          clientKey,
        },
      });
    } else {
      return axios.get(fingerCheckEndpoints.employees, {
        params: {
          clientKey,
        },
      });
    }
  } catch (error) {
    return error;
  }
}

export function getEmployeesRateByDateRange({
  dateRange,
  clientKey,
  employeeNumber = null,
}) {
  const startDate = dayjsNY(dateRange[0]).format(DATE_FORMAT);
  const endDate = dayjsNY(dateRange[1]).format(DATE_FORMAT);

  try {
    return axios.get(fingerCheckEndpoints.employeeRates, {
      params: {
        startDate,
        endDate,
        clientKey,
        employeeNumber,
      },
    });
  } catch (error) {
    return error;
  }
}

/**
 * Get live punch status for employees excluding the employeeNumbers included in {excludedEmployees} list
 * @param {{excludedEmployees: Array<string>, selectedDate?: Date | string; clientKey: ClientKey}} props
 */
export function getPayrollLiveReport({
  excludedEmployees = [],
  selectedDate,
  clientKey,
}) {
  const date = selectedDate ?? dayjsNY().format(DATE_FORMAT);

  try {
    return axios.get(fingerCheckEndpoints.liveReport, {
      params: {
        date,
        clientKey,
        excludedEmployees,
      },
    });
  } catch (error) {
    return error;
  }
}

/**
 * Get live punch status for the employee with this employeeNumber in selected company {clientCompany}
 * @param {{employeeNumber: string; clientKey: ClientKey}} props
 */
export function getLiveEmployeeReport({ employeeNumber, clientKey }) {
  try {
    return axios.get(fingerCheckEndpoints.employeeReport, {
      params: {
        clientKey,
        employeeNumber,
      },
    });
  } catch (error) {
    return error;
  }
}

/**
 * Get List of latest entries for the selected Company
 * @param {{companyName: string; date?: string}} param
 * @returns {Array<EntryType>}
 */
export function getEmployeesLiveEntries({ companyName, date }) {
  return axios
    .get(fingerCheckEndpoints.getLiveEntries, {
      params: {
        date,
        companyName,
      },
    })
    .then((res) => res.data);
}
