import { API } from "aws-amplify";

/**
 * Function that filter table values against a list of matches
 * @param {string} tableName
 * @param {string} keyPath
 * @param {Array<any>} matchList
 */
async function fetchByList(tableName, keyPath, matchList) {
  let items = [];
  let ExclusiveStartKey = undefined;

  do {
    await API.post("fetchByList", "/fetchByList", {
      body: {
        tableName,
        keyPath,
        matchList,
      },
    }).then(({ items: responseItems, LastEvaluatedKey }) => {
      items = items.concat(responseItems);
      ExclusiveStartKey = LastEvaluatedKey;
    });
  } while (ExclusiveStartKey);

  return items;
}

export default fetchByList;
