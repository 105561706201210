import { driveApi } from "../../../../../../../integrations/DriveRequest";
import hearingPdfRowData from "../../../../../Claims/Hearings/Page/hearingPdfRowData";
import claimPdfRowData from "../../../../../Claims/Page/claimPdfRowData";
import { imageForPdf } from "../../../../safetyPdf";
import incidentReportWordData from "./incidentReportWordData";
import safetyInspectionWordData from "./safetyInspectionWordData";
import safetyReportWordData, { mapWordImages } from "./safetyReportWordData";
import vehicleDamageWordData from "./vehicleDamageWordData";

async function initialExportData({
  modalType,
  rowObject,
  accessToken,

  formFields,
  selectedTeam,
  safetyPdfBody,
  safetyCategory,
  witnessCount,
  witnessSignings,
  defaultId,
  userConfiguration,
  safetyFormData,
  rowObjectKey,

  currentProject = {},
}) {
  let formDataUsers = {};
  if (safetyFormData) {
    if (modalType === "safetyInspection") {
      formDataUsers = safetyFormData;
    }
    let foremen = [];
    let crewMembers = [];
    const crews = safetyFormData?.crews || [];
    crews.map(({ crewId, crewName, members, foreman }) => {
      if (foreman === true) {
        foremen.push({ label: crewName, value: crewId, members });
      } else {
        crewMembers.push({ label: crewName, value: crewId });
      }
    });

    formDataUsers = {
      foremen,
      crewMembers,
      drivers: safetyFormData?.drivers || [],
      fleet: safetyFormData?.fleet || [],
    };
  }

  const generateBody = () => {
    switch (modalType) {
      case "vehicleDamage":
        return vehicleDamageWordData({
          rowObject,
          dynamicDetails: formFields,
          uploads: false,
        });
      case "safety":
        return safetyReportWordData({
          formFields,
          selectedTeam,
          safetyPdfBody,
          safetyCategory,
          rowObject,
          witnessCount,
          witnessSignings,
          defaultId,
          formDataUsers,
          userConfiguration,
          accessToken,
          rowObjectKey,
        });
      case "incident":
        return incidentReportWordData({
          dynamicFields: formFields,
          selectedTeam,
          rowObject,
          formDataUsers,
          userConfiguration,
          defaultId,
          witnessCount,
          witnessSignings,
          rowObjectKey,
        });
      case "safetyInspection":
        return safetyInspectionWordData({
          accessToken,
          userConfiguration,
          rowObject,
          safetyCategory,
          selectedTeam,
          formDataUsers,
          dynamicFields: formFields,
        });
      case "claims":
        return [
          {
            title: "General Information",
            body: claimPdfRowData(rowObject),
          },
        ];
      case "hearings":
        return [
          {
            title: "General Information",
            body: hearingPdfRowData(rowObject),
          },
        ];
      default:
        return [];
    }
  };

  const generateNewRowObject = () => {
    switch (modalType) {
      case "vehicleDamage":
        return {
          ...rowObject,
          googleDriveUpload: rowObject?.googleDriveUpload?.flatMap(
            (el) => el?.files || []
          ),
        };
      case "claims":
        return {
          ...rowObject,
          googleDriveUpload: rowObject?.claimObject?.googleDriveUpload,
        };
      case "hearings":
        return {
          ...rowObject,
          googleDriveUpload: rowObject?.hearingObject?.googleDriveUpload,
        };
      case "safety":
        return rowObject;
      default:
        return rowObject;
    }
  };
  const projectData =
    Object.keys(currentProject)?.length > 0
      ? [
          {
            title: "projectData",
            body: currentProject,
            additionalData: true,
          },
        ]
      : [];
  const body = generateBody();
  const newRowObject = generateNewRowObject();
  const images = (await fetchPdfImages(newRowObject, accessToken)) || [];

  return body.concat([...images, ...projectData]);
}

export default initialExportData;
export async function fetchPdfImages(obj, accessToken) {
  const fetchImages = await imageForPdf({
    rowObject: obj,
    driveRequest: driveApi({ accessToken }),
  });
  const imgSection = mapWordImages(fetchImages) || [];
  if (imgSection?.flatMap((el) => el?.body)?.length) {
    return imgSection;
  }
  return [];
}
