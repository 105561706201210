import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/**
@param {String} categoryName - Name of category to find creation progress data
@param {String} actionType - Action Name example: "Create", "Update"
*/
export const useProgressComponent = ({
  categoryName,
  actionType: propActionType,
} = {}) => {
  const { programFields } = useSelector((state) => state.programFields);

  const [visibleCreationProgress, setVisibleCreationProgress] = useState(false);
  const [creationProgresses, setCreationProgresses] = useState([]);
  const [actionTypeState, setActionType] = useState();

  const actionType = actionTypeState || propActionType;

  const progressStepsConfigs = programFields?.find(
    ({ fieldName }) => fieldName === "Progress Steps Configuration"
  ).fieldOptions;

  useEffect(() => {
    returnInitialProgresses();
  }, [categoryName, propActionType]);

  const returnInitialProgresses = () => {
    setCreationProgresses(
      progressStepsConfigs?.[categoryName]?.[actionType] || []
    );
  };

  /**
  @param {Object} obj - Object with progressKey values
  @param {String} actionType - custom action type example in AutomationExecutions
  */
  const updateProgressStatus = (obj, action = actionType) => {
    setActionType(action);
    const progresses = progressStepsConfigs?.[categoryName]?.[action] || [];

    setCreationProgresses(progresses);

    const isAnyProgressUsed = progresses.some(({ progressKey }) =>
      obj.hasOwnProperty(progressKey)
    );

    isAnyProgressUsed &&
      setCreationProgresses(
        progresses?.map((progress) =>
          obj.hasOwnProperty(progress.progressKey)
            ? {
                ...progress,
                progressStatus: obj[progress.progressKey],
              }
            : progress
        )
      );
  };

  /**
  @param {Number} index - Index of progress that need to be deleted
  */
  const hideProgress = (progressKey, hide = true) => {
    setCreationProgresses((prev) =>
      prev.map((progress) =>
        progress.progressKey === progressKey ? { ...progress, hide } : progress
      )
    );
  };

  return {
    visibleCreationProgress,
    setVisibleCreationProgress: (data) => {
      if (!data) {
        returnInitialProgresses(); //if progress modal closed return initial values
      }
      setVisibleCreationProgress(data);
    },
    actionType,
    creationProgresses,
    setCreationProgresses,
    updateProgressStatus,
    hideProgress,
  };
};
