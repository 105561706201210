import { UserAddOutlined } from "@ant-design/icons";
import { InputComponent } from "../../../components/SidebarPages/Fleet/components";
import {
  HoverButton,
  MondayButton,
} from "../../../components/commonComponents";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import { getFieldsToUpdate } from "../../../components/pages/Settings/settingsComponents/TeamManagement/components/helpers/helperFunctions";
import RecordsToUpdate from "../../../components/pages/Settings/settingsComponents/TeamManagement/components/RecordsToUpdate/RecordsToUpdate";
import { message } from "antd";
import { LeftArrow } from "../../../components/SidebarPages/BasePage/src";

function NewUserTeamSelect({
  user,
  form,
  setOpenedCards,
  getRecordsToBePartOf,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [allTeams, setAllTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [loading, setLoading] = useState(false);
  const [recordsToUpdate, setRecordsToUpdate] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [changed, setChanged] = useState(false);
  const email = form.getFieldValue("username");

  useEffect(() => {
    async function fetchTeams() {
      await API.get("teams", "/teams")
        .then((r) => {
          setAllTeams(r);
        })
        .catch((error) => {
          message.error("Failed to fetch teams", 5);
          console.log({ error });
        });
    }
    fetchTeams();
  }, []);

  async function fetchFieldsToUpdate(type) {
    if (
      !!selectedTeam &&
      recordsToUpdate.length > 0 &&
      type === "select" &&
      !changed
    ) {
      setModalVisible(true);
      return;
    }

    setLoading(type);
    await getFieldsToUpdate([], selectedTeam, false, true).then((res) => {
      const filter = res.filter((el) => el?.fields?.length > 0);
      setRecordsToUpdate(filter);
      if (type === "all") {
        const resultObject = filter.reduce((acc, el) => {
          acc[el.topic] = el.fields;
          return acc;
        }, {});
        getRecordsToBePartOf(resultObject);
      } else if (type === "select") {
        setModalVisible(true);
      }
      setLoading(false);
      setChanged(false);
    });
  }

  return (
    <div className="NewUserTeamSelect flip-in-hor-bottom">
      <div className="NewUserTeamSelectHeader">
        <UserAddOutlined style={{ fontSize: "24px" }} />
        <h2>Team</h2>
        <p>
          Please select the team you want {user?.username || email} to be a part
          of.
        </p>
      </div>
      <div className="formInputs">
        <InputComponent
          {...{
            label: "Select Team",
            required: false,
            formItemName: "userTeam",
            placeholder: "Select Team...",
            dropdownClassName: isDarkMode ? "darkDropDown" : "",
            type: "select",
            customOptions:
              (allTeams || [])?.map((team) => ({
                label: team.teamName,
                value: team.teamId,
              })) || [],
            onSelect: (e) => {
              console.log("NewUserTeamSelect ~ e:", { e });
              const selectedTeam = allTeams.find((team) => team.teamId === e);
              setSelectedTeam(selectedTeam);
              setChanged(true);
            },
            noFormItem: true,
            onClear: () => setSelectedTeam(""),
          }}
        />
      </div>
      <div className="controls">
        <div>Give Access to: </div>
        <div className="controls-buttons">
          <HoverButton
            {...{
              text: "All Records",
              disabled: !selectedTeam,
              alwaysShowIcon: true,
              type: "action",
              loading: loading === "all",
              onClick: async () => {
                await fetchFieldsToUpdate("all");
              },
            }}
          />
          <HoverButton
            {...{
              disabled: !selectedTeam,
              text: "Select Records",
              alwaysShowIcon: true,
              type: "new",
              loading: loading === "select",
              onClick: async () => {
                await fetchFieldsToUpdate("select");
              },
            }}
          />
        </div>
        <HoverButton
          {...{
            text: "Go Back",
            type: "action",
            onClick: () => {
              setOpenedCards((prev) => ({
                ...prev,
                left: false,
                animate: true,
              }));
            },
            icon: <LeftArrow />,
            className: "backButtonTeam",
            alwaysShowIcon: true,
          }}
        />
      </div>
      {modalVisible && (
        <RecordsToUpdate
          {...{
            visible: modalVisible,
            setVisible: setModalVisible,
            recordsToUpdate,
            callBack: (selected) => {
              getRecordsToBePartOf(selected);
            },
          }}
        />
      )}
    </div>
  );
}

export default NewUserTeamSelect;
