import { Form, Select, message } from "antd";
import { useState } from "react";
import { driveApi } from "../../../../../../../../../integrations/DriveRequest";
import FoldersAccessDrawer from "./FoldersAccessDrawer";
import { getFolderAccess } from "../utils/FoldersAccessHelpers/getFolderAccess";
import { RedWarningModal } from "../../../../../../../../commonComponents";
import AccessRadioGroup from "../../AccessRadioGroup/AccessRadioGroup";

const GoogleDrivePermissions = ({
  form,
  gDriveCredentials,
  accessToken,
  currentUser,
  searchRouteConfigs = "",
  isDarkMode,
}) => {
  const [visible, setVisible] = useState(false);
  const [permissionList, setPermissionList] = useState({});
  const [loading, setLoading] = useState(false);
  const driveRequest = driveApi({ accessToken });
  const [warningModal, setWarningModal] = useState(false);

  const filteredCredentials = gDriveCredentials.filter((el) =>
    el.credentialKey.toLowerCase().includes(searchRouteConfigs.toLowerCase())
  );

  // className="user-access-list-body" id="configBody"
  return (
    <>
      <Form form={form} layout={"horizontal"}>
        {filteredCredentials?.map((credential, i) => (
          <AccessRadioGroup
            {...{
              title: credential.credentialKey,
              name: credential.credentialKey,
              onLabelClick: () => {
                !!currentUser && setWarningModal(credential);
              },
              isDarkMode,
            }}
          />
        ))}
      </Form>
      {visible && (
        <FoldersAccessDrawer
          {...{
            permissionList,
            setPermissionList,
            visible,
            setVisible,
            accessToken,
            driveRequest,
            loading,
            currentUser,
          }}
        />
      )}
      {!!warningModal && (
        <RedWarningModal
          {...{
            visible: !!warningModal,
            onCancel: () => setWarningModal(false),
            titleText: "Proceed with Data Loading?",
            children: (
              <span>
                Accessing detailed drive information <b>may take some time</b>.
                Do you wish to proceed? Please be patient as the system gathers
                and displays the necessary data.
              </span>
            ),
            footerProps: {
              onConfirm: () => {
                getFolderAccess(
                  warningModal.credentialValue,
                  currentUser.userName,
                  warningModal.credentialKey,
                  setLoading,
                  driveRequest
                )
                  .then((res) => {
                    setPermissionList(res);
                    setLoading(false);
                  })
                  .catch((err) => {
                    message.error(err);
                    setLoading(false);
                  });
                setVisible(true);
                setWarningModal(false);
              },
            },
          }}
        />
      )}
    </>
  );
};

export default GoogleDrivePermissions;
