import { camelCase } from "lodash";
import { attachDataSources, attachParameters } from ".";
import { dataSourceReportsFetch } from "../../../../utils";
import { driveApi } from "../../../../integrations/DriveRequest";

const formatDataSourceData = (accessToken) => {
  const driveRequest = driveApi({ accessToken });

  return {
    inspections: async (data = []) =>
      await Promise.all(
        data.map(async (inspection) => {
          const { inspectionReport = [] } = inspection;

          return {
            ...inspection,
            inspectionReport: await Promise.all(
              inspectionReport.map(async (service) => {
                const { questions = [] } = service;

                return {
                  ...service,
                  questions: await Promise.all(
                    questions.map(async (question) => {
                      const { fileIds = [] } = question;

                      return {
                        ...question,
                        images: await Promise.all(
                          fileIds.map(
                            async (id) =>
                              (
                                await driveRequest.getImageSrc(id)
                              ).src
                          )
                        ),
                      };
                    })
                  ),
                };
              })
            ),
          };
        })
      ),
    tasksManagement: (data = []) =>
      data?.flatMap(({ taskAssignedTo = [], ...record }) => {
        return taskAssignedTo?.map((user) => ({
          ...record,
          taskAssignedTo: user,
        }));
      }),
  };
};

export const populateReport = async (
  report,
  categoryParameters,
  accessToken,
  activeFilters = {},
  recordId,
  partitionKeys, // optional for custom data
  customData // optional
) => {
  const {
    datasources = [],
    reportParameters,
    reportObj,
    categoryName,
  } = report;

  const formatter = formatDataSourceData(accessToken);
  let completedDataSources = 0;
  const totalDataSources = datasources.length;

  const populatedDataSources = await Promise.all(
    datasources.map(async (name = "") => {
      const currentDataSourcePartitionKey = partitionKeys?.identityId;

      // filter object to filter the datasource { [keyToFilter]: valueToMatch}
      const currentDataSourceFilter = activeFilters?.[camelCase(name)] || {};

      //datasource response
      // const data = await fetchData(camelCase(name));
      //if custom data, do not fetch
      let data =
        customData ||
        (await dataSourceReportsFetch(
          {
            dataSourceName: camelCase(name),
            categoryName,
          },
          recordId
        ));
      completedDataSources++;

      if (window) {
        window.progressInterval &&
          window.progressInterval(completedDataSources, totalDataSources);
      }

      //formatter function (optional)
      const { [name.toLowerCase()]: dataFormatter } = formatter;

      //returns the fetched response if we don't have a formatter for this datasource
      const dataToUse = (await dataFormatter?.(data)) || data;
      //filters the datasource if we have a filter object or a partition key
      const filteredData =
        (Array.isArray(dataToUse) &&
          dataToUse?.filter((record) => {
            //if we don't have a value(active filters) returns "true", otherwise compares the values
            if (
              Object.entries(currentDataSourceFilter).every(
                ([key, value]) => !value || record[key] === value
              )
            ) {
              // if we have a recordId and a partition key
              if (Array.isArray(recordId)) {
                // if we want to get multiple records, filter rowdata with given array of ids
                return recordId.includes(record[currentDataSourcePartitionKey]);
              } else {
                if (currentDataSourcePartitionKey in record) {
                  return (
                    !recordId ||
                    !currentDataSourcePartitionKey ||
                    record[currentDataSourcePartitionKey] === recordId
                  );
                } else {
                  return true;
                }
              }
            } else {
              return false;
            }
          })) ||
        [];
      return {
        name,
        data: filteredData,
      };
    })
  );
  return attachParameters(
    attachDataSources(reportObj, populatedDataSources),
    reportParameters,
    categoryParameters
  );
};
