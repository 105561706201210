import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import {
  calCreditAmountForCharge,
  convertRequisitionIntoChargeItems,
} from "../utils";
import {
  generateServiceOptionsCurrentCharge,
  generateServiceOptionsNewCharge,
} from "./saveChargeHelpers";
import { exitIfInEditMode } from "../utils/exitIfEditMode";
import { useRedux } from "../../../../../../hooks/useRedux";
import { generateChargeItems } from "../utils/generateChargeItems";
import { calcTotalChargeAmount } from "../utils/calcTotalChargeAmount";
import { filterTables } from "../../../../../../../../utils/filterTables";
import { showLoadingMsg } from "../../../../../../../../utils/windowMessages";
import { sendNotification } from "../../../../../../../../utils/sendNotification";
import { extractNumberFromString } from "../utils/extractNumberFromString";
import { getChangedData } from "../../../../../../Accounting/components/utilities";
import { updateDocumentTitle } from "../../../../../../../../utils/updateDocumentTitle";
import { useProgressContext } from "../../../../../../../commonComponents/ProgressComponent/context/ProgressContext";
import { formatAccumulatedRentals } from "../utils/formatAccumulatedRentals";
import { groupServicesByEstimationId } from "../../ChooseChargeFromModal/utils/groupServicesByEstimationId";
import { useEditLogs } from "../../../../../../../../hooks";

export const useCreateCharge = ({
  project,
  selectedData,
  getCreatedCharge = (currentCharge) => {},
  setCurrentCharge = (boolean) => {},
  applicationsProp,
  udpateSingleRowElement = (chargeId, currentCharge) => {},
  nextStepHandler = () => {},
  closeModal = () => {},
  getBodyToSaveInAutomation,
  handleSavedRecord = (result) => {},
}) => {
  const [objectData, setVisible] = useRedux("chargeItemsModal");
  const [, setIsWritable] = useRedux("chargesIsWritable");
  const [creditsMemo, setCreditsMemo] = useRedux("creditsMemo");
  const [chargesRowData, setChargesRowData] = useRedux("chargesRowData");
  const [, setChargeFromVisible] = useRedux("newChargeVisible");
  const [, setChargeToView] = useRedux("chargeToView");

  const categoryFrom = objectData?.categoryFrom;
  const selectedGroup = objectData?.selectedGroup;
  const whereToFind = objectData?.whereToFind;
  const selectedCatFrom = objectData?.selectedCatFrom;
  const currentCharge = objectData?.currentCharge;

  const [applications, setApplications] = useState(applicationsProp);
  const [chargeToSave, setChargeToSave] = useState(
    currentCharge?.chargeItems
      ? { chargeItems: currentCharge?.chargeItems }
      : {}
  );
  const [initialCharge, setInitialCharge] = useState();
  const [selectedServices, setSelectedServices] = useState([]);
  const [includeRentals, setIncludeRentals] = useState(true);

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const authUser = useSelector(
    (state) => state.authenticatedUser.authenticatedUser
  );

  const navigate = useNavigate();
  const location = useLocation();

  const { hideProgress, updateProgressStatus, setVisibleCreationProgress } =
    useProgressContext();
  const { saveAddedLogs } = useEditLogs();

  const removePreviousDataOnDestroy = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";

    if (currentCharge)
      exitIfInEditMode(location, objectData, currentCharge, navigate);
    setChargeToSave({});
    setVisible(false);
    setChargeFromVisible(false);
    setChargeToView(false);
    setCurrentCharge(false);
    setIsWritable(false);
    closeModal();
  };

  async function fetchNewChargeData() {
    if (objectData) {
      let newApplications = applicationsProp;
      if (categoryFrom === "Requisition" && !(applicationsProp?.length > 0)) {
        const res = await filterTables(
          "applications",
          "projectId",
          (currentCharge || project).projectId
        );
        const includedRentals = await filterTables(
          "includedRentals",
          "projectId",
          (currentCharge || project).projectId
        );

        const projectApplications = res.map((app) => ({
          ...app,
          includedRentals: includedRentals.filter(
            (rental) => rental.applicationId === app.applicationId
          ),
        }));

        setApplications(projectApplications);
        newApplications = projectApplications;
      }

      if (whereToFind?.services || whereToFind?.invoiceItems) {
        let services =
          (categoryFrom === "Estimation"
            ? whereToFind?.services[selectedGroup].filter(
                (service) =>
                  service.serviceOptions?.[0]?.length > 0 ||
                  service?.serviceAddons?.length > 0
              )
            : whereToFind?.services) || [];

        if (categoryFrom === "Rental") {
          services = services
            .map((service) => ({
              ...service,
              serviceOptions: service.serviceOptions
                .map((option) => ({
                  ...option,
                  items: option.items.map((item) => ({
                    ...item,
                    isTaxable: item?.taxRate || selectedData?.taxRate > 0,
                  })),
                }))
                .map((option) => ({
                  ...option,
                  items: option.items.filter((item) => item.applied === true),
                }))
                .filter((option) => option.items.length > 0),
            }))
            .filter((newServices) => newServices.serviceOptions.length > 0);
        }

        if (categoryFrom === "Schedule Of Value") {
          services = services.filter((service) => {
            if (service.label === "Hoist" || service.isHoist) {
              return service.serviceOptions?.map((serviceOption) =>
                serviceOption.map((option) => option.amounts?.length > 0)
              );
            } else return service.amounts?.length > 0;
          });
        }

        if (!Array.isArray(services)) return;

        const updatedServices = structuredClone(services);
        setChargeToSave(currentCharge || { chargeItems: updatedServices });

        setInitialCharge({
          services: updatedServices,
          projectTaxRate: whereToFind?.projectTaxRate,
        });

        const initialSelectedServices = groupServicesByEstimationId(
          updatedServices
        ).map((estimation) => ({
          ...estimation,
          isSelected: true,
          services: updatedServices?.reduce(
            (obj, { label }) => ({ ...obj, [label]: true }),
            {}
          ),
        }));

        setSelectedServices(initialSelectedServices);
        //Auto select PLIs
        if (
          whereToFind &&
          categoryFrom === "Requisition" &&
          objectData.type === "Charge" &&
          !!!currentCharge
        ) {
          setChargeToSave({
            chargeItems: convertRequisitionIntoChargeItems(
              whereToFind,
              newApplications
            ),
          });
        }
      }
    }
  }

  const saveCharge = async () => {
    /**
     * calculate charge Amount
     */
    let chargeAmount = calcTotalChargeAmount(
      chargeToSave,
      categoryFrom,
      whereToFind
    );

    if (
      chargeToSave?.chargeItems?.length === 0 &&
      !whereToFind?.accumulatedRentals
    ) {
      showLoadingMsg({
        content:
          "No items were selected! Please select the items that need to be charged.",
      });
      throw new Error();
    }
    if (chargeAmount === 0) {
      showLoadingMsg({
        content:
          "You can not create a charge with negative value. Please check the balance before continue.",
      });
      throw new Error();
    }

    const creditAmount = calCreditAmountForCharge(chargeToSave?.chargeItems);
    const chargeItems = generateChargeItems(chargeToSave, categoryFrom) || [];
    const creditChargeAmount = chargeItems?.reduce(
      (acc, { creditAmount }) => (acc += creditAmount || 0),
      0
    );
    const totalThisPeriod = chargeItems?.reduce(
      (acc, { totalThisPeriod }) => (acc += totalThisPeriod || 0),
      0
    );

    let objToPost = {
      chargeItems,
      chargeAmount,
      appliedAmount: creditAmount,
      creditAmount,
      taxAmount: chargeItems?.reduce(
        (acc, { taxAmount }) => (acc += taxAmount),
        0
      ),
      remainingAmount: 0,
      ...(whereToFind?.accumulatedRentals && {
        accumulatedRentals: formatAccumulatedRentals(
          whereToFind?.accumulatedRentals
        ),
      }),
      ...(currentCharge
        ? {}
        : {
            chargeType:
              objectData.type === "Charge" ? "Regular" : "Credit Memo",
            categoryFrom,
            categoryNumber: extractNumberFromString(selectedCatFrom),
            recordId: selectedGroup,
            projectId: project.projectId,
            projectAddress: project.projectName,
            ...(categoryFrom === "Requisition"
              ? {
                  periodTo: whereToFind?.periodTo,
                  totalThisPeriod,
                  creditChargeAmount,
                }
              : {}),
            reqCreateAt: categoryFrom?.createdFrom,
          }),
    };

    if (!!getBodyToSaveInAutomation) {
      getBodyToSaveInAutomation({ bodyForApi: objToPost });
    } else {
      hideProgress("sendingNotification");
      setVisibleCreationProgress(true);
      updateProgressStatus({ updatingRecord: "executing" });
      await API[currentCharge ? "put" : "post"](
        "charges",
        `/charges${currentCharge ? `/${currentCharge?.chargeId}` : ""}`,
        { body: objToPost }
      )
        .then(async (res) => {
          setVisibleCreationProgress(currentCharge ? currentCharge : res);
          handleSavedRecord(res);
          const categoryToTableRel = {
            Requisition: "applications",
            Rental: "rentals",
            Estimation: "estimations",
            "Schedule Of Value": "scheduleOfValues",
          };
          const defaultParams = {
            recordId: selectedGroup,
            tableFrom: categoryToTableRel[categoryFrom],
            categoryFrom,
            chargeItems,
            whereToFind,
            selectedData,
            selectedGroup,
          };
          let newServiceOptions;
          if (!!!currentCharge) {
            newServiceOptions = generateServiceOptionsNewCharge({
              categoryFrom,
              result: res,
              defaultParams,
              whereToFind,
              selectedGroup,
            });
          } else {
            const updatedCharge = { ...currentCharge, ...objToPost };

            newServiceOptions = generateServiceOptionsCurrentCharge({
              categoryFrom,
              updatedCharge,
              defaultParams,
              whereToFind,
              selectedGroup,
              objToPost,
            });
          }
          selectedData = newServiceOptions;
          const { parent = {}, ...rest } = res;
          getCreatedCharge(
            currentCharge
              ? {
                  ...currentCharge,
                  chargeItems,
                  chargeAmount,
                }
              : rest
          );

          //Replaces the current charge with the new one
          if (objectData?.type === "Credit Memo") {
            setCreditsMemo([res, ...(creditsMemo || [])]);
          }
          if (currentCharge) {
            if (!!chargesRowData) {
              const rowIndex = chargesRowData.findIndex(
                ({ chargeId }) => chargeId === currentCharge.chargeId
              );
              setChargesRowData([
                ...chargesRowData.slice(0, rowIndex),
                {
                  ...currentCharge,
                  ...objToPost,
                },
                ...chargesRowData.slice(rowIndex + 1, chargesRowData?.length),
              ]);
            }
            udpateSingleRowElement(currentCharge.chargeId, {
              ...currentCharge,
              ...objToPost,
            });
          }
          setIsWritable(false);

          const changes = currentCharge && getChangedData(currentCharge, res);
          await saveAddedLogs({
            recordId: currentCharge?.chargeId || res?.chargeId,
            recordName: `Charge ${
              currentCharge?.chargeNumber || res?.chargeNumber
            }`,
            category: "Charges",
            ...(currentCharge
              ? {
                  actionType: "Edit",
                  currentData: changes?.curr,
                  previousData: changes?.prev,
                  topic: `${currentCharge?.projectAddress} - ${currentCharge?.categoryFrom} ${currentCharge?.categoryNumber}`,
                }
              : {}),
          }).catch((err) => console.error("Error creating logs:", err));

          const project = await filterTables(
            "projects",
            "projectId",
            currentCharge ? currentCharge.projectId : res.projectId
          );

          const account = await filterTables(
            "accounts",
            "accountId",
            project?.[0]?.accountId
          );
          if (account.length > 0) {
            hideProgress("sendingNotification", false);
            updateProgressStatus({
              updatingRecord: "finished",
              sendingNotification: "executing",
            });

            const hasPriceChanged =
              currentCharge?.chargeAmount !== chargeAmount;

            // await sendNotification({
            //   id: "97",
            //   action: currentCharge
            //     ? hasPriceChanged
            //       ? "onChargeEditPriceChange"
            //       : "onChargeEdit"
            //     : "onChargeCreation",
            //   team: account?.[0]?.teamsConfiguration || [],
            //   otherKeys: {
            //     topicName: account?.[0]?.accountName,
            //     chargeType: "Charge",
            //     categoryFrom: currentCharge
            //       ? currentCharge?.categoryFrom
            //       : res?.categoryFrom,
            //     ...(currentCharge && {
            //       chargeNumber: currentCharge.chargeNumber,
            //     }),
            //   },
            //   recordId: currentCharge ? chargeToSave.chargeId : res.chargeId,
            //   authenticatedUser: authUser,
            //   userConfiguration,
            // }).then((notificationSent) => {
            //   updateProgressStatus({
            //     sendingNotification: !!notificationSent
            //       ? "finished"
            //       : "hasError",
            //   });
            // });
          } else {
            updateProgressStatus({ updatingRecord: "finished" });
          }
          //   removePreviousDataOnDestroy(); //Closes Modal
          //   nextStepHandler(); // Opens Next Step
        })
        .catch((e) => {
          updateProgressStatus({ updatingRecord: "hasError" });
          console.error("Error Putting Element: ", e);
          //   removePreviousDataOnDestroy();
        });
    }
  };

  useEffect(() => {
    fetchNewChargeData();
  }, [objectData]);

  return {
    project,
    selectedData,
    applications,
    chargeToSave,
    initialCharge,
    selectedServices,
    currentCharge,
    setChargeToSave,
    closeModal,
    saveCharge,
    removePreviousDataOnDestroy,
    nextStepHandler,
    handleSavedRecord,
    setSelectedServices,
    includeRentals,
    setIncludeRentals,
  };
};
