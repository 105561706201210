import { groupBy } from "lodash";

// import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { EntryType } from "src/components/pages/PayrollLive/payrollLiveTypes";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

type Props = {
  entries: Array<EntryType>;
};

type GroupedEntries = { [index: string]: Array<EntryType> };

// const groupStatuses = ["noLunch", "incorrect", "hr"];
const shiftFormat = ["ID", "OL", "IL", "OD"];
const shiftNoLunch = ["ID", "OD"];

export function getShiftOrder(props: Props) {
  const { entries } = props;
  let reportsToReturn = [];
  const dateGroup: GroupedEntries = groupBy(entries, (emp: EntryType) =>
    parseInTz(emp?.punchDate).format("YYYY-MM-DD")
  );

  for (const date in dateGroup) {
    const dateEntries = dateGroup?.[date] || [];
    const groupedEmployees: GroupedEntries = groupBy(
      dateEntries,
      (emp: EntryType) => emp?.employeeId
    );
    for (const employeeId in groupedEmployees) {
      if (Object.prototype.hasOwnProperty.call(groupedEmployees, employeeId)) {
        const employeeEntries = groupedEmployees[employeeId].sort(
          (a, b) => a?.punchTimeStamp - b?.punchTimeStamp
        );
        // const odIndex = employeeEntries.findIndex(
        //   (el) => el?.punchType === "OD"
        // );
        // 1;

        // if (odIndex > -1 && employeeEntries.slice(odIndex + 1)?.length) {
        //   console.log("employeeEntries: ", employeeEntries);
        //   console.log(
        //     "employeeEntries sliced: ",
        //     employeeEntries.slice(odIndex + 1)
        //   );
        // }

        // let shiftGroupedEntries = [];
        // for (let i = 0; i < employeeEntries?.length; i++) {
        //   // const entry = employeeEntries[i];
        //   let odEntry = employeeEntries.findIndex(
        //     (el) => el.punchType === "OD"
        //   );
        //   const shiftSlice = [...employeeEntries].slice(i, odEntry);
        //   console.log("shiftSlice: ", shiftSlice);
        //   shiftGroupedEntries.push(shiftSlice);
        //   if (odEntry === employeeEntries?.length - 1) {
        //     break;
        //   } else if (odEntry > 0) {
        //     i += odEntry;
        //   }
        // }
        // console.log("shiftGroupedEntries: ", shiftGroupedEntries);

        let status = "";
        const entriesType = groupBy(employeeEntries, (el) => el?.punchType);
        const shiftPunchTypes = Object.keys(entriesType);
        let regularShift = false;
        let noLunch = false;
        for (const punchType in entriesType) {
          const typeEntires = entriesType[punchType];
          if (typeEntires?.length > 1) {
            status = "incorrect";
            break;
          }
          if (shiftFormat.includes(punchType)) {
            regularShift = true;
          } else {
            regularShift = false;
          }

          if (shiftNoLunch.includes(punchType)) {
            noLunch = true;
          } else {
            noLunch = false;
          }
        }

        if (noLunch && shiftPunchTypes?.length === 2) {
          status = "noLunch";
        }

        if (shiftPunchTypes?.length === 1 && shiftPunchTypes?.[0] === "HR") {
          status = "hr";
        } else if (shiftPunchTypes?.length == 1) {
          status = "incorrect";
        }

        if (shiftPunchTypes?.length === 4 && !regularShift) {
          status = "incorrect";
        }

        if (shiftPunchTypes?.length === 3) {
          status = "incorrect";
        }

        const empReport = {
          date,
          status,
          employeeId,
          crewId: employeeEntries?.[0]?.crewId,
          employeeName: employeeEntries?.[0]?.employeeFullName || "",
          ...entriesType,
        };
        reportsToReturn.push(empReport);
      }
    }
  }

  return reportsToReturn;
}
